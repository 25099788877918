import React from "react";

export const Documentation = (
  <div className="documentationBody">
    <p>
      This Integration will automatically create/suspend users in your G Suite
      account when employees are hired/terminated in OnePoint.
    </p>
    <p>
      In order to get started, a G Suite administrator with the permissions
      required to create and suspend users must connect above in the Connection
      Status section. This will redirect them to a dialogue where they grant
      Onepoint access to perform actions on their behalf. Once the administrator
      is connected, make sure the ON/OFF setting is set to Enabled in order for
      the integration to start working.
    </p>
    <p>
      If you wish to temporarily stop these automatic actions from occuring,
      select Disabled in the Integration Settings area.
    </p>
    <p>
      <b>Important</b>: under the New Email Address Settings, select the email
      address format that best suites your company. If you select an
      Autogenerate setting, you must enter the Domain Name that you want new
      users to be created under.
    </p>
    <p>
      If you choose an Autogenerate option and the process is successful, the
      new email address will be added as the Employee's Primary Email Address in
      OnePoint. If the Employee already had a Primary Email Address, it will be
      moved to Secondary.
    </p>
    <p>
      The new user{"'"}s password can be found in the Summary Email that is sent
      to the selected Employee in the User Provisioning Product Setting. The
      first time that the user logs in, they will be prompted to change their
      password.
    </p>
    <p>
      Upon termination in OnePoint, the user's account in G Suite will be
      Suspended. <b>Important</b>: Suspended users will be blocked from logging
      into their Google accounts, but your organization will still be charged
      for that account and their data will still remain availiable to your
      organization. We suspend users in case you wish to restore their account
      or salvage some of their data. You may Delete the G Suite user after they
      are suspended once you are ready for their data to be lost.
    </p>
    <p>
      We process new hires on the <b>Date Hired</b> in OnePoint, and we process
      terminations using the <b>Date Terminated</b>.
    </p>
    <div className="subHead">Common Error Causes</div>
    <ul>
      <li>
        Hiring/Terminating the employee with a Primary Email address which is
        not in a domain that is linked to your G Suite organization. G Suite
        will not allow you to create a User if it is not in a domain that you
        own.
      </li>
      <li>
        Signing into G Suite with a User that does not have admin permissions.
        The user must be allowed to create and update users. If not, this
        integration will fail.
      </li>
      <li>
        Terminating an employee while their Primary Email in OnePoint is
        different than their email address in G Suite. These must match for this
        integration to suspend the correct User account in G Suite.
      </li>
      <li>
        Hiring an employee while their Primary Email in OnePoint is already
        linked to a User account in G Suite. Two users cannot have the same
        email address, so this will result in a failure.
      </li>
    </ul>
  </div>
);
