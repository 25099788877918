import { normalize, schema } from "normalizr";
import { getToken } from "../actions/auth";

const callApi = (endpoint, schema, postdata) => {
  const method = postdata ? "POST" : "GET";

  let headers = { "Content-Type": "application/json" };

  const { token, userToken, sites } = getToken();

  if (
    endpoint === "api/add/quick-apply/sponsorship" ||
    endpoint === "api/tlm/upload/custom/report"
  ) {
    headers["Content-Type"] = "multipart/form-data";
  }

  if (endpoint === "api/get/comp/usr/write/permission" && sites) {
    headers["sites"] = sites;
  }

  if (token) {
    headers["Authorization"] = `${token}`;
  }

  if (userToken) {
    headers["OPUserToken"] = `${userToken}`;
  }

  let config = {
    method,
    headers,
    body: JSON.stringify(postdata),
  };

  return fetch("/" + endpoint, config).then((response) =>
    response.json().then((json) => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      if (!schema) {
        return Object.assign({}, json);
      }

      return Object.assign({}, normalize(json, schema));
    })
  );
};

const integrationSchema = new schema.Entity(
  "integrations",
  {},
  { idAttribute: "url" }
);
const integrationSchemaList = [integrationSchema];
const intResponseSchema = new schema.Object({
  integrations: integrationSchemaList,
});

const productSchema = new schema.Entity(
  "products",
  { integrations: integrationSchemaList },
  { idAttribute: "product_url" }
);

const productSchemaList = [productSchema];
const productsResponseSchema = new schema.Object({
  products: productSchemaList,
});

const eventsSchema = new schema.Entity("events", {}, { idAttribute: "url" });

const applicationSchema = new schema.Entity("applications", {}, {});
const jobSchema = new schema.Entity(
  "jobs",
  { CC_sponsorship: { applications: [applicationSchema] } },
  {}
);
const jobSchemaList = [jobSchema];

const wotcFormSchema = new schema.Entity("wotc_forms", {}, {});
const wotcApplicantSchema = new schema.Entity(
  "wotc_applicants",
  { form: wotcFormSchema },
  {}
);
const wotcApplicantSchemaList = [wotcApplicantSchema];

const cpEnrollment = new schema.Entity("cp_enrollments", {}, {});
const cpEmployee = new schema.Entity(
  "cp_employees",
  { enrollments: [cpEnrollment] },
  {}
);
const cpInstall = new schema.Entity("cp_installations", {}, {});
const cpInstallList = [cpInstall];
const cpEmployeeList = [cpEmployee];
const cpAllDataResponseSchema = new schema.Object({
  installations: cpInstallList,
  employees: cpEmployeeList,
});

const ghapps = new schema.Entity("created_applicants", {}, {});
const ghappsList = [ghapps];
const ghResponse = new schema.Object({ created_applicants: ghappsList });

export const Schemas = {
  PRODUCTS_RESP: productsResponseSchema,
  PRODUCT_LIST: productSchemaList,
  INTEGRATIONS_RESP: intResponseSchema,
  INTEGRATION: integrationSchema,
  EVENT: eventsSchema,
  JOBS_LIST: jobSchemaList,
  JOB: jobSchema,
  JOB_APP: applicationSchema,
  WOTC_APPLICANT_LIST: wotcApplicantSchemaList,
  WOTC_APPLICANT: wotcApplicantSchema,
  CP_RESP: cpAllDataResponseSchema,
  CP_INSTALLS_RESP: cpInstallList,
  CP_INSTALL: cpInstall,
  CP_EMPS_RESP: cpEmployeeList,
  CP_ENROLLMENT: cpEnrollment,
  CP_EMPLOYEE: cpEmployee,
  GH_APPLICANTS_LIST: ghResponse,
};

export const CALL_API = "Call API";

export default (store) => (next) => (action) => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === "undefined") {
    return next(action);
  }

  let { endpoint, postdata } = callAPI;
  const { schema, types, passthru } = callAPI;

  if (typeof endpoint === "function") {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== "string") {
    throw new Error("Specify a string endpoint URL.");
  }
  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error("Expected an array of three action types.");
  }
  if (!types.every((type) => typeof type === "string")) {
    throw new Error("Expected action types to be strings.");
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType, passthru: passthru }));

  return callApi(endpoint, schema, postdata).then(
    (response) =>
      next(
        actionWith({
          response,
          type: successType,
          passthru,
          ...callAPI,
        })
      ),
    (error) =>
      next(
        actionWith({
          type: failureType,
          error: error.error || "An error occurred.",
        })
      )
  );
};
