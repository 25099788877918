import React, { Component } from "react";
import PropTypes from "prop-types";

import "../styles/BaseIntegration.css";
import { Documentation as GSuiteDoc } from "./documentation/gsuite";
import { Documentation as MicrosoftDoc } from "./documentation/microsoft365";
import { Documentation as SalesforceDoc } from "./documentation/salesforce";
import { Documentation as BoxDoc } from "./documentation/box";
import { Documentation as DropboxDoc } from "./documentation/dropbox";
import { Documentation as ADDoc } from "./documentation/activedirectory";
import { Documentation as zoomDoc } from "./documentation/zoom";
import { Documentation as x8Doc } from "./documentation/8x8";
import { Documentation as ateraDoc } from "./documentation/atera";
import { Documentation as OneLoginDoc } from "./documentation/OneLogin";
import { Documentation as HubSpotDoc } from "./documentation/hubspot";
import { Documentation as mineralDoc } from "./documentation/mineral";
import Loading from "./ui/Loading";
import HiredFiredOAuth from "./integration_types/HiredFiredOAuth";
import JobBoard from "./integration_types/JobBoard";
import CareerIntegration from "./integration_types/CareerIntegration";
import Zoom from "./integration_types/Zoom";
import AteraIntegration from "./integration_types/AteraIntegration";
import X8Integration from "./integration_types/8X8Integration";
import OneLoginIntegration from "./integration_types/OneLoginIntegration";
import BackgroundCheckIntegration from "./integration_types/BackgroundCheckIntegration";
import HubspotIntegration from "./integration_types/HubspotIntegratuin";
import MineralIntegration from "./integration_types/MineralIntegration";
import SlackIntegration from "./integration_types/SlackIntegration";

const docs = {
  gsuite: GSuiteDoc,
  microsoft365: MicrosoftDoc,
  salesforce: SalesforceDoc,
  box: BoxDoc,
  dropbox: DropboxDoc,
  activedirectory: ADDoc,
  "8x8": x8Doc,
  zoom: zoomDoc,
  atera: ateraDoc,
  onelogin: OneLoginDoc,
  hubspot: HubSpotDoc,
  mineral:mineralDoc
};

class BaseIntegration extends Component {
  static propTypes = {
    intsAreFetching: PropTypes.bool.isRequired,
    saveSettings: PropTypes.func.isRequired,
    disconnectIntegration: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {};
    this.findIntegration = this.findIntegration.bind(this);
  }

  findIntegration(topicURL) {
    const {
      topicData,
      saveSettings,
      intsAreDisconnecting,
      disconnectIntegration,
      settingsAreSaving,
      error,
    } = this.props;
console.log('topicData',topicURL);
    switch (topicURL) {
      case "gsuite":
      case "microsoft365":
      case "salesforce":
      case "box":
      case "dropbox":
      case "activedirectory":
        if (topicData === undefined) {
          return null;
        }
        return (
          <div>
            <HiredFiredOAuth
              data={topicData}
              intsAreDisconnecting={intsAreDisconnecting}
              saveSettings={(newSettings) =>
                saveSettings(newSettings, topicURL)
              }
              disconnectIntegration={() => disconnectIntegration(topicURL)}
              documentation={docs[topicURL]}
              settingsAreSaving={settingsAreSaving}
              error={error}
              topicURL={topicURL}
            />
          </div>
        );
      case "slack":
        if (topicData === undefined) {
          return null;
        }

        return (
          <SlackIntegration
            data={topicData}
            intsAreDisconnecting={intsAreDisconnecting}
            saveSettings={(newSettings) => saveSettings(newSettings, topicURL)}
            disconnectIntegration={() => disconnectIntegration(topicURL)}
            documentation={docs[topicURL]}
            settingsAreSaving={settingsAreSaving}
            error={error}
            topicURL={topicURL}
          />
        );
      case "mineral":
        if (topicData === undefined) {
          return null;
        }

        return (
          <MineralIntegration
            data={topicData}
            intsAreDisconnecting={intsAreDisconnecting}
            saveSettings={(newSettings) => saveSettings(newSettings, topicURL)}
            disconnectIntegration={() => disconnectIntegration(topicURL)}
            documentation={docs[topicURL]}
            settingsAreSaving={settingsAreSaving}
            error={error}
            topicURL={topicURL}
          />
        );
      case "hubspot":
        if (topicData === undefined) {
          return null;
        }

        return (
          <HubspotIntegration
            data={topicData}
            intsAreDisconnecting={intsAreDisconnecting}
            saveSettings={(newSettings) => saveSettings(newSettings, topicURL)}
            disconnectIntegration={() => disconnectIntegration(topicURL)}
            documentation={docs[topicURL]}
            settingsAreSaving={settingsAreSaving}
            error={error}
            topicURL={topicURL}
          />
        );
      case "bg_checks":
        if (topicData === undefined) {
          return null;
        }

        return (
          <BackgroundCheckIntegration
            data={topicData}
            saveSettings={(newSettings) => saveSettings(newSettings, topicURL)}
            settingsAreSaving={settingsAreSaving}
            error={error}
            topicURL={topicURL}
          />
        );
      case "indeed":
      case "ziprecruiter":
        if (topicData === undefined) {
          return null;
        }

        return (
          <JobBoard
            data={topicData}
            saveSettings={(newSettings) => saveSettings(newSettings, topicURL)}
            settingsAreSaving={settingsAreSaving}
            error={error}
            topicURL={topicURL}
          />
        );
      case "career":
        return (
          <CareerIntegration
            data={topicData}
            saveSettings={(newSettings) => saveSettings(newSettings, topicURL)}
            settingsAreSaving={settingsAreSaving}
            error={error}
            topicURL={topicURL}
          />
        );

      case "zoom":
        if (topicData === undefined) {
          return null;
        }
        return (
          <div>
            <Zoom
              data={topicData}
              intsAreDisconnecting={intsAreDisconnecting}
              saveSettings={(newSettings) =>
                saveSettings(newSettings, topicURL)
              }
              disconnectIntegration={() => disconnectIntegration(topicURL)}
              documentation={docs[topicURL]}
              settingsAreSaving={settingsAreSaving}
              error={error}
              topicURL={topicURL}
            />
          </div>
        );

      case "atera":
        if (topicData === undefined) {
          return null;
        }
        return (
          <div>
            <AteraIntegration
              data={topicData}
              intsAreDisconnecting={intsAreDisconnecting}
              saveSettings={(newSettings) =>
                saveSettings(newSettings, topicURL)
              }
              disconnectIntegration={() => disconnectIntegration(topicURL)}
              documentation={docs[topicURL]}
              settingsAreSaving={settingsAreSaving}
              error={error}
              topicURL={topicURL}
            />
          </div>
        );

      case "onelogin":
        if (topicData === undefined) {
          return null;
        }
        return (
          <div>
            <OneLoginIntegration
              data={topicData}
              intsAreDisconnecting={intsAreDisconnecting}
              saveSettings={(newSettings) =>
                saveSettings(newSettings, topicURL)
              }
              disconnectIntegration={() => disconnectIntegration(topicURL)}
              documentation={docs[topicURL]}
              settingsAreSaving={settingsAreSaving}
              error={error}
              topicURL={topicURL}
            />
          </div>
        );

      case "8x8":
        if (topicData === undefined) {
          return null;
        }
        return (
          <div>
            <X8Integration
              data={topicData}
              intsAreDisconnecting={intsAreDisconnecting}
              saveSettings={(newSettings) =>
                saveSettings(newSettings, topicURL)
              }
              disconnectIntegration={() => disconnectIntegration(topicURL)}
              documentation={docs[topicURL]}
              settingsAreSaving={settingsAreSaving}
              error={error}
              topicURL={topicURL}
            />
          </div>
        );
      default:
        return <div>Integration Not Found</div>;
    }
  }

  render() {
    const { match, intsAreFetching, topicData, history } = this.props;

    let backLinkText = "Back to list of Integrations";
    if (topicData && topicData.productURL === "jobs") {
      backLinkText = "Back to Job Board Integrations";
    }

    return (
      <div className="baseIntegration">
        <div className="breadcrumbs">
          <span onClick={history.goBack}>&larr; {backLinkText}</span>
        </div>
        {intsAreFetching ? (
          <Loading />
        ) : (
          this.findIntegration(match.params.topicURL)
        )}
      </div>
    );
  }
}

export default BaseIntegration;
