import {
  ADD_REPORT,
  IMPORT_COMPANY_SFTP_REQUEST,
  IMPORT_COMPANY_SFTP_SUCCESS,
  LOADING_REPORT,
  NEXT_PAGE_REPORT,
  PREV_PAGE_REPORT,
  SELECT_CHANGE_PAGE_REPORT,
} from "../actions/customReport";

const initialState = {
  tableHeaders: [],
  tableRow: [],
  tableRowCopy: [],
  loading: true,
  rowPerPage: 10,
  totalPage: 0,
  currentPage: 0,
  processPercentage: 0,
  reportCount: [],
  companyData: { Task: "Hours per Day" },
  companyDataMapping: {},
  csvReportSting: "",
  importLog: {
    loading: false,
  },
};

const customReport = (state = initialState, action) => {
  const {
    rowPerPage,
    currentPage,
    tableRowCopy,
    companyData,
    companyDataMapping,
    tableHeaders,
  } = state;

  switch (action.type) {
    case IMPORT_COMPANY_SFTP_REQUEST:
      return { ...state, importLog: { loading: true } };

    case IMPORT_COMPANY_SFTP_SUCCESS:
      return { ...state, importLog: { loading: false } };

    case SELECT_CHANGE_PAGE_REPORT:
      if (action.payload === "All") {
        return {
          ...state,
          rowPerPage: action.payload,
          tableRow: tableRowCopy,
          totalPage: 1,
          currentPage: 0,
        };
      }
      let val = parseInt(action.payload);
      return {
        ...state,
        rowPerPage: val,
        tableRow: tableRowCopy.slice(0, val),
        totalPage: Math.ceil(tableRowCopy.length / val - 1),
        currentPage: 0,
      };

    case PREV_PAGE_REPORT:
      const page = currentPage - 1;
      return {
        ...state,
        currentPage: page,
        tableRow: tableRowCopy.slice(
          page * rowPerPage,
          page * rowPerPage + rowPerPage
        ),
      };

    case NEXT_PAGE_REPORT:
      const start = (currentPage + 1) * rowPerPage;
      const end = (currentPage + 1) * rowPerPage + rowPerPage;

      return {
        ...state,
        currentPage: currentPage + 1,
        tableRow: tableRowCopy.slice(start, end),
      };

    case LOADING_REPORT:
      const loading = action.payload;
      return { ...state, loading };

    case ADD_REPORT:
      const {
        report,
        report_name,
        count,
        total_count,
        report_count,
        data,
        headers,
      } = action.payload;

      if (report === "data not found") {
        return {
          ...state,
          reportName: report_name,
          loading: false,
          processPercentage: parseInt(Math.round((count * 100) / total_count)),
          reportCount: report_count,
        };
      }

      // console.log("data", action.payload);
      // const { headers, result } = toJSON(report);

      companyDataMapping[count] = data.length;

      report_count.forEach(({ name }, index) => {
        if (companyDataMapping[index + 1]) {
          companyData[name] = companyDataMapping[index + 1];
        }
      });

      const totalPage = Math.ceil(data.length / rowPerPage - 1);

      return {
        ...state,
        tableHeaders: tableHeaders.length === 0 ? headers : tableHeaders,
        tableRow: data.slice(0, rowPerPage),
        tableRowCopy: [...state.tableRowCopy, ...data],
        reportName: report_name,
        loading: false,
        totalPage,
        processPercentage: parseInt(Math.round((count * 100) / total_count)),
        reportCount: report_count,
        companyData,
        companyDataMapping,
      };

    default:
      return { ...state, loading: false };
  }
};

export default customReport;
