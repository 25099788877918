import React, { Component } from "react";
import EmployeeChooser from "./EmployeeChooserSetting";
import { connect } from "react-redux";

import { createAccessControl } from "../../actions/products";
import { editAccessControl } from "../../actions/products";

const AccessControlLine = ({
  employeeName,
  active,
  accessType,
  toggleActive,
  changeAccessType,
}) => {
  return (
    <div
      className={
        active ? "AccessControlLine active" : "AccessControlLine inactive"
      }
    >
      <div className="top">
        <div className="employee">{employeeName}</div>
        <div className="actions">
          <div className="accessType">
            <button
              onClick={() => changeAccessType("R")}
              className={accessType === "R" ? "active" : ""}
            >
              READ
            </button>
            <button
              onClick={() => changeAccessType("W")}
              className={accessType === "W" ? "active" : ""}
            >
              WRITE
            </button>
          </div>
        </div>
      </div>
      <div className="bottom">
        <div className="active">
          {active ? (
            <button onClick={() => toggleActive(false)}>REMOVE ACCESS</button>
          ) : (
            <button onClick={() => toggleActive(true)}>REACTIVATE</button>
          )}
        </div>
      </div>
    </div>
  );
};

class AccessControl extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedEmployee: null, accessType: "W" };

    this.employeeSelected = this.employeeSelected.bind(this);
    this.submit = this.submit.bind(this);
    this.changeAccessType = this.changeAccessType.bind(this);
    this.toggleActive = this.toggleActive.bind(this);
  }

  employeeSelected(selectedEmployee) {
    if (selectedEmployee && selectedEmployee.length > 0) {
      this.setState({ selectedEmployee: selectedEmployee[0] });
    }
  }

  submit() {
    const { selectedEmployee, accessType } = this.state;
    const { companyProductId, dispatch } = this.props;

    const newAC = {
      company_product_id: companyProductId,
      employee_name: selectedEmployee.name,
      employee_op_id: selectedEmployee.id,
      access_type: accessType,
    };

    dispatch(createAccessControl(newAC));

    this.setState({ selectedEmployee: null });
  }

  toggleActive(ac, newActive) {
    if (ac.active === newActive) {
      return;
    }
    const { dispatch } = this.props;
    ac.active = newActive;
    dispatch(editAccessControl(ac.id, ac));
  }

  changeAccessType(ac, newAccessType) {
    if (ac.access_type === newAccessType) {
      return;
    }
    const { dispatch } = this.props;
    ac.access_type = newAccessType;
    dispatch(editAccessControl(ac.id, ac));
  }

  render() {
    const { selectedEmployee, accessType } = this.state;
    const { accessControls } = this.props;

    return (
      <div className="AccessControl">
        <div className="existingCont">
          {accessControls && accessControls.length > 0 ? (
            <div className="existing">
              {accessControls.map((ac) => {
                return (
                  <AccessControlLine
                    key={"edqdqwd" + ac.id}
                    employeeName={ac.employee_name}
                    active={ac.active}
                    accessType={ac.access_type}
                    toggleActive={(newActive) =>
                      this.toggleActive(ac, newActive)
                    }
                    changeAccessType={(newType) =>
                      this.changeAccessType(ac, newType)
                    }
                  />
                );
              })}
            </div>
          ) : (
            <div className="existingEmpty">
              No employees currently have access. Only Company Administrators
              can access this product.
            </div>
          )}
        </div>
        {selectedEmployee ? (
          <div className="addMore">
            <div>Confirm the choices below and then click Submit:</div>
            <div className="accessType" style={{ paddingLeft: "20px" }}>
              <div>
                <span style={{ marginRight: "10px" }}>Employee:</span>
                <span>{selectedEmployee.name}</span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span style={{ marginRight: "10px" }}>Access Type:</span>
                <button
                  onClick={() => this.setState({ accessType: "R" })}
                  className={accessType === "R" ? "active" : ""}
                >
                  READ
                </button>
                <button
                  onClick={() => this.setState({ accessType: "W" })}
                  className={accessType === "W" ? "active" : ""}
                >
                  WRITE
                </button>
              </div>
            </div>
            <div className="endButs">
              <button
                className="cancel"
                onClick={() => this.setState({ selectedEmployee: null })}
              >
                CLOSE
              </button>
              <button onClick={() => this.submit()}>SUBMIT</button>
            </div>
          </div>
        ) : (
          <div className="addMore">
            <EmployeeChooser
              title={""}
              tip={""}
              values={[]}
              onChange={(newEmployees) => this.employeeSelected(newEmployees)}
              dontAllowEmail={true}
              addLabel={"GIVE NEW USER ACCESS"}
            />
          </div>
        )}
      </div>
    );
  }
}

export default connect()(AccessControl);
