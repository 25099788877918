import React, { useEffect, useState } from "react";
import CompanyDetailsModal from "./CompanyDetailsModal";
import { withRouter } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";
import ReactPaginate from "react-paginate";

const CompanyList = (props) => {
  const [fetchCompany, companyLoading] = useFetch();
  const [companyData, setCompanyData] = useState([]);
  const [companyDataCopy, setCompanyDataCopy] = useState([]);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [activeKey, setActiveKey] = useState(null);

  const pageCount = Math.ceil(
    Array.isArray(companyData)
      ? companyData.length / rowPerPage
      : 0 / rowPerPage
  );

  const offset = currentPage * rowPerPage;
  const rowPerPageValue = [10, 25, 50, 100, 150, 200];

  const handleActiveKey = (key) => () => {
    console.log("handleActiveKey");
    setActiveKey(key);
  };

  const handleFetchCompany = async () => {
    try {
      const payload = {
        endPoint: "/api/all/companies",
        method: "get",
      };
      const res = await fetchCompany(payload);
      setCompanyData(res);
      setCompanyDataCopy(res);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSelectSort = (e) => {
    const { value } = e.target;
    setCurrentPage(0);
    setRowPerPage(Number(value));
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    const filterData = companyDataCopy.filter((item) => {
      return Object.values(item)
        .join("")
        .toLowerCase()
        .includes(value.toLowerCase());
    });

    setCompanyData(filterData);
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  useEffect(() => {
    if (companyData.length === 0) {
      handleFetchCompany();
    }
  }, []);

  return (
    <div>
      {/* <Route
        path={`${match.url}/:id`}
        component={(props) => <CompanyDetailsModal {...props} />}
      /> */}
      {activeKey && (
        <CompanyDetailsModal
          activeKey={activeKey}
          handleActiveKey={handleActiveKey}
        />
      )}
      <div className="company_system_table company-list">
        <div className="tbl_header">
          <div className="page_upper flex-filter">
            <div className="left_tsec flex-filter">
              <div className="row_filter">
                <label htmlFor="rows">
                  <b>Rows On Page:</b>
                </label>
                <select
                  id="rows"
                  onChange={handleSelectSort}
                  defaultValue={rowPerPage}
                >
                  {rowPerPageValue.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="show_filter flex-filter">
                <div>
                  <label htmlFor="rows">
                    <b>Showing:</b>
                  </label>
                  <span>
                    {offset}-{offset + rowPerPage} of
                    {Array.isArray(companyData) && companyData.length}
                  </span>
                </div>
                {/* <div className="arrow_box flex-filter">
                  <span className="arrow_bx">
                    <img src="/images/logo/left-arrow.svg" alt="left-arrow" />
                  </span>
                  <span>Page</span>
                  <input type="text" className="arrow_input" />
                  <span>of 36</span>
                  <span className="arrow_bx flex-filter">
                    <img src="/images/logo/right-arrow.svg" alt="right-arrow" />
                  </span>
                  <p className="refresh_data flex-filter">
                    <img
                      src="/images/logo/refresh.svg"
                      className="fresh_img"
                      alt="refresh"
                    />
                    Refresh Data
                  </p>
                </div> */}
              </div>
            </div>
            <div className="right_tsec flex-filter">
              {/* <p className="default_drop flex-filter">
                <img
                  src="/images/logo/full-screen.svg"
                  className="fresh_img"
                  alt="full-screen"
                />
                Full Screen
              </p>
              <p className="flex-filter">
                <select id="default" className="default_drop">
                  <option value="1">[Default]</option>
                </select>
              </p>

              <p className="flex-filter">
                <img
                  src="/images/logo/setting.svg"
                  className="drop_img"
                  alt="setting"
                />
                <select id="setting" className="default_drop">
                  <option value="Settings">Settings</option>
                </select>
              </p>
              <p className="flex-filter">
                <img
                  src="/images/logo/filter.svg"
                  className="drop_img"
                  alt="filter"
                />
                <select id="filter" className="default_drop">
                  <option value="Filter">Filter</option>
                </select>
              </p>
              <p className="flex-filter">
                <img
                  src="/images/logo/select.svg"
                  className="drop_img"
                  alt="select"
                />
                <select id="column" className="default_drop">
                  <option value="Select Columns">Select Columns</option>
                </select>
              </p> */}
              <div className="mt-2 mb-2">
                <input
                  type="text"
                  placeholder="Search"
                  value={searchValue}
                  onChange={handleSearchChange}
                />
              </div>
              {/* <p className="flex-filter">
                <img
                  src="/images/logo/export.svg"
                  className="drop_img"
                  alt="export"
                />
                <select id="export" className="default_drop">
                  <option value="Export">Export</option>
                </select>
              </p> */}
            </div>
          </div>
          {/* <div className="filter_down">
            <div className="date_filter">
              <span>
                <label htmlFor="range">
                  <b>Date Created:</b>
                </label>
                <select id="ranges">
                  <option value="Calender">Calender Range</option>
                </select>
              </span>
              <span>
                <select id="ranges">
                  <option value="all">All</option>
                </select>
              </span>
            </div>
            <div className="custom_filter">
              <label htmlFor="range">
                <b>Custom Filter:</b>
              </label>
              <button className="add_new">Add New</button>
            </div>
          </div> */}
        </div>
        {companyLoading ? (
          <Loading />
        ) : (
          <div className="tbl_container">
            <table>
              <thead>
                <tr>
                  <th>
                    <input type="checkbox" />
                  </th>
                  <th></th>
                  <th>Company:ShortName</th>
                  <th>Company:Name</th>
                  <th>Company:Type</th>
                  <th>Status</th>
                  {/* <th>HR Status</th>
                <th>PR Status</th>
                <th>Company:Created</th>
                <th>Emps In Payroll</th>
                <th>Company:Locked</th> */}
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <div>
                      <select id="short">
                        <option value="1">Starts With</option>
                        <option value=""></option>
                      </select>
                    </div>
                    <div>
                      <select id="short">
                        <option value=""></option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div>
                      <select id="company_name">
                        <option value="1">Starts With</option>
                      </select>
                    </div>
                    <div>
                      <select id="company_name">
                        <option value=""></option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div>
                      <select id="company_type">
                        <option value="=">=</option>
                      </select>
                    </div>
                    <div>
                      <select id="company_type">
                        <option value=""></option>
                      </select>
                    </div>
                  </td>
                  <td>
                    <div>
                      <select id="tlm_status">
                        <option value="=">=</option>
                      </select>
                    </div>
                    <div>
                      <select id="tlm_status">
                        <option value=""></option>
                      </select>
                    </div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {Array.isArray(companyData) &&
                  companyData
                    .slice(offset, offset + rowPerPage)
                    .map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="text-center">
                            <span>
                              <input type="checkbox" />
                            </span>
                          </td>
                          {/* <td className="text-center">
                        <span>
                          <img src="/images/logo/calender.svg" alt="calender" />
                        </span>
                      </td>
                      <td className="text-center">
                        <span>
                          <img src="/images/logo/users.svg" alt="users" />
                        </span>
                      </td>
                      <td className="text-center">
                        <span>
                          <img
                            src="/images/logo/user-setting.svg"
                            alt="user-setting"
                          />
                        </span>
                      </td> */}
                          <td className="text-center">
                            <span
                              onClick={handleActiveKey(item.token || item.key)}
                              className="cursor-pointer"
                            >
                              {/* <Link to={`/app/list-company/${index}`}> */}
                              <img
                                src="/images/logo/computer.svg"
                                alt="computer"
                              />
                              {/* </Link> */}
                            </span>
                          </td>
                          <td className="inf_td">
                            <span>
                              <img
                                src="/images/logo/information.svg"
                                alt="information"
                              />
                            </span>
                            {item.short_name}
                          </td>
                          <td>{item.name}</td>
                          <td>Client</td>
                          <td>{item.status === 0 ? "Active" : "Inactive"}</td>
                        </tr>
                      );
                    })}
              </tbody>
              {/* <tfoot>
              <tr>
                <td colSpan="14">
                  <b>Page Total</b>
                </td>
              </tr>
              <tr>
                <td colSpan="12"></td>
                <td colSpan="14">230</td>
              </tr>
            </tfoot> */}
            </table>
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              onPageChange={handlePageClick}
              pageCount={pageCount}
              previousLabel="Previous"
              renderOnZeroPageCount={null}
              containerClassName={"pagination-container"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"disabled"}
              activeClassName={"active"}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default withRouter(CompanyList);
