import React, { useEffect } from "react";
import useToggle from "../../hooks/useToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { connect } from "react-redux";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";
import Swal from "sweetalert2";
import { deleteImportOptionByIdSFTP } from "../../../actions/customReport";
import ImportBodyDataTable from "./ImportBodyDataTable";
import UserProvisioningSettings from "../settings/UserProvisioningSettings";

const ImportsBody = (props) => {
  const {
    dispatch,
    userInfo,
    companyProductId,
    settings,
    productsAreFetching,
    productsAreUpdating,
  } = props;
  const [settingsToggle, handleSettingsToggle] = useToggle(true);
  const [fetchData, loading, response] = useFetch();

  const handleDeleteImportLog = (id) => async () => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete",
      });
      if (result.isConfirmed) {
        await dispatch(deleteImportOptionByIdSFTP(id));
        fetchData();
        Swal.fire({
          title: "Deleted",
          text: "Your record has been deleted.",
          icon: "success",
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData({
      endPoint: `/api/tlm/all/imports/company/sftp`,
      method: "get",
    });
  }, []);

  return (
    <div>
      <div className="product-modal-content">
        <div className="product-modal-header">
          <h3>Imports</h3>

          <span onClick={handleSettingsToggle}>
            <FontAwesomeIcon icon={solid("gear")} />
          </span>
        </div>
        <div className="product-card-content-outer ">
          <div
            className={`Integration-outer ${
              !settingsToggle && "integration-active"
            }`}
          >
            {loading ? (
              <Loading />
            ) : Array.isArray(response) ? (
              <ImportBodyDataTable
                userInfo={userInfo}
                row={response}
                copyRow={response}
                handleDeleteImportLog={handleDeleteImportLog}
              />
            ) : null}
          </div>
          <div
            className={`product-setting-section ${
              settingsToggle ? "setting-show" : "setting-hide"
            }`}
          >
            <UserProvisioningSettings
              settings={settings}
              companyProductId={companyProductId}
              productsAreUpdating={productsAreUpdating}
              productsAreFetching={productsAreFetching}
            />
            {/* <h1 className="mb-0 p-0">Settings</h1>
            <AccessControl /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { products } = state.entities;
  const { isFetching: productsAreFetching, isUpdating: productsAreUpdating } =
    state.products;

  const productData = products["imports"];

  return {
    userInfo: state.products.userInfo,
    productsAreFetching,
    productsAreUpdating,
    settings: productData ? productData.settings : {},
    companyProductId:
      productData === undefined ? null : productData.company_product_id,
  };
};

export default connect(mapStateToProps)(ImportsBody);
