import React, { useEffect, useState } from "react";
import ProductModal from "../UI/ProductModal";
import { connect } from "react-redux";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";
import { productsAvailable } from "../../../constants/constant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const CompanyDetailsModal = (props) => {
  const { handleActiveKey, activeKey } = props;
  const [fetchProductByKey, fetchLoading] = useFetch();
  const [productData, setProductData] = useState(null);
  const productsAvailableCopy = { ...productsAvailable };

  const handleFetchProduct = async () => {
    const payload = {
      method: "get",
      endPoint: `/api/products`,
      headers: { Authorization: activeKey },
    };

    const res = await fetchProductByKey(payload);
    setProductData(res);
  };

  useEffect(() => {
    handleFetchProduct();
  }, []);

  return (
    <div>
      <ProductModal
        heading="Company details"
        closeModal={handleActiveKey(null)}
        // history={history}
      >
        {fetchLoading ? (
          <Loading />
        ) : (
          <div className="product-company-detail">
            <div className="company-details-container">
              <p className="m-0">
                <strong>Company Name:</strong>
                <strong className="ms-2 color-green">
                  {productData && productData.company.name}
                </strong>
              </p>
              <p className="mb-0 mt-0 ms-2">
                <strong>Company Short Name:</strong>
                <strong className="ms-2 color-green">
                  {productData && productData.company.short_name}
                </strong>
              </p>
            </div>
            <div className="company_system_table">
              <div className="tbl_container">
                <table>
                  <thead>
                    <tr>
                      {/* <th>S.NO</th> */}
                      <th>Product</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productData &&
                      Array.isArray(productData.products) &&
                      productData.products.map((item, index) => {
                        // console.log("item", item.product_url);
                        delete productsAvailableCopy[item.product_url];
                        return (
                          <tr key={item}>
                            {/* <th>{index + 1}</th> */}
                            <th>{item.product_name}</th>
                            <th>
                              <span className="color-green">Active</span>
                            </th>
                            <th>
                              <button className="me-10-px">
                                <FontAwesomeIcon icon={solid("eye")} />
                              </button>
                            </th>
                          </tr>
                        );
                      })}
                    {Object.keys(productsAvailableCopy).map((item, index) => {
                      return (
                        <tr key={item}>
                          {/* <th>{index + 1}</th> */}
                          <th>{productsAvailableCopy[item].label}</th>
                          <th>
                            <span className="color-red">Inactive</span>
                          </th>
                          <th>
                            <button className="me-10-px" disabled>
                              <FontAwesomeIcon icon={solid("eye")} />
                            </button>
                          </th>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* <div className="my-flex">
            {Object.keys(products).map((item) => {
              return (
                <div
                  key={item}
                  className="product-card-outer-out productcardout-list"
                >
                  <div className="product-card-outer">
                    <div className="product-card-inner py-2">
                      <img src={products[item].image_url} alt="" />
                    </div>
                    <div className="product-card-inner1">
                      <div className="relative">
                        <input
                          type="text"
                          className="input-h3 false"
                          name="integration_name"
                          disabled={true}
                          value={products[item].product_name}
                        />
                        <div className="prod-description-extra-dev"></div>
                      </div>
                      <div className="relative">
                        <textarea
                          type="text"
                          className="input-p false"
                          name="description"
                          disabled={true}
                          value={products[item].product_description}
                        ></textarea>
                        <div className="prod-description-extra-dev"></div>
                      </div>
                    </div>
                    <div className="product-card-inner2 green status">
                      <h4>Active</h4>
                    </div>
                  </div>
                </div>
              );
            })}
          </div> */}
          </div>
        )}
      </ProductModal>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { products } = state.entities;

  return { products };
};

export default connect(mapStateToProps)(CompanyDetailsModal);
