import React from "react";
import JobsSiteOuter from "./JobsSiteOuter";
import Row from "../ui/JobAppRow";

const ZipRecruiter = ({
  markAsReviewed,
  application,
  sendEmail,
  idUpdating,
}) => {
  return (
    <JobsSiteOuter
      markAsReviewed={markAsReviewed}
      application={application}
      sendEmail={sendEmail}
      idUpdating={idUpdating}
    >
      <div>
        <table>
          <tbody>
            <Row label={"Email Address"} value={application.applicant_email} />
            <Row
              label={"Phone Number"}
              value={application.applicant_phone_number}
            />
          </tbody>
        </table>
      </div>
      <div className="disclaimer">
        Note: not all information included in the ZipRecruiter application is
        shown here.
      </div>
    </JobsSiteOuter>
  );
};

export default ZipRecruiter;
