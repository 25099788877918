import React, { Component } from 'react'
import PropTypes from 'prop-types'

import BaseSetting from './BaseProductSetting'

class SimpleProductTextSetting extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {inputText: props.value}

    this.handleChange = this.handleChange.bind(this)
  }

  componentWillReceiveProps(newProps) {
    this.setState({inputText: newProps.value !== undefined ? newProps.value : ''})
  }

  handleChange(event) {
    this.setState({inputText: event.target.value});
  }

  render() {
    const { title, value, tip, onChange } = this.props
    const { inputText } = this.state

    return (
      <BaseSetting title={title} tip={tip}>
        <div className="SimpleTextSetting">
          <div className="top">
            <input type="text" value={inputText} onChange={this.handleChange} />
          </div>
          {
            value !== inputText
            &&
            (
              <div className="bottom" style={{marginTop: '10px'}}>
                <button onClick={() => onChange(inputText)}>
                  SAVE
                </button>
              </div>
            )
          }
        </div>
      </BaseSetting>
    )
  }
}

export default SimpleProductTextSetting
