import React, { Component } from "react";
import "../styles/BaseProduct.css";
import Loading from "./ui/Loading";
import { store } from "../index";
import { addReport, loadingReport } from "../actions/customReport";
import { v4 as uuidv4 } from "uuid";
import CSVToJSON from "csvtojson";
import { isEmpty } from "lodash";

const connectionString = `wss://connect.onehcm.com/ws`;

export const ws = new WebSocket(connectionString);

const getHeaders = (csv) => {
  const lines = csv.split("\n");
  let headers = lines[0].split(",");

  headers = headers.map((item, index) => {
    item = item.replaceAll(`"`, "");
    item = item.replaceAll(`\r`, "");
    item = isEmpty(item) ? `Field${index + 1}` : item;
    return item;
  });

  return headers;
};

const { pathname } = window.location;

if (pathname.includes("/custom/report/")) {
  ws.onerror = (e) => {
    console.log("error", e);
    store.dispatch(loadingReport(false));
  };

  ws.onopen = () => {
    console.info("web socket connected!");

    const { pathname } = window.location;

    if (pathname.includes("/custom/report/")) {
      const pathArray = pathname.split("/");

      const body = {
        id: pathArray[pathArray.length - 3],
      };

      ws.send(JSON.stringify(body));
    }
  };

  ws.onclose = (e) => {
    console.error("web socket close!", e);
    // store.dispatch(loadingReport(false));
  };

  let count = 0;

  ws.onmessage = async (event) => {
    if (count === 0) {
      store.dispatch(loadingReport(true));
    } else {
      store.dispatch(loadingReport(false));
    }

    const response = JSON.parse(event.data);

    const { pathname } = window.location;

    console.log("response", response);

    if (pathname.includes("/custom/report/")) {
      const pathArray = pathname.split("/");

      let newId = atob(pathArray[pathArray.length - 2]);

      // console.log("newId", newId);

      if (response.report && response.report_name) {
        if (
          response.report_name.toLocaleLowerCase() === newId.toLocaleLowerCase()
        ) {
          count = count + 1;
          try {
            const data = await CSVToJSON().fromString(response.report);
            const headers = getHeaders(response.report);

            const responseData = {
              ...response,
              data: data,
              headers,
            };
            store.dispatch(addReport(responseData));
          } catch (error) {
            store.dispatch(loadingReport(false));
          }
        }
      } else {
        // console.log("response", response);
      }
    }
  };
}

class EmployeeReportModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      reportType: "default",
      generateLoading: false,
      generateReportData: [],
      messageRequired: "",
    };
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.handleSetCompanyLoading(true);
    const { companies, reports, companyInfo } = this.props;
    const { reportType } = this.state;
    const ids = [];
    const data = companies.filter((elem) => {
      return elem.checked === true;
    });

    data.forEach((elem) => {
      ids.push(elem.short_name);
    });

    let reportName = "";
    let associationId = this.props.associationId;

    const newId = uuidv4();

    /* eslint-disable */
    const reportData = reports
      .filter((report) => report.checked)
      .map((report) => {
        if (report.reports) {
          reportName = report.report_name;
          const reportValue = report.reports.map(
            ({ report_id, short_name }) => {
              return {
                report_name: report.report_name,
                short_name,
                report_id: report_id.toString(),
                association_id: this.props.associationId.toString(),
                key: newId,
                p_company_id: String(this.props.userInfo.company_id),
              };
            }
          );
          return reportValue;
        }
      })
      .flat();

    if (reportData.length === 0 && reportType === "custom") {
      this.props.handleSetCompanyLoading(false);
      this.setState({ messageRequired: "Please select report!" });
      return;
    }
    /* eslint-enable */
    const dataString = JSON.stringify(reportData);

    const encryptedMessage = btoa(dataString);

    this.setState({ generateLoading: false });

    if (reportType === "default") {
      window.open(`/default/report/?shortname=${ids.join()}`);
    }
    if (reportType === "custom") {
      // const endPoint = `/api/tlm/get/association/reports`;
      // const { token, userToken } = getToken();
      // let headers = {
      //   "Content-Type": "application/json",
      //   Authorization: token,
      //   OPUserToken: userToken,
      //   id: encryptedMessage,
      // };

      // fetch(endPoint, {
      //   headers,
      // });

      const strParams = btoa(
        companyInfo.short_name + associationId + reportName + newId
      );

      const selectedReport = reports.find((report) => report.checked);

      window.open(
        `/custom/report/${encryptedMessage}/${strParams}/${selectedReport.id}`
      );
    }
    this.props.handleSetCompanyLoading(false);
    this.props.Modaltoggle(false);
    this.setState({ reportType: "default", messageRequired: "" });
  };

  handleReportTypeChange = (e) => {
    const { value } = e.target;
    this.setState({ reportType: value });
  };

  handleClose = () => {
    this.setState({ reportType: "default" });
    this.props.Modaltoggle(false);
  };

  render() {
    const { companies, isLoading, reports } = this.props;
    const { generateLoading, reportType, messageRequired } = this.state;

    return (
      <>
        {this.props.toggleModal && (
          <div id="modalOuter" className="custom-modal">
            <div id="myModal" className="custom-modal">
              <div className="modal-content">
                <span onClick={this.handleClose} className="close">
                  &times;
                </span>
                <h1 className="text-center">Company Employees Report</h1>
                {isLoading || generateLoading ? (
                  <Loading />
                ) : (
                  <form onSubmit={this.handleSubmit}>
                    <p className="color-red">{messageRequired}</p>
                    {reportType === "default" && (
                      <>
                        <h3>Association company</h3>
                        <div className="col-12 mbcstm asscmp-a-table-scroll">
                          <table className="table tablecustompop">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col">Company Name</th>
                                <th scope="col"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {companies.length !== 0 &&
                                companies.map((val, ind) => {
                                  return (
                                    <tr key={ind}>
                                      <td>
                                        <input
                                          type="checkbox"
                                          id="title"
                                          name="title"
                                          value={val.company_id}
                                          checked={
                                            val.p_company_id
                                              ? true
                                              : val.checked
                                          }
                                          disabled={
                                            val.p_company_id ? true : false
                                          }
                                          onChange={(e) =>
                                            this.props.handleChange(e, ind)
                                          }
                                        />
                                      </td>
                                      <td>{val.name}</td>
                                      <td>
                                        {val.p_company_id ? (
                                          <span className="badgecolor">
                                            Primary
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}

                    {reportType === "custom" && (
                      <div>
                        <h3>Association custom report</h3>
                        <div className="col-12 mbcstm asscmp-a-table-scroll">
                          <table className="table tablecustompop">
                            <thead>
                              <tr>
                                <th scope="col"></th>
                                <th scope="col">Report Name</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reports.length !== 0 &&
                                reports.map((val, ind) => {
                                  return (
                                    <tr key={ind}>
                                      <td>
                                        <input
                                          type="radio"
                                          id="title"
                                          name="title"
                                          value={JSON.stringify(val.reports)}
                                          onChange={this.props.handleReportNameChange(
                                            ind
                                          )}
                                        />
                                      </td>
                                      <td>{val.report_name}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}

                    <div className="row d-flex1">
                      <div className="col-12">
                        <select
                          name=""
                          className="report-type"
                          onClick={this.handleReportTypeChange}
                          defaultValue={reportType}
                        >
                          <option value="default">Default</option>
                          <option value="custom">Custom</option>
                        </select>
                        <input
                          className="submitbtncstm"
                          type="submit"
                          value="Generate Report"
                        />

                        <input
                          onClick={this.handleClose}
                          className="submitbtncstm cancelbtncstm"
                          type="button"
                          value="Cancel"
                        />
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default EmployeeReportModel;
