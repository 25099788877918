import { CALL_API, Schemas } from "../middleware/api";

export const PRODUCTS_REQUEST = "PRODUCTS_REQUEST";
export const PRODUCTS_SUCCESS = "PRODUCTS_SUCCESS";
export const PRODUCTS_FAILURE = "PRODUCTS_FAILURE";

export const PRODUCTS_EDIT_REQUEST = "PRODUCTS_EDIT_REQUEST";
export const PRODUCTS_EDIT_SUCCESS = "PRODUCTS_EDIT_SUCCESS";
export const PRODUCTS_EDIT_FAILURE = "PRODUCTS_EDIT_FAILURE";

const fetchProductsx = () => ({
  [CALL_API]: {
    types: [PRODUCTS_REQUEST, PRODUCTS_SUCCESS, PRODUCTS_FAILURE],
    endpoint: "api/products",
    schema: Schemas.PRODUCTS_RESP,
  },
});

export const fetchProducts = () => (dispatch, getState) => {
  return dispatch(fetchProductsx());
};

export const PRODUCT_SETTINGS_REQUEST = "PRODUCT_SETTINGS_REQUEST";
export const PRODUCT_SETTINGS_SUCCESS = "PRODUCT_SETTINGS_SUCCESS";
export const PRODUCT_SETTINGS_FAILURE = "PRODUCT_SETTINGS_FAILURE";

const saveSettingsx = (newSettingObj, companyProductId) => ({
  [CALL_API]: {
    types: [
      PRODUCT_SETTINGS_REQUEST,
      PRODUCT_SETTINGS_SUCCESS,
      PRODUCT_SETTINGS_FAILURE,
    ],
    endpoint: "api/companyproduct/" + companyProductId + "/settings/update",
    schema: Schemas.PRODUCT_LIST,
    postdata: newSettingObj,
  },
});

export const saveSettings =
  (newSettingObj, companyProductId) => (dispatch, getState) => {
    return dispatch(saveSettingsx(newSettingObj, companyProductId));
  };

const editCompanyProductsListx = (productURL, addOrDelete) => ({
  [CALL_API]: {
    types: [
      PRODUCTS_EDIT_REQUEST,
      PRODUCTS_EDIT_SUCCESS,
      PRODUCTS_EDIT_FAILURE,
    ],
    endpoint: "api/companyproductlist/update",
    schema: Schemas.PRODUCTS_RESP,
    postdata: { product_url: productURL, change_type: addOrDelete },
  },
});

export const editCompanyProductsList =
  (productURL, addOrDelete) => (dispatch, getState) => {
    return dispatch(editCompanyProductsListx(productURL, addOrDelete));
  };

export const NEW_ACCESSCONTROL_REQUEST = "NEW_ACCESSCONTROL_REQUEST";
export const NEW_ACCESSCONTROL_SUCCESS = "NEW_ACCESSCONTROL_SUCCESS";
export const NEW_ACCESSCONTROL_FAILURE = "NEW_ACCESSCONTROL_FAILURE";

const createAccessControlx = (newAccessControl) => ({
  [CALL_API]: {
    types: [
      NEW_ACCESSCONTROL_REQUEST,
      NEW_ACCESSCONTROL_SUCCESS,
      NEW_ACCESSCONTROL_FAILURE,
    ],
    endpoint: "api/companyproductaccess/new",
    schema: Schemas.PRODUCT_LIST,
    postdata: newAccessControl,
  },
});

export const createAccessControl =
  (newAccessControl) => (dispatch, getState) => {
    return dispatch(createAccessControlx(newAccessControl));
  };
// mineral start //

const createMineralAccessControlx = (newAccessControl) => ({
  [CALL_API]: {
    types: [
      NEW_ACCESSCONTROL_REQUEST,
      NEW_ACCESSCONTROL_SUCCESS,
      NEW_ACCESSCONTROL_FAILURE,
    ],
    endpoint: "api/mineral/users/update",
    schema: Schemas.PRODUCT_LIST,
    postdata: newAccessControl,
  },
});

export const createMineralAccessControl =
  (newAccessControl) => (dispatch, getState) => {
    console.log('newAccessControl',newAccessControl);
    return dispatch(createMineralAccessControlx(newAccessControl));
  };

  const editMineralAccessControlx = (acID, newAccessControl) => ({
    [CALL_API]: {
      types: [
        EDIT_ACCESSCONTROL_REQUEST,
        EDIT_ACCESSCONTROL_SUCCESS,
        EDIT_ACCESSCONTROL_FAILURE,
      ],
      endpoint: "api/companyproduct/integration/access/edit/" + acID,
      schema: Schemas.PRODUCT_LIST,
      postdata: newAccessControl,
    },
  });
  
  export const editMineralAccessControl =
    (acID, newAccessControl) => (dispatch, getState) => {
      console.log(newAccessControl,'newAccessControl',acID);
      return dispatch(editMineralAccessControlx(acID, newAccessControl));
    };

// mineral end //
export const EDIT_ACCESSCONTROL_REQUEST = "EDIT_ACCESSCONTROL_REQUEST";
export const EDIT_ACCESSCONTROL_SUCCESS = "EDIT_ACCESSCONTROL_SUCCESS";
export const EDIT_ACCESSCONTROL_FAILURE = "EDIT_ACCESSCONTROL_FAILURE";

const editAccessControlx = (acID, newAccessControl) => ({
  [CALL_API]: {
    types: [
      EDIT_ACCESSCONTROL_REQUEST,
      EDIT_ACCESSCONTROL_SUCCESS,
      EDIT_ACCESSCONTROL_FAILURE,
    ],
    endpoint: "api/companyproductaccess/edit/" + acID,
    schema: Schemas.PRODUCT_LIST,
    postdata: newAccessControl,
  },
});

export const editAccessControl =
  (acID, newAccessControl) => (dispatch, getState) => {
    return dispatch(editAccessControlx(acID, newAccessControl));
  };

export const REFRESHCACHE_REQUEST = "REFRESHCACHE_REQUEST";
export const REFRESHCACHE_SUCCESS = "REFRESHCACHE_SUCCESS";
export const REFRESHCACHE_FAILURE = "REFRESHCACHE_FAILURE";

const refreshCachesx = (product) => ({
  [CALL_API]: {
    types: [REFRESHCACHE_REQUEST, REFRESHCACHE_SUCCESS, REFRESHCACHE_FAILURE],
    endpoint: "api/cacherefresh/" + product,
    postdata: {},
  },
});

export const refreshCaches = (product) => (dispatch, getState) => {
  return dispatch(refreshCachesx(product));
};
