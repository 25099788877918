import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { BrowserRouter, Route } from "react-router-dom";

import configureStore from "./store/configureStore";
import Root from "./containers/Root";
import { unregister } from "./registerServiceWorker";
import "./styles/index.css";

if (process.env.NODE_ENV !== "development") {
  ReactGA.initialize("UA-129280371-2");
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export const store = configureStore();

const ScrollToTop = () => {
  window.scrollTo(0, 0);
  return null;
};

unregister();

ReactDOM.render(
  <BrowserRouter>
    <div>
      <Route component={ScrollToTop} />
      <Root store={store} />
    </div>
  </BrowserRouter>,
  document.getElementById("root")
);
