import { CALL_API, Schemas } from "../middleware/api";

export const INTEGRATIONS_REQUEST = "INTEGRATIONS_REQUEST";
export const INTEGRATIONS_SUCCESS = "INTEGRATIONS_SUCCESS";
export const INTEGRATIONS_FAILURE = "INTEGRATIONS_FAILURE";

const fetchIntegrationsx = () => ({
  [CALL_API]: {
    types: [INTEGRATIONS_REQUEST, INTEGRATIONS_SUCCESS, INTEGRATIONS_FAILURE],
    endpoint: "api/products",
    schema: Schemas.PRODUCTS_RESP,
  },
});

export const fetchIntegrations = () => (dispatch, getState) => {
  return dispatch(fetchIntegrationsx());
};

export const INTEGRATION_SETTINGS_REQUEST = "INTEGRATION_SETTINGS_REQUEST";
export const INTEGRATION_SETTINGS_SUCCESS = "INTEGRATION_SETTINGS_SUCCESS";
export const INTEGRATION_SETTINGS_FAILURE = "INTEGRATION_SETTINGS_FAILURE";

const saveSettingsx = (newSettings, intURL) => ({
  [CALL_API]: {
    types: [
      INTEGRATION_SETTINGS_REQUEST,
      INTEGRATION_SETTINGS_SUCCESS,
      INTEGRATION_SETTINGS_FAILURE,
    ],
    endpoint: "api/companyintegration/" + intURL + "/settings/update",
    schema: Schemas.INTEGRATION,
    postdata: newSettings,
  },
});

export const saveSettings = (newSettings, intURL) => (dispatch, getState) => {
  return dispatch(saveSettingsx(newSettings, intURL));
};

export const INTEGRATION_DISCONNECT_REQUEST = "INTEGRATION_DISCONNECT_REQUEST";
export const INTEGRATION_DISCONNECT_SUCCESS = "INTEGRATION_DISCONNECT_SUCCESS";
export const INTEGRATION_DISCONNECT_FAILURE = "INTEGRATION_DISCONNECT_FAILURE";

const disconnectIntegrationx = (intURL) => ({
  [CALL_API]: {
    types: [
      INTEGRATION_DISCONNECT_REQUEST,
      INTEGRATION_DISCONNECT_SUCCESS,
      INTEGRATION_DISCONNECT_FAILURE,
    ],
    endpoint: "api/my/integrations/disconnect",
    schema: Schemas.INTEGRATION,
    postdata: { url: intURL },
  },
});

export const disconnectIntegration = (intURL) => (dispatch, getState) => {
  return dispatch(disconnectIntegrationx(intURL));
};

export const QUICK_APPLY_SPONSORSHIP_REQUEST =
  "QUICK_APPLY_SPONSORSHIP_REQUEST";
export const QUICK_APPLY_SPONSORSHIP_SUCCESS =
  "QUICK_APPLY_SPONSORSHIP_SUCCESS";
export const QUICK_APPLY_SPONSORSHIP_FAILURE =
  "QUICK_APPLY_SPONSORSHIP_FAILURE";

const getQuickApplySponsorshipx = (jobId) => ({
  [CALL_API]: {
    types: [
      QUICK_APPLY_SPONSORSHIP_REQUEST,
      QUICK_APPLY_SPONSORSHIP_SUCCESS,
      QUICK_APPLY_SPONSORSHIP_FAILURE,
    ],
    endpoint: `api/quick-apply/sponsorship`,
  },
});

export const getQuickApplySponsorship = () => (dispatch) => {
  return dispatch(getQuickApplySponsorshipx());
};

export const ADD_QUICK_APPLY_SPONSORSHIP_REQUEST =
  "ADD_QUICK_APPLY_SPONSORSHIP_REQUEST";
export const ADD_QUICK_APPLY_SPONSORSHIP_SUCCESS =
  "ADD_QUICK_APPLY_SPONSORSHIP_SUCCESS";
export const ADD_QUICK_APPLY_SPONSORSHIP_FAILURE =
  "ADD_QUICK_APPLY_SPONSORSHIP_FAILURE";

const addQuickApplySponsorshipx = (body) => ({
  [CALL_API]: {
    types: [
      ADD_QUICK_APPLY_SPONSORSHIP_REQUEST,
      ADD_QUICK_APPLY_SPONSORSHIP_SUCCESS,
      ADD_QUICK_APPLY_SPONSORSHIP_FAILURE,
    ],
    endpoint: "api/add/quick-apply/sponsorship",
    postdata: body,
  },
});

export const addQuickApplySponsorship = (body) => (dispatch) => {
  return dispatch(addQuickApplySponsorshipx(body));
};

export const GET_QUICK_APPLY_APPLICATION_REQUEST =
  "GET_QUICK_APPLY_APPLICATION_REQUEST";
export const GET_QUICK_APPLY_APPLICATION_SUCCESS =
  "GET_QUICK_APPLY_APPLICATION_SUCCESS";
export const GET_QUICK_APPLY_APPLICATION_FAILURE =
  "GET_QUICK_APPLY_APPLICATION_FAILURE";

const getQuickApplyApplicationx = (jobId, record) => ({
  [CALL_API]: {
    types: [
      GET_QUICK_APPLY_APPLICATION_REQUEST,
      GET_QUICK_APPLY_APPLICATION_SUCCESS,
      GET_QUICK_APPLY_APPLICATION_FAILURE,
    ],
    endpoint: `api/quickapply/recruitment/applications/${jobId}/${record}`,
  },
});

export const getQuickApplyApplication = (jobId, record) => (dispatch) => {
  return dispatch(getQuickApplyApplicationx(jobId, record));
};

export const INTEGRATION_CONNECT_DIS_REQUEST =
  "INTEGRATION_CONNECT_DIS_REQUEST";
export const INTEGRATION_CONNECT_DIS_SUCCESS =
  "INTEGRATION_CONNECT_DIS_SUCCESS";
export const INTEGRATION_CONNECT_DIS_FAILURE =
  "INTEGRATION_CONNECT_DIS_FAILURE";

const ateraIntegrationConnectDisconnectx = (body) => ({
  [CALL_API]: {
    types: [
      INTEGRATION_CONNECT_DIS_REQUEST,
      INTEGRATION_CONNECT_DIS_SUCCESS,
      INTEGRATION_CONNECT_DIS_FAILURE,
    ],
    endpoint: "api/integration/connection",
    postdata: body,
  },
});

export const integrationConnectDisconnect = (body) => (dispatch) => {
  return dispatch(ateraIntegrationConnectDisconnectx(body));
};

export const INTEGRATION_EMPLOYEES_SYNC_REQUEST =
  "INTEGRATION_EMPLOYEES_SYNC_REQUEST";
export const INTEGRATION_EMPLOYEES_SYNC_SUCCESS =
  "INTEGRATION_EMPLOYEES_SYNC_SUCCESS";
export const INTEGRATION_EMPLOYEES_SYNC_FAILURE =
  "INTEGRATION_EMPLOYEES_SYNC_FAILURE";

const integrationEmployeeSyncx = (key) => ({
  [CALL_API]: {
    types: [
      INTEGRATION_EMPLOYEES_SYNC_REQUEST,
      INTEGRATION_EMPLOYEES_SYNC_SUCCESS,
      INTEGRATION_EMPLOYEES_SYNC_FAILURE,
    ],
    endpoint: `api/emp/custom/${key}`,
  },
});

export const integrationEmployeeSync = (key) => (dispatch) => {
  return dispatch(integrationEmployeeSyncx(key));
};

export const INTEGRATION_EMPLOYEES_HF_PROCESS_REQUEST =
  "INTEGRATION_EMPLOYEES_HF_PROCESS_REQUEST";
export const INTEGRATION_EMPLOYEES_HF_PROCESS_SUCCESS =
  "INTEGRATION_EMPLOYEES_HF_PROCESS_SUCCESS";
export const INTEGRATION_EMPLOYEES_HF_PROCESS_FAILURE =
  "INTEGRATION_EMPLOYEES_HF_PROCESS_FAILURE";

const integrationEmployeeHFProcessx = (name, type) => ({
  [CALL_API]: {
    types: [
      INTEGRATION_EMPLOYEES_HF_PROCESS_REQUEST,
      INTEGRATION_EMPLOYEES_HF_PROCESS_SUCCESS,
      INTEGRATION_EMPLOYEES_HF_PROCESS_FAILURE,
    ],
    endpoint: `api/emp/hf/process/${name}/${type}`,
  },
});

export const integrationEmployeeHFProcess = (name, type) => (dispatch) => {
  return dispatch(integrationEmployeeHFProcessx(name, type));
};

export const INTEGRATION_EMPLOYEES_EVENT_PROCESS_REQUEST =
  "INTEGRATION_EMPLOYEES_EVENT_PROCESS_REQUEST";
export const INTEGRATION_EMPLOYEES_EVENT_PROCESS_SUCCESS =
  "INTEGRATION_EMPLOYEES_EVENT_PROCESS_SUCCESS";
export const INTEGRATION_EMPLOYEES_EVENT_PROCESS_FAILURE =
  "INTEGRATION_EMPLOYEES_EVENT_PROCESS_FAILURE";

const integrationEmployeeEventProcessx = (integrationId, type) => ({
  [CALL_API]: {
    types: [
      INTEGRATION_EMPLOYEES_EVENT_PROCESS_REQUEST,
      INTEGRATION_EMPLOYEES_EVENT_PROCESS_SUCCESS,
      INTEGRATION_EMPLOYEES_EVENT_PROCESS_FAILURE,
    ],
    endpoint: `api/emp/custom/event/process/${integrationId}/${type}`,
  },
});

export const integrationEmployeeEventProcess =
  (integrationId, type) => (dispatch) => {
    return dispatch(integrationEmployeeEventProcessx(integrationId, type));
  };

export const GET_BG_CHECK_APPLICANTS_REQUEST =
  "GET_BG_CHECK_APPLICANTS_REQUEST";
export const GET_BG_CHECK_APPLICANTS_SUCCESS =
  "GET_BG_CHECK_APPLICANTS_SUCCESS";
export const GET_BG_CHECK_APPLICANTS_FAILURE =
  "GET_BG_CHECK_APPLICANTS_FAILURE";

const getBGCheckApplicantsx = () => ({
  [CALL_API]: {
    types: [
      GET_BG_CHECK_APPLICANTS_REQUEST,
      GET_BG_CHECK_APPLICANTS_SUCCESS,
      GET_BG_CHECK_APPLICANTS_FAILURE,
    ],
    endpoint: `api/company/quickapply/bg-checks/applicants`,
  },
});

export const getBGCheckApplicants = () => (dispatch) => {
  return dispatch(getBGCheckApplicantsx());
};

export const ASSIGN_APPLICANTS_BG_CHECK_REQUEST =
  "ASSIGN_APPLICANTS_BG_CHECK_REQUEST";
export const ASSIGN_APPLICANTS_BG_CHECK_SUCCESS =
  "ASSIGN_APPLICANTS_BG_CHECK_SUCCESS";
export const ASSIGN_APPLICANTS_BG_CHECK_FAILURE =
  "ASSIGN_APPLICANTS_BG_CHECK_FAILURE";

const assignApplicantx = (body) => ({
  [CALL_API]: {
    types: [
      ASSIGN_APPLICANTS_BG_CHECK_REQUEST,
      ASSIGN_APPLICANTS_BG_CHECK_SUCCESS,
      ASSIGN_APPLICANTS_BG_CHECK_FAILURE,
    ],
    endpoint: `api/company/quickapply/assign/applicant/bg-checks`,
    postdata: body,
  },
});

export const assignApplicant = (body) => (dispatch) => {
  return dispatch(assignApplicantx(body));
};

export const CONNECTOR_CONFIG_REQUEST = "CONNECTOR_CONFIG_REQUEST";
export const CONNECTOR_CONFIG_SUCCESS = "CONNECTOR_CONFIG_SUCCESS";
export const CONNECTOR_CONFIG_FAILURE = "CONNECTOR_CONFIG_FAILURE";

const connectorConfigx = (id) => ({
  [CALL_API]: {
    types: [
      CONNECTOR_CONFIG_REQUEST,
      CONNECTOR_CONFIG_SUCCESS,
      CONNECTOR_CONFIG_FAILURE,
    ],
    endpoint: `api/connector/config/${id}`,
  },
});

export const connectorConfig = (id) => (dispatch) => {
  return dispatch(connectorConfigx(id));
};

export const CONNECTOR_CONFIGURATION_REQUEST =
  "CONNECTOR_CONFIGURATION_REQUEST";
export const CONNECTOR_CONFIGURATION_SUCCESS =
  "CONNECTOR_CONFIGURATION_SUCCESS";
export const CONNECTOR_CONFIGURATION_FAILURE =
  "CONNECTOR_CONFIGURATION_FAILURE";

const connectorConfigurationx = (body) => ({
  [CALL_API]: {
    types: [
      CONNECTOR_CONFIGURATION_REQUEST,
      CONNECTOR_CONFIGURATION_SUCCESS,
      CONNECTOR_CONFIGURATION_FAILURE,
    ],
    endpoint: `api/connector_cred`,
    postdata: body,
  },
});

export const connectorConfiguration = (body) => (dispatch) => {
  return dispatch(connectorConfigurationx(body));
};

export const USER_PROVISIONING_TOOL_CONFIRMATION_REQUEST =
  "USER_PROVISIONING_TOOL_CONFIRMATION_REQUEST";
export const USER_PROVISIONING_TOOL_CONFIRMATION_SUCCESS =
  "USER_PROVISIONING_TOOL_CONFIRMATION_SUCCESS";
export const USER_PROVISIONING_TOOL_CONFIRMATION_FAILURE =
  "USER_PROVISIONING_TOOL_CONFIRMATION_FAILURE";

const userProvisioningToolConfirmationx = (body) => ({
  [CALL_API]: {
    types: [
      USER_PROVISIONING_TOOL_CONFIRMATION_REQUEST,
      USER_PROVISIONING_TOOL_CONFIRMATION_SUCCESS,
      USER_PROVISIONING_TOOL_CONFIRMATION_FAILURE,
    ],
    endpoint: `api/emp/user-provisioning/tool/confirmation`,
    postdata: body,
  },
});

export const userProvisioningToolConfirmation = (body) => (dispatch) => {
  return dispatch(userProvisioningToolConfirmationx(body));
};
