export const stateAbbreviations = [
    'AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA',
    'GU', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA',
    'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND',
    'MP', 'OH', 'OK', 'OR', 'PW', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT',
    'VT', 'VI', 'VA', 'WA', 'WV', 'WI', 'WY'
];

export const fingerPositionNames = (position) => {
    switch (position) {
        case 0:
            return 'Right Thumb'
        case 1:
            return 'Right Index'
        case 2:
            return 'Right Middle'
        case 3:
            return 'Right Ring'
        case 4:
            return 'Right Pinky'
        case 5:
            return 'Left Thumb'
        case 6:
            return 'Left Index'
        case 7:
            return 'Left Middle'
        case 8:
            return 'Left Ring'
        case 9:
            return 'Left Pinky'
        default:
            break;
    }
}