import { CALL_API } from "../middleware/api";

export const COMPANY_DATA_REQUEST = "COMPANY_DATA_REQUEST";
export const COMPANY_DATA_SUCCESS = "COMPANY_DATA_SUCCESS";
export const COMPANY_DATA_FAILURE = "COMPANY_DATA_FAILURE";

const fetchCompanyDatax = (dataName) => ({
  [CALL_API]: {
    types: [COMPANY_DATA_REQUEST, COMPANY_DATA_SUCCESS, COMPANY_DATA_FAILURE],
    endpoint: "api/company/" + dataName,
    passthru: { dataName },
  },
});

export const fetchCompanyData = (dataName) => (dispatch, getState) => {
  return dispatch(fetchCompanyDatax(dataName));
};
