import React from "react";
import PropTypes from "prop-types";
import logo from "../assets/images/op-logo.png";
import { Link } from "react-router-dom";

import "../styles/TopNav.css";
import { oldAppPath } from "../constants/constant";

const TopNav = (props) => {
  const { userInfo, companyInfo, logout } = props;
  const is_system_admin = userInfo ? userInfo.is_system_admin : false;

  return (
    <div className="topNav blue">
      <div className="cont">
        <div className="logoCont">
          <Link to={`${oldAppPath}`}>
            <img src={logo} alt="logo" />
          </Link>
        </div>
        <div className="rightSide">
          <div className="companyInfo">
            {userInfo && (
              <div>
                {is_system_admin && (
                  <span>
                    <Link to={`${oldAppPath}/systemadmin`}>SYSTEM ADMIN</Link>
                  </span>
                )}{" "}
                {userInfo.username}
              </div>
            )}
            {companyInfo != null && companyInfo.name}
          </div>
          <div className="logoutCont">
            <button onClick={logout}>SIGN OUT</button>
          </div>
        </div>
      </div>
    </div>
  );
};

TopNav.propTypes = {
  logout: PropTypes.func.isRequired,
};

export default TopNav;
