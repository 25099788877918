import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import useToggle from "../../hooks/useToggle";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";
import AddEditAssociation from "./AddEditAssociation";
import Swal from "sweetalert2";
import {
  deleteAssociation,
  reActiveAssociation,
} from "../../../actions/cloudpunch";
import EmployeeReport from "./EmployeeReport";
import MultipleEINDataTable from "./MultipleEINDataTable";
import UserProvisioningSettings from "../settings/UserProvisioningSettings";

const MultipleEINBody = (props) => {
  const {
    settings,
    companyProductId,
    productsAreUpdating,
    productsAreFetching,
  } = props;
  const [settingsToggle, handleSettingsToggle] = useToggle(true);
  const [openAssociation, handleOpenAssociation] = useToggle(false);
  const [openReportModel, handleOpenReportModel] = useToggle(false);
  const [fetchData, loading, response] = useFetch();
  const [mode, setMode] = useState(null);
  const [editId, setEditId] = useState(null);
  const [reportId, setReportId] = useState(null);

  const handleOpenMode = (m) => () => {
    if (m) {
      setMode(m);
    } else {
      setMode(null);
    }
    setEditId(null);
    handleOpenAssociation();
  };

  const handleOpenModeReport = (m) => () => {
    setReportId(m);
    handleOpenReportModel();
  };

  const fetchAssociationsData = () => {
    fetchData({
      endPoint: `/api/tlm/cp/associations`,
      method: "get",
    });
  };

  const deleteAssociationsItem = (id) => async () => {
    const data = { association_id: id };
    const result = await Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, De-Activated it!",
    });
    if (result.isConfirmed) {
      await props.dispatch(deleteAssociation(data));
      // this.fetchAssociationsList();
      // this.props.dispatch(fetchAssociations(this.props.userInfo.company_id));
      Swal.fire("De-Activated!", "Your file has been De-Activated.", "success");
    }
  };

  const reActiveAssociationsItem = (id) => async () => {
    const data = { association_id: id };
    const result = await Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Re Active it!",
    });
    if (result.isConfirmed) {
      await props.dispatch(reActiveAssociation(data));
      // await this.props.dispatch(fetchAssociations(this.props.userInfo.company_id));
      Swal.fire("Re-Activated!", "Your file has been Re-Activated.", "success");
    }
  };

  const handleEditAssociationOpen = (id) => () => {
    console.log("id", id);
    setEditId(id);
    setMode("EDIT");
    handleOpenAssociation();
  };

  useEffect(() => {
    fetchAssociationsData();
  }, []);

  return (
    <div className="product-modal-content">
      <div className="product-modal-header">
        <h3>Associations</h3>

        <span onClick={handleSettingsToggle}>
          <FontAwesomeIcon icon={solid("gear")} />
        </span>
      </div>
      <div className="product-card-content-outer ">
        <div
          className={`Integration-outer ${
            !settingsToggle && "integration-active"
          }`}
        >
          {loading ? (
            <Loading />
          ) : (
            <div className="product-table">
              {Array.isArray(response) && (
                <MultipleEINDataTable
                  row={response}
                  copyRow={response}
                  deleteAssociationsItem={deleteAssociationsItem}
                  reActiveAssociationsItem={reActiveAssociationsItem}
                  handleEditAssociationOpen={handleEditAssociationOpen}
                  handleOpenModeReport={handleOpenModeReport}
                  handleOpenMode={handleOpenMode}
                  history={props.history}
                />
              )}
            </div>
          )}
        </div>
        <div
          className={`product-setting-section ${
            settingsToggle ? "setting-show" : "setting-hide"
          }`}
        >
          <UserProvisioningSettings
            settings={settings}
            companyProductId={companyProductId}
            productsAreUpdating={productsAreUpdating}
            productsAreFetching={productsAreFetching}
          />
          {/* <h1 className="mb-0 p-0">Settings</h1>
          <AccessControl /> */}
        </div>
        <AddEditAssociation
          handleOpenMode={handleOpenMode}
          fetchAssociationsData={fetchAssociationsData}
          openAssociation={openAssociation}
          mode={mode}
          editId={editId}
        />
        <EmployeeReport
          handleOpenMode={handleOpenModeReport}
          openAssociation={openReportModel}
          reportId={reportId}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { cp_installations, cp_employees, cp_enrollments } = state.entities;
  const { products } = state.entities;
  const {
    beenLoadedOnce,
    allFetching,
    installationsList,
    employeesList,
    installsFetching,
    empsAreFetching,
    associationsList,
    associationspostError,
    editAssociationsCompanyError,
  } = state.cloudpunch;
  const { costCenters, isFetching: cdFetching } = state.company_data;
  const { companyInfo, userInfo } = state.products;
  const { isFetching: productsAreFetching, isUpdating: productsAreUpdating } =
    state.products;
  const costCentersFetching = cdFetching["cost-centers"];

  const productData = products["multiple"];

  const instsListJoin =
    installationsList === undefined
      ? []
      : installationsList.map((id) => {
          let thisIn = Object.assign({}, cp_installations[id]);
          return thisIn;
        });

  const empsListJoin =
    employeesList === undefined
      ? []
      : employeesList.map((id) => {
          let thisIn = Object.assign({}, cp_employees[id]);
          thisIn.enrollments = thisIn.enrollments.map((id) => {
            {
              let thisEn = Object.assign({}, cp_enrollments[id]);
              return thisEn;
            }
          });
          return thisIn;
        });

  return {
    beenLoadedOnce,
    allFetching,
    installations: instsListJoin,
    associations: associationsList,
    employees: empsListJoin,
    installsFetching,
    empsAreFetching,
    companyInfo,
    userInfo,
    costCenters: costCenters !== undefined ? costCenters : null,
    costCentersFetching,
    associationspostError,
    editAssociationsCompanyError,
    settings: productData ? productData.settings : {},
    companyProductId:
      productData === undefined ? null : productData.company_product_id,
    productsAreFetching,
    productsAreUpdating,
  };
};

export default withRouter(connect(mapStateToProps)(MultipleEINBody));
