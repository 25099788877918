import React, { useEffect, useState } from "react";
import useToggle from "../../hooks/useToggle";
import Loading from "../../../components/ui/Loading";
import { getToken } from "../../../actions/auth";
import {
  createAccessControl,
  editAccessControl,
} from "../../../actions/products";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import HideShow from "../../../components/ui/HideShow";

const AccessControl = (props) => {
  const { dispatch, companyProductId, accessControls, userInfo } = props;
  const [showAccess, handleShowAccess] = useToggle(false);
  const [employees, setEmployees] = useState([]);
  const [accessControlsState, setAccessControlsState] = useState(accessControls);
  const [isFetching, setIsFetching] = useState(false);
  const [selectEmail, setSelectEmail] = useState("");
  const { token, userToken } = getToken();
  const [accessType, setAccessType] = useState("W");
  const [accessRemove] = useFetch();

  if (userInfo && !userInfo.is_system_admin) {
    return null;
  }

  const fetchEmployees = async () => {
    try {
      setIsFetching(true);
      const response = await fetch(`/api/company/employees`, {
        headers: {
          Opusertoken: userToken,
          Authorization: token,
        },
      });
      const { data } = await response.json();
      if (Array.isArray(data.values)) {
        setEmployees(data.values);
      }

      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.log("error", error);
    }
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setSelectEmail(value);
  };

  const handleChange = (e) => {
    setAccessType(e.target.value);
  };

  const handleSubmit = () => {
    if (!selectEmail) {
      return;
    }
    const selectedEmployee = employees.find((emp) => emp.name === selectEmail);

    const newAC = {
      company_product_id: companyProductId,
      employee_name: selectedEmployee.name,
      employee_op_id: selectedEmployee.id,
      access_type: accessType,
    };

    dispatch(createAccessControl(newAC));
  };

  const handleChangeReadWrite = (ac, newAccessType) => () => {
    console.log(ac, newAccessType);
    if (ac.access_type === newAccessType) {
      return;
    }
    ac.access_type = newAccessType;
    dispatch(editAccessControl(ac.id, ac));
  };

  const handleReActiveRemove = (ac, newActive) => () => {
    if (ac.active === newActive) {
      return;
    }
    ac.active = newActive;
    dispatch(editAccessControl(ac.id, ac));
  };

  const handleAccessDelete = (ac, index) => async () => {
    setIsFetching(true);
    let formData = new FormData();
    formData.append("id", ac.id);

    const payload = {
      endPoint: "/api/companyproductaccess/remove",
      body: formData,
      method: "POST",
      formData: true,
    };
    await accessRemove(payload);

    const copy = [...accessControlsState];
    copy.splice(index, 1);
    setAccessControlsState(copy);

    setIsFetching(false);
  };

  useEffect(() => {
    if (employees.length === 0 && showAccess) {
      fetchEmployees();
    }
  }, [showAccess]);

  if (isFetching) {
    return <Loading />;
  }

  return (
    // className="product-setting-inner1" 
    <div className="product-setting-inner email-notification mb-2">
      <HideShow
        label={<label>Access Control</label>}
        body={<div>
          <p>
            Control which employees in your company can access this Product. Only
            Company Administrators can access this area.
          </p>
          <div className="scrollable-content">
            {Array.isArray(accessControlsState) &&
              accessControlsState.map((item, index) => {
                return (
                  <div
                    className={`access-control mb-2 ${!item.active && "inactive"}`}
                    key={item.employee_name}
                  >
                    <div className="my-flex my-justify-content-between my-align-item-center">
                      <h3>{item.employee_name}</h3>
                      <div>
                        <button
                          className={`me-10-px ${item.access_type === "R" ? "" : "inactive"
                            }`}
                          onClick={handleChangeReadWrite(item, "R")}
                        >
                          READ
                        </button>
                        <button
                          className={`${item.access_type === "W" ? "" : "inactive"}`}
                          onClick={handleChangeReadWrite(item, "W")}
                        >
                          WRITE
                        </button>
                      </div>
                    </div>
                    {item.active ? (
                      <button onClick={handleReActiveRemove(item, false)}>
                        Remove Access
                      </button>
                    ) : (
                      <button onClick={handleReActiveRemove(item, true)}>
                        REACTIVATE
                      </button>
                    )}
                    <button
                      className="ms-1"
                      onClick={handleAccessDelete(item, index)}
                    >
                      Remove user
                    </button>
                  </div>
                );
              })}
          </div>
          {showAccess && (
            <div className="my-flex">
              <div className="employees mt-2 one-col-6">
                <div className="top">
                  <div>Select Employee to Add</div>
                  <select
                    className="mb-2"
                    value={selectEmail}
                    onChange={handleSelectChange}
                  >
                    <option disabled value="">
                      Select Employee
                    </option>
                    {Array.isArray(employees) &&
                      employees.map((item) => (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                  </select>
                  <div className="bot mb-2">
                    <button onClick={handleSubmit} className="m-0">
                      ADD EMPLOYEE
                    </button>
                  </div>
                </div>
              </div>

              <div className="Access-type one-col-6">
                <p>Access Type</p>
                <div>
                  <div>
                    <input
                      type="radio"
                      name="read"
                      onChange={handleChange}
                      id="read"
                      value={"R"}
                    />
                    <label htmlFor="read">Read</label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      name="read"
                      onChange={handleChange}
                      id="write"
                      value={"W"}
                    />
                    <label htmlFor="write">Write</label>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="product-setting-inner2">
            <button onClick={handleShowAccess}>
              {showAccess ? "Close" : "GIVE NEW USER ACCESS"}
            </button>
          </div>
        </div>}
      />



    </div>
  );
};

const mapStateToProps = (state, newProps) => {
  const { products, integrations } = state.entities;
  const {
    isFetching: productsAreFetching,
    isUpdating: productsAreUpdating,
    userInfo,
  } = state.products;

  const { match } = newProps;
  const prodURL = match.url.split("/");

  const productData = products[prodURL[prodURL.length - 1]];

  const intsList =
    productData === undefined
      ? []
      : productData.integrations.map((intURL) => {
        return integrations[intURL];
      });

  return {
    integrations: intsList,
    productsAreFetching,
    productsAreUpdating,
    settings: productData ? productData.settings : {},
    companyProductId:
      productData === undefined ? null : productData.company_product_id,
    accessControls: productData !== undefined && productData.access_controls,
    userInfo,
  };
};

export default withRouter(connect(mapStateToProps)(AccessControl));
