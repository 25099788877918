const pg1_check1 = `
  <div>
    <div>
      Check here if you received a conditional certification from the state workforce agency (SWA) or a participating local agency for the work opportunity credit.
    </div>
  </div>`
const pg1_check2 = `
  <div>
    <div>
      Check here if <b>any</b> of the following statements apply to you.
    </div>
    <ul>
      <li>
        I am a member of a family that has received assistance from Temporary Assistance for Needy Families (TANF) for any 9 months during the past 18 months.
      </li>
      <li>
        I am a veteran and a member of a family that received Supplemental Nutrition Assistance Program (SNAP) benefits (food stamps) for at least a 3-month period during the past 15 months.
      </li>
      <li>
        I was referred here by a rehabilitation agency approved by the state, an employment network under the Ticket to Work program, or the Department of Veterans Affairs.
      </li>
      <li>
        I am at least age 18 but not age 40 or older and I am a member of a family that:
        <ol>
          <li>
            Received SNAP benefits (food stamps) for the past 6 months; <b>or</b>
          </li>
          <li>
            Received SNAP benefits (food stamps) for at least 3 of the past 5 months, <b>but</b> is no longer eligible to receive them.
          </li>
        </ol>
      </li>
      <li>
        During the past year, I was convicted of a felony or released from prison for a felony.
      </li>
      <li>
        I received supplemental security income (SSI) benefits for any month ending during the past 60 days.
      </li>
      <li>
      I am a veteran and I was unemployed for a period or periods totaling at least 4 weeks but less than 6 months during the past year.
      </li>
    </ul>
  </div>`
const pg1_check3 = `
  <div>
    <div>
      Check here if you are a veteran and you were unemployed for a period or periods totaling at least 6 months during the past year.
    </div>
  </div>`
const pg1_check4 = `
  <div>
    <div>
      Check here if you are a veteran entitled to compensation for a service-connected disability and you were discharged or released from active duty in the U.S. Armed Forces during the past year.
    </div>
  </div>`
const pg1_check5 = `
  <div>
    <div>
      Check here if you are a veteran entitled to compensation for a service-connected disability and you were unemployed for a period or periods totaling at least 6 months during the past year.
    </div>
  </div>`
const pg1_check6 = `
  <div>
    <div>
      Check here if you are a member of a family that:
    </div>
    <ul>
      <li>
        Received TANF payments for at least the past 18 months; <b>or</b>
      </li>
      <li>
        Received TANF payments for any 18 months beginning after August 5, 1997, <b>and</b> the earliest 18-month period beginning after August 5, 1997, ended during the past 2 years; <b>or</b>
      </li>
      <li>
        Stopped being eligible for TANF payments during the past 2 years because federal or state law limited the maximum time those payments could be made.
      </li>
    </ul>
  </div>`
const pg1_check7 = `
  <div>
    <div>
      Check here if you are in a period of unemployment that is at least 27 consecutive weeks and for all or part of that period you received unemployment compensation.
    </div>
  </div>`

const QuestionnaireTemplate = [
  {
    type: 'pageTitle',
    value: 'Form 8850'
  },
  {
    fieldName: 'title',
    type: 'title',
    value: 'Job Applicant Info'
  },
  {
    fieldName: 'name',
    type: 'text100',
    label: 'Your Name',
    required: true
  },
  {
    fieldName: 'ssn',
    type: 'text50',
    label: 'Social Security Number',
    required: true
  },
  {
    fieldName: 'street_address',
    type: 'text100',
    label: 'Street address where you live',
    required: true
  },
  {
    fieldName: 'city',
    type: 'text50',
    label: 'City',
    required: true
  },
  {
    fieldName: 'state',
    type: 'state',
    label: 'State',
    required: true
  },
  {
    fieldName: 'zip',
    type: 'text50',
    label: 'ZIP Code',
    required: true
  },
  {
    fieldName: 'county',
    type: 'text50',
    label: 'County',
    required: true
  },
  {
    fieldName: 'phone',
    type: 'text50',
    label: 'Telephone number',
    required: true
  },
  {
    fieldName: 'date_of_birth',
    type: 'text100',
    label: 'If you are under age 40, enter your date of birth (MM/DD/YYYY)'
  },
  {
    fieldName: 'lineBreak',
    type: 'lineBreak'
  },
  {
    fieldName: 'pg1_check1',
    type: 'check',
    label: pg1_check1
  },
  {
    fieldName: 'pg1_check2',
    type: 'check',
    label: pg1_check2
  },
  {
    fieldName: 'pg1_check3',
    type: 'check',
    label: pg1_check3
  },
  {
    fieldName: 'pg1_check4',
    type: 'check',
    label: pg1_check4
  },
  {
    fieldName: 'pg1_check5',
    type: 'check',
    label: pg1_check5
  },
  {
    fieldName: 'pg1_check6',
    type: 'check',
    label: pg1_check6
  },
  {
    fieldName: 'pg1_check7',
    type: 'check',
    label: pg1_check7
  },
  {
    type: 'signature',
    label: 'Job Applicant‘s Signature',
    signatureFieldName: 'applicant_signature',
    dateFieldName: 'applicant_signature_date',
    required: true,
    disclaimer: 'Under penalties of perjury, I declare that I gave the above information to the employer on or before the day I was offered a job, and it is, to the best of my knowledge, true, correct, and complete.'
  },
  {
    type: 'title',
    value: 'Employer Use Only',
    hideFromApplicant: true,
  },
  {
    fieldName: 'employer_name',
    type: 'text100',
    hideFromApplicant: true,
    label: 'Employer’s name',
    required: true
  },
  {
    fieldName: 'employer_phone',
    type: 'text50',
    hideFromApplicant: true,
    label: 'Telephone number',
    required: true
  },
  {
    fieldName: 'employer_ein',
    type: 'text50',
    hideFromApplicant: true,
    label: 'EIN',
    required: true
  },
  {
    fieldName: 'employer_street_address',
    type: 'text100',
    hideFromApplicant: true,
    label: 'Street address',
    required: true
  },
  {
    fieldName: 'employer_city_state_zip',
    type: 'text100',
    hideFromApplicant: true,
    label: 'City or town, state, and ZIP Code',
    required: true
  },
  {
    type: 'subTitle',
    value: 'Person To Contact',
    hideFromApplicant: true,
    required: true
  },
  {
    fieldName: 'employer_person_to_contact',
    type: 'text100',
    hideFromApplicant: true,
    label: 'Name',
    required: true
  },
  {
    fieldName: 'employer_signature_title',
    type: 'text50',
    hideFromApplicant: true,
    label: 'Title',
    required: true
  },
  {
    fieldName: 'employer_person_to_contact_phone',
    type: 'text100',
    hideFromApplicant: true,
    label: 'Telephone number',
    required: true
  },
  {
    fieldName: 'employer_person_to_contact_address',
    type: 'text100',
    hideFromApplicant: true,
    label: 'Street address',
    required: true
  },
  {
    fieldName: 'employer_person_to_contact_city_state_zip',
    type: 'text100',
    hideFromApplicant: true,
    label: 'City or town, state, and ZIP Code',
    required: true
  },
  {
    fieldName: 'group_number_member',
    type: 'textSmallField',
    hideFromApplicant: true,
    label: 'If, based on the individual’s age and home address, he or she is a member of group 4 or 6 (as described under Members of Targeted Groups in the separate instructions), enter that group number (4 or 6)'
  },
  {
    type: 'subTitle',
    value: 'Applicant Dates (MM/DD/YYYY)',
    hideFromApplicant: true,
  },
  {
    fieldName: 'date_applied',
    type: 'text50',
    hideFromApplicant: true,
    label: 'Date Applied'
  },
  {
    fieldName: 'date_offered',
    type: 'text50',
    hideFromApplicant: true,
    label: 'Date Offered'
  },
  {
    fieldName: 'date_hired',
    type: 'text50',
    hideFromApplicant: true,
    label: 'Date Hired'
  },
  {
    fieldName: 'date_started',
    type: 'text50',
    hideFromApplicant: true,
    label: 'Date Started'
  },
  {
    type: 'signature',
    label: 'Employer‘s Signature',
    signatureFieldName: 'employer_signature',
    dateFieldName: 'employer_signature_date',
    hideFromApplicant: true,
    required: true,
    disclaimer: 'Under penalties of perjury, I declare that the applicant provided the information on this form on or before the day a job was offered to the applicant and that the information I have furnished is, to the best of my knowledge, true, correct, and complete. Based on the information the job applicant furnished on page 1, I believe the individual is a member of a targeted group. I hereby request a certification that the individual is a member of a targeted group.'
  }
]

export default QuestionnaireTemplate
