import React, { Component } from "react";
import { connect } from "react-redux";
import "../../styles/career.css";
import "../../styles/JobsBody.css";

import { getBGCheckApplicants } from "../../actions/integrations";
import Loading from "../ui/Loading";
import BackgroundVerificationDataTable from "./BackgroundVerificationDatatable";

class BackgroundCheckIntegration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applicantsList: [],
      isLoading: false,
    };
  }

  async componentDidMount() {
    this.setState({
      isLoading: true,
    });

    let { response } = await this.props.dispatch(getBGCheckApplicants());

    if (response) {
      this.setState({
        applicantsList: Object.values(response),
      });
    }
    this.setState({
      isLoading: false,
    });
  }

  render() {
    const { data } = this.props;
    const { applicantsList, isLoading } = this.state;

    if (data === undefined) {
      return null;
    }

    return (
      <div className="career JobsBody JobBoard">
        <div className="head">
          <h1>
            <div>Background verification.</div>
            <div className="desc disappearSmall">
              This is use for the applicants detail assign for the background
              verification, and check the verification status.
            </div>
          </h1>
          <div className="img">
            <img src={`/images/${data.image_name}`} alt=" " />
          </div>
        </div>
        <div className="bg-verification-integration">
          {isLoading ? (
            <Loading />
          ) : applicantsList.length !== 0 ? (
            <div>
              <BackgroundVerificationDataTable
                row={applicantsList}
                copyRow={applicantsList}
              />
            </div>
          ) : (
            <h1 className="text-center">Record not found</h1>
          )}
        </div>

        {/* <div>
          <HideShow
            label={<h2>Integration Documentation</h2>}
            body={IndeedDocumentation}
          />
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { userInfo } = state.products;
  const { isFetching, idUpdating } = state.jobs;

  return {
    isFetching,
    jobs: [],
    userInfo,
    idUpdating,
  };
};

export default connect(mapStateToProps)(BackgroundCheckIntegration);
