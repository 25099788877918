import * as EventTypes from '../actions/wotc'

const initialState = {
  error: '',
  list: null,
  loading: {
    applicantList: false,
    newApplicant: false,
    wotcForm: false,
    wotcFormUpdate: false
  },
  success: {
    newApplicant: false,
    wotcFormUpdate: false
  }
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case EventTypes.WOTC_APPLICANTS_REQUEST:
      return Object.assign({}, state, {
        error: '',
        loading: {...state.loading, applicantList: true}
      })
    case EventTypes.WOTC_APPLICANTS_SUCCESS:
      return Object.assign({}, state, {
        error: '',
        list: action.response.result,
        loading: {...state.loading, applicantList: false}
      })
    case EventTypes.WOTC_APPLICANTS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        loading: {...state.loading, applicantList: false}
      })
    case EventTypes.WOTC_APPLICANT_CREATE_REQUEST:
      return Object.assign({}, state, {
        error: '',
        loading: {...state.loading, newApplicant: true},
        success: {...state.success, newApplicant: false}
      })
    case EventTypes.WOTC_APPLICANT_CREATE_SUCCESS:
      return Object.assign({}, state, {
        error: '',
        list: [action.response.result, ...state.list],
        loading: {...state.loading, newApplicant: false},
        success: {...state.success, newApplicant: true}
      })
    case EventTypes.WOTC_APPLICANT_CREATE_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        loading: {...state.loading, newApplicant: false},
        success: {...state.success, newApplicant: false}
      })
    case EventTypes.WOTC_FORM_REQUEST:
      return Object.assign({}, state, {
        error: '',
        loading: {...state.loading, wotcForm: true},
      })
    case EventTypes.WOTC_FORM_SUCCESS:
      return Object.assign({}, state, {
        error: '',
        loading: {...state.loading, wotcForm: false},
      })
    case EventTypes.WOTC_FORM_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        loading: {...state.loading, wotcForm: false},
      })
    case EventTypes.WOTC_FORM_UPDATE_REQUEST:
      return Object.assign({}, state, {
        error: '',
        loading: {...state.loading, wotcFormUpdate: true},
        success: {...state.success, wotcFormUpdate: false}
      })
    case EventTypes.WOTC_FORM_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        error: '',
        loading: {...state.loading, wotcFormUpdate: false},
        success: {...state.success, wotcFormUpdate: true}
      })
    case EventTypes.WOTC_FORM_UPDATE_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        loading: {...state.loading, wotcFormUpdate: false},
        success: {...state.success, wotcFormUpdate: false}
      })
    default:
      return state
  }
}
