import React from "react";

export const Documentation = (
  <div className="documentationBody">
    <p>
      In order to get started, a hubspot administrator with the permissions
      required to create and suspend users must connect above in the Connection
      Status section. This will redirect them to a dialogue where they grant
      Onepoint access to perform actions on their behalf.
    </p>
    <p>
      If you wish to temporarily stop these automatic actions from occuring,
      select Disabled in the Integration Settings area.
    </p>
    <p>
      <b>Important</b>: under the New Username Settings, select the email
      address format that best suites your company. The hubspot Username should
      match the user's email address.
    </p>
    <p>
      The new user{"'"}s password can be found in the Summary Email that is sent
      to the selected Employee in the User Provisioning Product Setting. The
      first time that the user logs in, they will be prompted to change their
      password.
    </p>
    <p>
      When we generate the new hubspot user, they are assigned the "Standard
      User" Profile. You can change this in hubspot after creation.
    </p>
    <p>
      Upon termination in OnePoint, the user's account in hubspot will be made
      Inactive. They will be blocked from logging in, and it will free up a One
      Login license.
    </p>
    <p>
      We process new hires on the <b>Date Hired</b> in OnePoint, and we process
      terminations using the <b>Date Terminated</b>.
    </p>
  </div>
);
