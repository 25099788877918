import * as AuthTypes from '../actions/auth'

export default function auth(state = {
    error: '',
    isFetching: false,
    isAuthenticated: localStorage.getItem(AuthTypes.TOKEN_KEY) ? true : false
  }, action) {
  switch (action.type) {
    case AuthTypes.LOGIN_REQUEST:
      return Object.assign({}, state, {
        error: '',
        isFetching: true,
        isAuthenticated: false,
      })
    case AuthTypes.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        error: '',
        isFetching: false,
        isAuthenticated: true,
      })
    case AuthTypes.LOGIN_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
        isAuthenticated: false,
      })
    case AuthTypes.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        error: '',
        isFetching: false,
        isAuthenticated: false
      })
    default:
      return state
  }
}
