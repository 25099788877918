import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { NavLink } from 'react-router-dom';
import Loading from './ui/Loading'; // Assuming you have a Loading component
import { getToken } from '../actions/auth'; // Assuming you have a function to get tokens
// import useDownloader from'react-use-downloader';


const InstallationLogs = () => {
    const [isLoading, setLoading] = useState(false);
    const [logs, setLogs] = useState([]);
    const { token, userToken } = getToken();

    const segments = window.location.pathname.split('/');
    var installationId = segments[segments.length - 3];
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('/api/tlm/cp/installations/'+installationId+'/logs',
                {
                    headers: {
                        Opusertoken: userToken,
                        Authorization: token,
                    },
                }
            );
            setLogs(response.data);
            setLoading(false);
        } catch (error) {
            setLoading(true);
            console.error('Error fetching data:', error);
        }
    };
    // const { size, elapsed, percentage, download, cancel, error, isInProgress } = useDownloader();

    const corsProxyUrl = 'https://cors-anywhere.herokuapp.com/';
    // const corsProxyUrl = 'https://cors-anywhere.herokuapp.com/corsdemo/ ';
    console.log('logs',logs);
    const handleDownload = (url, filename) => {
        console.log("url",url)
      fetch(corsProxyUrl + url, {
        headers: {
          'Origin': 'null',
          'x-requested-with': 'XMLHttpRequest'
        }
      })
      .then(response => response.blob())
      .then(blob => {
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      })
      .catch(error => console.error('Error downloading file:', error));
    };
    
    return (
        <div>
            <div className='modalContainer'>
                <div className='sidebarCustom'>
                    <ul className='navlistCustom'>
                        <li>
                            <NavLink to={'/app/system-product/cloudpunch/installation/'+installationId+'/settings'} className={`navitemCustom ${segments[segments.length - 1] === "settings" ? "myactive":""}`}>Security</NavLink>
                        </li>
                        <li>
                            <NavLink to={'/'} className={`navitemCustom ${segments[segments.length - 1] === "logs" ? "myactive":""}`}>Logs</NavLink>
                        </li>
                    </ul>
                </div>
                <div className='mainContentCustom'>
                    <fieldset className='customFieldSet'>
                        <legend>logs</legend>
                        <div>
                            <h2>Installation Logs</h2>
                            <table border="1">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Last Uploaded</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                {isLoading && <Loading />}
                                <tbody>
                                    {logs.map(log => (
                                        <tr key={log.id}>
                                            <td>{log.name}</td>
                                            <td>{log.created_at}</td>
                                            <td>
                                            <button onClick={() => handleDownload(log.url, log.name + '.txt')}>download</button>
                                                {/* <button onClick={()=> handleDownload(log.url)}>Download</button> */}
                                                {/* <button onClick={()=> download(log.url, "filename.txt")}>Download</button> */}
                                                {/* <a href={log.url} target='_blank' download >Download</a> */}
                                                {/* <a href='https://miro.medium.com/v2/resize:fit:720/format:webp/1*ax52sFw39fWy6wGPX1ILMA.png' download={"image"}> hDoneload</a> */}

                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>
    );
};

export default InstallationLogs;

