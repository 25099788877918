import { useState } from "react";
import { getToken } from "../../actions/auth";

const useFetch = () => {
  const [Loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const { token, userToken } = getToken();

  const fetchData = async (payload) => {
    try {
      setLoading(true);
      const { endPoint, method, body } = payload;

      var myHeaders = new Headers();

      if (payload.headers) {
        for (let key in payload.headers) {
          myHeaders.append(key, payload.headers[key]);
        }
      }

      if (!myHeaders.get("Authorization")) {
        myHeaders.append("Authorization", token);
      }

      if (!myHeaders.get("OPUserToken")) {
        myHeaders.append("OPUserToken", userToken);
      }

      let config = {
        headers: myHeaders,
        method,
      };

      if (payload.formData) {
        config.body = body;
      } else {
        config.body = JSON.stringify(body);
      }

      const res = await fetch(endPoint, config);
      const result = await res.json();
      setResponse(result);
      setLoading(false);
      return result;
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      setError(error);
    }
  };

  return [fetchData, Loading, response, error];
};

export default useFetch;
