import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, Route, Switch, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import useToggle from "../dashboard/hooks/useToggle";
import ProductModal from "../dashboard/components/UI/ProductModal";
import MarketPlace from "../components/marketplace/MarketPlace";
import Navigation from "../dashboard/components/layout/Navigation";
import { fetchProducts } from "../actions/products";
import { productDataFetch } from "../actions/dashboard";
import UserProvisioning from "./userProvisioning";
import Loading from "../components/ui/Loading";
import IntegrationESSBody from "./IntegrationESSBody";

const ESSIndex = (props) => {
  const {
    match,
    history,
    dispatch,
    isAuthenticated,
    productData,
    productLoading,
  } = props;
  const [sidebar, sidebarHandle] = useToggle(false);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchProducts());
    }
    dispatch(productDataFetch());
  }, []);

  if (productLoading) {
    return (
      <div className="loader-container">
        <Loading />
      </div>
    );
  }

  return (
    <div className="ess-portal">
      <Navigation
        sideBar
        sidebarValue={sidebar}
        sidebarHandle={sidebarHandle}
      />
      <div className="container-banner">
        <div className="hero-banner-img">{/* <img src={banner} /> */}</div>
        <div className="hero-banner-content">
          <div className="content-data">
            <h2>
              <strong>ONE</strong> employee record.
            </h2>
            <h2>
              <strong>ONE</strong> service provider.
            </h2>
            <h2>
              <strong>ONE</strong> seamless solution.
            </h2>
            <p>
              All your human capital management solutions – unified by the power
              of technology.
            </p>
            <a href="#our-products">
              <button>
                Get Started
                <span className="ms-2">
                  <FontAwesomeIcon icon={solid("arrow-right")} />
                </span>
              </button>
            </a>
          </div>
          {/* <div className="upper-img">
            <img src={onePointInterface} />
          </div> */}
        </div>
      </div>
      <div id="our-products" className={`emp-content sidebar ess`}>
        <div className="emp-body">
          <div className="emp-body-inner">
            <h1 className="tlt_dash text-center">Our Products</h1>

            <div className="my-flex">
              <div>
                <div className="dash-product-row">
                  {Array.isArray(productData) &&
                    productData.map((item) => (
                      <div className="dash-product-card" key={item.product_url}>
                        <Link to={`${match.url}/product/${item.product_url}`}>
                          <div className="prod-card">
                            <div className="card-bar-one">
                              <div>
                                <p className="prod-heading">
                                  {item.product_name}
                                </p>
                                <p>{item.product_description}</p>
                              </div>
                              <div className="prod-img">
                                <img
                                  src={item.image_url}
                                  alt={item.product_name}
                                />
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="marketplacecard-container">
              {Array.isArray(productData) && productData.length >= 1 && (
                <MarketPlace productData={productData} />
              )}
            </div>
          </div>
          <footer className="footer_sec">
            <div class="text-center p-3">
              © {new Date().getFullYear()} Copyright :
              <a class="text ms-1" href="/">
                connect.onehcm.com
              </a>
            </div>
          </footer>
        </div>
      </div>
      <Switch>
        <Route
          path={`${match.url}/product/user-provisioning`}
          component={() => (
            <ProductModal
              heading="User Provisioning"
              closeModal={history.goBack}
              history={history}
            >
              <UserProvisioning />
            </ProductModal>
          )}
        />
        <Route
          path={`${match.url}/integrations/:integrationName`}
          component={() => (
            <ProductModal
              heading="User Provisioning"
              closeModal={history.goBack}
              history={history}
            >
              <IntegrationESSBody />
            </ProductModal>
          )}
        />
      </Switch>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    list,
    isFetching: productsAreFetching,
    companyInfo,
    userInfo,
  } = state.products;
  const { products } = state.entities;
  const { isAuthenticated } = state.auth;
  const { productData, productLoading } = state.dashboard;

  const prodListJoin =
    list === undefined
      ? []
      : list.map((prodURL) => {
          return products[prodURL];
        });

  return {
    productsAreFetching,
    products: prodListJoin,
    companyInfo,
    userInfo,
    isAuthenticated,
    productData,
    productLoading,
  };
};

export default withRouter(connect(mapStateToProps)(ESSIndex));
