import React from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";

const IntegrationDataTable = ({ data, row, copyRow }) => {
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState(row);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const pageCount = Math.ceil(rowData.length / rowPerPage);
  const offset = currentPage * rowPerPage;
  const rowPerPageValue = [10, 25, 50, 100, 150, 200];

  const handleSearchChange = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    const filterData = copyRow.filter((item) => {
      let firstArray = Object.values(item);
      let secondArray = Object.values(firstArray[firstArray.length - 1]);

      delete firstArray[firstArray.length - 1];
      return [...firstArray, ...secondArray]
        .join("")
        .toLocaleLowerCase()
        .includes(value.toLocaleLowerCase());
    });

    setRowData(filterData);
  };

  const handleSelectSort = (e) => {
    const { value } = e.target;
    setCurrentPage(0);
    setRowPerPage(value);
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  return (
    <div>
      <div className="integration-table">
        <div className="header-action">
          <select
            className="sort-select"
            onChange={handleSelectSort}
            name=""
            id=""
            defaultValue={rowPerPage}
          >
            {rowPerPageValue.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
          />
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="date">Date Processed</th>
            <th className="event">Event</th>
            <th className="first">First Name</th>
            <th className="last">Last Name</th>
            <th className="email">
              {data.url === "activedirectory"
                ? `Onepoint connect Username`
                : `Onepoint connect Primary Email`}
            </th>
            <th className="statusz">Result</th>
            <th className="description">Description</th>
          </tr>
        </thead>
        <tbody>
          {rowData.slice(offset, offset + rowPerPage).map((event) => (
            <tr className="row" key={"result" + event.id}>
              <td className="col date">{event.event_date}</td>
              <td className="col event">
                {event.type === 1 && "HIRED"}
                {event.type === 2 && "TERMINATED"}
                {event.type === 3 && "MODIFIED"}
              </td>
              <td className="col first">{event.first_name}</td>
              <td className="col last">{event.last_name}</td>
              <td className="col email">{event.email}</td>
              <td className={"col statusz " + event.result.status}>
                {event.result.status}
              </td>
              <td className="col description">{event.result.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="previous"
        renderOnZeroPageCount={null}
        containerClassName={"pagination-container"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"disabled"}
        activeClassName={"active"}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
      />
    </div>
  );
};

export default IntegrationDataTable;
