import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fetchFormData, updateFormData } from '../actions/wotc'

import WOTCForm from '../components/WOTCForm'

const FORM_TOKEN_QUERY_PARAM = "formToken"

class FormContainer extends Component {
  componentWillMount() {
    const { isAuthenticated, applicantID, formURL, token, dispatch,
      applicant, form } = this.props
    let apiURL = `api/wotc/applicants/${applicantID}/loadForm/${formURL}`
    if (!isAuthenticated) {
      apiURL = apiURL + `?${FORM_TOKEN_QUERY_PARAM}=${token}`
    }

    if (applicant === null || form === null) {
      dispatch(fetchFormData(apiURL))
    }
  }

  saveForm(data) {
    const { isAuthenticated, applicantID, formURL, token, dispatch } = this.props
    let apiURL = `api/wotc/applicants/${applicantID}/updateForm/${formURL}`
    if (!isAuthenticated) {
      apiURL = apiURL + `?${FORM_TOKEN_QUERY_PARAM}=${token}`
    }

    dispatch(updateFormData(apiURL, data))
  }

  render() {
    const { loading, applicant, form, formURL, isAuthenticated,
      entirePage, success } = this.props

    return (
      <WOTCForm
        isAuthenticated={isAuthenticated}
        entirePage={entirePage}
        formURL={formURL}
        applicant={applicant}
        form={form}
        loading={loading}
        saveForm={this.saveForm.bind(this)}
        success={success}
        />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { formURL, applicantID, token } = ownProps

  const { isAuthenticated } = state.auth
  const { loading, success } = state.wotc
  const { wotc_applicants, wotc_forms } = state.entities

  const applicant = wotc_applicants[applicantID] ? Object.assign({}, wotc_applicants[applicantID]) : null
  const form = applicant !== null ? Object.assign({}, wotc_forms[applicant.form_id]) : null

  return {
    applicant,
    form,
    applicantID,
    formURL,
    token,
    isAuthenticated,
    loading: loading.wotcForm,
    success
  }
}

export default connect(mapStateToProps)(FormContainer)
