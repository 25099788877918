import React from "react";

const CheckBox = ({ label, value, name, onChange, checked }) => {
  let id = label.replaceAll(" ", "-");
  return (
    <div className="one-control-input">
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

export default CheckBox;
