import * as ActionTypes from '../actions/google_hire'

const initialState = {
  error: '',
  isFetching: false,
  isDeauthing: false,
  success: false,
  auth_info: null,
  createdAppsList: [],
}

export default function google_hire(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.GOOGLEHIRE_REQUEST:
      return Object.assign({}, state, {
        error: '',
        isFetching: true,
      })
    case ActionTypes.GOOGLEHIRE_SUCCESS:
      return Object.assign({}, state, {
        error: '',
        isFetching: false,
        success: true,
        auth_info: action.response.result.auth_info,
        createdAppsList: action.response.result.created_applicants,
      })
    case ActionTypes.GOOGLEHIRE_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
      })
    case ActionTypes.GOOGLEHIRE_DEAUTH_REQUEST:
      return Object.assign({}, state, {
        isDeauthing: true,
      })
    case ActionTypes.GOOGLEHIRE_DEAUTH_SUCCESS:
      return Object.assign({}, state, {
        isDeauthing: false,
        auth_info: action.response.auth_info
      })
    case ActionTypes.GOOGLEHIRE_DEAUTH_FAILURE:
      return Object.assign({}, state, {
        isDeauthing: false,
      })
    default:
      return state
  }
}
