import React, { Component } from 'react'

import '../../styles/SettingComponents.css';

const options = [
  {
    inputs: {
      employee_id: true,
      password: false,
      photo: false,
      username: false
    },
    choiceLabel: 'Employee ID Only'
  },
  {
    inputs: {
      employee_id: true,
      password: false,
      photo: true,
      username: false,
    },
    choiceLabel: 'Employee ID with Photo'
  },
  {
    inputs: {
      employee_id: false,
      password: true,
      photo: false,
      username: true,
    },
    choiceLabel: 'Username & Password Only'
  },
  {
    inputs: {
      employee_id: false,
      password: true,
      photo: true,
      username: true,
    },
    choiceLabel: 'Username & Password with Photo'
  }
]

class WebclockInputs extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.change = this.change.bind(this)
    this.isChosen = this.isChosen.bind(this)
  }

  isChosen(optionInputs, savedInputs) {
    const fields = ['employee_id', 'password', 'photo', 'username']
    let areSame = true
    fields.forEach(field => {
      if (optionInputs[field] !== savedInputs[field]) {
        areSame = false
        return
      }
    })

    if (areSame) {
      return 'chosen nodeWithX'
    } else {
      return 'nodeWithX'
    }
  }

  change(choice) {
    this.props.onChange(choice)
  }

  render() {
    const { value } = this.props

    return (
      <div className="WebclockInputs list">
        {
          options.map((option, i) => (
            <span onClick={() => this.change(option.inputs)} className={this.isChosen(option.inputs, value)} key={option.choiceLabel}>
              {option.choiceLabel}
            </span>
          ))
        }
      </div>
    )
  }
}

export default WebclockInputs
