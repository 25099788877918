import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const CustomReportForm = (props) => {
  const {
    customOpen,
    handleCustomOpen,
    formValue,
    handleChange,
    handleAddCustomReportID,
  } = props;
  const reportsData =
    formValue.activeReportIndex !== null
      ? formValue.customReportName[formValue.activeReportIndex].reports
      : [];

  return (
    <div className={`custom-report-multiple ${customOpen && "active"}`}>
      <div>
        <span className="icon-btn" onClick={handleCustomOpen(null)}>
          <FontAwesomeIcon icon={solid("xmark")} />
        </span>
      </div>
      <div className="px-4">
        <h1>Custom Report</h1>
        <div className="one-select-option mb-2">
          <div>
            <strong>Association Company</strong>
          </div>
          <select
            value={formValue.customReportCompany}
            name="customReportCompany"
            onChange={handleChange}
          >
            <option value="" disabled>
              Please select association
            </option>
            {formValue.associateCompanyList.map((company) => {
              return (
                <option key={company.title} value={JSON.stringify(company)}>
                  {company.title}
                </option>
              );
            })}
          </select>
        </div>
        <div className="my-flex my-align-item-end mb-2">
          <div className="one-control-input one-col-11">
            <label htmlFor="">Report ID</label>
            <input
              type="text"
              placeholder="Report ID"
              name="customReportId"
              onChange={handleChange}
              value={formValue.customReportId}
            />
            {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
          </div>
          <div className="ms-1 one-control-button1">
            <button type="button" onClick={handleAddCustomReportID}>
              <FontAwesomeIcon icon={solid("plus")} />
            </button>
          </div>
        </div>
        <div className="mb-2">
          <table>
            <thead>
              <tr>
                <th>S.NO</th>
                <th>Company Name</th>
                <th>Report ID</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(reportsData) &&
                reportsData.map((report, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{report.title}</td>
                      <td>{report.report_id}</td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <div className="mul-association-btn my-flex my-justify-content-end">
          <button onClick={handleCustomOpen(null)}>Back</button>
        </div>
      </div>
    </div>
  );
};

export default CustomReportForm;
