import React, { Component } from "react";
import "../styles/BaseProduct.css";

class SyncCompanyModel extends Component {
  render() {
    return (
      <>
        {this.props.toggleModal && (
          <div id="modalOuter" className="custom-modal">
            <div id="myModal" className="custom-modal">
              <div className="modal-content">
                <h1 className="text-center">Please Wait...</h1>
                <p className="text-center">
                  Do not back and refresh, Employee data is syncing.
                </p>
                <div className="row d-flex1"></div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default SyncCompanyModel;
