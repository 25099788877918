import React, { Component } from 'react'
import PropTypes from 'prop-types'

import BaseSetting from './BaseProductSetting'

class WOTCConstantsSetting extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {value: null}

    this.textChange = this.textChange.bind(this)
  }

  componentWillMount() {
    this.setState({value: this.props.value})
  }

  componentWillReceiveProps(newProps) {
    this.setState({value: newProps.value})
  }

  textChange(cat, field, newVal) {
    this.setState({value: {...this.state.value, [cat]: {...this.state.value[cat], [field]: newVal}}});
  }

  render() {
    const { title, tip, value, onChange } = this.props
    const { value: stateVal } = this.state

    if (!stateVal) {
      return
    }

    return (
      <BaseSetting title={title} tip={tip}>
        <div className="settingsCont WOTCConstantsSetting">
          <div className="section">
            <div className="header">
              Employer Info
            </div>
            <div className="formCont">
              <div className="formRow">
                <label>
                  Name
                </label>
                <input type="text" value={stateVal.employer.name} onChange={(e) => this.textChange('employer', 'name', e.target.value)} />
              </div>
              <div className="formRow">
                <label>
                  Telephone Number
                </label>
                <input type="text" value={stateVal.employer.telephone_number} onChange={(e) => this.textChange('employer', 'telephone_number', e.target.value)} />
              </div>
              <div className="formRow">
                <label>
                  EIN
                </label>
                <input type="text" value={stateVal.employer.ein} onChange={(e) => this.textChange('employer', 'ein', e.target.value)} />
              </div>
              <div className="formRow">
                <label>
                  Street Address
                </label>
                <input type="text" value={stateVal.employer.street_address} onChange={(e) => this.textChange('employer', 'street_address', e.target.value)} />
              </div>
              <div className="formRow">
                <label>
                  City, State, and ZIP Code
                </label>
                <input type="text" value={stateVal.employer.city_state_zip} onChange={(e) => this.textChange('employer', 'city_state_zip', e.target.value)} />
              </div>
            </div>
          </div>
          <div className="section">
            <div className="header">
              Person to Contact
            </div>
            <div className="formCont">
              <div className="formRow">
                <label>
                  Name
                </label>
                <input type="text" value={stateVal.contact.name} onChange={(e) => this.textChange('contact', 'name', e.target.value)} />
              </div>
              <div className="formRow">
                <label>
                  Title
                </label>
                <input type="text" value={stateVal.contact.title} onChange={(e) => this.textChange('contact', 'title', e.target.value)} />
              </div>
              <div className="formRow">
                <label>
                  Telephone Number
                </label>
                <input type="text" value={stateVal.contact.telephone_number} onChange={(e) => this.textChange('contact', 'telephone_number', e.target.value)} />
              </div>
              <div className="formRow">
                <label>
                  Street Address
                </label>
                <input type="text" value={stateVal.contact.street_address} onChange={(e) => this.textChange('contact', 'street_address', e.target.value)} />
              </div>
              <div className="formRow">
                <label>
                  City, State, and ZIP Code
                </label>
                <input type="text" value={stateVal.contact.city_state_zip} onChange={(e) => this.textChange('contact', 'city_state_zip', e.target.value)} />
              </div>
            </div>
          </div>
          {
            JSON.stringify(value) !== JSON.stringify(this.state.value)
            &&
            (
              <div className="bottom" style={{marginTop: '10px'}}>
                <button onClick={() => onChange(stateVal)}>
                  SAVE
                </button>
              </div>
            )
          }
        </div>
      </BaseSetting>
    )
  }
}

export default WOTCConstantsSetting
