import React from 'react'
import JobsSiteOuter from './JobsSiteOuter'
import Row from '../ui/JobAppRow'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

// see http://opensource.indeedeng.io/api-documentation/docs/xml-feed-ia/#resume_fields
function dateToString(month, year) {
  month = Number(month)

  if (year === -1) {
    return 'N/A'
  }

  if (month === -1) {
    return year
  }

  return month === -1 ? 'N/A' : months[month] + ', ' + year
}

function endDateString(month, year, current) {
  if (current) {
    return 'Present'
  }

  return dateToString(month, year)
}

const Indeed = ({ markAsReviewed, application, sendEmail, idUpdating }) => {
    return (
      <JobsSiteOuter markAsReviewed={markAsReviewed} application={application} sendEmail={sendEmail} idUpdating={idUpdating}>
        <div>
          <table>
            <tbody>
              <Row label={'Email Address'} value={application.applicant_email} />
              <Row label={'Phone Number'} value={application.applicant_phone_number} />
              <Row label={'Headline'} value={application.data.headline} />
              <Row label={'Summary'} value={application.data.summary} />
              <Row label={'Additional Info'} value={application.data.additionalInfo} />
              {
                application.data.location
                &&
                (
                  <Row label={'City'} value={application.data.location.city} />
                )
              }
              <Row label={'Skills'} value={application.data.skills} />
            </tbody>
          </table>
        </div>
        <div>
          <div className="title">
            Positions
          </div>
          <div className="multi">
            {
              application.data.positions._total === 0
              ?
              (
                <div>
                  None submitted.
                </div>
              )
              :
              (
                application.data.positions.values.map((pos, index) => (
                  <table key={'pos'+index}>
                    <tbody>
                      <Row label={'Title'} value={pos.title} />
                      <Row label={'Company'} value={pos.company} />
                      <Row label={'Location'} value={pos.location} />
                      <Row label={'Description'} value={pos.description} />
                      <Row label={'Time Period'} value={dateToString(pos.startDateMonth, pos.startDateYear) + ' - ' + endDateString(pos.endDateMonth, pos.endDateYear, pos.endCurrent)} />
                    </tbody>
                  </table>
                ))
              )
            }
          </div>
        </div>
        <div>
          <div className="title">
            Education
          </div>
          <div className="multi">
            {
              application.data.educations._total === 0
              ?
              (
                <div>
                  None submitted.
                </div>
              )
              :
              (
                application.data.educations.values.map((edu, index) => (
                  <table key={'edus'+index}>
                    <tbody>
                      <Row label={'School'} value={edu.school} />
                      <Row label={'Location'} value={edu.location} />
                      <Row label={'Field'} value={edu.field} />
                      <Row label={'Degree'} value={edu.degree} />
                      <Row label={'Time Period'} value={dateToString(-1, edu.startDate) + ' - ' + endDateString(-1, edu.endDate, edu.endCurrent)} />
                    </tbody>
                  </table>
                ))
              )
            }
          </div>
        </div>
        <div className="disclaimer">
          Note: not all information included in the Indeed application is shown here.
        </div>
      </JobsSiteOuter>
    )
}

export default Indeed
