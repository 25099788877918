import * as OauthTypes from '../actions/oauth'

const initialState = {
  error: '',
  isPosting: false,
  success: false,
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case OauthTypes.NEW_TOKEN_REQUEST:
      return Object.assign({}, state, {
        error: '',
        isPosting: true,
      })
    case OauthTypes.NEW_TOKEN_SUCCESS:
      return Object.assign({}, state, {
        error: '',
        isPosting: false,
        success: true,
      })
    case OauthTypes.NEW_TOKEN_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isPosting: false,
      })
    default:
      return state
  }
}
