import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "./ui/Loading";
import logo from "../assets/images/op-logo-cloud.png";
import "url-search-params-polyfill";

import "../styles/LoginBox.css";

class Login extends Component {
  static propTypes = {
    login: PropTypes.func.isRequired,
    loginFetching: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      csInput: "",
      unInput: "",
      pwInput: "",
      error: "",
      opAdmin: false,
    };

    this.handleCSChange = this.handleCSChange.bind(this);
    this.handleUNChange = this.handleUNChange.bind(this);
    this.handlePWChange = this.handlePWChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleAdmin = this.toggleAdmin.bind(this);
    this.decideAdmin = this.decideAdmin.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { error: beforeError } = this.props;
    const { error: afterError } = newProps;

    if (beforeError !== afterError) {
      this.setState({ error: afterError });
    }
  }

  componentWillMount() {
    this.decideAdmin();
  }

  decideAdmin() {
    var queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("admin") === "" || queryParams.get("admin")) {
      this.setState({ opAdmin: true });
    }
  }

  handleCSChange(event) {
    this.setState({ csInput: event.target.value, error: "" });
  }

  handleUNChange(event) {
    this.setState({ unInput: event.target.value, error: "" });
  }

  handlePWChange(event) {
    this.setState({ pwInput: event.target.value, error: "" });
  }

  toggleAdmin() {
    this.setState({ opAdmin: !this.state.opAdmin });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { csInput, unInput, pwInput, opAdmin } = this.state;
    const { login } = this.props;

    if (csInput === "" || unInput === "" || pwInput === "") {
      this.setState({ error: "All fields are required." });
      return;
    }
    localStorage.setItem("isAdmin", opAdmin);
    login(csInput, unInput, pwInput, opAdmin);
  }

  handleAdminChange = (e) => {
    this.setState({ opAdmin: e.target.value === "admin" ? true : false });
  };

  render() {
    const { loginFetching } = this.props;
    const { csInput, unInput, pwInput, error, opAdmin } = this.state;

    return (
      <div className="login-container">
        <div className="login-box">
          <div className="login-logo">
            <img src={logo} alt="logo" />
          </div>
          <div className="header">
            {opAdmin
              ? "Enter your admin account credentials and the Short Name of the company you want to login as"
              : "Login to your OnePoint HCM account"}
          </div>

          {error !== "" && <div className="error color-red">{error}</div>}

          {loginFetching ? (
            <Loading />
          ) : (
            <div>
              <div className="form">
                {/* <div className="row mb-2 my-flex my-justify-content-center">
                  <div>
                    <input
                      type="radio"
                      id="User"
                      name="role"
                      value={"user"}
                      checked={opAdmin === false}
                      onChange={this.handleAdminChange}
                    />
                    <label htmlFor="User">User</label>
                  </div>
                  <div className="ms-4">
                    <input
                      type="radio"
                      id="Admin"
                      name="role"
                      checked={opAdmin === true}
                      value={"admin"}
                      onChange={this.handleAdminChange}
                    />
                    <label htmlFor="Admin">Admin</label>
                  </div>
                </div> */}

                <div className="row mb-2">
                  <div className="label">Company Short Name</div>
                  <div className="one-input">
                    <input
                      type="text"
                      placeholder="Company Short Name"
                      value={csInput}
                      onChange={this.handleCSChange}
                      autoFocus={true}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="label">Username</div>
                  <div className="one-input">
                    <input
                      type="text"
                      placeholder="Username"
                      value={unInput}
                      onChange={this.handleUNChange}
                      autoFocus={true}
                    />
                  </div>
                </div>
                <div className="row mb-2">
                  <div className="label">Password</div>
                  <div className="one-input">
                    <input
                      type="password"
                      placeholder="Password"
                      value={pwInput}
                      onChange={this.handlePWChange}
                      autoFocus={true}
                    />
                  </div>
                </div>

                <div>
                  <button onClick={this.handleSubmit} className="login-btn">
                    Login
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Login;
