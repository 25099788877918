import React, { memo } from "react";
import NotificationEmailSetting from "./NotificationEmailSetting";
import { saveSettings } from "../../../actions/products";
import Loading from "../../../components/ui/Loading";
import AccessControl from "./AccessControl";
import { connect } from "react-redux";
import IntegrationLogs from "./IntegrationLogs";

const UserProvisioningSettings = (props) => {
  
  const { settings, dispatch, companyProductId, productsAreUpdating } = props;
  const segments = window.location.pathname.split('/');
  const product_name = segments[segments.length - 1];
  const handleUpdateSettings = (key, values) => {
    settings[key] = values;
    dispatch(saveSettings(settings, companyProductId));
  };

  const renderSettings = (key, value) => {
    switch (key) {
      case "notification_email_recipient":
        return (
          <NotificationEmailSetting
            key={key}
            value={value}
            handleUpdateSettings={(updateValue) =>
              handleUpdateSettings(key, updateValue)
            }
          />
        );
      // case "hired_timing":

      default:
        return null;
    }
  };

  return (
    <div>
      {productsAreUpdating && (
        <div className="loadCont">
          <Loading />
        </div>
      )}
      <h3 className="mb-2">Settings</h3>
      {Object.keys(settings).map((item) => {
        return renderSettings(item, settings[item]);
      })}
      {product_name === "user-provisioning" && <IntegrationLogs />}
      <AccessControl />
    </div>
  );
  
};

export default memo(connect()(UserProvisioningSettings));
