export const TOKEN_KEY = "opintx_token";
export const USER_TOKEN_KEY = "opintx_user_token";
export const SITES_KEY = "sites";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

function requestLogin(creds) {
  return {
    type: LOGIN_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds,
  };
}

function receiveLogin(user) {
  return {
    type: LOGIN_SUCCESS,
    isFetching: false,
    isAuthenticated: true,
  };
}

function loginError(error) {
  return {
    type: LOGIN_FAILURE,
    isFetching: false,
    isAuthenticated: false,
    error,
  };
}

export function loginUser(creds) {
  let config = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      company_short: creds.shortName,
      username: creds.username,
      password: creds.password,
      is_admin: creds.opAdmin,
    }),
  };

  return (dispatch) => {
    dispatch(requestLogin(creds));

    return fetch("/api/login", config)
      .then((response) => response.json().then((user) => ({ user, response })))
      .then(({ user, response }) => {
        if (!response.ok) {
          dispatch(loginError(user.error));
          return Promise.reject(user);
        } else {
          localStorage.setItem(TOKEN_KEY, user.token);
          localStorage.setItem(USER_TOKEN_KEY, user.user_token);
          localStorage.setItem(SITES_KEY, user.sites_key);
          dispatch(receiveLogin(user));
        }
      })
      .catch((err) => console.log("Error: ", err));
  };
}

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

function requestLogout() {
  return {
    type: LOGOUT_REQUEST,
    isFetching: true,
    isAuthenticated: true,
  };
}

function receiveLogout() {
  return {
    type: LOGOUT_SUCCESS,
    isFetching: false,
    isAuthenticated: false,
  };
}

export function logoutUser() {
  return (dispatch) => {
    dispatch(requestLogout());
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(USER_TOKEN_KEY);
    dispatch(receiveLogout());
  };
}

export function getToken() {
  return {
    token: localStorage.getItem(TOKEN_KEY),
    userToken: localStorage.getItem(USER_TOKEN_KEY),
    sites: localStorage.getItem(SITES_KEY),
  };
}
