import React, { Component } from 'react'
import PropTypes from 'prop-types'

class SimpleIntegrationTextSetting extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    this.props.onChange(event.target.value);
  }

  render() {
    const { value } = this.props

    return (
      <div className="SimpleTextSetting">
        <div className="top">
          <input type="text" value={value} onChange={this.handleChange} />
        </div>
      </div>
    )
  }
}

export default SimpleIntegrationTextSetting
