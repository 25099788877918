import React from "react";
import closeButton from "../../assets/images/close-white.png";

import "../../styles/Modal.css";

const Modal = (props) => {
  return (
    <div className="modalOuter" onClick={() => props.closeModal()}>
      <img className="close" src={closeButton} alt="close" />
      <div
        className="modalBody"
        style={props.width ? { width: props.width } : {}}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
