import { CALL_API, Schemas } from "../middleware/api";

export const CP_REQUEST = "CP_REQUEST";
export const CP_SUCCESS = "CP_SUCCESS";
export const CP_FAILURE = "CP_FAILURE";
export const CP_INST_REQUEST = "CP_INST_REQUEST";
export const CP_INST_SUCCESS = "CP_INST_SUCCESS";
export const CP_INST_FAILURE = "CP_INST_FAILURE";
export const CP_EMP_REQUEST = "CP_EMP_REQUEST";
export const CP_EMP_SUCCESS = "CP_EMP_SUCCESS";
export const CP_EMP_FAILURE = "CP_EMP_FAILURE";
export const CP_DELETEENR_REQUEST = "CP_DELETEENR_REQUEST";
export const CP_DELETEENR_SUCCESS = "CP_DELETEENR_SUCCESS";
export const CP_DELETEENR_FAILURE = "CP_DELETEENR_FAILURE";
export const CP_UPDATEDINSTALL_REQUEST = "CP_UPDATEDINSTALL_REQUEST";
export const CP_UPDATEDINSTALL_SUCCESS = "CP_UPDATEDINSTALL_SUCCESS";
export const CP_UPDATEDINSTALL_FAILURE = "CP_UPDATEDINSTALL_FAILURE";
export const CP_UPDATEDEMP_REQUEST = "CP_UPDATEDEMP_REQUEST";
export const CP_UPDATEDEMP_SUCCESS = "CP_UPDATEDEMP_SUCCESS";
export const CP_UPDATEDEMP_FAILURE = "CP_UPDATEDEMP_FAILURE";
export const CP_ASSOCIATION_REQUEST = "CP_ASSOCIATION_REQUEST";
export const CP_ASSOCIATION_FAILURE = "CP_ASSOCIATION_FAILURE";
export const CP_ASSOCIATION_SUCCESS = "CP_ASSOCIATION_SUCCESS";
export const CP_SEARCH_SUCCESS = "CP_SEARCH_SUCCESS";
export const CP_SEARCH_REQUEST = "CP_SEARCH_REQUEST";
export const CP_SEARCH_FAILURE = "CP_SEARCH_FAILURE";
export const CP_POST_ASSOCIATION_SUCCESS = "CP_POST_ASSOCIATION_SUCCESS";
export const CP_POST_ASSOCIATION_REQUEST = "CP_POST_ASSOCIATION_REQUEST";
export const CP_POST_ASSOCIATION_FAILURE = "CP_POST_ASSOCIATION_FAILURE";
export const CP_DELETE_ASSOCIATION_SUCCESS = "CP_DELETE_ASSOCIATION_SUCCESS";
export const CP_DELETE_ASSOCIATION_REQUEST = "CP_DELETE_ASSOCIATION_REQUEST";
export const CP_DELETE_ASSOCIATION_FAILURE = "CP_DELETE_ASSOCIATION_FAILURE";
export const CP_REACTIVE_ASSOCIATION_SUCCESS =
  "CP_REACTIVE_ASSOCIATION_SUCCESS";
export const CP_REACTIVE_ASSOCIATION_REQUEST =
  "CP_REACTIVE_ASSOCIATION_REQUEST";
export const CP_REACTIVE_ASSOCIATION_FAILURE =
  "CP_REACTIVE_ASSOCIATION_FAILURE";
export const CP_GET_ASSOCIATION_BYID_SUCCESS =
  "CP_GET_ASSOCIATION_BYID_SUCCESS";
export const CP_GET_ASSOCIATION_BYID_REQUEST =
  "CP_GET_ASSOCIATION_BYID_REQUEST";
export const CP_GET_ASSOCIATION_BYID_FAILURE =
  "CP_GET_ASSOCIATION_BYID_FAILURE";
export const DELETE_ASSOCIATION_COMPANY_SUCCESS =
  "DELETE_ASSOCIATION_COMPANY_SUCCESS";
export const DELETE_ASSOCIATION_COMPANY_REQUEST =
  "DELETE_ASSOCIATION_COMPANY_REQUEST";
export const DELETE_ASSOCIATION_COMPANY_FAILURE =
  "DELETE_ASSOCIATION_COMPANY_FAILURE";
export const EDIT_ASSOCIATION_COMPANY_SUCCESS =
  "EDIT_ASSOCIATION_COMPANY_SUCCESS";
export const EDIT_ASSOCIATION_COMPANY_REQUEST =
  "EDIT_ASSOCIATION_COMPANY_REQUEST";
export const EDIT_ASSOCIATION_COMPANY_FAILURE =
  "EDIT_ASSOCIATION_COMPANY_FAILURE";
export const GET_ASSOCIATION_COMPANY_SUCCESS =
  "GET_ASSOCIATION_COMPANY_SUCCESS";
export const GET_ASSOCIATION_COMPANY_REQUEST =
  "GET_ASSOCIATION_COMPANY_REQUEST";
export const GET_ASSOCIATION_COMPANY_FAILURE =
  "GET_ASSOCIATION_COMPANY_FAILURE";
export const CLOUD_PUNCH_SUCCESS = "CLOUD_PUNCH_SUCCESS";
export const CLOUD_PUNCH_REQUEST = "CLOUD_PUNCH_REQUEST";
export const CLOUD_PUNCH_FAILURE = "CLOUD_PUNCH_FAILURE";
export const GET_CLOUD_PUNCH_SUCCESS = "CLOUD_PUNCH_SUCCESS";
export const GET_CLOUD_PUNCH_REQUEST = "CLOUD_PUNCH_REQUEST";
export const GET_CLOUD_PUNCH_FAILURE = "CLOUD_PUNCH_FAILURE";
export const EMPLOYEE_REPORT_CATCH_SUCCESS = "CLOUD_PUNCH_SUCCESS";
export const EMPLOYEE_REPORT_CATCH_REQUEST = "CLOUD_PUNCH_REQUEST";
export const EMPLOYEE_REPORT_CATCH_FAILURE = "CLOUD_PUNCH_FAILURE";
export const ASSOCIATION_DELETE_COMPANY_REPORT_SUCCESS =
  "ASSOCIATION_DELETE_COMPANY_REPORT_SUCCESS";
export const ASSOCIATION_DELETE_COMPANY_REPORT_REQUEST =
  "ASSOCIATION_DELETE_COMPANY_REPORT_REQUEST";
export const ASSOCIATION_DELETE_COMPANY_REPORT_FAILURE =
  "ASSOCIATION_DELETE_COMPANY_REPORT_FAILURE";
export const ASSOCIATION_DELETE_COMPANY_REPORT_NAME_SUCCESS =
  "ASSOCIATION_DELETE_COMPANY_REPORT_NAME_SUCCESS";
export const ASSOCIATION_DELETE_COMPANY_REPORT_NAME_REQUEST =
  "ASSOCIATION_DELETE_COMPANY_REPORT_NAME_REQUEST";
export const ASSOCIATION_DELETE_COMPANY_REPORT_NAME_FAILURE =
  "ASSOCIATION_DELETE_COMPANY_REPORT_NAME_FAILURE";

const fetchCPInfox = () => ({
  [CALL_API]: {
    types: [CP_REQUEST, CP_SUCCESS, CP_FAILURE],
    endpoint: "api/tlm/cp/companyinfo",
    schema: Schemas.CP_RESP,
  },
});

export const fetchCPInfo = () => (dispatch, getState) => {
  return dispatch(fetchCPInfox());
};

const fetchCPInstallsx = () => ({
  [CALL_API]: {
    types: [CP_INST_REQUEST, CP_INST_SUCCESS, CP_INST_FAILURE],
    endpoint: "api/tlm/cp/companyinfo/installations",
    schema: Schemas.CP_INSTALLS_RESP,
  },
});

export const fetchCPInstalls = () => (dispatch, getState) => {
  return dispatch(fetchCPInstallsx());
};

const fetchCPEmpsx = () => ({
  [CALL_API]: {
    types: [CP_EMP_REQUEST, CP_EMP_SUCCESS, CP_EMP_FAILURE],
    endpoint: "api/tlm/cp/companyinfo/employees",
    schema: Schemas.CP_EMPS_RESP,
  },
});

export const fetchCPEmps = () => (dispatch, getState) => {
  return dispatch(fetchCPEmpsx());
};

const deleteCPEnrollmentx = (id) => ({
  [CALL_API]: {
    types: [CP_DELETEENR_REQUEST, CP_DELETEENR_SUCCESS, CP_DELETEENR_FAILURE],
    endpoint: "api/tlm/cp/sync/deleteenrollment",
    schema: Schemas.CP_ENROLLMENT,
    postdata: { global_id: id },
  },
});

export const deleteCPEnrollment = (id) => (dispatch, getState) => {
  return dispatch(deleteCPEnrollmentx(id));
};

const updateInstallationx = (installToken, updateBody) => ({
  [CALL_API]: {
    types: [
      CP_UPDATEDINSTALL_REQUEST,
      CP_UPDATEDINSTALL_SUCCESS,
      CP_UPDATEDINSTALL_FAILURE,
    ],
    endpoint: `api/tlm/cp/installations/${installToken}/update`,
    schema: Schemas.CP_INSTALL,
    postdata: updateBody,
  },
});

export const updateInstallation =
  (installToken, updateBody) => (dispatch, getState) => {
    return dispatch(updateInstallationx(installToken, updateBody));
  };

const updateEmployeex = (empAccountID) => ({
  [CALL_API]: {
    types: [
      CP_UPDATEDEMP_REQUEST,
      CP_UPDATEDEMP_SUCCESS,
      CP_UPDATEDEMP_FAILURE,
    ],
    endpoint: `api/tlm/cp/companyinfo/employee/${empAccountID}/toggleActive`,
    schema: Schemas.CP_EMPLOYEE,
    postdata: { force: "POST" },
  },
});

export const updateEmployee = (empAccountID) => (dispatch, getState) => {
  return dispatch(updateEmployeex(empAccountID));
};

const fetchAssociationsx = (id) => ({
  [CALL_API]: {
    types: [
      CP_ASSOCIATION_REQUEST,
      CP_ASSOCIATION_SUCCESS,
      CP_ASSOCIATION_FAILURE,
    ],
    endpoint: `api/tlm/cp/associations`,
  },
});

export const fetchAssociations = (id) => (dispatch, getState) => {
  return dispatch(fetchAssociationsx(id));
};

const searchCompaniesx = (company_name) => ({
  [CALL_API]: {
    types: [CP_SEARCH_REQUEST, CP_SEARCH_SUCCESS, CP_SEARCH_FAILURE],
    endpoint: `api/tlm/cp/search/company/byname/${company_name}`,
  },
});

export const searchCompanies = (company_name) => (dispatch, getState) => {
  return dispatch(searchCompaniesx(company_name));
};

const postAssociationx = (body) => ({
  [CALL_API]: {
    types: [
      CP_POST_ASSOCIATION_REQUEST,
      CP_POST_ASSOCIATION_SUCCESS,
      CP_POST_ASSOCIATION_FAILURE,
    ],
    endpoint: `api/tlm/cp/company/associations`,
    postdata: body,
  },
});

export const postAssociation = (body) => (dispatch, getState) => {
  return dispatch(postAssociationx(body));
};

const deleteAssociationx = (body) => ({
  [CALL_API]: {
    types: [
      CP_DELETE_ASSOCIATION_SUCCESS,
      CP_DELETE_ASSOCIATION_REQUEST,
      CP_DELETE_ASSOCIATION_FAILURE,
    ],
    endpoint: `api/tlm/cp/inactive/association`,
    postdata: body,
  },
});

export const deleteAssociation = (body) => (dispatch, getState) => {
  return dispatch(deleteAssociationx(body));
};

const reActiveAssociationx = (body) => ({
  [CALL_API]: {
    types: [
      CP_REACTIVE_ASSOCIATION_SUCCESS,
      CP_REACTIVE_ASSOCIATION_REQUEST,
      CP_REACTIVE_ASSOCIATION_FAILURE,
    ],
    endpoint: `api/tlm/cp/reactive/association`,
    postdata: body,
  },
});

export const reActiveAssociation = (body) => (dispatch, getState) => {
  return dispatch(reActiveAssociationx(body));
};

const getAssociationByIdx = (id) => ({
  [CALL_API]: {
    types: [
      CP_GET_ASSOCIATION_BYID_SUCCESS,
      CP_GET_ASSOCIATION_BYID_REQUEST,
      CP_GET_ASSOCIATION_BYID_FAILURE,
    ],
    endpoint: `api/tlm/cp/association/companies/${id}`,
  },
});

export const getAssociationById = (id) => (dispatch, getState) => {
  return dispatch(getAssociationByIdx(id));
};

const deleteAssociationCompanyByIdx = (body) => ({
  [CALL_API]: {
    types: [
      DELETE_ASSOCIATION_COMPANY_SUCCESS,
      DELETE_ASSOCIATION_COMPANY_REQUEST,
      DELETE_ASSOCIATION_COMPANY_FAILURE,
    ],
    endpoint: `api/tlm/ass/delete/company`,
    postdata: body,
  },
});

export const deleteAssociationCompanyById = (body) => (dispatch, getState) => {
  return dispatch(deleteAssociationCompanyByIdx(body));
};

const editAssociationCompanyByIdx = (body) => ({
  [CALL_API]: {
    types: [
      EDIT_ASSOCIATION_COMPANY_SUCCESS,
      EDIT_ASSOCIATION_COMPANY_REQUEST,
      EDIT_ASSOCIATION_COMPANY_FAILURE,
    ],
    endpoint: `api/tlm/cp/edit/association`,
    postdata: body,
  },
});

export const editAssociationCompanyById = (body) => (dispatch, getState) => {
  return dispatch(editAssociationCompanyByIdx(body));
};

const getAssociationCompanyByIdx = (id) => ({
  [CALL_API]: {
    types: [
      GET_ASSOCIATION_COMPANY_SUCCESS,
      GET_ASSOCIATION_COMPANY_REQUEST,
      GET_ASSOCIATION_COMPANY_FAILURE,
    ],
    endpoint: `api/tlm/cp/associations/${id}`,
  },
});

export const getAssociationCompanyById = (id) => (dispatch) => {
  return dispatch(getAssociationCompanyByIdx(id));
};

const cloudPunchSettingsx = (body) => ({
  [CALL_API]: {
    types: [CLOUD_PUNCH_SUCCESS, CLOUD_PUNCH_REQUEST, CLOUD_PUNCH_FAILURE],
    endpoint: `api/tlm/cloudpunch/settings`,
    postdata: body,
  },
});

export const cloudPunchSettings = (body) => (dispatch) => {
  return dispatch(cloudPunchSettingsx(body));
};

const getCloudPunchSettingsx = (id) => ({
  [CALL_API]: {
    types: [
      GET_CLOUD_PUNCH_SUCCESS,
      GET_CLOUD_PUNCH_REQUEST,
      GET_CLOUD_PUNCH_FAILURE,
    ],
    endpoint: `api/tlm/cloudpunch/get_settings?installation_id=${id}`,
  },
});

export const getCloudPunchSettings = (id) => (dispatch) => {
  return dispatch(getCloudPunchSettingsx(id));
};

const getEmployeeReportCatchx = (shortName) => ({
  [CALL_API]: {
    types: [
      EMPLOYEE_REPORT_CATCH_SUCCESS,
      EMPLOYEE_REPORT_CATCH_REQUEST,
      EMPLOYEE_REPORT_CATCH_FAILURE,
    ],
    endpoint: `api/tlm/employees/reports?shortnames=${shortName}`,
  },
});

export const getEmployeeReportCatch = (shortName) => (dispatch) => {
  return dispatch(getEmployeeReportCatchx(shortName));
};

//get Association Custom Reports by id.
const getAssociationCustReportsById = (id) => ({
  [CALL_API]: {
    types: [
      CP_GET_ASSOCIATION_BYID_SUCCESS,
      CP_GET_ASSOCIATION_BYID_REQUEST,
      CP_GET_ASSOCIATION_BYID_FAILURE,
    ],
    endpoint: `api/tlm/cp/association/company/cust/reports/${id}`,
  },
});

export const getAssCustReportsById = (id) => (dispatch, getState) => {
  return dispatch(getAssociationCustReportsById(id));
};

//get Association Custom Reports by id.
const getAssociationCustReportDeletex = (body) => ({
  [CALL_API]: {
    types: [
      ASSOCIATION_DELETE_COMPANY_REPORT_SUCCESS,
      ASSOCIATION_DELETE_COMPANY_REPORT_REQUEST,
      ASSOCIATION_DELETE_COMPANY_REPORT_FAILURE,
    ],
    endpoint: `api/tlm/ass/delete/company/report`,
    postdata: body,
  },
});

export const getAssociationCustReportDelete = (body) => (dispatch) => {
  return dispatch(getAssociationCustReportDeletex(body));
};

const getAssociationCustReportNameDeletex = (body) => ({
  [CALL_API]: {
    types: [
      ASSOCIATION_DELETE_COMPANY_REPORT_NAME_SUCCESS,
      ASSOCIATION_DELETE_COMPANY_REPORT_NAME_REQUEST,
      ASSOCIATION_DELETE_COMPANY_REPORT_NAME_FAILURE,
    ],
    endpoint: `api/tlm/ass/delete/report`,
    postdata: body,
  },
});

export const getAssociationCustReportNameDelete = (body) => (dispatch) => {
  return dispatch(getAssociationCustReportNameDeletex(body));
};


