import React from "react";

import "../../styles/BaseProductSetting.css";

const BaseProductSetting = ({ title, tip, children }) => {
  return (
    <div className="BaseProductSetting">
      <div className="title">{title}</div>
      {tip !== "" && <div className="tip">{tip}</div>}
      {children}
    </div>
  );
};

export default BaseProductSetting;
