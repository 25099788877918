import React from "react";
import TopNavContainer from "../containers/TopNavContainer";
import DashboardContainer from "../containers/DashboardContainer";
import CallbacksContainer from "../containers/CallbacksContainer";
import SystemAdminContainer from "../containers/SystemAdminContainer";
import { Switch, Route } from "react-router-dom";
import "../styles/SiteOutline.css";
import AssociationsCompanies from "./AssociationsCompanies";
import AssociationsCustomReport from "./AssociationsCustomReport";
import CustomReportSchedule from "./multipleEINReport/CustomReportSchedule";
import AddConfiguration from "./exports/AddConfiguration";
import ExportLogs from "./exports/ExportLogs";
import MarketPlaceDetail from "./marketplace/MarketPlaceDetail";
import ProductContainer from "../containers/ProductContainer";
import IntegrationsContainer from "../containers/IntegrationsContainer";

const SiteOutline = (props) => {
  const { match } = props;

  return (
    <Switch>
      <div className="outline">
        <div className="topNavCont">
          <TopNavContainer />
        </div>
        <div className="body">
          <Route exact path={match.url} component={DashboardContainer} />
          <Route
            exact
            path={`${match.url}/products/:productURL`}
            component={ProductContainer}
          />
          <Route
            exact
            path={`${match.url}/integrations/:topicURL`}
            component={IntegrationsContainer}
          />
          <Route
            exact
            path={`${match.url}/callbacks/:callbackName`}
            component={CallbacksContainer}
          />

          <Route
            exact
            path={`${match.url}/systemadmin`}
            component={SystemAdminContainer}
          />

          <Route
            exact
            path={`${match.url}/product/view/:id`}
            component={AssociationsCompanies}
          />

          <Route
            exact
            path={`${match.url}/reports/view/:id`}
            component={AssociationsCustomReport}
          />

          <Route
            exact
            path={`${match.url}/reports/schedule/:id/:reportId`}
            component={CustomReportSchedule}
          />

          <Route
            exact
            path={`${match.url}/exports/data`}
            component={AddConfiguration}
          />

          <Route
            exact
            path={`${match.url}/exports/data/:id`}
            component={AddConfiguration}
          />

          <Route
            exact
            path={`${match.url}/exports/logs/:type/:id/:assId`}
            component={ExportLogs}
          />

          <Route
            exact
            path="/marketplace/:name"
            component={MarketPlaceDetail}
          />

          {/* <Route component={NoMatch} /> */}
        </div>
      </div>
    </Switch>
  );
};

export default SiteOutline;
