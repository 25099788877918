import * as EventTypes from "../actions/companyData";

const initialState = {
  error: "",
  isFetching: {},
};

export default function company_data(state = initialState, action) {
  switch (action.type) {
    case EventTypes.COMPANY_DATA_REQUEST:
      return Object.assign({}, state, {
        error: "",
        isFetching: { ...state.isFetching, [action.passthru.dataName]: true },
      });
    case EventTypes.COMPANY_DATA_SUCCESS:
      return Object.assign({}, state, {
        error: "",
        isFetching: { ...state.isFetching, [action.passthru.dataName]: false },
        [action.response.data.name]: action.response.data.values,
      });
    case EventTypes.COMPANY_DATA_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: { ...state.isFetching },
        // isFetching: { ...state.isFetching, [action.passthru.dataName]: false },
      });
    default:
      return state;
  }
}
