import React from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../../actions/auth";
import logo from "../../../assets/images/op-logo.png";
import { isEmpty } from "lodash";

const Navigation = ({
  sidebarHandle,
  sidebarValue,
  sideBar,
  history,
  isAuthenticated,
  dispatch,
  location,
  companyInfo,
  userInfo,
}) => {
  const [dropDown, setDropDown] = useState(false);

  const dropDownHandle = () => {
    setDropDown((prev) => !prev);
  };

  const handleGoIntoHome = () => {
    const path = location.pathname.includes("/app") ? "/app" : "/ess";
    history.push(path);
  };

  const handleLogOut = () => {
    dispatch(logoutUser());

    // eslint-disable-next-line
    window.location = `/login`;

    localStorage.clear();
  };

  return (
    <header>
      <div className="logo">
        <div className="logo-wrp">
          <img onClick={handleGoIntoHome} src={logo} alt="Logo" />
        </div>
        {!sideBar && (
          <button className="open_sidebar-close" onClick={sidebarHandle}>
            {sidebarValue ? (
              <FontAwesomeIcon icon={solid("x")} />
            ) : (
              <FontAwesomeIcon icon={solid("bars")} />
            )}
          </button>
        )}
      </div>
      <div className="header-right my-flex">
        {isAuthenticated ? (
          <>
            <div className="m-auto">
              <div className="me-2">
                <div className="header-company-info">
                  <p className="color-fff m-0">
                    {!isEmpty(userInfo) && userInfo.username}
                  </p>
                  <p className="color-fff m-0">
                    {!isEmpty(companyInfo) && companyInfo.name}
                  </p>
                </div>
              </div>
            </div>
            <button onClick={dropDownHandle}>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png"
                alt=""
              />
            </button>
            <ul className={`drop_down-admin ${dropDown && "active"}`}>
              <li onClick={handleLogOut}>
                <Link to="#">Logout</Link>
              </li>
            </ul>
          </>
        ) : (
          <>
            <button onClick={() => history.push("/login")}>Login</button>
          </>
        )}
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.auth;
  const { companyInfo, userInfo } = state.products;

  return {
    isAuthenticated,
    companyInfo,
    userInfo,
  };
};

export default withRouter(connect(mapStateToProps)(Navigation));
