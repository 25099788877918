import React, { useState } from "react";
import useForm from "../../hooks/useForm";
import { isEmpty } from "lodash";
import * as yup from "yup";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";

const initialValues = { op_id: "", name: "", short_name: "", api_key: "" };

const addCompanySchema = yup.object().shape({
  op_id: yup.number().required("Company ID is a required field"),
  name: yup.string().required("Company Full Name is a required field"),
  short_name: yup.string().required("Company Short Name is a required field"),
  api_key: yup.string().required("Company API Key is a required field"),
});

const AddCompany = () => {
  const methods = useForm({
    defaultValue: initialValues,
    validationSchema: addCompanySchema,
  });
  const [submitCompany, companyLoading] = useFetch();
  const [responseError, setResponseError] = useState(null);
  const [companyResponse, setCompanyResponse] = useState(null);
  const { formValue, handleChange, handleSubmit, errors } = methods;

  const onSubmit = async (data) => {
    const payload = {
      endPoint: `/api/companies`,
      method: "post",
      body: { ...data, op_id: Number(data.op_id) },
    };
    const response = await submitCompany(payload);
    if (response.error) {
      setResponseError(response.error);
    } else {
      setResponseError(null);
      const successNote = `Success! Company has been added to OnePoint Connect. You must log out of your current company and login using shortname=${data.short_name} to view their info.`;
      setCompanyResponse(successNote);
    }
  };

  return (
    <div>
      <h1 className="tlt_dash">System Admin Tools</h1>
      <div className="system-admin-tool-form">
        {companyLoading && (
          <div className="loadCont">
            <Loading />
          </div>
        )}
        <h3>Add Company to OnePoint Connect</h3>
        <div>
          {!isEmpty(responseError) && (
            <p className="color-red">{responseError}</p>
          )}
          {!isEmpty(companyResponse) && (
            <p className="color-green">{companyResponse}</p>
          )}
          <div className="one-col-md-4 mb-2">
            <div className="one-control-input">
              <strong>Company ID</strong>
              <input
                type="number"
                placeholder="Company ID"
                name="op_id"
                value={formValue.op_id}
                onChange={handleChange}
              />
              {!isEmpty(errors.op_id) && (
                <span className="color-red">{errors.op_id}</span>
              )}
            </div>
          </div>
          <div className="one-col-md-4 mb-2">
            <div className="one-control-input">
              <strong>Company Full Name</strong>
              <input
                type="text"
                placeholder="Company Full Name"
                name="name"
                value={formValue.name}
                onChange={handleChange}
              />
              {!isEmpty(errors.name) && (
                <span className="color-red">{errors.name}</span>
              )}
            </div>
          </div>
          <div className="one-col-md-4 mb-2">
            <div className="one-control-input">
              <strong>Company Short Name</strong>
              <input
                type="text"
                placeholder="Company Short Name"
                name="short_name"
                value={formValue.short_name}
                onChange={handleChange}
              />
              {!isEmpty(errors.short_name) && (
                <span className="color-red">{errors.short_name}</span>
              )}
            </div>
          </div>
          <div className="one-col-md-4 mb-2">
            <div className="one-control-input">
              <strong>Company API Key</strong>
              <input
                type="text"
                placeholder="Company API Key"
                name="api_key"
                value={formValue.api_key}
                onChange={handleChange}
              />
              {!isEmpty(errors.api_key) && (
                <span className="color-red">{errors.api_key}</span>
              )}
            </div>
          </div>
          <div className="one-col-md-4 systemadmin-btn">
            <button className="my-btn" onClick={handleSubmit(onSubmit)}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddCompany;
