import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { connect } from "react-redux";

import useToggle from "../../hooks/useToggle";
import CommonIntegration from "./integration/CommonIntegration";
import IntegrationDocument from "./IntegrationDocument";
import IntegrationSettings from "../settings/IntegrationSettings";
import useFetch from "../../hooks/useFetch";
import IntegrationConfiguration from "./integration/IntegrationConfiguration";
import { showIntegrationConfigurationButton } from "../../../constants/constant";
import Loading from "../../../components/ui/Loading";

const typeValue = {
  1: "HIRED",
  2: "TERMINATED",
  3: "MODIFIED",
};

const IntegrationBody = (props) => {
  const { history, match, integration } = props;
  const [settingsToggle, handleSettingsToggle] = useToggle(true);
  const [integrationDoc, handleIntegrationDoc] = useToggle();
  const [configurationState, handleConfigurationToggle] = useToggle();
  const [rowData, setRowData] = useState([]);
  const [rowDataCopy, setRowDataCopy] = useState([]);
  const [totalRecord, setTotalRecord] = useState(0);
  const [offSet, setOffSet] = useState(0);
  const [syncLoading, setSyncLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [fetchEvent, isLoading] = useFetch();
  const [integrationEmployeeSync] = useFetch();
  const [integrationEmployeeHFProcess] = useFetch();
  const [integrationEmployeeEventProcess] = useFetch();

  const handleFetchEvent = async (offSetNum) => {
    try {
      const payload = {
        endPoint: `/api/integrations/${match.params.integrationName}/events/${offSetNum}/10`,
        method: "get",
      };
      const result = await fetchEvent(payload);
      if (result.events) {
        const data = result.events.map((item) => ({
          ...item,
          statusType: typeValue[item.type],
          resultStatus: item.result.status,
          resultDescription: item.result.description,
        }));
        setRowData(data);
        setRowDataCopy(data);
        setTotalRecord(result.count);
        setOffSet(result.offset);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleRefresh = () => {
    handleFetchEvent(0);
    setCurrentPage(0);
  };

  const handlePageClick = (e) => {
    setCurrentPage(e.selected);
    handleFetchEvent(e.selected * 10);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    const result = rowDataCopy.filter((item) =>
      Object.values(item).toString().toLowerCase().includes(value.toLowerCase())
    );
    setRowData(result);
  };

  const handleSyncEmployeeProcess = async (type, syncValue) => {
    setSyncLoading(true);
    const payloadEmpSync = {
      endPoint: `/api/emp/custom/${syncValue}`,
      method: "get",
    };
    const payloadEmpProcess = {
      endPoint: `/api/emp/hf/process/${integration.integration_id}/${type}`,
      method: "get",
    };
    const payloadEmpEventProcess = {
      endPoint: `/api/emp/custom/event/process/${integration.integration_id}/${type}`,
      method: "get",
    };
    await integrationEmployeeSync(payloadEmpSync);
    await integrationEmployeeHFProcess(payloadEmpProcess);
    await integrationEmployeeEventProcess(payloadEmpEventProcess);
    handleFetchEvent(offSet);
    setSyncLoading(false);
  };

  useEffect(() => {
    handleFetchEvent(offSet);
  }, []);
  const integrationdata = integration
  // console.log(integrationdata , 'lalit');
  // if (
  //     ( integration.url === "mineral") &&
  //     (!integration.authorization || (!integration.authorization.read && !integration.authorization.write))
  //   ) {
  //     return <div>You are not authorized.</div>;
  //   }
  // {isLoading ||
  //   isEmpty(integration) ||
  //   isEmpty(settings) ||
  //   settingsAreSaving ? (
  //   <Loading />
  // const [dataval, setDataVal] = useState(false)
  // if (integrationdata === undefined ) {
  //   console.log(integrationdata !== undefined, 'integrationdata!== undefined');
  //   setDataVal(true)
  //   return;
  //   // alert('shd')
  // }
  return (
    <div className="provision-modal">
      <div className="product-modal-header">
        <span onClick={history.goBack} className="back-btn">
          ← Back to list of Integrations
        </span>

        <div>
          <span className="ms-2" onClick={handleSettingsToggle}>
            <FontAwesomeIcon icon={solid("gear")} />
          </span>
        </div>
      </div>
      {/* {dataval === false ? <Loading /> : (<>{console.log(integration.url, 'lalit')}</>)} */}
      <div className="product_card_content">
        <div
          className={`product-left-content ${!settingsToggle && "integration-active"
            }`}
        >
          <CommonIntegration
            integrationName={match.params.integrationName}
            handleSearch={handleSearch}
            handlePageClick={handlePageClick}
            handleRefresh={handleRefresh}
            rowData={rowData}
            isLoading={isLoading}
            totalRecord={totalRecord}
            currentPage={currentPage}
            syncLoading={syncLoading}
            handleConfigurationToggle={handleConfigurationToggle}
          />
        </div>

        <IntegrationSettings
          settingsToggle={settingsToggle}
          handleIntegrationDoc={handleIntegrationDoc}
          handleSyncEmployeeProcess={handleSyncEmployeeProcess}
          syncLoading={syncLoading}
        />

        <IntegrationDocument
          integrationDoc={integrationDoc}
          handleIntegrationDoc={handleIntegrationDoc}
          integrationName={match.params.integrationName}
        />
        {integration &&
          showIntegrationConfigurationButton.includes(integration.url) && (
            <IntegrationConfiguration
              configurationState={configurationState}
              handleConfigurationToggle={handleConfigurationToggle}
              integrationName={match.params.integrationName}
              integration={integration}
            />
          )}
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { integrations } = state.entities;
  const { match } = ownProps;
  const { settingsAreSaving } = state.integrations;

  const { integrationName } = match.params;

  return {
    integration: integrations[integrationName],
    settingsAreSaving,
  };
};

export default withRouter(connect(mapStateToProps)(IntegrationBody));
