import React, { useEffect } from "react";
import useForm from "../../../hooks/useForm";

const initialValues = {
  api_key: "",
  customer_id: "",
  url: "",
  created_at: "",
  updated_at: "",
};

const X8x8ConfigurationForm = (props) => {
  const { handleUpdate, formResponse, handleConfigurationToggle } = props;
  const { formValue, handleChange, handleSubmit, setFormValue } = useForm({
    defaultValue: initialValues,
  });

  const onSubmit = (data) => {
    handleUpdate(data);
  };

  useEffect(() => {
    if (formResponse) {
      setFormValue(() => {
        return {
          api_key: formResponse.config.api_key,
          customer_id: formResponse.config.customer_id,
          url: formResponse.config.url,
          created_at: formResponse.created_at,
          updated_at: formResponse.updated_at,
        };
      });
    }
  }, [formResponse]);

  return (
    <>
      <h4>Application configuration</h4>
      <div>
        <div className="one-control-input">
          <label htmlFor="">
            API authentication key <span className="color-red">*</span>
          </label>
          <input
            type="text"
            placeholder="API authentication key"
            name="api_key"
            value={formValue.api_key}
            onChange={handleChange}
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
        <div className="one-control-input">
          <label htmlFor="">
            Customer ID <span className="color-red">*</span>
          </label>
          <input
            type="text"
            placeholder="Customer ID"
            name="customer_id"
            value={formValue.customer_id}
            onChange={handleChange}
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
        <div className="one-control-input">
          <label htmlFor="">
            API URL <span className="color-red">*</span>
          </label>
          <input
            type="text"
            placeholder="API URL"
            name="url"
            value={formValue.url}
            onChange={handleChange}
            disabled
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
        <div className="one-control-input">
          <label htmlFor="">Create At</label>
          <input
            type="text"
            placeholder="Create At"
            name="redirect_url"
            value={formValue.created_at}
            onChange={handleChange}
            disabled
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
        <div className="one-control-input">
          <label htmlFor="">Updated At</label>
          <input
            type="text"
            placeholder="Updated At"
            name="redirect_url"
            value={formValue.updated_at}
            onChange={handleChange}
            disabled
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
      </div>
      <div class="mul-association-btn my-flex my-justify-content-end">
        <button onClick={handleConfigurationToggle}>Cancel</button>
        <button class="ms-2" onClick={handleSubmit(onSubmit)}>
          Save
        </button>
      </div>
    </>
  );
};

export default X8x8ConfigurationForm;
