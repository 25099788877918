import React, { Component } from 'react'
import { connect } from 'react-redux'

import { fetchCompanyData } from '../../actions/companyData'
import SelectSetting from './SelectSetting'

import '../../styles/SettingComponents.css';

class WebclockActions extends Component {
  constructor(p) {
    super(p)
    this.state = { value: p.value }

    this.save = this.save.bind(this)
    this.fetchCCs = this.fetchCCs.bind(this)
    this.changeActionValue = this.changeActionValue.bind(this)
    this.ccCheckClicked = this.ccCheckClicked.bind(this)
  }

  componentWillMount() {
    const { value, costCenters, ccsFetching } = this.props
    const { cc_change } = value

    if (typeof cc_change !== 'object') {
      this.save({ ...value, cc_change: { active: cc_change, label: 'Cost Center Change', indexes_available: [], use_employee_limits: false } })
    }

    if (!costCenters && !ccsFetching) {
      this.fetchCCs()
    }
  }

  ccCheckClicked(newChecked, ccIndex) {
    const { value } = this.state
    const { cc_change } = value
    const { indexes_available } = cc_change
    let oldIAWithoutThisindex = indexes_available.filter((ia) => {
      if (ia.index === ccIndex) {
        return false
      }
      return true
    })

    if (newChecked) {
      oldIAWithoutThisindex.push({ index: ccIndex })
    }

    oldIAWithoutThisindex.sort((a, b) => a.index - b.index);

    this.changeActionValue('cc_change', 'indexes_available', [...oldIAWithoutThisindex])
  }

  fetchCCs() {
    const { dispatch } = this.props

    dispatch(fetchCompanyData('cost-centers'))
  }

  save(choice) {
    this.props.onChange(choice)
  }

  changeActionValue(action, key, newVal) {
    const { value } = this.state
    const oldActionVal = value[action]
    const newActionVal = { ...oldActionVal, [key]: newVal }
    this.setState({ value: { ...value, [action]: newActionVal } })
  }

  render() {
    const { value } = this.state
    const { value: propValue, costCenters } = this.props
    const { cc_change } = value

    let ccIndexes = {}
    cc_change.indexes_available.map((ia) => (
      ccIndexes[ia.index] = { index: ia.index }
    ))

    return (
      <div className="WebclockActions" >
        <div className="action">
          <div className="name">
            Cost Center Change
          </div>
          <div className="main">
            <div>
              <SelectSetting
                label={'Enabled:'}
                description={''}
                value={cc_change.active ? 'yes' : 'no'}
                onChange={(newChoice) => this.changeActionValue('cc_change', 'active', newChoice.target.value === 'yes' ? true : false)}
                options={
                  [
                    { value: 'yes', label: 'Yes' },
                    { value: 'no', label: 'No' },
                  ]
                }
              />
            </div>
            {
              cc_change.active
              &&
              (
                <div>
                  <div className="textInput">
                    <label>Label Text:</label>
                    <input onChange={(e) => this.changeActionValue('cc_change', 'label', e.target.value)} value={cc_change.label} type="text" />
                  </div>
                  <div className="ccs">
                    {
                      costCenters
                      &&
                      (
                        <div>
                          <div className="hea">
                            Cost Centers Available to Change
                          </div>
                          <div className="ccCont">
                            {
                              costCenters.map((cc, i) => (
                                <div key={`qouhd${i}`}>
                                  <input checked={!!ccIndexes[cc.index]} onChange={(e) => this.ccCheckClicked(e.target.checked, cc.index)} type="checkbox" />
                                  <span>{cc.tree_name + '  '}</span>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            }
          </div>
          {
            JSON.stringify(propValue) !== JSON.stringify(value)
            &&
            (
              <div className="save">
                <button onClick={() => this.save(value)}>
                  SAVE SETTING
                </button>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { costCenters, isFetching } = state.company_data

  const ccsFetching = isFetching['cost-centers']

  return {
    costCenters: costCenters !== undefined ? costCenters : null,
    ccsFetching
  }
}

export default connect(mapStateToProps)(WebclockActions)
