import React from "react";
import JobBoard from "../../components/integration_types/JobBoard";
import { saveSettings } from "../../actions/integrations";
import { connect } from "react-redux";
import CareerIntegration from "../../components/integration_types/CareerIntegration";
import BackgroundCheckIntegration from "../../components/integration_types/BackgroundCheckIntegration";

const AppIntegration = (props) => {
  const { topicData, settingsAreSaving, error, topicURL, dispatch, history } =
    props;

  const saveSettingsUpdate = (newSettings, intURL) => {
    dispatch(saveSettings(newSettings, intURL));
  };

  const findIntegration = () => {
    if (topicData === undefined) {
      return null;
    }

    switch (topicURL) {
      case "ziprecruiter":
      case "indeed":
        return (
          <JobBoard
            data={topicData}
            saveSettings={(newSettings) =>
              saveSettingsUpdate(newSettings, topicURL)
            }
            settingsAreSaving={settingsAreSaving}
            error={error}
            topicURL={topicURL}
          />
        );

      case "career":
        return (
          <CareerIntegration
            data={topicData}
            saveSettings={(newSettings) =>
              saveSettingsUpdate(newSettings, topicURL)
            }
            settingsAreSaving={settingsAreSaving}
            error={error}
            topicURL={topicURL}
          />
        );

      case "bg_checks":
        return (
          <BackgroundCheckIntegration
            data={topicData}
            saveSettings={(newSettings) =>
              saveSettingsUpdate(newSettings, topicURL)
            }
            settingsAreSaving={settingsAreSaving}
            error={error}
            topicURL={topicURL}
          />
        );

      default:
        break;
    }
  };

  return (
    <div>
      <div>
        <span onClick={history.goBack} className="back-btn">
          ← Back to Job Board Integrations
        </span>
      </div>
      {findIntegration()}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  const {
    isDisconnecting: intsAreDisconnecting,
    settingsAreSaving,
    error,
  } = state.integrations;
  const { list: productList, isFetching: intsAreFetching } = state.products;
  const { integrations, products } = state.entities;
  const { match } = ownProps;

  const topicURL = match.params.topicURL;

  let topicData =
    integrations[topicURL] && Object.assign(integrations[topicURL], {});

  if (productList !== undefined) {
    for (var i = 0; i < productList.length; i++) {
      const productURL = productList[i];
      const product = products[productURL];
      const prodInts = product.integrations;

      for (var x = 0; x < prodInts.length; x++) {
        const intURL = prodInts[x];
        if (intURL === topicURL) {
          topicData.productURL = productURL;
        }
      }
    }
  }

  return {
    intsAreFetching,
    topicData,
    topicURL,
    intsAreDisconnecting,
    settingsAreSaving,
    error,
  };
};

export default connect(mapStateToProps)(AppIntegration);
