import React, { Component } from "react";
import moment from "moment";
import { isEmpty } from "lodash";

class HubspotConfigurationForm extends Component {
  constructor(props) {
    super(props);
    this.state = { errorBody: {} };
  }

  handleSubmit = () => {
    // const { formValue } = this.props;
    // const error = handleValidate(formValue);
    // if (error.status) {
    //   this.setState({ errorBody: error.error });
    //   return;
    // }
    this.props.handleSubmit();
  };

  render() {
    const {
      handleChange,
      formValue: { client_id, client_secret, redirect_url },
      created_at,
      updated_at,
    } = this.props;

    const {
      handleSubmit,
      state: { errorBody },
    } = this;

    return (
      <div>
        <h4>Application configuration</h4>
        <div className="d-flex">
          <div className="con-input">
            <label>Client ID</label>
            <input
              type="text"
              value={client_id}
              name="client_id"
              placeholder="Client ID"
              onChange={handleChange}
            />
            {!isEmpty(errorBody.client_id) && (
              <span className="color-red">{errorBody.client_id}</span>
            )}
          </div>
          <div className="con-input">
            <label>Client secret</label>
            <input
              type="text"
              value={client_secret}
              name="client_secret"
              placeholder="Client secret"
              onChange={handleChange}
            />
            {!isEmpty(errorBody.client_secret) && (
              <span className="color-red">{errorBody.client_secret}</span>
            )}
          </div>
        </div>

        <div className="connector-configuration-input">
          <label className="bold">Redirect URL</label>
          <input
            type="text"
            value={redirect_url}
            name="redirect_url"
            placeholder="https://api.test.com/callbacks/hubspot"
            disabled={!isEmpty(errorBody.redirect_url)}
            onChange={handleChange}
          />
          {!isEmpty(errorBody.redirect_url) && (
            <span className="color-red">{errorBody.redirect_url}</span>
          )}
        </div>
        <div className="d-flex">
          {!isEmpty(created_at) && (
            <div className="con-input">
              <label>Create at</label>
              <input
                type="text"
                value={moment(created_at).format("MM/DD/YYYY kk:mm:ss")}
                disabled
              />
            </div>
          )}
          {!isEmpty(updated_at) && (
            <div className="con-input">
              <label>Updated at</label>
              <input
                type="text"
                value={moment(updated_at).format("MM/DD/YYYY kk:mm:ss")}
                disabled
              />
            </div>
          )}
        </div>
        <div className="btn-configuration">
          <button
            className="submitbtncstm"
            type="submit"
            onClick={handleSubmit}
          >
            {!isEmpty(updated_at) ? "Update" : "Submit"}
          </button>
        </div>
      </div>
    );
  }
}

export default HubspotConfigurationForm;
