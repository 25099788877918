import { useCallback, useState } from "react";

const useToggle = (defaultValue = false) => {
  const [toggleState, setToggleState] = useState(defaultValue);

  const handleToggle = useCallback(() => {
    setToggleState((prev) => !prev);
  }, []);

  return [toggleState, handleToggle];
};

export default useToggle;
