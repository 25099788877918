import React, { useEffect, useMemo } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useForm from "../../hooks/useForm";
import Autocomplete from "react-autocomplete";
import { connect } from "react-redux";
import useFetch from "../../hooks/useFetch";
import { debounce } from "lodash";
import useToggle from "../../hooks/useToggle";
import CustomReportForm from "./CustomReportForm";
import Loading from "../../../components/ui/Loading";

const initialValues = {
  title: "",
  associateCompany: "",
  associateCustomReportName: "",
  selectedAssociateCompany: null,
  associateCompanyList: [],
  customReportName: [],
  activeReportIndex: null,
  customReportCompany: "",
  customReportId: "",
};

const AddEditAssociation = (props) => {
  const { openAssociation, handleOpenMode, fetchAssociationsData, editId } =
    props;
  const [fetchData, , response] = useFetch();
  const [postAssociation, postLoading] = useFetch();
  const [editAssociation, editLoading] = useFetch();
  const [getAssociationById, getLoading] = useFetch();
  const [getAssociationCustomById, getCustomLoading] = useFetch();
  const [deleteAssociationCompany] = useFetch();
  const [customOpen, handleCustomOpen] = useToggle();

  const primaryCompany = {
    title: props.companyInfo.name,
    company_id: props.userInfo.company_id,
    p_company_id: 1,
  };

  const {
    formValue,
    handleChange,
    append,
    setValues,
    remove,
    handleSubmit,
    setFormValue,
    setErrors,
  } = useForm({
    defaultValue: {
      ...initialValues,
      associateCompanyList: [primaryCompany],
    },
  });

  const handleDebounceFn = async (value) => {
    fetchData({
      endPoint: `/api/tlm/cp/search/company/byname/${value}`,
      method: "get",
    });
  };

  const debounceFn = useMemo(() => debounce(handleDebounceFn, 1000), []);

  const handleChangeAutoComplete = async (e) => {
    const { value } = e.target;
    setValues("associateCompany", value);
    debounceFn(value);
  };

  const handleRemoveAssociation = (index) => {
    const associationDelete = formValue.associateCompanyList[index];
    if (associationDelete.isNew) {
      remove("associateCompanyList", index);
    } else {
      const company_id = associationDelete.company_id
        ? associationDelete.company_id
        : associationDelete.ID;
      const payload = {
        endPoint: `/api/tlm/ass/delete/company`,
        method: "post",
        body: {
          company_id: company_id,
          association_id: editId,
        },
      };
      deleteAssociationCompany(payload);
      remove("associateCompanyList", index);
    }
  };

  const handleRemoveCustomReport = (index) => {
    remove("customReportName", index);
  };

  const handleAddAssociation = () => {
    const companyExists = formValue.associateCompanyList.findIndex(
      (company) => company.name === formValue.selectedAssociateCompany.name
    );
    if (companyExists === -1) {
      const body = {
        title: formValue.selectedAssociateCompany.name,
        company_id: formValue.selectedAssociateCompany.ID,
        p_company_id: 0,
        isNew: true,
      };
      append("associateCompanyList", body);
      setValues("selectedAssociateCompany", null);
      setValues("associateCompany", "");
    } else {
      setErrors((prev) => {
        return { ...prev };
      });
    }
  };

  const handleAddCustomReport = () => {
    append("customReportName", {
      report_name: formValue.associateCustomReportName,
      reports: [],
    });
    setValues("associateCustomReportName", "");
  };

  const handleOpenCustomReport = (index) => () => {
    setValues("activeReportIndex", index);
    setValues("customReportCompany", "");
    handleCustomOpen();
  };

  const handleAddCustomReportID = () => {
    if (formValue.customReportCompany) {
      const company = JSON.parse(formValue.customReportCompany);
      const company_id = company.company_id ? company.company_id : company.ID;
      setFormValue((prev) => {
        const copy = { ...prev };
        const reportsData =
          copy.customReportName[copy.activeReportIndex].reports;
        const reports = Array.isArray(reportsData) ? reportsData : [];

        copy.customReportName[copy.activeReportIndex].reports = [
          ...reports,
          {
            company_id: String(company_id),
            title: company.title,
            report_id: Number(copy.customReportId),
          },
        ];

        copy.customReportId = "";

        return { ...copy };
      });
    }
  };

  const onSubmit = async (data) => {
    const body = {};

    if (editId) {
      const associateCompanyList = data.associateCompanyList.map((item) => ({
        ...item,
        name: item.title,
      }));

      const customReportName = data.customReportName.map((item) => {
        item.reports = item.reports.map((re) => ({ ...re, name: re.title }));
        return item;
      });

      body.association_id = editId;
      body.companies = associateCompanyList;
      body.custom_reports = customReportName;
      body.title = data.title;

      const payload = {
        method: "post",
        endPoint: `/api/tlm/cp/edit/association`,
        body,
      };
      editAssociation(payload);
    } else {
      body.companies = data.associateCompanyList;
      body.custom_reports = data.customReportName;
      body.title = data.title;
      const payload = {
        method: "post",
        endPoint: `/api/tlm/cp/company/associations`,
        body,
      };

      const response = await postAssociation(payload);
      console.log("response", response);
    }

    handleOpenMode(null)();
    fetchAssociationsData();
  };

  const getAssociationBuId = async () => {
    const payload = {
      endPoint: `/api/tlm/cp/association/companies/${editId}`,
      method: `get`,
    };

    const payloadData = {
      endPoint: `/api/tlm/cp/association/company/cust/reports/${editId}`,
      method: `get`,
    };

    const response = await getAssociationById(payload);
    const responseData = await getAssociationCustomById(payloadData);

    // if (!response) {
    //   return;
    // }
    const companies =
      response && Array.isArray(response.companies)
        ? response.companies.map((item) => ({
            ...item,
            title: item.name,
          }))
        : [];

    const customReportName = Array.isArray(responseData)
      ? responseData.map((item) => {
          if (item.reports) {
            item.reports = item.reports.map((re) => ({
              ...re,
              title: re.name,
            }));
          }
          return item;
        })
      : [];

    setFormValue((prev) => {
      const copy = { ...prev };
      copy.title = response ? response.title : "";
      copy.associateCompanyList = companies;
      copy.customReportName = customReportName;
      return copy;
    });
  };

  useEffect(() => {
    if (editId) {
      getAssociationBuId();
    }
  }, [editId]);

  return (
    <div
      className={`dashboard-integration-doc association ${
        openAssociation && "open"
      }`}
    >
      <div className="association-con">
        <div>
          <span className="icon-btn" onClick={handleOpenMode(null)}>
            <FontAwesomeIcon icon={solid("xmark")} />
          </span>
        </div>
        <div className="integration-document">
          <CustomReportForm
            formValue={formValue}
            customOpen={customOpen}
            handleCustomOpen={handleOpenCustomReport}
            handleChange={handleChange}
            handleAddCustomReportID={handleAddCustomReportID}
          />
          <h1>{editId ? "Edit" : "Add"} Association</h1>
          {postLoading || getCustomLoading || getLoading || editLoading ? (
            <Loading />
          ) : (
            <div className="association-form">
              <div>
                <div className="one-control-input">
                  <label htmlFor="">
                    Title <span className="color-red">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Title"
                    name="title"
                    value={formValue.title}
                    onChange={handleChange}
                  />
                  {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
                </div>
                <div className="my-d-flex my-align-item-center">
                  <div className="one-control-input one-col-11 auto-com">
                    <label htmlFor="">Associate Company:</label>
                    <Autocomplete
                      className="formcontrol searchinput"
                      getItemValue={(item) => {
                        return JSON.stringify(item);
                      }}
                      wrapperStyle={{
                        display: "block",
                      }}
                      items={Array.isArray(response) ? response : []}
                      renderMenu={(items) => (
                        <div
                          style={{ position: "absolute", width: "100%" }}
                          children={items}
                        />
                      )}
                      renderItem={(item, isHighlighted) => (
                        <div
                          key={item.ID}
                          style={{
                            background: isHighlighted ? "lightgray" : "white",
                          }}
                        >
                          {item.name}
                        </div>
                      )}
                      value={formValue.associateCompany}
                      onChange={handleChangeAutoComplete}
                      onSelect={(e) => {
                        const data = JSON.parse(e);
                        setValues("associateCompany", data.name);
                        setValues("selectedAssociateCompany", {
                          ...data,
                          p_company_id: 0,
                        });
                      }}
                    />
                    {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
                  </div>
                  <div className="ms-1 one-control-button">
                    <button
                      className="p-2"
                      type="button"
                      onClick={handleAddAssociation}
                      disabled={!formValue.selectedAssociateCompany}
                    >
                      <FontAwesomeIcon icon={solid("plus")} />
                    </button>
                  </div>
                </div>
                <div className="my-d-flex my-align-item-center">
                  <div className="one-control-input one-col-11">
                    <label htmlFor="">Custom Report Name</label>
                    <input
                      type="text"
                      placeholder="Custom Report Name"
                      name="associateCustomReportName"
                      value={formValue.associateCustomReportName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="ms-1 one-control-button">
                    <button
                      className="p-2"
                      type="button"
                      onClick={handleAddCustomReport}
                      disabled={!formValue.associateCustomReportName}
                    >
                      <FontAwesomeIcon icon={solid("plus")} />
                    </button>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <table>
                  <thead>
                    <tr>
                      <th>Company Name</th>
                      <th></th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formValue.associateCompanyList.map((company, index) => {
                      return (
                        <tr key={index}>
                          <td>{company.title}</td>
                          <td>
                            {company.p_company_id === 1 && (
                              <span className="badgecolor">Primary</span>
                            )}
                          </td>
                          <td className="text-center">
                            <button
                              disabled={company.p_company_id === 1}
                              onClick={() => handleRemoveAssociation(index)}
                            >
                              <FontAwesomeIcon icon={solid("trash-alt")} />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="mb-2">
                <table>
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Report Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formValue.customReportName.map((report, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{report.report_name}</td>
                          <td className="text-center">
                            <button onClick={handleOpenCustomReport(index)}>
                              <FontAwesomeIcon icon={solid("eye")} />
                            </button>
                            <button
                              className="ms-2"
                              onClick={() => handleRemoveCustomReport(index)}
                            >
                              <FontAwesomeIcon icon={solid("trash-alt")} />
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="mul-association-btn my-flex my-justify-content-end">
                <button onClick={handleOpenMode()}>Cancel</button>
                <button onClick={handleSubmit(onSubmit)} className="ms-2">
                  Save
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { companyInfo, userInfo } = state.products;

  return { companyInfo, userInfo };
};

export default connect(mapStateToProps)(AddEditAssociation);
