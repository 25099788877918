import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "../ui/Loading";
import HideShow from "../ui/HideShow";
import IntegrationSettings from "../IntegrationSettings";
import { connect } from "react-redux";
import { fetchEvents } from "../../actions/events";
import IntegrationDataTable from "./IntegrationDataTable";
import { isEmpty } from "lodash";
import ConfigurationModel from "./modal/ConfigurationModel";

class OneLoginIntegration extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    saveSettings: PropTypes.func.isRequired,
    disconnectIntegration: PropTypes.func.isRequired,
    documentation: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};

    this.launchWindow = this.launchWindow.bind(this);
  }

  async componentDidMount() {
    // const { data } = this.props;
    // // console.log("this.props", data.integration_id);
    // const { response } = await this.props.dispatch(
    //   connectorConfig(data.integration_id)
    // );
    // console.log("response", response);
  }

  componentWillMount() {
    const { dispatch, topicURL, topicEvents } = this.props;

    if (topicEvents === undefined || topicEvents === null) {
      dispatch(fetchEvents(topicURL));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { topicURL: oldTopicURL, dispatch } = this.props;
    const { topicURL: newTopicURL } = nextProps;

    if (oldTopicURL !== newTopicURL) {
      dispatch(fetchEvents(newTopicURL));
    }
  }

  launchWindow(url) {
    window.location.replace(url);
    // window.location.reload();
  }

  refreshEvents() {
    const { topicURL, dispatch } = this.props;

    var myDiv = document.getElementById("containerDiv");
    myDiv.scrollTop = 0;

    dispatch(fetchEvents(topicURL));
  }

  render() {
    const {
      data,
      events,
      eventsAreFetching,
      saveSettings,
      intsAreDisconnecting,
      disconnectIntegration,
      documentation,
      settingsAreSaving,
      error,
      topicURL,
    } = this.props;
    if (data === undefined) {
      return null;
    }

    return (
      <div className="">
        {intsAreDisconnecting ? (
          <Loading />
        ) : (
          <div>
            <div className="head">
              <h1>{data.integration_name} Integration</h1>
              <div className="img">
                <img src={`/images/${data.image_name}`} alt=" " />
              </div>
            </div>
            <div className="intSect">
              <h2>Connection Status</h2>
              <div className="status sect">
                {topicURL === "activedirectory" ? (
                  data.authenticated ? (
                    <div className="connected">
                      <div className="good">
                        Connected to On-Premise Service
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="bad">
                        Not Connected to On-Premise Service
                      </div>
                    </div>
                  )
                ) : data.authenticated ? (
                  <div className="connected">
                    <div className="good">
                      Connected to {data.integration_name} with user:{" "}
                      {data.authed_username}
                    </div>
                    <div className="connect">
                      <button
                        className="red"
                        onClick={() => disconnectIntegration()}
                      >
                        DISCONNECT THIS USER
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="bad">Not Connected</div>
                    <div className="connect">
                      <button
                        disabled={isEmpty(data.auth_url)}
                        onClick={() => this.launchWindow(data.auth_url)}
                      >
                        Sign Into {data.integration_name}
                      </button>
                    </div>
                    <ConfigurationModel data={data} />
                  </div>
                )}
              </div>
              <div className="results sect">
                <h2>
                  Integration Events
                  <span>
                    <button onClick={this.refreshEvents.bind(this)}>
                      REFRESH
                    </button>
                  </span>
                  <span className="hint">
                    *New OnePoint HCM hires/terminations can take up to 5
                    minutes to process
                  </span>
                </h2>
                {eventsAreFetching &&
                (events === undefined || events.events.length === 0) ? (
                  <Loading />
                ) : (
                  <div
                    id="containerDiv"
                    className={eventsAreFetching ? "loading" : ""}
                  >
                    {eventsAreFetching ? (
                      <div className="tableLoading">
                        <Loading />
                      </div>
                    ) : events !== undefined &&
                      events.events !== undefined &&
                      events.events.length !== 0 ? (
                      <>
                        {/* <table className="table">
                          <thead>
                            <tr>
                              <th className="date">Date Processed</th>
                              <th className="event">Event</th>
                              <th className="first">First Name</th>
                              <th className="last">Last Name</th>
                              <th className="email">
                                {data.url === "activedirectory"
                                  ? "OnePoint Username"
                                  : "OnePoint Primary Email"}
                              </th>
                              <th className="statusz">Result</th>
                              <th className="description">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {events.events.map((event) => (
                              <tr className="row" key={"result" + event.id}>
                                <td className="col date">{event.event_date}</td>
                                <td className="col event">
                                  {event.type === 1 && "HIRED"}
                                  {event.type === 2 && "TERMINATED"}
                                  {event.type === 3 && "MODIFIED"}
                                </td>
                                <td className="col first">
                                  {event.first_name}
                                </td>
                                <td className="col last">{event.last_name}</td>
                                <td className="col email">{event.email}</td>
                                <td
                                  className={
                                    "col statusz " + event.result.status
                                  }
                                >
                                  {event.result.status}
                                </td>
                                <td className="col description">
                                  {event.result.description}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table> */}
                        <IntegrationDataTable
                          copyRow={events.events}
                          row={events.events}
                          data={data}
                        />
                      </>
                    ) : (
                      <div style={{ textAlign: "center", padding: "10px" }}>
                        No hires or terminations have been processed yet.
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="settings sect">
                <h2>Integration Settings</h2>
                <IntegrationSettings
                  settingsAreSaving={settingsAreSaving}
                  settings={data.settings}
                  saveSettings={saveSettings}
                  error={error}
                  integrationName={data.integration_name}
                  integrationId={data.integration_id}
                />
              </div>
              <div>
                <HideShow
                  label={<h2>Integration Documentation</h2>}
                  body={documentation ? documentation : "Not available."}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { isFetching: eventsAreFetching } = state.events;
  const { events } = state.entities;

  const topicEvents = events[ownProps.topicURL];

  return {
    events: topicEvents,
    eventsAreFetching,
  };
};

export default connect(mapStateToProps)(OneLoginIntegration);
