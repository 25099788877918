import React from "react";
import ReactPaginate from "react-paginate";

import Loading from "../../../../components/ui/Loading";

const IntegrationDataTable = (props) => {
  const {
    handleSearch,
    handlePageClick,
    handleRefresh,
    rowData,
    isLoading,
    totalRecord,
    currentPage,
    syncLoading,
  } = props;



  return (
    <>
      <div className="product-table">
        <div className="integration-table my-flex my-justify-content-between">
          <h4 className="m-0">
            Integration Events
            <button className="ms-1 me-1 suite-btn" onClick={handleRefresh}>
              REFRESH
            </button>
            <span>
              *New OnePoint HCM hires/terminations can take up to 5 minutes to
              process
            </span>
          </h4>
          <div className="header-action">
            <input type="text" placeholder="Search" onChange={handleSearch} />
          </div>
        </div>
        {isLoading || syncLoading ? (
          <Loading />
        ) : (
          <>
            <div className="table-responsive">
              <table className="table userprovision-table">
                <thead>
                  <tr>
                    <th className="date">Date Processed</th>
                    <th className="event">Event</th>
                    <th className="first">First Name</th>
                    <th className="last">Last Name</th>
                    <th className="email">Onepoint connect Primary Email</th>
                    <th className="statusz">Result</th>
                    <th className="description">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(rowData) &&
                    rowData.map((item) => {
                      return (
                        <tr className="row" key={"result" + item.id}>
                          <td className="col date">{item.event_date}</td>
                          <td className="col event">
                            {item.type === 1 && "HIRED"}
                            {item.type === 2 && "TERMINATED"}
                            {item.type === 3 && "MODIFIED"}
                          </td>
                          <td className="col first">{item.first_name}</td>
                          <td className="col last">{item.last_name}</td>
                          <td className="col email">{item.email}</td>
                          <td className={`col statusz ${item.result.status}`}>
                            {" "}
                            {item.result.status}
                          </td>
                          <td className="col description">
                            {item.result.description}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            <ReactPaginate
              breakLabel="..."
              nextLabel="Next"
              forcePage={currentPage}
              onPageChange={handlePageClick}
              pageCount={Math.floor(totalRecord / 10)}
              previousLabel="Previous"
              containerClassName={"pagination-container"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"disabled"}
              activeClassName={"active"}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
            />
          </>
        )}
      </div>
    </>
  );
};

export default IntegrationDataTable;
