import React, { memo, useEffect } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Loading from "../components/ui/Loading";
import ProductModal from "./components/UI/ProductModal";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import UserProvisioningBody from "./components/userProvisioning/UserProvisioningBody";
import IntegrationBody from "./components/userProvisioning/IntegrationBody";
import ProductCard from "./components/cards/ProductCard";
import MarketPlace from "../components/marketplace/MarketPlace";
import ProductSettings from "./components/settings/ProductSettings";
import ImportsBody from "./components/imports/ImportsBody";
import ImportConfiguration from "./components/imports/ImportConfiguration";
import {
  productDataFetch,
  productSettingsToggle,
  userPermissionAPICount,
  userWritePermission,
} from "../actions/dashboard";
import ExportsLogsBody from "./components/imports/ExportsLogsBody";
import MultipleEINBody from "./components/multipleEIN/MultipleEINBody";
import AssociationCompanyList from "./components/multipleEIN/AssociationCompanyList";
import AssociationCustomReport from "./components/multipleEIN/AssociationCustomReport";
import CustomReportScheduler from "./components/multipleEIN/CustomReportScheduler";
import BaseProduct from "./BaseProduct";

const EmployeeBody = (props) => {
  const {
    products,
    productsAreFetching,
    match,
    history,
    dispatch,
    userAdminPermission,
    productData,
    userInfo,
    settingsToggle,
    userPermissionCount,
  } = props;

  const handleProductSettingsToggle = () => {
    dispatch(productSettingsToggle());
  };
  const getPermissionWriteUser = async () => {
    dispatch(userPermissionAPICount());
    if (userPermissionCount === 0) {
      const { response } = await dispatch(userWritePermission());
      if (response && response.IsWrite) {
        localStorage.setItem("IsWrite", response.IsWrite)
      }
    }
  };
  
  const fetchAPIs = async () => {
    if (productData.length === 0) {
      dispatch(productDataFetch());
    }
  };

  useEffect(() => {
    fetchAPIs();
    getPermissionWriteUser();
  }, []);
  
  return (
    <>
      <div className="my-flex my-justify-content-between my-align-item-center middletop-section">
        <h1 className="tlt_dash">Products</h1>

        <div>
          {userInfo && userInfo.is_system_admin && (
            <span
              className="setting-gear-icon cursor-pointer"
              onClick={handleProductSettingsToggle}
            >
              <FontAwesomeIcon icon={solid("gear")} />
            </span>
          )}
        </div>
      </div>
      {productsAreFetching && products.length === 0 ? (
        <Loading />
      ) : (
        <>
          <div className="my-flex">
            <div
              className={`${settingsToggle ? "middleproductcard-section" : "one-col-12"
                }`}
            >
              <div className="dash-product-row">
                {Array.isArray(products) &&
                  products.map((item, index) => (
                    <ProductCard
                      {...item}
                      key={index}
                      userAdminPermission={userAdminPermission}
                    />
                  ))}
              </div>
            </div>

            {settingsToggle && <ProductSettings />}
          </div>

          <Route
            path={`${match.url}/product/user-provisioning`}
            component={() => (
              <ProductModal
                heading="User Provisioning"
                closeModal={history.goBack}
                history={history}
              >
                <BaseProduct name="user-provisioning">
                  <UserProvisioningBody />
                </BaseProduct>
              </ProductModal>
            )}
          />
          <Route
            path={`${match.url}/product/multiple`}
            component={() => (
              <ProductModal
                heading="MultipleEIN"
                closeModal={history.goBack}
                history={history}
              >
                <BaseProduct name="multiple">
                  <MultipleEINBody />
                </BaseProduct>
              </ProductModal>
            )}
          />
          <Route
            path={`${match.url}/product/view/:id`}
            component={() => (
              <ProductModal
                heading="Association Companies"
                closeModal={history.goBack}
                history={history}
              >
                <AssociationCompanyList />
              </ProductModal>
            )}
          />
          <Route
            path={`${match.url}/reports/view/:id`}
            component={() => (
              <ProductModal
                heading="Association Custom Reports"
                closeModal={history.goBack}
                history={history}
              >
                <AssociationCustomReport />
              </ProductModal>
            )}
          />
          <Route
            path={`${match.url}/reports/schedule/:id/:reportId`}
            component={() => (
              <ProductModal
                heading="Association Custom Reports Scheduler"
                closeModal={history.goBack}
                history={history}
              >
                <CustomReportScheduler />
              </ProductModal>
            )}
          />

          <Route
            path={`${match.url}/integrations/:integrationName`}
            component={() => (
              <ProductModal
                heading="User Provisioning"
                closeModal={history.goBack}
                history={history}
              >
                <IntegrationBody />
              </ProductModal>
            )}
          />

          {/* <Route
            path={`${match.url}/login/mineral`}
            component={() => (
              <MineralLogin />
            )}
          /> */}

          <Route
            path={`${match.url}/product/imports`}
            component={() => (
              <ProductModal
                heading="Imports"
                closeModal={history.goBack}
                history={history}
              >
                <BaseProduct name="imports">
                  <ImportsBody />
                </BaseProduct>
              </ProductModal>
            )}
          />
          <Route
            path={`${match.url}/product/imports-add`}
            component={() => (
              <ProductModal
                heading="Imports Configuration"
                closeModal={history.goBack}
                history={history}
              >
                <ImportConfiguration />
              </ProductModal>
            )}
          />
          <Route
            path={`${match.url}/product/imports/:id`}
            component={() => (
              <ProductModal
                heading="Imports Configuration"
                closeModal={history.goBack}
                history={history}
              >
                <ImportConfiguration />
              </ProductModal>
            )}
          />
          <Route
            path={`${match.url}/logs/:logName/:id/:assId`}
            component={() => (
              <ProductModal
                heading="Exports Logs"
                closeModal={history.goBack}
                history={history}
              >
                <ExportsLogsBody />
              </ProductModal>
            )}
          />
          <Route
            path={`${match.url}/product/mineral`}
            component={() => (
              <ProductModal
                heading="Mineral"
                closeModal={history.goBack}
                history={history}
              >
                <BaseProduct name="mineral">
                  <ImportsBody />
                </BaseProduct>
              </ProductModal>
            )}
          />
        </>
      )}
      <div className="marketplace-card-margin">
        {Array.isArray(productData) && productData.length ? (
          <MarketPlace productData={productData} dashboard />
        ) : null}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const {
    list,
    isFetching: productsAreFetching,
    companyInfo,
    userInfo,
  } = state.products;
  const { products } = state.entities;

  const {
    userAdminPermission,
    productData,
    settingsToggle,
    userPermissionCount,
  } = state.dashboard;

  const prodListJoin =
    list === undefined
      ? []
      : list.map((prodURL) => {
        return products[prodURL];
      });

  return {
    productsAreFetching,
    products: prodListJoin,
    companyInfo,
    userInfo,
    userAdminPermission,
    productData,
    settingsToggle,
    userPermissionCount,
  };
};

export default memo(withRouter(connect(mapStateToProps)(EmployeeBody)));
