import React from "react";
import { weeklyDays } from "../../../constants/constant";
import { isEmpty } from "lodash";

const showTimeInput = ["day", "weekly", "monthly", "biweekly", "one-time"];
const showDateInput = ["monthly", "one-time"];
const showDayInput = ["weekly", "biweekly"];

const SchedulerTypeContainer = (props) => {
  const { handleChange, formValue, errors } = props;

  return (
    <div className="scheduler-type-container">
      <div className="my-flex my-justify-content-between">
        {showDayInput.includes(formValue.period) && (
          <div className="one-col-4 scheduler-div">
            <div className="my-flex my-align-item-center">
              <div className="one-col-2 scheduler-time-auto">
                <strong>Day</strong>
              </div>
              <div className="one-col-10">
                <div className="one-control-select">
                  <select
                    name="day"
                    value={formValue.day}
                    onChange={handleChange}
                  >
                    <option value="" disabled>
                      Please Select
                    </option>
                    {weeklyDays.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </select>
                  {!isEmpty(errors.day) && (
                    <span className="color-red">{errors.day}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {showDateInput.includes(formValue.period) && (
          <div className="one-col-4 scheduler-div">
            <div className="my-flex my-align-item-center">
              <div className="one-col-2 scheduler-time-auto">
                <strong>Date</strong>
              </div>
              <div className="one-col-10">
                <div className="one-control-input-date ms-2">
                  <input
                    type="date"
                    name="interval_date"
                    value={formValue.interval_date}
                    onChange={handleChange}
                  />
                  {!isEmpty(errors.interval_date) && (
                    <span className="color-red">{errors.interval_date}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {showTimeInput.includes(formValue.period) && (
          <div className="one-col-4 scheduler-div">
            <div className="my-flex my-align-item-center">
              <div className="one-col-2 scheduler-time-auto">
                <strong>Time</strong>
              </div>
              <div className="one-col-10">
                <div className="one-control-input-date ms-2">
                  <input
                    type="time"
                    name="interval_time"
                    value={formValue.interval_time}
                    onChange={handleChange}
                  />
                  {!isEmpty(errors.interval_time) && (
                    <span className="color-red">{errors.interval_time}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SchedulerTypeContainer;
