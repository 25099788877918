import moment from "moment";

export const makeCsv = async (rows) => {
  const csvContent = `${rows
    .map((row) =>
      row.map((k) => {
        let cell = k;
        cell = `"${cell}"`;
        return cell;
      })
    )
    .join("\n")}`;

  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  return blob;
};

export const toJSON = (csv) => {
  const lines = csv.split("\n");
  let result = [];
  let headers = lines[0].split(",");
  lines.shift();
  headers = headers.map((h) => h.replaceAll(`"`, ""));

  lines.forEach((l) => {
    const line = l.split(",");
    let s = [];
    headers.forEach((_item, i) => {
      s[i] = line[i] ? line[i].replaceAll(`"`, "") : "";
    });
    result.push(s);
  });

  return { headers, result };
};

export const renderImage = (url) => {
  return url.includes("http") ? url : `/images/${url}`;
};

// convert ISO time into the UTC format
export const convertToUTC = (timeString) => {
  const today = new Date().toDateString();
  const time = new Date(`${today} ${timeString}`);
  const UTC = time.toUTCString();
  const UTCTime = UTC.split(" ")[4];
  const t = UTCTime.split(":");
  return t[0] + ":" + t[1];
};

// convert UAT time into the ISO format
export const convertToISO = (timeString) => {
  var date = new Date(`2023-01-01 ${timeString}:00 UTC`);
  const time = date.toString().split(" ")[4];
  const t = time.split(":");
  return t[0] + ":" + t[1];
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

// get next day of the week date
export function getNextDayOfTheWeek(
  dayName,
  excludeToday = true,
  refDate = new Date()
) {
  const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].indexOf(
    dayName.slice(0, 3).toLowerCase()
  );
  if (dayOfWeek < 0) return;
  refDate.setHours(0, 0, 0, 0);
  refDate.setDate(
    refDate.getDate() +
      +!!excludeToday +
      ((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
  );
  return refDate;
}

// date convert into the UAT format
export const dateConvertToUTC = (date) => {
  return moment(new Date(date).toUTCString()).format("YYYY-MM-DD");
};
