import React from "react";
import { connect } from "react-redux";

const BaseProduct = (props) => {
  const { children, name, productsAreFetching, authorization } = props;
  if (!name && !productsAreFetching) {
    return <div>That product does not exist.</div>;
  }

  //hard code job boards its grandfathered in
  if (
    name !== "Job Board Integrations" &&
    (!authorization || (!authorization.read && !authorization.write))
  ) {
    return <div className="auth_text">You are not Authorized.</div>;
  }
  return <div>{children}</div>;
};

const mapStateToProps = (state, props) => {
  const { products } = state.entities;
  const { name } = props;
  return {
    products,
    authorization: products[name] ? products[name].authorization : undefined,
  };
};

export default connect(mapStateToProps)(BaseProduct);
