import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import useToggle from "../../hooks/useToggle";
import ViewAssociationCompany from "./ViewAssociationCompany";
import Swal from "sweetalert2";
import { getAssociationCustReportNameDelete } from "../../../actions/cloudpunch";
import { connect } from "react-redux";

const AssociationCustomReport = (props) => {
  const {
    match: { params },
    history,
    dispatch,
  } = props;
  const [openAssociation, handleOpenMode] = useToggle();
  const [getAssCustReportsById, loading, response] = useFetch();
  const [selectData, setSelectData] = useState(null);

  const fetchAssociations = () => {
    const payload = {
      endPoint: `/api/tlm/cp/association/company/cust/reports/${params.id}`,
      method: "get",
    };

    getAssCustReportsById(payload);
  };

  const handleAssociationDelete =
    ({ report_name }) =>
    async () => {
      const result = await Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete it!",
      });
      if (result.isConfirmed) {
        const body = {
          association_id: Number(params.id),
          report_name: report_name,
        };

        await dispatch(getAssociationCustReportNameDelete(body));

        fetchAssociations();

        Swal.fire("Deleted!", "Your file has been Deleted.", "success");
      }
    };

  const handleSetMode = (data) => () => {
    setSelectData(data);
    handleOpenMode();
  };

  useEffect(() => {
    fetchAssociations();
  }, []);

  console.log("response", response);

  return (
    <div className="product-modal-content">
      <span onClick={history.goBack} className="back-btn">
        ← Back to list of Associations
      </span>
      <div className="association-list my-association-list">
        <ViewAssociationCompany
          openAssociation={openAssociation}
          handleOpenMode={handleSetMode}
          selectData={selectData}
          id={params.id}
          fetchAssociations={fetchAssociations}
        />
        <div className="">
          {loading ? (
            <Loading />
          ) : (
            <div className="product-table table-responsive">
              <table className="table associationcustomreports">
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Report Name</th>
                    <th>Count</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(response)
                    ? response.map((item, index) => {
                        return (
                          <tr className="row" key={index}>
                            <td>{index + 1}</td>
                            <td>{item.report_name}</td>
                            <td>
                              {Array.isArray(item.reports) &&
                                item.reports.length}
                            </td>
                            <td>
                              <div className="text-center">
                                <button
                                  className="me-10-px"
                                  title="View Logs"
                                  onClick={handleSetMode(item)}
                                >
                                  <FontAwesomeIcon icon={solid("eye")} />
                                </button>

                                <button
                                  className="me-10-px"
                                  title="View Logs"
                                  onClick={() =>
                                    history.push(
                                      `/app/logs/custom_report/${item.id}/${params.id}`
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={solid("clock-rotate-left")}
                                  />
                                </button>

                                <button
                                  className="me-10-px"
                                  title="file-export"
                                  onClick={() =>
                                    history.push(
                                      `/app/reports/schedule/${params.id}/${item.id}`
                                    )
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={solid("calendar-days")}
                                  />
                                </button>
                                <button
                                  className="me-10-px"
                                  onClick={handleAssociationDelete(item)}
                                  title="Delete"
                                >
                                  <FontAwesomeIcon icon={solid("trash-alt")} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(connect()(AssociationCustomReport));
