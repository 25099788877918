import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import React from "react";

const SFTPCompanyDataTable = ({ formValue, findCompanyName }) => {
  return (
    <div>
      {Array.isArray(formValue.companySFTP) &&
      formValue.companySFTP.length !== 0 ? (
        <div className="my-flex mb-2 sftp-table">
          <div className="one-col-1 my-flex my-align-item-center"></div>
          <div className="one-col-11">
            <div>
              <table>
                <thead>
                  <tr>
                    <th>S.NO</th>
                    <th>Host</th>
                    <th>Port</th>
                    <th>Company Name</th>
                    <th>Username</th>
                    <th>Remote file path</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {formValue.companySFTP.map(
                    (
                      { host, port, username, remote_file_path, company_id },
                      index
                    ) => (
                      <tr key={index}>
                        <th>{index + 1}</th>
                        <th>{host}</th>
                        <th>{port}</th>
                        <th>
                          {!isEmpty(findCompanyName(company_id))
                            ? findCompanyName(company_id).name
                            : "N/A"}
                        </th>
                        <th>{username}</th>
                        <th>{remote_file_path}</th>
                        <th>
                          <div>
                            <button title="Delete">
                              <FontAwesomeIcon icon={solid("trash-alt")} />
                            </button>
                          </div>
                        </th>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SFTPCompanyDataTable;
