import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

const IndexContainer = ({ isAuthenticated }) => {
  if (isAuthenticated) {
    return <Redirect to="/app" />;
  }

  // history.push("/ess");

  return <Redirect to="/ess" />;
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.auth;

  return {
    isAuthenticated,
  };
};

export default connect(mapStateToProps)(IndexContainer);
