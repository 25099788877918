/* eslint-disable no-undef */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import AuthPageContainer from "./AuthPageContainer";
import SiteOutline from "../components/SiteOutline";
import { fetchProducts } from "../actions/products";

import "../styles/App.css";

class App extends Component {
  componentWillMount() {
    const { intsBeenFetched, dispatch, isAuthenticated } = this.props;

    if (isAuthenticated && !intsBeenFetched) {
      dispatch(fetchProducts());
    }
  }

  componentWillReceiveProps(newProps) {
    const { isAuthenticated: wasAuthenticated } = this.props;
    const { intsBeenFetched, dispatch, isAuthenticated } = newProps;

    if (!wasAuthenticated && isAuthenticated) {
      if (!intsBeenFetched) {
        dispatch(fetchProducts());
      }
    }
  }

  render() {
    const { isAuthenticated } = this.props;

    return (
      <main>
        {!isAuthenticated ? (
          <AuthPageContainer />
        ) : (
          <SiteOutline {...this.props} />
        )}
      </main>
    );
  }
}

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.auth;
  const { isFetching: intsAreFetching, beenFetched: intsBeenFetched } =
    state.integrations;

  return {
    isAuthenticated,
    intsAreFetching,
    intsBeenFetched,
  };
};

export default withRouter(connect(mapStateToProps)(App));
