import merge from "lodash/merge";
import auth from "./auth";
import cloudpunch from "./cloudpunch";
import company_data from "./company_data";
import integrations from "./integrations";
import products from "./products";
import events from "./events";
import oauth from "./oauth";
import jobs from "./jobs";
import wotc from "./wotc";
import google_hire from "./google_hire";
import { combineReducers } from "redux";
import customReport from "./customReport";
import logs from "./logs";
import dashboard from "./dashboard";
import { UPDATE_INTEGRATION_DATA } from "../actions/dashboard";

const entities = (
  state = {
    integrations: {},
    events: {},
    products: {},
    applications: {},
    jobs: {},
    wotc_applicants: {},
    wotc_forms: {},
    cp_enrollments: {},
    cp_employees: {},
    cp_installations: {},
  },
  action
) => {
  if (action.type === "PRODUCT_SETTINGS_SUCCESS") {
    // added to handle setting deletion
    return Object.assign({}, state, {
      products: action.response.entities.products,
    });
  }

  if (action.type === "CP_UPDATEDINSTALL_SUCCESS") {
    //(array deletion issue)

    const updatedID = action.response.result;

    let installs = Object.assign({}, state.cp_installations);
    installs[updatedID].employee_filters = action.postdata.employee_filters;

    return Object.assign({}, state, { cp_installations: installs });
  }

  // problem, only one is returned, must only update that one (array deletion issue)
  if (action.type === "INTEGRATION_SETTINGS_SUCCESS") {
    return Object.assign({}, state, {
      integrations: {
        ...state.integrations,
        [action.response.result]:
          action.response.entities.integrations[action.response.result],
      },
    });
  }

  if (action.response && action.response.entities) {
    return merge({}, state, action.response.entities);
  }

  if (action.type === UPDATE_INTEGRATION_DATA) {
    const { url, integration_name, description, image_name } = action.payload;
    return {
      ...state,
      integrations: {
        ...state.integrations,
        [url]: {
          ...state.integrations[url],
          integration_name,
          description,
          image_name,
        },
      },
    };
  }

  return state;
};

const rootReducer = combineReducers({
  auth,
  cloudpunch,
  company_data,
  events,
  entities,
  google_hire,
  integrations,
  jobs,
  oauth,
  products,
  wotc,
  customReport,
  logs,
  dashboard,
});

export default rootReducer;
