import React from 'react'

export const Documentation = (
  <div className="documentationBody">
    <p>
      Microsoft Office 365 and Microsoft Azure Active Directory share the same user base, so this integration will create/delete a User in both services. The rest of this documentation will use Office 365 for brevity.
    </p>
    <p>
      This Integration will automatically create/delete Users in your Office 365 account when employees are hired/terminated in OnePoint.
    </p>
    <p>
      In order to get started, an Office 365 administrator with the permissions required to create and delete Users must connect above in the Connection Status section. This will redirect them to a dialogue where they grant Onepoint access to perform actions on their behalf.
    </p>
    <p>
      If you wish to temporarily stop these automatic actions from occuring, select Disabled in the Integration Settings area.
    </p>
    <p>
      <b>Important</b>: under the New Email Address Settings, select the email address format that best suites your company. If you select an Autogenerate setting, you must enter the Domain Name that you want new users to be created under.
    </p>
    <p>
      If you choose an Autogenerate option and the process is successful, the new email address will be added as the Employee's Primary Email Address in OnePoint. If the Employee already had a Primary Email Address, it will be moved to Secondary.
    </p>
    <p>
      The new user{'\''}s password can be found in the Summary Email that is sent to the selected Employee in the User Provisioning Product Setting. The first time that the user logs in, they will be prompted to change their password.
    </p>
    <p>
      Upon termination in OnePoint, we will attempt to delete the Office 365 account that is under the corresponding email address. If the deletion is successful, their data is deleted and their licenses can be assigned to other users. You can restore deleted users and their data for up to 30 days after you delete them.
    </p>
    <p>
      We process new hires on the <b>Date Hired</b> in OnePoint, and we process terminations using the <b>Date Terminated</b>.
    </p>
    <div className="subHead">
      Common Error Causes
    </div>
    <ul>
      <li>
        Hiring/Terminating the employee with a Primary Email address which is not in a domain that is linked to your Office 365 organization. Office 365 will not allow you to create a User if it is not in a domain that you own.
      </li>
      <li>
        Signing into Office 365 with a User that does not have admin permissions. The user must be allowed to create and delete Users. If not, this integration will fail.
      </li>
      <li>
        Terminating an employee while their Primary Email in OnePoint is different than their email address in Office 365. These must match for this integration to delete the correct User account in Office 365.
      </li>
      <li>
        Hiring an employee while their Primary Email in OnePoint is already linked to a User account in Office 365. Two Users cannot have the same email address, so this will result in a failure.
      </li>
    </ul>
  </div>
)
