import * as LogsTypes from "../actions/logs";

const initialState = {
  error: "",
  isFetching: false,
  logsData: [],
};

export default function logsReducer(state = initialState, action) {
  switch (action.type) {
    case LogsTypes.GET_EXPORTS_LOGS_REQUEST:
      return Object.assign({}, state, {
        error: "",
        isFetching: true,
      });
    case LogsTypes.GET_EXPORTS_LOGS_SUCCESS:
      return Object.assign({}, state, {
        error: "",
        isFetching: false,
        // logsData: action.response.result,
      });
    case LogsTypes.GET_EXPORTS_LOGS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
      });

    default:
      return state;
  }
}
