import React, { useEffect, useState } from "react";
import logo from "../assets/images/op-logo.png";
import { Link } from "react-router-dom";
import { userProvisioningToolConfirmation } from "../actions/integrations";
import { connect } from "react-redux";
import Loading from "../components/ui/Loading";
import badgeCheck from "../assets/images/badge-check.png";
import closeIcon from "../assets/images/cancel.png";

const ToolConfirmation = (props) => {
  const { dispatch } = props;
  const [, setResponseData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const id = new URLSearchParams(props.location.search).get("id");
  const isdone = new URLSearchParams(props.location.search).get("isdone");

  const confirmationEvent = async () => {
    try {
      setIsLoading(true);
      const { response } = await dispatch(
        userProvisioningToolConfirmation({
          id: Number(id),
          is_confirmed: Number(isdone),
        })
      );
      console.log("response", response);
      setResponseData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    confirmationEvent();
  }, []);

  return (
    <div>
      <div className="topNav blue">
        <div className="cont">
          <div className="logoCont">
            <Link to={`/`}>
              <img src={logo} alt="logo" />
            </Link>
          </div>
          <div className="rightSide">
            <div className="logoutCont">
              {/* <button onClick={logout}>SIGN OUT</button> */}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-8 confirm-body">
        <div className="confirm-container">
          {isLoading ? (
            <Loading />
          ) : isdone === "1" ? (
            <>
              <h1>Congratulation!!</h1>
              <div className="badge-check-icon">
                <img src={badgeCheck} alt="img" />
              </div>
              <h2 className="mt-2">Thanks for confirming!</h2>
              <p>
                Congratulation you have joined, this user-provisioning tool.
              </p>
            </>
          ) : (
            <>
              <h1>Denied</h1>
              <div className="badge-check-icon">
                <img src={closeIcon} alt="img"/>
              </div>
              <h2 className="mt-2">Thanks for your confirming!</h2>
              <p>You have no need to join, this user-provisioning tool.</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default connect()(ToolConfirmation);
