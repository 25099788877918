import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import '../../styles/SettingComponents.css';

const options = [
  {
    'label': 'Use Primary Email from OnePoint (must include at time of hire)',
    'value': 'use_op',
    'needsDomain': false,
  },
  {
    'label': 'Autogenerate using pattern: {lowercase First Name}{lowercase Last Name}@{Domain Name}',
    'value': 'adjacent',
    'needsDomain': true,
    'example': 'janedoe@yourcompany.com'
  },
  {
    'label': 'Autogenerate using pattern: {lowercase First Name}.{lowercase Last Name}@{Domain Name}',
    'value': 'period',
    'needsDomain': true,
    'example': 'jane.doe@yourcompany.com'
  },
  {
    'label': 'Autogenerate using pattern: {lowercase First Inital}{lowercase Last Name}@{Domain Name}',
    'value': 'first_initial',
    'needsDomain': true,
    'example': 'jdoe@yourcompany.com'
  },
  {
    'label': 'Use Username from OnePoint: {OnePoint Username}@{Domain Name}',
    'value': 'op_username',
    'needsDomain': true,
    'example': 'opusername@yourcompany.com'
  },
]

 class EmailNameSetting extends Component {
   constructor(props) {
     super(props)

     this.state = {error: ''}

     this.renderDomainName = this.renderDomainName.bind(this)
     this.onTextChange = this.onTextChange.bind(this)
     this.renderLabel = this.renderLabel.bind(this)
   }

  onSelectChange(event) {
    const { setting, onChange } = this.props

    let thisOption = this.getOptionFromChoice(event.target.value)
    if (thisOption.needsDomain && (setting.domain_name === '' || setting.domain_name === undefined)) {
      this.setState({error: 'Domain Name is required for this choice.'})
    } else {
      this.setState({error: ''})
    }

    onChange({...setting, choice: event.target.value})
  }

  onTextChange(name, event) {
    const { setting, onChange } = this.props

    if (event.target.value === '') {
      let thisOption = this.getOptionFromChoice(setting.choice)
      if (thisOption.needsDomain) {
        this.setState({error: 'Domain Name is required for this choice.'})
      } else {
        this.setState({error: ''})
      }
    }

    onChange({...setting, [name]: event.target.value})
  }

  getOptionFromChoice(choice) {
    const thisOptionA = this.props.options.filter((opt) => opt.value === choice)

    if (thisOptionA.length === 0) {
      return null
    }

    return thisOptionA[0]
  }

  renderDomainName(thisOption, choice) {
    const { setting, integrationName } = this.props

    if (thisOption.needsDomain) {
      return (
        <div className="domainSetting">
          <div className="textSetting">
            <label>
              Domain Name to Use:
            </label>
            <div className="inputCont">
              <input type="text" value={setting.domain_name !== undefined ? setting.domain_name : ''} onChange={(event) => this.onTextChange('domain_name', event)} />
            </div>
          </div>
          {
            integrationName !== 'Salesforce'
            &&
            (
              <div className="note">
                Note: this domain name must be connected to { integrationName } in order to successfully create an email address
              </div>
            )
          }
        </div>
      )
    } else {
      return null
    }
  }

  renderLabel(integrationName) {
    switch (integrationName) {
      case 'Salesforce':
      case 'Box':
      case 'Dropbox':
        return 'New Username Source'
      default:
        return 'New Email Address Format'
    }
  }

  render() {
    const { setting, integrationName } = this.props
    const { error } = this.state

    let thisOption = this.getOptionFromChoice(setting.choice)

    return (
      <div className="enSetting">
        {
          error !== ''
          &&
          (
            <div className="error">
              { error }
            </div>
          )
        }
        <div className="selectSetting">
          <label>
            { this.renderLabel(integrationName) }:
          </label>
          <div className="selectCont">
            <select value={ setting.choice } onChange={this.onSelectChange.bind(this)}>
              {
                this.props.options.map((opt) => (
                  <option key={opt.label} value={opt.value}>
                    {opt.label}
                  </option>
                ))
              }
            </select>
          </div>
        </div>
        {
          thisOption.example !== '' && thisOption.example !== undefined
          &&
          (
            <div className="example">
              example: { thisOption.example }
            </div>
          )
        }
        { this.renderDomainName(thisOption, setting.choice) }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { products } = state.entities
  if (products === undefined || ownProps.integrationName === 'G Suite' || ownProps.integrationName === 'Microsoft Office 365/Active Directory') {
    return {
      options
    }
  }

  let emailServices = []
  products['user-provisioning']['integrations'].forEach((int) => {
    switch (int) {
      case 'gsuite':
        emailServices.push({
          'label': 'Use the email address generated by your G Suite Integration',
          'value': 'gsuite',
          'needsDomain': false,
        })
        return
      case 'microsoft365':
        emailServices.push({
          'label': 'Use the email address generated by your Office 365 Integration',
          'value': 'microsoft365',
          'needsDomain': false,
        })
        return
      default:
        return
    }
  })

  const combinedOptions = options.concat(emailServices);

  return {
    options: combinedOptions
  }
}

export default connect(mapStateToProps)(EmailNameSetting)
