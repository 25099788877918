import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect } from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { oldAppPath } from "../../constants/constant";

const MultipleEINDataTable = ({
  data,
  row,
  copyRow,
  userInfo,
  isAdmin,
  navigate,
  toggleEditCompanyModal,
  deleteAssociationsItem,
  reActiveAssociationsItem,
  toggleEmployeeModel,
  toggleAddCompanyModal,
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState(row);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const pageCount = Math.ceil(rowData.length / rowPerPage);

  const offset = currentPage * rowPerPage;

  const rowPerPageValue = [10, 25, 50, 100, 150, 200];

  const handleSearchChange = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    const filterData = copyRow.filter((item) => {
      let firstArray = Object.values(item);
      let secondArray = Object.values(firstArray[firstArray.length - 1]);

      delete firstArray[firstArray.length - 1];

      return [...firstArray, ...secondArray]
        .join("")
        .toLocaleLowerCase()
        .includes(value.toLocaleLowerCase());
    });

    setRowData(filterData);
  };

  const handleSelectSort = (e) => {
    const { value } = e.target;
    setCurrentPage(0);
    setRowPerPage(value);
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  useEffect(() => {
    (() => {
      setRowData(row);
    })();
  }, [row]);

  return (
    <div>
      <div className="integration-table">
        <div className="header-action">
          <select
            className="sort-select"
            onChange={handleSelectSort}
            name=""
            id=""
            defaultValue={rowPerPage}
          >
            {rowPerPageValue.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          <input
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
          />

          {
            // eslint-disable-next-line
            isAdmin == "true" && (
              <button
                style={{ marginLeft: "10px" }}
                className="cstmbtn1 bg-theme"
                onClick={toggleAddCompanyModal}
              >
                ADD
              </button>
            )
          }
        </div>
      </div>

      <table className="table tablecstm">
        <thead>
          <tr>
            <th className="col1">Title</th>
            <th className="col2">Parent Company</th>
            <th className="col3">Associated Company Count</th>
            <th className="col4">Created Date</th>
            <th className="col5">Last Modified Date</th>
            <th className="col5">Status</th>
            <th className="col5">Actions</th>
          </tr>
        </thead>
        <tbody>
          {row && row.length === 0 ? (
            <td colSpan={7}>
              <div className="noapps">No Associations have created yet</div>
            </td>
          ) : (
            rowData.slice(offset, offset + rowPerPage).map((i) => (
              <tr key={`installs${i.id}`}>
                <td className="col2">{i.title || "N/A"}</td>
                <td className="col3">{i.Parent_company_name || "N/A"}</td>
                <td className="col4 but">
                  <Link
                    to={`${oldAppPath}/product/view/${i.id}`}
                    style={{
                      color: "-webkit-link",
                      pointerEvents: `${i.status ? "" : "none"}`,
                    }}
                  >
                    {i.associated_company_count || "N/A"}
                  </Link>
                </td>

                <td className="col6">
                  {moment(i.created_at).format("MM/DD/YYYY kk:mm:ss") || "N/A"}
                </td>
                <td className="col6">
                  {moment(i.updated_at).format("MM/DD/YYYY kk:mm:ss") || "N/A"}
                </td>
                <td className="col5">
                  {
                    // eslint-disable-next-line
                    i.status == "1" ? "ACTIVE" : "INACTIVE"
                  }
                </td>
                <td className="col5">
                  <button
                    title="View Report"
                    disabled={i.status === 1 ? false : true}
                    onClick={() =>
                      navigate(`${oldAppPath}/reports/view/${i.id}`)
                    }
                    className={i.status === 1 ? "" : "my-btn-disable"}
                  >
                    <FontAwesomeIcon icon={solid("eye")} />
                  </button>
                  {userInfo.is_system_admin && (
                    <>
                      {
                        // eslint-disable-next-line
                        isAdmin == "true" && (
                          <>
                            <button
                              title="edit"
                              disabled={i.status === 1 ? false : true}
                              className={i.status === 1 ? "" : "my-btn-disable"}
                              style={{ marginLeft: "10px" }}
                              onClick={() => toggleEditCompanyModal(i.id)}
                            >
                              <FontAwesomeIcon icon={solid("edit")} />
                            </button>
                            <button
                              title={i.status ? "Inactive" : "Reactive"}
                              style={{ marginLeft: "10px" }}
                              onClick={() => {
                                i.status
                                  ? deleteAssociationsItem(i.id)
                                  : reActiveAssociationsItem(i.id);
                              }}
                            >
                              {i.status ? (
                                <FontAwesomeIcon icon={solid("trash-alt")} />
                              ) : (
                                <FontAwesomeIcon icon={solid("repeat")} />
                              )}
                            </button>
                          </>
                        )
                      }
                    </>
                  )}
                  <button
                    title="Report"
                    disabled={i.status === 1 ? false : true}
                    className={i.status === 1 ? "" : "my-btn-disable"}
                    style={{ marginLeft: "10px" }}
                    onClick={() => toggleEmployeeModel(i.id)}
                  >
                    <FontAwesomeIcon icon={solid("file-export")} />
                  </button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>

      <ReactPaginate
        breakLabel="..."
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
        containerClassName={"pagination-container"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"disabled"}
        activeClassName={"active"}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
      />
    </div>
  );
};

export default MultipleEINDataTable;
