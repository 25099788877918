import React from 'react'

export const Documentation = (
  <div className="documentationBody">
    <p>
      In order to get started, a Salesforce administrator with the permissions required to create and suspend users must connect above in the Connection Status section. This will redirect them to a dialogue where they grant Onepoint access to perform actions on their behalf.
    </p>
    <p>
      If you wish to temporarily stop these automatic actions from occuring, select Disabled in the Integration Settings area.
    </p>
    <p>
      <b>Important</b>: under the New Username Settings, select the email address format that best suites your company. The Salesforce Username should match the user's email address.
    </p>
    <p>
      The new user{'\''}s password can be found in the Summary Email that is sent to the selected Employee in the User Provisioning Product Setting. The first time that the user logs in, they will be prompted to change their password.
    </p>
    <p>
      When we generate the new Salesforce user, they are assigned the "Standard User" Profile. You can change this in Salesforce after creation.
    </p>
    <p>
      Each inserted user also counts as a license. Every organization has a maximum number of licenses. If you attempt to exceed the maximum number of licenses by inserting user records, the create is rejected.
    </p>
    <p>
      Upon termination in OnePoint, the user's account in Salesforce will be made Inactive. They will be blocked from logging in, and it will free up a Salesforce license.
    </p>
    <p>
      We process new hires on the <b>Date Hired</b> in OnePoint, and we process terminations using the <b>Date Terminated</b>.
    </p>
    <div className="subHead">
      Common Error Causes
    </div>
    <ul>
      <li>
        Connecting to Salesforce with a user that does not have admin permissions. The user must be allowed to create and update users. If not, this integration will fail. A key permission to check for in Salesforce is the "Manage Internal users" permission.
      </li>
      <li>
        Terminating an employee while their Primary Email in OnePoint is different than their Username in Salesforce. These must match for this integration to deactivate the correct User account in Salesforce.
      </li>
      <li>
        Hiring an employee while their Primary Email in OnePoint is already being used as a Username in Salesforce. Salesforce Usernames must be unique across <b>all</b> organizations.
      </li>
    </ul>
  </div>
)
