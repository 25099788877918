import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import "../styles/UserProvisioningBody.css";
import { integrationDisable, oldAppPath } from "../constants/constant";

class UserProvisioningBody extends Component {
  
  render() {
    const { integrations } = this.props;

    const integrationsSort = integrations.sort(
      (a, b) => b.authenticated - a.authenticated
    );

    return (
      <div className="UserProvisioningBody">
        <h2>Integrations</h2>
        <div className="intsCont">
          {integrationsSort.map((integration, index) => {
            const integrationCheck = !integrationDisable.includes(
              integration.url
            );
            return (
              <div className={`intx ${integration.url}`} key={`ints ${index}`}>
                <Link
                  to={
                    integrationCheck
                      ? "#"
                      : `${oldAppPath}/integrations/${integration.url}`
                  }
                >
                  <div
                    className="img"
                    style={{
                      backgroundImage:
                        "url(/images/" + integration.image_name + ")",
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundColor: "#eeeeee",
                      backgroundRepeat: "no-repeat",
                    }}
                  ></div>
                  <div className="title">{integration.integration_name}</div>
                  <div className="desc">{integration.description}</div>
                  <div
                    className={
                      integrationCheck
                        ? "status blue"
                        : integration.authenticated &&
                          integration.settings.enabled
                        ? "status green"
                        : "red status"
                    }
                  >
                    {integrationCheck
                      ? "COMING SOON"
                      : integration.settings.enabled
                      ? integration.authenticated
                        ? "CONNECTED AND ENABLED"
                        : "ENABLED BUT NOT CONNECTED"
                      : "DISABLED"}
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default withRouter(UserProvisioningBody);
