import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";

import App from "./App";
import FormPage from "../components/FormPage";
import BaseProductEIN from "../components/BaseProductEIN";
import CustomReport from "../components/multipleEINReport/CustomReport";
import DefaultReport from "../components/multipleEINReport/DefaultReport";
import DashboardIndex from "../dashboard/DashboardIndex";
import ToolConfirmation from "./ToolConfirmation";
import ESSIndex from "../ESS/ESSIndex";
import AuthPageContainer from "./AuthPageContainer";
import RequireAuth from "./RequireAuth";
import IndexContainer from "./IndexContainer";
import LoginContainer from "./LoginContainer";
import CallbacksContainer from "./CallbacksContainer";
import EmpIntegrationEventStatus from "../dashboard/components/userProvisioning/empIntegrationEventStatus";
import MineralLogin from "../components/MineralLogin";

// App is main UI, but Switch allows us to render different not nested UIs
const Root = ({ store }) => {
  // document.body.style.zoom = "80%";
  return (
    <Provider store={store}>
      <div>
        <Switch>
          <Route
            exact
            path="/user-provisioning/tool/confirmation"
            component={ToolConfirmation}
          />

          <Route exact path="/" component={IndexContainer} />

          <Route
            exact
            path={`/callbacks/:callbackName`}
            component={RequireAuth(CallbacksContainer)}
          />

          <Route path="/ess" component={ESSIndex} />
          <Route path="/app" component={RequireAuth(DashboardIndex)} />
          <Route path="/form/:formURL/:applicantID" component={FormPage} />
          <Route
            path="/custom/report/:id/:str/:reportId"
            component={CustomReport}
          />
           <Route
            path="/emp-integration-status/:eventId"
            component={EmpIntegrationEventStatus}
          />
          <Route path="/default/report" component={DefaultReport} />

          <Route path="/login" component={AuthPageContainer} />
          <Route path="/login-new" component={LoginContainer} />

          <Route path="/old" component={App} />

          <Route path="/products/multipleEIN" component={BaseProductEIN} />
          <Route path="/mineral/login" component={MineralLogin} />

          
        </Switch>
      </div>
    </Provider>
  );
};

Root.propTypes = {
  store: PropTypes.object.isRequired,
};

export default Root;
