import React, { useEffect } from "react";
import useForm from "../../../hooks/useForm";
import { isEmpty } from "lodash";

const initialValues = {
  client_id: "",
  client_secret: "",
  oneloginDomain: "",
  redirect_url: "",
  sup_client_id: "",
  sup_client_secret: "",
  sup_redirect_url: "",
  created_at: "",
  updated_at: "",
};

const OneLoginConfigurationForm = (props) => {
  const { handleUpdate, formResponse, handleConfigurationToggle } = props;
  const { formValue, handleChange, handleSubmit, setFormValue } = useForm({
    defaultValue: initialValues,
  });

  const onSubmit = (data) => {
    handleUpdate(data);
  };

  useEffect(() => {
    if (formResponse) {
      setFormValue(() => {
        return {
          client_id: formResponse.config.client_id,
          client_secret: formResponse.config.client_secret,
          oneloginDomain: formResponse.config.oneloginDomain,
          redirect_url: formResponse.config.redirect_url,
          sup_client_id: formResponse.config.sup_client_id,
          sup_client_secret: formResponse.config.sup_client_secret,
          sup_redirect_url: formResponse.config.sup_redirect_url,
          created_at: formResponse.created_at,
          updated_at: formResponse.updated_at,
        };
      });
    }
  }, [formResponse]);

  return (
    <div className="one-login-configuration-form">
      <h4 className="mb-0 mt-0">Domain URL</h4>
      <div className="one-control-input">
        <input
          type="text"
          placeholder="Domain URL"
          name="oneloginDomain"
          value={formValue.oneloginDomain}
          onChange={handleChange}
        />
        {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
      </div>
      <div>
        <h4>Application configuration</h4>
        <div className="one-control-input">
          <label htmlFor="">Client ID</label>
          <input
            type="text"
            placeholder="Client ID"
            name="client_id"
            value={formValue.client_id}
            onChange={handleChange}
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
        <div className="one-control-input">
          <label htmlFor="">Client secret</label>
          <input
            type="text"
            placeholder="Client secret"
            name="client_secret"
            value={formValue.client_secret}
            onChange={handleChange}
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
      </div>
      <div>
        <h4>API configuration</h4>
        <div className="one-control-input">
          <label htmlFor="">Client ID</label>
          <input
            type="text"
            placeholder="Client ID"
            name="sup_client_id"
            value={formValue.sup_client_id}
            onChange={handleChange}
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
        <div className="one-control-input">
          <label htmlFor="">Client secret</label>
          <input
            type="text"
            placeholder="Client secret"
            name="sup_client_secret"
            value={formValue.sup_client_secret}
            onChange={handleChange}
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
      </div>
      <div className="one-control-input">
        <label htmlFor="">Redirect URL</label>
        <input
          type="text"
          placeholder="Redirect URL"
          name="sup_redirect_url"
          value={formValue.sup_redirect_url}
          onChange={handleChange}
        />
        {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
      </div>
      {!isEmpty(formValue.created_at) && (
        <div className="one-control-input">
          <label htmlFor="">Create At</label>
          <input
            type="text"
            placeholder="Create At"
            name="redirect_url"
            value={formValue.created_at}
            onChange={handleChange}
            disabled
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
      )}
      {!isEmpty(formValue.updated_at) && (
        <div className="one-control-input">
          <label htmlFor="">Updated At</label>
          <input
            type="text"
            placeholder="Updated At"
            name="redirect_url"
            value={formValue.updated_at}
            onChange={handleChange}
            disabled
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
      )}
      <div className="mul-association-btn my-flex my-justify-content-end">
        <button className="ms-2" onClick={handleConfigurationToggle}>
          Cancel
        </button>
        <button className="ms-2" onClick={handleSubmit(onSubmit)}>
          Save
        </button>
      </div>
    </div>
  );
};

export default OneLoginConfigurationForm;
