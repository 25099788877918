import React, { useState } from "react";
import axios from "axios";
import { getToken } from "../actions/auth";
import toast, { Toaster } from 'react-hot-toast';
import { Link } from "react-router-dom";

const MineralLogin = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [userError, setUserError] = useState("");
    const [isLoading, setLoading] = useState(false);
    const { token } = getToken();

    const handleSubmit = async () => {
        switch (true) {
            case username === "":
                setUserError("Please Enter The Username")
                return;
            case password === "":
                setError("Please Enter Strong Password.");
                return;
            case !/^(?=.*[A-Z])/.test(password):
                setError("At least One Uppercase Character.");
                return;
            case !/^(?=.*[a-z])/.test(password):
                setError("At least One Lowercase Character.");
                return;
            case !/^(?=.*[0-9])/.test(password):
                setError("At least One Number.");
                return;
            case !/^(?=.*[!@#%^&$*])/.test(password):
                setError("At least One Special Character.");
                return;
            default:
                break;
        }

        setLoading(true);
        try {
            const requestBody = {
                name: "mineral",
                username: username,
                password: password
            };

            const response = await axios.post(
                "/api/oauth/code",
                requestBody,
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );

            // Handle successful authentication response
            console.log("Authentication successful:", response.data);
            // window.location.replace("http://localhost:3000/app/integrations/mineral");
            window.location.replace("https://"+window.location.host+"/app/integrations/mineral");

            toast.success("Login Successfully");
        } catch (error) {
            // Handle authentication error
            toast.error("Login failed")
            console.error("Authentication error:", error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="mineral-login-page">
            <div className="main-wrapper">
                <div className="logo-wrapper">
                    <img src="https://s3-us-west-2.amazonaws.com/cdn.trustmineral-staging.com/assets/MIN-PrimaryLogo-TM-Citrine-RGB-01.png"
                        alt="Logo" className="Cstmlogo" />
                    <hr className="hr" />
                </div>
                <div>
                    <span className="signin-heading">Sign in to Your Account</span>
                </div>
                <div className="form-wrapper">
                    <form>

                        <div className="Cstmtext-start">
                            <label htmlFor="Username">Username</label>
                        </div>
                        <input
                            type="text"
                            id="Username"
                            name="Username"
                            value={username}
                            onChange={(e) => [setUsername(e.target.value), setUserError('')]}
                            placeholder="Username"
                            required
                        />
                        <div className="error color-red error_top">{userError}</div>
                        {/* <input type="text" id="Username" name="Username" placeholder="Username" /> */}
                        <br />
                        <label htmlFor="" className="remember-wrapper">
                            <div>
                                <input id="" name="checkbox" type="checkbox" />
                            </div>
                            <label>Remember my Username</label>
                            <svg aria-describedby="tooltip_" title="" width="24" height="24" viewBox="0 0 24 24"
                                color="inherit" aria-hidden="true" focusable="false">
                                <path
                                    d="M12 11.09a.91.91 0 0 1 .91.91v3.636a.91.91 0 0 1-1.82 0V12a.91.91 0 0 1 .91-.91zM12 7.455a.91.91 0 1 0 0 1.818h.01a.91.91 0 1 0 0-1.818H12z"
                                    fill="#000"></path>
                                <path fillRule="evenodd" clipRule="evenodd"
                                    d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12zm10-8.182a8.182 8.182 0 1 0 0 16.364 8.182 8.182 0 0 0 0-16.364z"
                                    fill="#000"></path>
                            </svg>
                        </label>
                        <div className="Cstmtext-start">
                            <label htmlFor="Password">Password</label><br />
                        </div>
                        <input
                            type="password"
                            id="Password" name="Password"
                            value={password}
                            onChange={(e) => [setPassword(e.target.value), setError('')]}
                            placeholder="Password"
                            required
                        />
                        <div className="error color-red error_top">{error}</div>
                        <br />
                        {/* <input type="Password" id="Password" name="Password" placeholder="Password" /><br /><br /> */}
                        <button
                            className="signin-btn"
                            type="button"
                            onClick={handleSubmit}
                            disabled={isLoading}
                        >
                            {isLoading ? "Logging in..." : "Sign in"}
                        </button>
                        {/* Add loading indicator if isLoading is true */}
                        {/* {isLoading && <p>Loading...</p>} */}
                    </form>
                </div>
                <Link to='' className="forgotpasstext">Forgot Username or Password?</Link>
                <p className="termstext">By signing in, you agree to the Mineral <Link to='' className="cstmtext-color"> Terms of Service</Link>
                    and
                    <Link to='' className="cstmtext-color">Privacy Policy</Link>
                </p>
            </div>
            <Toaster />
        </div>
    );
}

export default MineralLogin;
