import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "../ui/Loading";
import HideShow from "../ui/HideShow";
import IntegrationSettings from "../IntegrationSettings";
import { connect } from "react-redux";
import { fetchEvents } from "../../actions/events";
import { integrationConnectDisconnect } from "../../actions/integrations";
import { fetchProducts } from "../../actions/products";
import IntegrationDataTable from "./IntegrationDataTable";
import ConfigurationModel from "./modal/ConfigurationModel";

class X8Integration extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    saveSettings: PropTypes.func.isRequired,
    disconnectIntegration: PropTypes.func.isRequired,
    documentation: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {};

    this.launchWindow = this.launchWindow.bind(this);
  }

  componentWillMount() {
    const { dispatch, topicURL, topicEvents } = this.props;

    if (topicEvents === undefined || topicEvents === null) {
      dispatch(fetchEvents(topicURL));
    }
  }

  componentWillReceiveProps(nextProps) {
    const { topicURL: oldTopicURL, dispatch } = this.props;
    const { topicURL: newTopicURL } = nextProps;

    if (oldTopicURL !== newTopicURL) {
      dispatch(fetchEvents(newTopicURL));
    }
  }

  launchWindow(url) {
    // window.location.replace(url);
  }

  integrationConnectAndDisconnect = async (val) => {
    const body = {
      event_url: "8x8",
      st: val,
    };

    await this.props.dispatch(integrationConnectDisconnect(body));
    this.props.dispatch(fetchProducts());
  };

  refreshEvents() {
    const { topicURL, dispatch } = this.props;

    var myDiv = document.getElementById("containerDiv");
    myDiv.scrollTop = 0;

    dispatch(fetchEvents(topicURL));
  }

  render() {
    const {
      data,
      events,
      eventsAreFetching,
      saveSettings,
      intsAreDisconnecting,
      documentation,
      settingsAreSaving,
      error,
      topicURL,
    } = this.props;
    if (data === undefined) {
      return null;
    }

    return (
      <div className="">
        {intsAreDisconnecting ? (
          <Loading />
        ) : (
          <div>
            <div className="head">
              <h1>{data.integration_name} Integration</h1>
              <div className="img">
                <img src={`/images/${data.image_name}`} alt=" " />
              </div>
            </div>
            <div className="intSect">
              <h2>Connection Status</h2>
              <div className="status sect">
                {topicURL === "activedirectory" ? (
                  data.authenticated ? (
                    <div className="connected">
                      <div className="good">
                        Connected to On-Premise Service
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="bad">
                        Not Connected to On-Premise Service
                      </div>
                    </div>
                  )
                ) : data.authenticated ? (
                  <div className="connected">
                    <div className="good">
                      Connected to {data.integration_name}
                    </div>
                    <div className="connect">
                      <button
                        className="red"
                        onClick={() => this.integrationConnectAndDisconnect(0)}
                      >
                        DISCONNECT
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div className="bad">Not Connected</div>
                    <div className="connect">
                      <button
                        className=""
                        onClick={() => this.integrationConnectAndDisconnect(1)}
                      >
                        Connect Into {data.integration_name}
                      </button>
                    </div>
                    <ConfigurationModel data={data} />
                  </div>
                )}
              </div>
              <div className="results sect">
                <h2>
                  Integration Events
                  <span>
                    <button onClick={this.refreshEvents.bind(this)}>
                      REFRESH
                    </button>
                  </span>
                  <span className="hint">
                    *New One point connect hires/terminations can take up to 5
                    minutes to process
                  </span>
                </h2>
                {eventsAreFetching &&
                (events === undefined || events.events.length === 0) ? (
                  <Loading />
                ) : (
                  <div
                    id="containerDiv"
                    className={eventsAreFetching ? "loading tableCont" : ""}
                  >
                    {eventsAreFetching && (
                      <div className="tableLoading">
                        <Loading />
                      </div>
                    )}
                    {events !== undefined &&
                    events.events !== undefined &&
                    events.events.length !== 0 ? (
                      <IntegrationDataTable
                        copyRow={events.events}
                        row={events.events}
                        data={data}
                      />
                    ) : (
                      <div style={{ textAlign: "center", padding: "10px" }}>
                        No hires or terminations have been processed yet.
                      </div>
                    )}
                  </div>
                )}
              </div>

              <div className="settings sect">
                <h2>Integration Settings</h2>
                <IntegrationSettings
                  settingsAreSaving={settingsAreSaving}
                  settings={data.settings}
                  saveSettings={saveSettings}
                  error={error}
                  integrationName={data.integration_name}
                  integrationId={data.integration_id}
                />
              </div>
              <div>
                <HideShow
                  label={<h2>Integration Documentation</h2>}
                  body={documentation ? documentation : "Not available."}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { isFetching: eventsAreFetching } = state.events;
  const { events } = state.entities;

  const topicEvents = events[ownProps.topicURL];

  return {
    events: topicEvents,
    eventsAreFetching,
  };
};

export default connect(mapStateToProps)(X8Integration);
