import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { getAssociationById } from "../actions/cloudpunch";
import { connect } from "react-redux";
import "../styles/CloudPunchBody.css";
import Loading from "./ui/Loading";

class AssociationsCompanies extends Component {
  constructor(p) {
    super(p);
    this.state = {
      associationsList: [],
      associationsListFilter: [],
      title: "",
      searchValue: "",
      selectSort: 10,
      isLoading: false,
    };
  }
  async componentDidMount() {
    this.setState({ isLoading: true });
    const {
      dispatch,
      match: { params },
    } = this.props;
    const { response } = await dispatch(getAssociationById(params.id));
    const myResponse = [];
    for (let item in response) {
      myResponse.push(response[item]);
    }
    // console.log("response", response)
    this.setState({
      isLoading: false,
      associationsList: response.companies.slice(0, this.state.selectSort),
      associationsListFilter: response.companies,
      title: response.title,
    });
  }

  requestSearch = (searchedVal) => {
    const filteredRows = this.state.associationsListFilter.filter((row) => {
      return row.name
        .toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase());
    });
    this.setState({ associationsList: filteredRows });
  };

  handleSearch = (e) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
    this.requestSearch(value);
  };
  handleSelectSort = (e) => {
    const { value } = e.target;
    const sortRows = this.state.associationsListFilter.slice(0, value);
    this.setState({ associationsList: sortRows, selectSort: value });
  };

  render() {
    const { associationsList, isLoading } = this.state;
    const { history } = this.props;
    const selectValue = [10, 25, 50, 100, 150, 200];
    // console.log("this.props", this.state.)

    return (
      <div>
        <div>
          <div className="breadcrumbs">
            <p onClick={history.goBack}>
              &larr; Back to list of your associations
            </p>
          </div>
          <h1>Association Companies</h1>
          <div className="section installations">
            {isLoading && (
              <div className="loadCont">
                <Loading />
              </div>
            )}
            <h2>
              <div className="d-flexcstm">
                <div className="col">{this.state.title}</div>
                <div className="col">
                  <select
                    className="sort-select"
                    onChange={this.handleSelectSort}
                    name=""
                    id=""
                  >
                    {selectValue.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    className="search-input"
                    name="searchValue"
                    value={this.state.searchValue}
                    onChange={this.handleSearch}
                    placeholder="Search"
                  />
                </div>
              </div>
            </h2>
            <div className="tableCont">
              {associationsList && associationsList.length === 0 ? (
                <div className="noapps">No Associations have created yet</div>
              ) : (
                <table className="table tablecstm">
                  <thead>
                    <tr>
                      <th className="col1">S.NO</th>
                      <th className="col2">Name</th>
                      <th className="col3">Short name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {associationsList.map((item, ind) => (
                      <tr key={`installs${item.ID}`}>
                        <td className="col2">{ind + 1 || "N/A"}</td>
                        <td className="col5">
                          <span>{item.name || "N/A"}</span>
                          {item.p_company_id ? (
                            <span
                              style={{ float: "right" }}
                              className="badgecolor"
                            >
                              Primary
                            </span>
                          ) : (
                            ""
                          )}
                        </td>
                        <td className="col5">{item.short_name || "N/A"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { cp_installations, cp_employees, cp_enrollments } = state.entities;
  const {
    beenLoadedOnce,
    allFetching,
    installationsList,
    employeesList,
    installsFetching,
    empsAreFetching,
    associationsList,
    getAssociationsById,
  } = state.cloudpunch;
  // console.log("state.cloudpunch",state.cloudpunch.getAssociationsById)
  const { costCenters, isFetching: cdFetching } = state.company_data;
  const { companyInfo, userInfo } = state.products;
  // console.log("userInfo userInfo",state)
  const costCentersFetching = cdFetching["cost-centers"];

  const instsListJoin =
    installationsList === undefined
      ? []
      : installationsList.map((id) => {
          let thisIn = Object.assign({}, cp_installations[id]);
          return thisIn;
        });

  const empsListJoin =
    employeesList === undefined
      ? []
      : employeesList.map((id) => {
          let thisIn = Object.assign({}, cp_employees[id]);
          thisIn.enrollments = thisIn.enrollments.map((id) => {
            {
              let thisEn = Object.assign({}, cp_enrollments[id]);
              return thisEn;
            }
          });
          return thisIn;
        });

  return {
    beenLoadedOnce,
    allFetching,
    installations: instsListJoin,
    associations: associationsList,
    employees: empsListJoin,
    installsFetching,
    empsAreFetching,
    companyInfo,
    userInfo,
    costCenters: costCenters !== undefined ? costCenters : null,
    costCentersFetching,
    getAssociationsById,
  };
};

export default withRouter(connect(mapStateToProps)(AssociationsCompanies));
