import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loading from "../ui/Loading";
import "../../styles/reportschedule.css";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toaster, toast } from "react-hot-toast";
import { getAssociationById } from "../../actions/cloudpunch";
import { isEmpty } from "lodash";
import moment from "moment";

import {
  customReportSchedulerConfiguration,
  getCustomReportSchedulerConfiguration,
} from "../../actions/customReport";

import {
  ViewCompanySFTPModal,
  validateScheduleCompany,
} from "../exports/AddConfiguration";

// all week day array
export const weeklyDays = [
  { name: "Monday" },
  { name: "Tuesday" },
  { name: "Wednesday" },
  { name: "Thursday" },
  { name: "Friday" },
  { name: "Saturday" },
  { name: "Sunday" },
];

// company SFTP initial values
const companySFTPDataInitialValue = {
  port: "",
  host: "",
  password: "",
  username: "",
  company_id: "",
  remote_file_path: "",
};

// all configuration initial value method
const formValueInitialValue = (event) => ({
  period: "day",
  host: "",
  port: "",
  password: "",
  username: "",
  event: event,
  interval_time: "",
  day: "",
  interval_date: "",
  remote_file_path: "",
  settings: [],
  logType: "All",
  dateFormat: "YYYYDDMM_2",
  fileName: "",
});

// configuration validation function
const validateScheduleForm = (formData) => {
  let error = { status: false, values: {} };

  const emptyString = /^[a-zA-Z0-9/][^\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?].*$/;

  const fields = [
    "host",
    "port",
    "password",
    "username",
    "event",
    "interval_time",
    "remote_file_path",
  ];

  for (let key in formData) {
    if (fields.includes(key)) {
      const strCheck = emptyString.test(formData[key]);
      if (!strCheck) {
        error.values[key] = `${key} field is required.`;
        error.status = true;
      }
    }
  }

  if (["weekly", "biweekly"].includes(formData.period)) {
    const strCheck = emptyString.test(formData.day);
    if (!strCheck) {
      error.values.day = `day field is required.`;
      error.status = true;
    }
  }

  if (formData.period === "monthly") {
    const strCheck = emptyString.test(formData.interval_date);
    if (!strCheck) {
      error.values.interval_date = `interval_date field is required.`;
      error.status = true;
    }
  }

  return error;
};

// company SFTP configuration validation form
const validateCompanyForm = (formData) => {
  let error = { status: false, values: {} };

  const emptyString = /^[a-zA-Z0-9/][^\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?].*$/;

  for (let key in formData) {
    const strCheck = emptyString.test(formData[key]);
    if (!strCheck) {
      error.values[key] = `${key} field is required.`;
      error.status = true;
    }
  }

  return error;
};

// convert ISO time into the UTC format
export const convertToUTC = (timeString) => {
  const today = new Date().toDateString();
  const time = new Date(`${today} ${timeString}`);
  const UTC = time.toUTCString();
  const UTCTime = UTC.split(" ")[4];
  const t = UTCTime.split(":");
  return t[0] + ":" + t[1];
};

// convert UAT time into the ISO format
export const convertToISO = (timeString) => {
  var date = new Date(`2023-01-01 ${timeString}:00 UTC`);
  const time = date.toString().split(" ")[4];
  const t = time.split(":");
  return t[0] + ":" + t[1];
};

// date convert into the UAT format
const dateConvertToUTC = (date) => {
  return moment(new Date(date).toUTCString()).format("YYYY-MM-DD");
};

// get next day of the week date
function getNextDayOfTheWeek(
  dayName,
  excludeToday = true,
  refDate = new Date()
) {
  const dayOfWeek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"].indexOf(
    dayName.slice(0, 3).toLowerCase()
  );
  if (dayOfWeek < 0) return;
  refDate.setHours(0, 0, 0, 0);
  refDate.setDate(
    refDate.getDate() +
      +!!excludeToday +
      ((dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7)
  );
  return refDate;
}

// export format file name
const exportFileNames = [
  {
    title: `filename{MMDDYYYY}`,
    value: `MMDDYYYY_2`,
  },
  {
    title: `filename{MM-DD-YYYY}`,
    value: `MM-DD-YYYY_2`,
  },
  {
    title: `filename{MM.DD.YYYY}`,
    value: `MM.DD.YYYY_2`,
  },
  {
    title: `{MMDDYYYY}filename`,
    value: `MMDDYYYY_1`,
  },
  {
    title: `{MM-DD-YYYY}filename`,
    value: `MM-DD-YYYY_1`,
  },
  {
    title: `{MM.DD.YYYY}filename`,
    value: `MM.DD.YYYY_1`,
  },
];

//custom report scheduler
class CustomReportSchedule extends Component {
  constructor(p) {
    super(p);
    this.state = {
      associationsList: [],
      associationsListFilter: [],
      title: "",
      searchValue: "",
      selectSort: 10,
      isLoading: false,
      isReportLoading: false,
      formValue: formValueInitialValue("csv"),
      companySFTP: [],
      companySFTPData: companySFTPDataInitialValue,
      errors: {},
      errorsCompany: {},
      toggle: false,
      emailName: "",
      dateFormat: null,
      checkCredentials: false,
    };
  }

  // SFTP configuration field disable
  handleDisableFieldForSFTP = () => {
    this.setState({ checkCredentials: true });
  };

  // SFTP configuration field enable
  handleResetSFTP = () => {
    this.setState({ checkCredentials: false });
  };

  handleDeleteEmployee = (index) => () => {
    const { formValue } = this.state;
    const copy = { ...formValue };
    copy.settings.splice(index, 1);
    this.setState({ formValue: copy });
  };

  handleChangeEmailName = (e) => {
    this.setState({ emailName: e.target.value });
  };

  handleAddEmployeeAddress = () => {
    const validateEmail = (email) => {
      return email.match(
        // eslint-disable-next-line
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    };

    const { emailName, formValue } = this.state;

    if (validateEmail(emailName)) {
      this.setState({
        errors: {},
        emailName: "",
        formValue: {
          ...formValue,
          settings: [...formValue.settings, emailName],
        },
      });
    } else {
      this.setState({ errors: { email: "Email is not valid" } });
    }
  };

  // handle loge type change
  handleLogTypeChange = (e) => {
    const { value } = e.target;
    const { formValue } = this.state;

    this.setState({ formValue: { ...formValue, logType: value } });
  };

  handleToggle = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  // handle quick connect SFTP connection test
  handleQuickConnect = () => {
    const responseError = validateScheduleCompany(this.state.formValue);

    if (responseError.status) {
      this.setState({ errors: responseError.values });
      return null;
    }
    this.setState({ errors: responseError.values });

    this.handleToggle();
  };

  // fetch edit configuration data and set into the input field
  fetchCustomReport = async (eventName) => {
    try {
      this.setState({ isLoading: true });
      const {
        dispatch,
        match: { params },
      } = this.props;

      const { response } = await dispatch(
        getCustomReportSchedulerConfiguration(
          params.id,
          params.reportId,
          eventName
        )
      );

      const { response: result } = await dispatch(
        getAssociationById(params.id)
      );

      let stateBody = {
        isLoading: false,
        isReportLoading: false,
      };

      if (response) {
        const fileFormatSettings = JSON.parse(
          response.event_config.file_format_settings
        );

        const emailSettings = JSON.parse(
          response.event_config.email_notification_settings
        );

        const formatCondition =
          fileFormatSettings.is_first === "1" ? "_1" : "_2";

        const dateFormat = fileFormatSettings.date_format + formatCondition;

        stateBody.companySFTP = response.company_sftp_config;
        stateBody.formValue = {
          ...response.event_config,
          ...response.global_sftp_config,
          interval_time: convertToISO(response.event_config.interval_time),
          settings: [],
          dateFormat: dateFormat,
          logType: emailSettings.log_types,
          fileName: fileFormatSettings.file_name,
        };
      } else {
        stateBody.formValue = formValueInitialValue(eventName);
        stateBody.companySFTP = [];
      }

      if (result) {
        stateBody.associationsList = result.companies;
      }
      this.setState(stateBody);
    } catch (error) {
      console.log("error", error);
      this.setState({ isLoading: false });
    }
  };

  // this method run on component render
  componentDidMount() {
    this.fetchCustomReport("csv");
  }

  // this method check company same configuration exist
  configurationExist = (data) => {
    const {
      companySFTP,
      formValue: { password, port, host, username, remote_file_path },
    } = this.state;

    /* eslint-disable */
    const condition =
      password == data.password &&
      port == data.port &&
      host == data.host &&
      username == data.username &&
      remote_file_path == data.remote_file_path;

    if (condition) {
      return condition;
    }

    const filterData = companySFTP.filter(
      (item) => Number(item.company_id) === Number(data.company_id)
    );

    const matchData = filterData.map(
      ({ password, port, host, username, remote_file_path }) => {
        const condition =
          password == data.password &&
          port == data.port &&
          host == data.host &&
          username == data.username &&
          remote_file_path == data.remote_file_path;

        return condition;
      }
    );
    /* eslint-enable  */

    return matchData.includes(true);
  };

  // this method company add configuration
  handleAddSFTP = () => {
    const { companySFTP, companySFTPData } = this.state;

    const responseError = validateCompanyForm(companySFTPData);

    if (responseError.status) {
      this.setState({ errorsCompany: responseError.values });
      return null;
    }

    if (this.configurationExist(companySFTPData)) {
      this.setState({
        errorsCompany: { duplicate: "This configuration already exist!" },
      });
      return null;
    }
    this.setState({
      companySFTP: [...companySFTP, companySFTPData],
      companySFTPData: companySFTPDataInitialValue,
      errorsCompany: {},
    });
  };

  // this handle change SFTP
  handleChangeSFTP = (e) => {
    const { name, value } = e.target;
    const { companySFTPData } = this.state;

    this.setState({ companySFTPData: { ...companySFTPData, [name]: value } });
  };

  // delete SFTP configuration
  handleDeleteSFTPCompany = (index) => () => {
    const { companySFTP } = this.state;
    const copy = [...companySFTP];
    copy.splice(index, 1);
    this.setState({ companySFTP: copy });
  };

  // this method running ont input change
  handleChange = (e) => {
    const { name, value } = e.target;
    const { formValue } = this.state;
    const copy = { ...formValue };
    if (name === "event") {
      this.fetchCustomReport(value);
    }
    if (name === "period") {
      console.log();
      copy.interval_date = "";
    }
    copy[name] = value;
    this.setState({ formValue: copy });
  };

  // handle schedule configuration submit
  handleSchedule = async () => {
    try {
      const { formValue, companySFTP } = this.state;

      const responseError = validateScheduleForm(formValue);

      if (responseError.status) {
        this.setState({ errors: responseError.values });
        return null;
      }

      this.setState({ isLoading: true });

      const {
        match: { params },
        dispatch,
        userInfo: { company_id },
      } = this.props;

      const dateFormatValue = formValue.dateFormat.split("_");

      const body = {
        event: formValue.event,
        association_id: Number(params.id),
        report_id: Number(params.reportId),
        period: formValue.period,
        interval: 1,
        day: formValue.day,
        interval_date: !isEmpty(formValue.interval_date)
          ? dateConvertToUTC(formValue.interval_date)
          : "",
        interval_time: convertToUTC(formValue.interval_time),
        global_sftp: {
          host: formValue.host,
          username: formValue.username,
          password: formValue.password,
          port: Number(formValue.port),
          remote_file_path: formValue.remote_file_path,
        },
        company_sftp: companySFTP.map((item) => ({
          ...item,
          company_id: Number(item.company_id),
          port: Number(item.port),
        })),
        email_notification_settings: `${JSON.stringify({
          log_types: formValue.logType,
          email_recipient: formValue.settings,
        })}`,
        file_format_settings: `${JSON.stringify({
          file_name: formValue.fileName,
          date_format: dateFormatValue[0],
          is_first: dateFormatValue[1] === "1" ? "1" : "0",
        })}`,
        company_id: company_id,
      };

      if (formValue.period === "biweekly") {
        body.interval_date = moment(
          getNextDayOfTheWeek(formValue.day, false)
        ).format("YYYY-MM-DD");
      }

      const res = await dispatch(customReportSchedulerConfiguration(body));

      toast.success("Add successfully");

      // console.log("formValue", formValue);
      console.log("pres", res);
      this.setState({ isLoading: false });
    } catch (error) {
      console.log("error", error);
      this.setState({ isLoading: false });
    }
  };

  // this method find company by company ID
  findCompanyName = (id) => {
    const { associationsList } = this.state;
    let data = associationsList.find(({ ID }) => ID === Number(id));
    return data;
  };

  render() {
    const {
      associationsList,
      isLoading,
      formValue,
      companySFTPData,
      companySFTP,
      errors,
      errorsCompany,
      toggle,
      logType,
      checkCredentials,
    } = this.state;

    const { history, dispatch, match } = this.props;
    const { handleQuickConnect, handleResetSFTP } = this;

    return (
      <div>
        <Toaster />
        <div className="breadcrumbs">
          <p onClick={history.goBack}>
            &larr; Back to list of associations custom reports
          </p>
        </div>
        <h1>Association Custom Reports Scheduler</h1>
        {toggle && (
          <ViewCompanySFTPModal
            formValue={formValue}
            dispatch={dispatch}
            handleToggle={this.handleToggle}
            userInfo={{ company_id: Number(match.params.id) }}
            reportType="custom_report"
            reportId={
              !isEmpty(match.params.reportId) ? match.params.reportId : null
            }
            handleDisableFieldForSFTP={this.handleDisableFieldForSFTP}
          />
        )}
        <div className="section installations">
          {isLoading && (
            <div className="loadCont">
              <Loading />
            </div>
          )}

          <div className="schedule-report">
            <div className="Custmrow custmmb-2">
              <div className="custmCol-2">
                <div className="">
                  <strong>Global SFTP *</strong>
                </div>
              </div>
              <div className="col-10">
                <div className="Custmrow">
                  <div className="custmCol-3 custmmb-2">
                    <input
                      type="text"
                      className="formInput"
                      placeholder="Host"
                      name="host"
                      value={formValue.host}
                      onChange={this.handleChange}
                      disabled={checkCredentials}
                    />
                    {!isEmpty(errors.host) && (
                      <label className="color-red">{errors.host}</label>
                    )}
                  </div>
                  <div className="custmCol-3 custmmb-2">
                    <input
                      type="text"
                      className="formInput"
                      placeholder="Username"
                      name="username"
                      value={formValue.username}
                      onChange={this.handleChange}
                      disabled={checkCredentials}
                    />
                    {!isEmpty(errors.username) && (
                      <label className="color-red">{errors.username}</label>
                    )}
                  </div>
                  <div className="custmCol-3 custmmb-2">
                    <input
                      type="password"
                      className="formInput"
                      placeholder="Password"
                      name="password"
                      value={formValue.password}
                      onChange={this.handleChange}
                      disabled={checkCredentials}
                    />
                    {!isEmpty(errors.password) && (
                      <label className="color-red">{errors.password}</label>
                    )}
                  </div>
                  <div className="custmCol-3 custmmb-2">
                    <input
                      className="formInput"
                      type="number"
                      placeholder="Port"
                      name="port"
                      value={formValue.port}
                      onChange={this.handleChange}
                      disabled={checkCredentials}
                    />
                    {!isEmpty(errors.port) && (
                      <label className="color-red">{errors.port}</label>
                    )}
                  </div>
                  <div className="custmCol-3 custmmb-2">
                    <input
                      className="formInput"
                      type="text"
                      placeholder="Remote file path"
                      name="remote_file_path"
                      value={formValue.remote_file_path}
                      onChange={this.handleChange}
                      disabled={checkCredentials}
                    />
                    {!isEmpty(errors.remote_file_path) && (
                      <label className="color-red">
                        {errors.remote_file_path}
                      </label>
                    )}
                  </div>
                  <div className="Custmrow custmmb-2 quick-connect-reset-btn ">
                    <button
                      disabled={checkCredentials}
                      className="CustmBtn"
                      onClick={handleQuickConnect}
                    >
                      Quick connect
                    </button>
                    <button className="CustmBtn" onClick={handleResetSFTP}>
                      Reset
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="Custmrow">
              <div className="custmCol-2"></div>
              <div className="col-10">
                <div className="">
                  {!isEmpty(errorsCompany.duplicate) && (
                    <p className="color-red">{errorsCompany.duplicate}</p>
                  )}
                </div>
              </div>
            </div>
            <div className="Custmrow custmmb-2">
              <div className="custmCol-2">
                <div className="">
                  <strong>Company SFTP</strong>
                </div>
              </div>
              <div className="col-10">
                <div className="Custmrow">
                  <div className="custmCol-3 custmmb-2">
                    <select
                      name="company_id"
                      className="formselect"
                      onChange={this.handleChangeSFTP}
                      value={companySFTPData.company_id}
                    >
                      <option value="">Please select company</option>
                      {associationsList.map((item) => (
                        <option key={item.ID} value={item.ID}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {!isEmpty(errorsCompany.company_id) && (
                      <label className="color-red">
                        {errorsCompany.company_id}
                      </label>
                    )}
                  </div>
                  <div className="custmCol-3 custmmb-2">
                    <input
                      type="text"
                      className="formInput"
                      placeholder="Host"
                      name="host"
                      value={companySFTPData.host}
                      onChange={this.handleChangeSFTP}
                    />
                    {!isEmpty(errorsCompany.host) && (
                      <label className="color-red">{errorsCompany.host}</label>
                    )}
                  </div>
                  <div className="custmCol-3 custmmb-2">
                    <input
                      type="text"
                      className="formInput"
                      placeholder="Username"
                      name="username"
                      value={companySFTPData.username}
                      onChange={this.handleChangeSFTP}
                    />
                    {!isEmpty(errorsCompany.username) && (
                      <label className="color-red">
                        {errorsCompany.username}
                      </label>
                    )}
                  </div>
                  <div className="custmCol-3 custmmb-2">
                    <input
                      type="password"
                      className="formInput"
                      placeholder="Password"
                      name="password"
                      value={companySFTPData.password}
                      onChange={this.handleChangeSFTP}
                    />
                    {!isEmpty(errorsCompany.password) && (
                      <label className="color-red">
                        {errorsCompany.password}
                      </label>
                    )}
                  </div>
                  <div className="custmCol-3 custmmb-2">
                    <input
                      className="formInput"
                      type="number"
                      placeholder="Port"
                      name="port"
                      value={companySFTPData.port}
                      onChange={this.handleChangeSFTP}
                    />
                    {!isEmpty(errorsCompany.port) && (
                      <label className="color-red">{errorsCompany.port}</label>
                    )}
                  </div>
                  <div className="custmCol-3 custmmb-2">
                    <input
                      className="formInput"
                      type="text"
                      placeholder="Remote file path"
                      name="remote_file_path"
                      value={companySFTPData.remote_file_path}
                      onChange={this.handleChangeSFTP}
                    />
                    {!isEmpty(errorsCompany.remote_file_path) && (
                      <label className="color-red">
                        {errorsCompany.remote_file_path}
                      </label>
                    )}
                  </div>
                  <div className="custmCol-3 custmmb-2">
                    <button className="CustmBtn" onClick={this.handleAddSFTP}>
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="table-company-sftp">
              <div className="Custmrow custmmb-2 col-10">
                <div className="custmCol-12 custmmb-2">
                  {companySFTP && companySFTP.length === 0 ? null : ( // <div className="noapps">No reports have created yet</div>
                    <div className="tableCont custmEmpty">
                      <div className="ass-custom-report-table custmPaddingXY">
                        <table className="table ">
                          <thead>
                            <tr>
                              <th className="col1">S.NO</th>
                              <th className="col2">Host</th>
                              <th className="col2">Port</th>
                              <th className="col2">Company Name</th>
                              <th className="col3">Username</th>
                              <th className="col3">Remote file path</th>
                              <th className="col3">Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {companySFTP.map((item, index) => (
                              <tr
                                className={` ${
                                  item.checkBox ? `selected` : ``
                                }`}
                                key={index}
                              >
                                <td className="col2">{index + 1 || "N/A"}</td>
                                <td className="col5">{item.host || "N/A"}</td>
                                <td className="col5">{item.port || "N/A"}</td>
                                <td className="col5">
                                  {!isEmpty(
                                    this.findCompanyName(item.company_id)
                                  )
                                    ? this.findCompanyName(item.company_id).name
                                    : "N/A"}
                                </td>
                                <td className="col5">
                                  {item.username || "N/A"}
                                </td>
                                <td className="col5">
                                  {item.remote_file_path || "N/A"}
                                </td>
                                <td className="col5">
                                  <button
                                    title="Delete"
                                    onClick={this.handleDeleteSFTPCompany(
                                      index
                                    )}
                                  >
                                    <FontAwesomeIcon
                                      icon={solid("trash-alt")}
                                    />
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="Custmrow custmmb-2">
              <div className="custmCol-2 ">
                <div className="">
                  <strong>Event *</strong>
                </div>
              </div>
              <div className="col-10">
                <div className="Custmrow">
                  <div className="custmCol-3 custmmb-2">
                    <select
                      name="event"
                      className="formselect"
                      value={formValue.event}
                      onChange={this.handleChange}
                    >
                      <option value="csv">Import as a CSV</option>
                      <option value="excel">Import as a Excel</option>
                    </select>
                    {!isEmpty(errors.event) && (
                      <label className="color-red">{errors.event}</label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="Custmrow custmmb-2">
              <div className="custmCol-2">
                <div className="">
                  <strong>Scheduler *</strong>
                </div>
              </div>
              <div className="col-10">
                <div className="Custmrow">
                  <div className="custmCol-12 custmmb-2">
                    <div className="d-flex">
                      <div className="ch custmCol-2">
                        <input
                          type="radio"
                          name="period"
                          id="Daily"
                          value={"day"}
                          checked={"day" === formValue.period}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="Daily" className="px-1">
                          Daily
                        </label>
                      </div>
                      <div className="ch custmCol-2">
                        <input
                          type="radio"
                          name="period"
                          id="Weekly"
                          value={"weekly"}
                          checked={"weekly" === formValue.period}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="Weekly" className="px-1">
                          Weekly
                        </label>
                      </div>
                      <div className="ch custmCol-2">
                        <input
                          type="radio"
                          name="period"
                          id="Biweekly"
                          value={"biweekly"}
                          checked={"biweekly" === formValue.period}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="Biweekly" className="px-1">
                          Biweekly
                        </label>
                      </div>
                      <div className="ch custmCol-2">
                        <input
                          type="radio"
                          name="period"
                          id="Monthly"
                          value={"monthly"}
                          checked={"monthly" === formValue.period}
                          onChange={this.handleChange}
                        />
                        <label htmlFor="Monthly" className="px-1">
                          Monthly
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="time-management-sftp Custmrow custmmb-2 col-10">
              <div className="custmCol-12 custmmb-2">
                <div className="py-2">
                  {formValue.period === "day" && (
                    <div className="daily">
                      <div className="CustmWidth">
                        <strong>Time</strong>
                        <input
                          className="formInput"
                          type="time"
                          name="interval_time"
                          value={formValue.interval_time}
                          onChange={this.handleChange}
                        />
                      </div>
                      {!isEmpty(errors.interval_time) && (
                        <label className="color-red">
                          {errors.interval_time}
                        </label>
                      )}
                    </div>
                  )}
                  {formValue.period === "weekly" && (
                    <div className="daily d-flex custmSpaceBetwen">
                      <div className="col-5">
                        <div className="CustmWidth col-12">
                          <strong>Day</strong>
                          <select
                            className="custmFullWidth"
                            name="day"
                            value={formValue.day}
                            onChange={this.handleChange}
                          >
                            <option value="">Please Select</option>
                            {weeklyDays.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>
                              );
                            })}
                          </select>
                        </div>
                        {!isEmpty(errors.day) && (
                          <label className="color-red">{errors.day}</label>
                        )}
                      </div>
                      <div className="col-5">
                        <div className="CustmWidth col-12">
                          <strong>Time</strong>
                          <input
                            type="time"
                            className="formInput"
                            name="interval_time"
                            value={formValue.interval_time}
                            onChange={this.handleChange}
                          />
                        </div>
                        {!isEmpty(errors.interval_time) && (
                          <label className="color-red">
                            {errors.interval_time}
                          </label>
                        )}
                      </div>
                    </div>
                  )}
                  {formValue.period === "biweekly" && (
                    <div className="daily d-flex custmSpaceBetwen">
                      <div className="col-5">
                        <div className="CustmWidth col-12">
                          <strong>Day</strong>
                          <select
                            className="custmFullWidth"
                            name="day"
                            value={formValue.day}
                            onChange={this.handleChange}
                          >
                            <option value="">Please Select</option>
                            {weeklyDays.map((item) => {
                              return (
                                <option value={item.name}>{item.name}</option>
                              );
                            })}
                          </select>
                        </div>
                        {!isEmpty(errors.day) && (
                          <label className="color-red">{errors.day}</label>
                        )}
                      </div>
                      <div className="col-5">
                        <div className="CustmWidth col-12">
                          <strong>Time</strong>
                          <input
                            className="formInput"
                            type="time"
                            name="interval_time"
                            value={formValue.interval_time}
                            onChange={this.handleChange}
                          />
                        </div>
                        {!isEmpty(errors.interval_time) && (
                          <label className="color-red">
                            {errors.interval_time}
                          </label>
                        )}
                      </div>
                    </div>
                  )}
                  {formValue.period === "monthly" && (
                    <div className="daily d-flex custmSpaceBetwen">
                      <div className="col-5">
                        <div className="CustmWidth col-12">
                          <strong>Date</strong>
                          <input
                            className="formInput"
                            type="date"
                            name="interval_date"
                            value={formValue.interval_date}
                            onChange={this.handleChange}
                          />
                        </div>
                        {!isEmpty(errors.interval_date) && (
                          <label className="color-red">
                            {errors.interval_date}
                          </label>
                        )}
                      </div>
                      <div className="col-5">
                        <div className="CustmWidth col-12">
                          <strong>Time</strong>
                          <input
                            type="time"
                            className="formInput"
                            name="interval_time"
                            value={formValue.interval_time}
                            onChange={this.handleChange}
                          />
                        </div>
                        {!isEmpty(errors.interval_time) && (
                          <label className="color-red">
                            {errors.interval_time}
                          </label>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* email notification start */}
            <div className="Custmrow custmmb-2">
              <div className="custmCol-2">
                <div className="">
                  <strong>Notification logs types</strong>
                </div>
              </div>
              <div className="col-10">
                <div className="prod-settings">
                  <div className="psrow">
                    <div className="eChooser">
                      <div style={{ display: "flex" }}>
                        <div className="freeinput">
                          <div className="top">
                            <div>
                              <div className="custmmb-2">Log Type</div>
                              <div style={{ margin: "5px 0" }}>
                                <select
                                  name=""
                                  style={{ margin: 0 }}
                                  onChange={this.handleLogTypeChange}
                                  value={logType}
                                >
                                  <option value="All">All</option>
                                  <option value="Success">Success</option>
                                  <option value="Failed">Failed</option>
                                </select>
                                {!isEmpty(errors.email) && (
                                  <label className="color-red">
                                    {errors.email}
                                  </label>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* email notification end */}
            <div className="Custmrow custmmb-2">
              <div className="custmCol-2">
                <div className="">
                  <strong>Export file name</strong>
                </div>
              </div>
              <div className="col-10">
                <div className="Custmrow">
                  <div className="custmCol-3 custmmb-2">
                    <select
                      className="formInput"
                      style={{ margin: 0 }}
                      value={formValue.dateFormat}
                      name="dateFormat"
                      onChange={this.handleChange}
                    >
                      {exportFileNames.map((item) => {
                        return (
                          <option key={item.title} value={item.value}>
                            {item.title}
                          </option>
                        );
                      })}
                    </select>
                    {!isEmpty(errors.host) && (
                      <label className="color-red">{errors.host}</label>
                    )}
                  </div>
                  <div className="custmCol-3 custmmb-2">
                    <input
                      type="text"
                      className="formInput"
                      placeholder="File name"
                      name="fileName"
                      value={formValue.fileName}
                      onChange={this.handleChange}
                    />
                    {!isEmpty(errors.fileName) && (
                      <label className="color-red">{errors.fileName}</label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="scheduler-btn">
            <button disabled={!checkCredentials} onClick={this.handleSchedule}>
              Schedule
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { cp_installations, cp_employees, cp_enrollments } = state.entities;
  const {
    beenLoadedOnce,
    allFetching,
    installationsList,
    employeesList,
    installsFetching,
    empsAreFetching,
    associationsList,
    getAssociationsById,
  } = state.cloudpunch;

  const { costCenters, isFetching: cdFetching } = state.company_data;
  const { companyInfo, userInfo } = state.products;
  const costCentersFetching = cdFetching["cost-centers"];

  const instsListJoin =
    installationsList === undefined
      ? []
      : installationsList.map((id) => {
          let thisIn = Object.assign({}, cp_installations[id]);
          return thisIn;
        });

  const empsListJoin =
    employeesList === undefined
      ? []
      : employeesList.map((id) => {
          let thisIn = Object.assign({}, cp_employees[id]);
          thisIn.enrollments = thisIn.enrollments.map((id) => {
            {
              let thisEn = Object.assign({}, cp_enrollments[id]);
              return thisEn;
            }
          });
          return thisIn;
        });

  return {
    beenLoadedOnce,
    allFetching,
    installations: instsListJoin,
    associations: associationsList,
    employees: empsListJoin,
    installsFetching,
    empsAreFetching,
    companyInfo,
    userInfo,
    costCenters: costCenters !== undefined ? costCenters : null,
    costCentersFetching,
    getAssociationsById,
  };
};

export default withRouter(connect(mapStateToProps)(CustomReportSchedule));
