import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Loading from "../components/ui/Loading";

const userProvisioning = (props) => {
  const { productData, history, productLoading } = props;

  const handleGoIntoIntegration = (url) => () => {
    history.push(`/ess/integrations/${url}`);
  };

  const userProvisioningIntegration = productData.find(
    ({ product_url }) => product_url === "user-provisioning"
  );

  if (productLoading || !userProvisioningIntegration) {
    return <Loading />;
  }

  return (
    <div className="product-modal-content productmodal-padding">
      <div className="product-modal-header">
        <h3>Integrations</h3>
      </div>
      <div className="product-card-content-outer">
        <div className={`Integration-outer integration-active `}>
          <div className="product-card-content">
            {Array.isArray(userProvisioningIntegration.integrations) &&
              userProvisioningIntegration.integrations.map((item, index) => (
                <div
                  className="product-card-outer-out productcardout-list"
                  key={index}
                  onClick={handleGoIntoIntegration(item.url)}
                >
                  <div className="product-card-outer">
                    <div className="product-card-inner">
                      <img src={item.image_name} alt={item.integration_name} />
                    </div>
                    <div className="product-card-inner1">
                      <h3>{item.integration_name}</h3>
                      <p>{item.description}</p>
                    </div>
                    <div
                      className={`product-card-inner2 status red`}
                      onClick={() => history.push("/login")}
                    >
                      <h4>Login to configure</h4>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { productData, productLoading } = state.dashboard;

  return {
    productData,
    productLoading,
  };
};

export default withRouter(connect(mapStateToProps)(userProvisioning));
