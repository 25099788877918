import React from "react";
import LoginBox from "./LoginBox";
import PropTypes from "prop-types";

import "../styles/NotLoggedInPage.css";

const NotLoggedInPage = (props) => {
  return <LoginBox {...props} />;
};

NotLoggedInPage.propTypes = {
  login: PropTypes.func.isRequired,
};

export default NotLoggedInPage;
