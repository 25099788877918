import { CALL_API, Schemas } from "../middleware/api";

export const JOBS_REQUEST = "JOBS_REQUEST";
export const JOBS_SUCCESS = "JOBS_SUCCESS";
export const JOBS_FAILURE = "JOBS_FAILURE";

const fetchJobsx = (type) => ({
  [CALL_API]: {
    types: [JOBS_REQUEST, JOBS_SUCCESS, JOBS_FAILURE],
    endpoint: `api/recruitment/applications/${type}`,
    schema: Schemas.JOBS_LIST,
  },
});

export const fetchJobs = (type) => (dispatch, getState) => {
  return dispatch(fetchJobsx(type));
};

export const JOBS_INDEED_REQUEST = "JOBS_INDEED_REQUEST";
export const JOBS_INDEED_SUCCESS = "JOBS_INDEED_SUCCESS";
export const JOBS_INDEED_FAILURE = "JOBS_INDEED_FAILURE";

const fetchJobsIndeedx = (keyName) => ({
  [CALL_API]: {
    types: [JOBS_INDEED_REQUEST, JOBS_INDEED_SUCCESS, JOBS_INDEED_FAILURE],
    endpoint: `api/recruitment/${keyName}/applications`,
  },
});

export const fetchJobsIndeed = (keyName) => (dispatch, getState) => {
  return dispatch(fetchJobsIndeedx(keyName));
};

export const JOBS_APPLICANTS_REQUEST = "JOBS_APPLICANTS_REQUEST";
export const JOBS_APPLICANTS_SUCCESS = "JOBS_APPLICANTS_SUCCESS";
export const JOBS_APPLICANTS_FAILURE = "JOBS_APPLICANTS_FAILURE";

const fetchApplicantsx = (id, record) => ({
  [CALL_API]: {
    types: [
      JOBS_APPLICANTS_REQUEST,
      JOBS_APPLICANTS_SUCCESS,
      JOBS_APPLICANTS_FAILURE,
    ],
    endpoint: `api/recruitment/jobs/applicants/${id}/${record}`,
  },
});

export const fetchApplicants = (id, record) => (dispatch) => {
  return dispatch(fetchApplicantsx(id, record));
};

export const JOB_REQUEST = "JOB_REQUEST";
export const JOB_SUCCESS = "JOB_SUCCESS";
export const JOB_FAILURE = "JOB_FAILURE";

const loadMoreJobAppsx = (jobID, page) => ({
  [CALL_API]: {
    types: [JOB_REQUEST, JOB_SUCCESS, JOB_FAILURE],
    endpoint: "api/recruitment/jobs/" + jobID + "/" + page,
    schema: Schemas.JOB,
  },
});

export const loadMoreJobApps = (jobID, page) => (dispatch, getState) => {
  return dispatch(loadMoreJobAppsx(jobID, page));
};

export const JOB_SEND_EMAIL_REQUEST = "JOB_SEND_EMAIL_REQUEST";
export const JOB_SEND_EMAIL_SUCCESS = "JOB_SEND_EMAIL_SUCCESS";
export const JOB_SEND_EMAIL_FAILURE = "JOB_SEND_EMAIL_FAILURE";

const sendFinishEmailx = (appID) => ({
  [CALL_API]: {
    types: [
      JOB_SEND_EMAIL_REQUEST,
      JOB_SEND_EMAIL_SUCCESS,
      JOB_SEND_EMAIL_FAILURE,
    ],
    endpoint: "api/recruitment/applications/" + appID + "/sendFinishEmail",
    schema: Schemas.JOB_APP,
    postdata: { appID },
  },
  idUpdating: appID,
});

export const sendFinishEmail = (appID) => (dispatch, getState) => {
  return dispatch(sendFinishEmailx(appID));
};

export const JOB_MARKREVIEWED_REQUEST = "JOB_MARKREVIEWED_REQUEST";
export const JOB_MARKREVIEWED_SUCCESS = "JOB_MARKREVIEWED_SUCCESS";
export const JOB_MARKREVIEWED_FAILURE = "JOB_MARKREVIEWED_FAILURE";

const markAsReviewedx = (appID) => ({
  [CALL_API]: {
    types: [
      JOB_MARKREVIEWED_REQUEST,
      JOB_MARKREVIEWED_SUCCESS,
      JOB_MARKREVIEWED_FAILURE,
    ],
    endpoint: "api/recruitment/applications/" + appID + "/markAsReviewed",
    schema: Schemas.JOB_APP,
    postdata: { appID },
  },
  idUpdating: appID,
});

export const markAsReviewed = (appID) => (dispatch, getState) => {
  return dispatch(markAsReviewedx(appID));
};

export const NEW_SPONSOR_REQUEST = "NEW_SPONSOR_REQUEST";
export const NEW_SPONSOR_SUCCESS = "NEW_SPONSOR_SUCCESS";
export const NEW_SPONSOR_FAILURE = "NEW_SPONSOR_FAILURE";

const newIndeedSponsorshipx = (opJobID, budget, phone, contactName) => ({
  [CALL_API]: {
    types: [NEW_SPONSOR_REQUEST, NEW_SPONSOR_SUCCESS, NEW_SPONSOR_FAILURE],
    endpoint: "api/recruitment/indeedsponsorship/" + opJobID,
    schema: Schemas.JOB,
    postdata: { budget, phone, contact_name: contactName, time_interval: 30 },
  },
});

export const newIndeedSponsorship =
  (opJobID, budget, phone, contactName) => (dispatch, getState) => {
    return dispatch(newIndeedSponsorshipx(opJobID, budget, phone, contactName));
  };

export const INDEED_UPDATE_SPONSOR_REQUEST = "INDEED_UPDATE_SPONSOR_REQUEST";
export const INDEED_UPDATE_SPONSOR_SUCCESS = "INDEED_UPDATE_SPONSOR_SUCCESS";
export const INDEED_UPDATE_SPONSOR_FAILURE = "INDEED_UPDATE_SPONSOR_FAILURE";

const updateIndeedSponsorshipx = (
  opJobID,
  sponsorshipID,
  budget,
  manually_ended
) => ({
  [CALL_API]: {
    types: [
      INDEED_UPDATE_SPONSOR_REQUEST,
      INDEED_UPDATE_SPONSOR_SUCCESS,
      INDEED_UPDATE_SPONSOR_FAILURE,
    ],
    endpoint:
      "api/recruitment/indeedsponsorship/" +
      opJobID +
      "/" +
      sponsorshipID +
      "/update",
    schema: Schemas.JOB,
    postdata: { budget, manually_ended },
  },
});

export const updateIndeedSponsorship =
  (opJobID, sponsorshipID, budget, manually_ended) => (dispatch, getState) => {
    return dispatch(
      updateIndeedSponsorshipx(opJobID, sponsorshipID, budget, manually_ended)
    );
  };

const newZRSponsorshipx = (opJobID, level) => ({
  [CALL_API]: {
    types: [NEW_SPONSOR_REQUEST, NEW_SPONSOR_SUCCESS, NEW_SPONSOR_FAILURE],
    endpoint: "api/recruitment/zipsponsorship/" + opJobID,
    schema: Schemas.JOB,
    postdata: { level },
  },
});

export const newZRSponsorship = (opJobID, level) => (dispatch, getState) => {
  return dispatch(newZRSponsorshipx(opJobID, level));
};
