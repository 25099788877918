import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

// import Loading from './ui/Loading'

import '../styles/SystemAdmin.css'
import Loading from './ui/Loading'

class SystemAdmin extends Component {
  constructor(props) {
    super(props)

    this.state = { addCoInfo: { op_id: null, name: '', short_name: '', api_key: '' } }

    this.changeNewCoInfo = this.changeNewCoInfo.bind(this)
    this.submitNewCompany = this.submitNewCompany.bind(this)
  }

  componentWillReceiveProps(np) {
    const { addInfo: oldAddInfo } = this.props
    const { addInfo } = np

    if (!oldAddInfo.oading && addInfo.loading) {
      this.setState({ addCoInfo: { op_id: null, name: '', short_name: '', api_key: '' } })
    }
  }

  changeNewCoInfo(key, val) {
    const { addCoInfo } = this.state
    this.setState({ addCoInfo: { ...addCoInfo, [key]: val } })
  }

  submitNewCompany() {
    const { addCoInfo } = this.state
    const { op_id, name, short_name, api_key } = addCoInfo

    if ((!op_id || op_id === 0) || name === '' || short_name === '' || api_key === '') {
      return
    }

    this.props.submitNewCompany(addCoInfo)
  }

  render() {
    const { addInfo } = this.props
    const { addCoInfo } = this.state

    return (
      <div className="SystemAdmin">
        <h1>System Admin Tools</h1>
        <div className="section">
          {
            addInfo.loading
            &&
            (
              <div className="loading">
                <Loading />
              </div>
            )
          }
          <div className="head">
            Add Company to OnePoint Connect
          </div>
          <div className="form">
            {
              addInfo.error
              &&
              (
                <div className="error">
                  {addInfo.error}
                </div>
              )
            }
            {
              addInfo.success
              &&
              (
                <div className="success">
                  {addInfo.success}
                </div>
              )
            }
            <div>
              <label>Company ID: </label>
              <input value={addCoInfo.op_id ? addCoInfo.op_id : ''} onChange={(e) => this.changeNewCoInfo('op_id', parseInt(e.target.value, 10))} type="text" />
            </div>
            <div>
              <label>Company Full Name: </label>
              <input value={addCoInfo.name ? addCoInfo.name : ''} onChange={(e) => this.changeNewCoInfo('name', e.target.value)} type="text" />
            </div>
            <div>
              <label>Company Short Name: </label>
              <input value={addCoInfo.short_name ? addCoInfo.short_name : ''} onChange={(e) => this.changeNewCoInfo('short_name', e.target.value)} type="text" />
            </div>
            <div>
              <label>Company API Key: </label>
              <input value={addCoInfo.api_key ? addCoInfo.api_key : ''} onChange={(e) => this.changeNewCoInfo('api_key', e.target.value)} type="text" />
            </div>
            <div className="buts">
              <button onClick={() => this.submitNewCompany()}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(SystemAdmin)
