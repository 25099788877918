import React, { useEffect, useState } from "react";
import Loading from "../../../components/ui/Loading";
import Modal from "../../../components/ui/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import useFetch from "../../hooks/useFetch";

const ImportSFTPConnectionTest = (props) => {
  const {
    handleToggle,
    formValue,
    userInfo,
    reportType,
    reportId,
    handleDisableFieldForSFTP,
  } = props;
  const [responseData, setResponseData] = useState({});
  const [checkSFTPConnection, loading] = useFetch();

  // handle SFTP credentials test
  const handleSFTPConnectionTest = async () => {
    try {
      const body = {
        host: formValue.host,
        username: formValue.username,
        password: formValue.password,
        company_id: userInfo.company_id,
        report_id: reportId ? Number(reportId) : null,
        report_type: reportType,
        remote_file_path: formValue.remote_file_path,
        port: Number(formValue.port),
      };
      const payload = {
        endPoint: `/api/tlm/test/sftp/connection`,
        method: "post",
        body,
      };
      const response = await checkSFTPConnection(payload);
      if (response) {
        setResponseData(response);
        if (response.status === 200) {
          handleDisableFieldForSFTP && handleDisableFieldForSFTP();
        }
      } else {
        setResponseData({ status: "", message: "" });
      }
    } catch (error) {
      setResponseData({ status: "", message: "" });
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleSFTPConnectionTest();
  }, []);

  console.log("rendered");

  return (
    <>
      <Modal closeModal={handleToggle}>
        {loading ? (
          <>
            <Loading />
            <h1>Connecting to {formValue.host}</h1>
          </>
        ) : (
          <div className="connect-sftp">
            <h2>
              {responseData.status === 200 ? (
                <FontAwesomeIcon
                  icon={regular("circle-check")}
                  size="2xl"
                  style={{ color: "#002e6c" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={regular("circle-xmark")}
                  size="2xl"
                  style={{ color: "#ff0000" }}
                />
              )}
            </h2>
            <h1>
              {responseData.status === 400 ? "Failed" : "Connected"} to{" "}
              {formValue.host}
            </h1>
            <p
              className={
                responseData.status === 400 ? "color-red" : "color-green"
              }
            >
              {responseData.message && responseData.message}
            </p>
          </div>
        )}
        <div className="scheduler-btn">
          <button onClick={handleToggle}>Cancel</button>
        </div>
      </Modal>
    </>
  );
};

export default ImportSFTPConnectionTest;
