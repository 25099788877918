import React, { useEffect } from "react";
import Loading from "../ui/Loading";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { getExportLogs } from "../../actions/logs";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import ClickAwayListener from "../../utils/ClickAwayListener";
import { CSVLink } from "react-csv";
import QueIdSearchModal from "./QueIdSearchModal";
import Modal from "../ui/Modal";
import toast, { Toaster } from "react-hot-toast";
import ReactPaginate from "react-paginate";

// object for the put dynamic css class into the logs table row
const dynamicCSSClass = {
  Error: "error",
  Info: "info",
  Success: "success",
};

// Main component for the export logs
const ExportLogs = (props) => {
  // destructure value in the props
  const {
    dispatch,
    match: { params },
    history,
  } = props;
  // the all state
  const [isLoading, setIsLoading] = useState(false);
  const [rowDataCopy, setRowDataCopy] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [rangeShow, setRangeShow] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [toggleModal, setToggleModal] = useState(false);
  const [selectedQueueID, setSelectedQueueID] = useState(null);
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  // this state define the pagination
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  //this convert the pagination logic
  const pageCount = Math.ceil(rowData.length / rowPerPage);
  const offset = currentPage * rowPerPage;
  const rowPerPageValue = [10, 25, 50, 100, 150, 200];

  // show the modal click on queue id
  const handleModal = (id) => () => {
    if (id) {
      setSelectedQueueID(id);
    }
    setToggleModal(!toggleModal);
  };

  // search data table for logs
  const onChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    const filterData = rowDataCopy.filter((item) => {
      return Object.values(item)
        .join()
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    setRowData(filterData);
  };

  // handle change for the date range piker change
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setRangeDate([selection]);
  };

  // this method fetch the logs API
  const fetchLogs = async () => {
    try {
      let { endDate, startDate } = rangeDate[0];

      endDate = moment(endDate).format("YYYY-MM-DD");
      startDate = moment(startDate).format("YYYY-MM-DD");

      setIsLoading(true);

      const { response } = await dispatch(
        getExportLogs(params.assId, params.id, params.type, {
          startDate,
          endDate,
        })
      );
      if (response) {
        setRowData(Object.values(response));
        setRowDataCopy(Object.values(response));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  // show the filter calender
  const handleShow = () => setRangeShow(!rangeShow);

  // after select date range fetch filter data from API
  const handleFilter = () => {
    fetchLogs();
    handleShow();
  };

  // data table sort function
  const handleSelectSort = (e) => {
    const { value } = e.target;
    setCurrentPage(0);
    setRowPerPage(value);
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  // csv export data
  const csvData = rowDataCopy.map(
    ({
      que_id,
      line_number,
      log_types,
      status_code,
      status_message,
      date,
      time,
      message,
    }) =>
      Object.values({
        que_id,
        line_number,
        log_types,
        status_code,
        status_message,
        date,
        time,
        message,
      })
  );

  // csv header array for export csv file
  let csvHeader = [
    "que_id",
    "line_number",
    "log_types",
    "status_code",
    "status_message",
    "date",
    "time",
    "message",
  ];

  // pagination select page function
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  return (
    <div className="log-management">
      <div className="breadcrumbs">
        <p onClick={history.goBack}>&larr; Back to list of your exports</p>
      </div>
      <Toaster />

      <h1>Export Logs</h1>

      {isLoading && (
        <div className="loadCont">
          <Loading />
        </div>
      )}
      {toggleModal && (
        <Modal closeModal={handleModal()}>
          <QueIdSearchModal QueueID={selectedQueueID} dispatch={dispatch} />
        </Modal>
      )}
      <div>
        <div className="date-picker">
          <button onClick={fetchLogs}>Refresh</button>
          <div className="d-flex">
            <select
              className="sort-select"
              onChange={handleSelectSort}
              name=""
              id=""
              defaultValue={rowPerPage}
            >
              {rowPerPageValue.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <input
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={onChange}
              className="mr-1"
            />
            <button onClick={handleShow}>Filter Date</button>
            {rangeShow && (
              <div className="date-range-log">
                <ClickAwayListener onClickAway={handleShow}>
                  <div>
                    <DateRangePicker
                      onChange={handleOnChange}
                      ranges={rangeDate}
                    />
                  </div>
                  <div className="log-btn">
                    <button onClick={handleFilter}>Filter</button>
                  </div>
                </ClickAwayListener>
              </div>
            )}
            <CSVLink
              filename={"logs-file.csv"}
              className="csv-export-btn"
              data={[csvHeader, ...csvData]}
              onClick={() => {
                if (csvData.length === 0) {
                  console.log("ddd");
                  toast.error("Logs not found!");
                  return false;
                }
              }}
            >
              Export
            </CSVLink>
          </div>
        </div>
        <div className="logs-table">
          <table className="table tablecstm">
            <thead>
              <tr>
                <th>Date</th>
                <th>Queue Id</th>
                <th className="text-center">Status type</th>
                <th>Message</th>
              </tr>
            </thead>
            <tbody>
              {rowData.length !== 0 ? (
                rowData
                  .slice(offset, offset + rowPerPage)
                  .map(
                    ({ id, message, date, time, status_message, que_id }) => (
                      <tr key={id}>
                        <td>
                          {moment(new Date(date)).format("MMM DD,YYYY") +
                            " " +
                            time}
                        </td>
                        <td className="queId">
                          <span
                            onClick={handleModal(que_id)}
                            className="queue-id"
                          >
                            {que_id}
                          </span>
                        </td>
                        <td className="text-center">
                          <span
                            className={`status ${dynamicCSSClass[status_message]}`}
                          >
                            {status_message}
                          </span>
                        </td>
                        <td>{message}</td>
                      </tr>
                    )
                  )
              ) : (
                <tr>
                  <td colSpan={4}>
                    <h3 className="text-center">
                      No logs found for today, please select another date
                    </h3>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          previousLabel="Previous"
          forcePage={currentPage}
          renderOnZeroPageCount={null}
          containerClassName={"pagination-container"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
          pageRangeDisplayed={2}
          marginPagesDisplayed={1}
        />
      </div>
    </div>
  );
};

export default connect()(ExportLogs);
