import { CALL_API } from "../middleware/api";

export const GET_USER_WRITE_PERMISSION_SUCCESS =
  "GET_USER_WRITE_PERMISSION_SUCCESS";
export const GET_USER_WRITE_PERMISSION_REQUEST =
  "GET_USER_WRITE_PERMISSION_REQUEST";
export const GET_USER_WRITE_PERMISSION_FAILURE =
  "GET_USER_WRITE_PERMISSION_FAILURE";

export const GET_INTEGRATION_DOCUMENT_SUCCESS =
  "GET_INTEGRATION_DOCUMENT_SUCCESS";
export const GET_INTEGRATION_DOCUMENT_REQUEST =
  "GET_INTEGRATION_DOCUMENT_REQUEST";
export const GET_INTEGRATION_DOCUMENT_FAILURE =
  "GET_INTEGRATION_DOCUMENT_FAILURE";

export const GET_PRODUCT_CONTENT_SUCCESS = "GET_PRODUCT_CONTENT_SUCCESS";
export const GET_PRODUCT_CONTENT_REQUEST = "GET_PRODUCT_CONTENT_REQUEST";
export const GET_PRODUCT_CONTENT_FAILURE = "GET_PRODUCT_CONTENT_FAILURE";

export const UPDATE_INTEGRATION_DATA = "UPDATE_INTEGRATION_DATA";
export const PRODUCT_SETTING_TOGGLE = "PRODUCT_SETTING_TOGGLE";
export const USER_PERMISSION_API_COUNT = "USER_PERMISSION_API_COUNT";

const productDataFetchx = () => ({
  [CALL_API]: {
    types: [
      GET_PRODUCT_CONTENT_REQUEST,
      GET_PRODUCT_CONTENT_SUCCESS,
      GET_PRODUCT_CONTENT_FAILURE,
    ],
    endpoint: `api/all/customize/content`,
  },
});

export const productDataFetch = () => (dispatch) => {
  return dispatch(productDataFetchx());
};

const userWritePermissionx = () => ({
  [CALL_API]: {
    types: [
      GET_USER_WRITE_PERMISSION_REQUEST,
      GET_USER_WRITE_PERMISSION_SUCCESS,
      GET_USER_WRITE_PERMISSION_FAILURE,
    ],
    endpoint: `api/get/comp/usr/write/permission`,
  },
});

export const userWritePermission = () => (dispatch) => {
  return dispatch(userWritePermissionx());
};

const getIntegrationDocumentx = () => ({
  [CALL_API]: {
    types: [
      GET_INTEGRATION_DOCUMENT_REQUEST,
      GET_INTEGRATION_DOCUMENT_SUCCESS,
      GET_INTEGRATION_DOCUMENT_FAILURE,
    ],
    endpoint: `api/get/comp/customize/content/zoom_integration_document`,
  },
});

export const getIntegrationDocument = () => (dispatch) => {
  return dispatch(getIntegrationDocumentx());
};

export const integrationSaveData = (data) => {
  return {
    type: UPDATE_INTEGRATION_DATA,
    payload: data,
  };
};

export const productSettingsToggle = (data) => {
  return {
    type: PRODUCT_SETTING_TOGGLE,
  };
};

export const userPermissionAPICount = () => {
  return {
    type: USER_PERMISSION_API_COUNT,
  };
};
