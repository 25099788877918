/* eslint-disable no-undef */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser, logoutUser } from "../actions/auth";
import NotLoggedInPage from "../components/NotLoggedInPage";
import { Redirect } from "react-router-dom";

class AuthContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    error: PropTypes.string.isRequired,
  };

  login(shortName, username, password, opAdmin) {
    this.props.dispatch(loginUser({ shortName, username, password, opAdmin }));
  }

  logout() {
    this.props.dispatch(logoutUser());
  }

  render() {
    const { isFetching, error, isAuthenticated } = this.props;

    if (isAuthenticated) {
      return <Redirect to="/app" />;
    }

    return (
      <div>
        <NotLoggedInPage
          login={this.login.bind(this)}
          loginFetching={isFetching}
          error={error}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { isAuthenticated, isFetching, error } = state.auth;

  return { isAuthenticated, isFetching, error };
};

export default connect(mapStateToProps)(AuthContainer);
