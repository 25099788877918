import { CALL_API } from "../middleware/api";

export const GET_EXPORTS_LOGS_SUCCESS = "UPLOAD_CUSTOM_REPORT_SUCCESS";
export const GET_EXPORTS_LOGS_REQUEST = "UPLOAD_CUSTOM_REPORT_REQUEST";
export const GET_EXPORTS_LOGS_FAILURE = "UPLOAD_CUSTOM_REPORT_FAILURE";

const getExportLogsx = (assId, id, type, { startDate, endDate }) => ({
  [CALL_API]: {
    types: [
      GET_EXPORTS_LOGS_SUCCESS,
      GET_EXPORTS_LOGS_REQUEST,
      GET_EXPORTS_LOGS_FAILURE,
    ],
    endpoint: `api/tlm/get/logs/${type}/${id}/${assId}/${startDate}/${endDate}`,
  },
});

export const getExportLogs = (assId, id, type, date) => (dispatch) => {
  return dispatch(getExportLogsx(assId, id, type, date));
};

export const GET_QUEUE_ID_DETAILS_SUCCESS = "GET_QUEUE_ID_DETAILS_SUCCESS";
export const GET_QUEUE_ID_DETAILS_REQUEST = "GET_QUEUE_ID_DETAILS_REQUEST";
export const GET_QUEUE_ID_DETAILS_FAILURE = "GET_QUEUE_ID_DETAILS_FAILURE";

const getQueueDetailx = (queId) => ({
  [CALL_API]: {
    types: [
      GET_QUEUE_ID_DETAILS_SUCCESS,
      GET_QUEUE_ID_DETAILS_REQUEST,
      GET_QUEUE_ID_DETAILS_FAILURE,
    ],
    endpoint: `api/tlm/get/que/details/${queId}`,
  },
});

export const getQueueDetail = (queId) => (dispatch) => {
  return dispatch(getQueueDetailx(queId));
};
