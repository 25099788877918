import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import SystemAdmin from '../components/SystemAdmin'

import { getToken } from '../actions/auth'

class SystemAdminContainer extends Component {
  constructor(props) {
    super(props)

    this.state = { addInfo: { success: null, error: null, loading: false } }

    this.submitNewCompany = this.submitNewCompany.bind(this)
  }

  componentWillUnmount() {
    if (this.addTimer) {
      clearInterval(this.addTimer);
    }
  }

  async submitNewCompany(newCoInfo) {
    if (this.addTimer) {
      clearInterval(this.addTimer);
    }
 
    this.setState({ addInfo: { ...this.state.addInfo, loading: true } })
    const url = '/api/companies'
    const data = newCoInfo

    let headers = { 'Content-Type': 'application/json' };
    const { token, userToken } = getToken()
    if (token) {
      headers['Authorization'] = `${token}`;
    }
    if (userToken) {
      headers['OPUserToken'] = `${userToken}`;
    }
    const response = await fetch(url, { method: 'POST', body: JSON.stringify(data), headers })
    const responseBody = await response.json()

    if (response.status >= 300) {
      this.setState({ addInfo: { ...this.state.addInfo, error: responseBody.error, success: null, loading: false } })
    } else {
      const successNote = `Success! Company has been added to OnePoint Connect. You must log out of your current company and login using shortname=${newCoInfo.short_name} to view their info.`
      this.setState({ addInfo: { ...this.state.addInfo, error: null, success: successNote, loading: false } })
    }

    this.addTimer = setTimeout(() => this.setState({ addInfo: { ...this.state.addInfo, error: null, success: null } }), 5000)
  }

  render() {
    const { addInfo } = this.state

    return (
      <SystemAdmin
        addInfo={addInfo}
        submitNewCompany={(b) => this.submitNewCompany(b)}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { list, isFetching: productsAreFetching } = state.products
  const { products } = state.entities

  const prodListJoin = list === undefined ? [] : list.map((prodURL) => {
    return products[prodURL]
  })

  return {
    productsAreFetching,
    products: prodListJoin,
  }
}

export default withRouter(connect(mapStateToProps)(SystemAdminContainer))
