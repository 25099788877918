import React, { Component } from 'react'
import '../../styles/HideShow.css';

class HideShow extends Component {
  constructor(props) {
    super(props)
    this.state = {show: false}
  }

  toggleShow() {
    this.setState({show: !this.state.show})
  }

  render() {
    const { label, body } = this.props
    const { show } = this.state

    return (
      <div className="HideShow">
        <div className="top" onClick={() => this.toggleShow()}>
          <span>{label}</span>
          <span className="controller">
            {
              show ? '-' : '+'
            }
          </span>
        </div>
        {
          show
          &&
          (
            <div className="bot">
              {body}
            </div>
          )
        }
      </div>
    )
  }
}

export default HideShow
