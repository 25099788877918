/* eslint-disable no-undef */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../actions/auth";
import TopNav from "../components/TopNav";

class TopNavContainer extends Component {
  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
  };

  logout() {
    // const { userInfo } = this.props;
    // const is_system_admin = userInfo ? userInfo.is_system_admin : false;

    this.props.dispatch(logoutUser());
    // const isAdmin = localStorage.getItem("isAdmin");

    // eslint-disable-next-line
    window.location = `/`;
    // window.location = `/${isAdmin == "true" ? "?admin" : ""}`;

    localStorage.clear();
  }

  render() {
    return (
      <TopNav
        logout={this.logout.bind(this)}
        companyInfo={this.props.companyInfo}
        userInfo={this.props.userInfo}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { isAuthenticated } = state.auth;
  const { companyInfo, userInfo } = state.products;

  return { isAuthenticated, companyInfo, userInfo };
};

export default connect(mapStateToProps)(TopNavContainer);
