import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isEmpty } from "lodash";
import React from "react";
import { getAssociationCustReportDelete } from "../../../actions/cloudpunch";
import { connect } from "react-redux";
import Swal from "sweetalert2";

const ViewAssociationCompany = (props) => {
  const {
    openAssociation,
    handleOpenMode,
    selectData,
    id,
    fetchAssociations,
    dispatch,
  } = props;

  const handleAssociationDelete = (item, reportName) => async () => {
    const { company_id, report_id } = item;

    const result = await Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    });
    if (result.isConfirmed) {
      const body = {
        association_id: Number(id),
        company_id: Number(company_id),
        report_id: report_id,
        report_name: reportName,
      };
      await dispatch(getAssociationCustReportDelete(body));
      fetchAssociations();

      Swal.fire("Deleted!", "Your record has been Deleted.", "success");
    }
  };

  return (
    <div
      className={`dashboard-integration-doc association ${
        openAssociation && "open"
      }`}
    >
      <div className="association-con">
        <div>
          <span className="icon-btn" onClick={handleOpenMode(null)}>
            <FontAwesomeIcon icon={solid("xmark")} />
          </span>
        </div>
        <div className="integration-document">
          <h1>{!isEmpty(selectData) && selectData.report_name}</h1>
          <table className="table tablecstm">
            <thead>
              <tr>
                <th className="col1">Report ID</th>
                <th className="col2">Name</th>
                <th className="col3">Short Name</th>
                <th className="col3">Action</th>
              </tr>
            </thead>
            <tbody>
              {!isEmpty(selectData) &&
                Array.isArray(selectData.reports) &&
                selectData.reports.map((item) => (
                  <tr key={`installs${item.report_id}`}>
                    <td className="col2">{item.report_id || "N/A"}</td>
                    <td className="col5">{item.name || "N/A"}</td>
                    <td className="col5">{item.short_name || "N/A"}</td>
                    <td>
                      <button
                        title="Delete"
                        onClick={handleAssociationDelete(
                          item,
                          selectData.report_name
                        )}
                      >
                        <FontAwesomeIcon icon={solid("trash-alt")} />
                      </button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default connect()(ViewAssociationCompany);
