import * as JobTypes from "../actions/jobs";

const initialState = {
  error: "",
  isFetching: false,
  list: [],
  jobs: {},
  idUpdating: 0,
  indeedIsPosting: false,
  indeedIsUpdating: false,
};

export default function company_data(state = initialState, action) {
  switch (action.type) {
    case JobTypes.JOBS_REQUEST:
      return Object.assign({}, state, {
        error: "",
        isFetching: true,
      });
    case JobTypes.JOBS_SUCCESS:
      return Object.assign({}, state, {
        error: "",
        isFetching: false,
        list: action.response.result,
        jobs: action.response.entities.jobs,
      });
    case JobTypes.JOBS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
      });
    case JobTypes.JOB_SEND_EMAIL_REQUEST:
    case JobTypes.JOB_MARKREVIEWED_REQUEST:
      return Object.assign({}, state, {
        idUpdating: action.idUpdating,
      });
    case JobTypes.JOB_SEND_EMAIL_SUCCESS:
    case JobTypes.JOB_SEND_EMAIL_FAILURE:
    case JobTypes.JOB_MARKREVIEWED_SUCCESS:
    case JobTypes.JOB_MARKREVIEWED_FAILURE:
      return Object.assign({}, state, {
        idUpdating: 0,
      });
    case JobTypes.NEW_SPONSOR_REQUEST:
      return Object.assign({}, state, {
        error: "",
        indeedIsPosting: true,
      });
    case JobTypes.NEW_SPONSOR_SUCCESS:
      return Object.assign({}, state, {
        error: "",
        indeedIsPosting: false,
      });
    case JobTypes.NEW_SPONSOR_FAILURE:
      return Object.assign({}, state, {
        error: "An error occured while creating the Indeed Sponsorship.",
        indeedIsPosting: false,
      });
    case JobTypes.INDEED_UPDATE_SPONSOR_REQUEST:
      return Object.assign({}, state, {
        error: "",
        indeedIsUpdating: true,
      });
    case JobTypes.INDEED_UPDATE_SPONSOR_SUCCESS:
      return Object.assign({}, state, {
        error: "",
        indeedIsUpdating: false,
      });
    case JobTypes.INDEED_UPDATE_SPONSOR_FAILURE:
      return Object.assign({}, state, {
        error: "An error occured while updating the Indeed Sponsorship.",
        indeedIsUpdating: false,
      });
    default:
      return state;
  }
}
