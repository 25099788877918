import React from "react";
import { withRouter } from "react-router-dom";
import { documentMap } from "../constants/constant";
import { connect } from "react-redux";
import Loading from "../components/ui/Loading";

const IntegrationESSBody = ({
  history,
  match,
  productData,
  productLoading,
}) => {
  const userProvisioningIntegration = productData.find(
    ({ product_url }) => product_url === "user-provisioning"
  );

  if (productLoading || !userProvisioningIntegration) {
    return <Loading />;
  }

  const integrationData = userProvisioningIntegration.integrations.find(
    (item) => item.url === match.params.integrationName
  );

  return (
    <div className="provision-modal">
      <div className="product-modal-header">
        <span onClick={history.goBack} className="back-btn">
          ← Back to list of Integrations
        </span>
      </div>

      <div className="product_card_content">
        <div className={`product-left-content  integration-active`}>
          <div className="my-flex landingpage-userpro">
            <div className="one-col-9">
              <h1 className="pb-0">
                {integrationData.integration_name} Integration
              </h1>
              {documentMap[match.params.integrationName]}
            </div>
            <div className="side-img ">
              <img
                src={integrationData.image_name}
                alt={integrationData.integration_name}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { productData, productLoading } = state.dashboard;

  return {
    productData,
    productLoading,
  };
};

export default withRouter(connect(mapStateToProps)(IntegrationESSBody));
