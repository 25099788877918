import { useState } from "react";

const useForm = (props) => {
  const { defaultValue = {}, validationSchema = null } = props;
  const [formValue, setFormValue] = useState(defaultValue);
  const [errors, setErrors] = useState({});
  const [submitCount, setSubmitCount] = useState(0);

  const validateField = async (name, value) => {
    try {
      let copy = { ...errors };
      await validationSchema.validateAt(name, { [name]: value });
      delete copy[name];
      setErrors({ ...copy });
    } catch (error) {
      setErrors({ ...errors, [name]: error.message });
    }
  };

  const handleChange = async (event) => {
    const { value, name } = event.target;
    setFormValue((prev) => {
      return { ...prev, [name]: value };
    });
    if (submitCount > 0 && validationSchema) {
      await validateField(name, value);
    }
  };

  const handleSubmit = (onSubmit) => async () => {
    try {
      setSubmitCount((prev) => prev + 1);
      if (validationSchema) {
        await validationSchema.validate(formValue, { abortEarly: false });
      }
      onSubmit(formValue);
    } catch (error) {
      let newErrors = {};
      error &&
        error.inner &&
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      setErrors(newErrors);
    }
  };

  const handleReset = () => {
    setFormValue(defaultValue);
  };

  const handleSetFormValue = (values) => {
    setFormValue({ ...defaultValue, ...values });
  };

  const handleSchemaCheck = async (checkSchema, values) => {
    try {
      await checkSchema.validate(values, { abortEarly: false });
      return true;
    } catch (error) {
      let newErrors = {};
      error &&
        error.inner &&
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      setErrors(newErrors);
      return false;
    }
  };

  const append = (key, value) => {
    setFormValue((prev) => {
      const copy = { ...prev };
      if (copy[key]) {
        copy[key] = [...copy[key], value];
      }
      return { ...copy };
    });
  };

  const remove = (key, index) => {
    setFormValue((prev) => {
      const copy = { ...prev };
      copy[key].splice(index, 1);
      return copy;
    });
  };

  const setValues = (key, value) => {
    setFormValue((prev) => {
      return { ...prev, [key]: value };
    });
  };

  return {
    formValue,
    handleChange,
    handleSubmit,
    errors,
    submitCount,
    handleReset,
    handleSetFormValue,
    setErrors,
    handleSchemaCheck,
    append,
    setValues,
    remove,
    setFormValue,
  };
};

export default useForm;
