import React, { Component } from 'react'
import Loading from './ui/Loading'
import { CSSTransitionGroup } from 'react-transition-group'
import { Prompt } from 'react-router-dom'

import QuestionnaireTemplate from '../form_templates/wotc-questionnaire'
import { stateAbbreviations } from '../constants/states'

import '../styles/WOTCForm.css';

function htmlObj(htmlStr) {
  return {__html: htmlStr}
}

class FormSignature extends Component {
  constructor(p) {
    super(p)
    this.state = { signing : false, disclaimerChecked: false, nameEntered: '' }

    this.complete = this.complete.bind(this)
  }

  complete() {
    const { nameEntered } = this.state
    var d = new Date();
    let day = d.getDate()
    let month = d.getMonth()
    let year = d.getFullYear()

    let date = `${month}/${day}/${year}`
    this.props.onChange(nameEntered, date)
    this.setState({signing: false})
  }

  render() {
    const { label, required, name, date, disclaimer } = this.props
    const { signing, disclaimerChecked, nameEntered } = this.state

    if (signing) {
      return (
        <div className="signature">
          <div className="label">{label}</div>
          <div className="disclaimer">
            {disclaimer}
          </div>
          <div className="agree">
            <input
              type="checkbox"
              checked={disclaimerChecked}
              onChange={(e) => this.setState({disclaimerChecked: e.target.checked})} />
            <div>
              I agree to the statement above
            </div>
          </div>
          {
            disclaimerChecked
            &&
            (
              <div className="sign">
                <label>Please type your full name:</label>
                <div>
                  <input type="text" value={nameEntered} onChange={(e) => this.setState({nameEntered: e.target.value})} />
                </div>
              </div>
            )
          }
          {
            nameEntered !== ''
            &&
            (
              <div className="submit">
                <button onClick={() => this.complete()}>SIGN</button>
              </div>
            )
          }
        </div>
      )
    }

    if (name && date) {
      return (
        <div className="signature">
          <div className="label">{label}</div>
          <div>
            Signed: {name}, {date}
          </div>
          <div>
            <button onClick={() => this.setState({signing: true})}>Re-sign</button>
          </div>
        </div>
      )
    }

    if (required && !signing) {
      return (
        <div className="signature">
          <div className="label">{label}</div>
          <div>
            <button onClick={() => this.setState({signing: true})}>Click to sign</button>
          </div>
        </div>
      )
    }
  }
}

class WOTCForm extends Component {
  constructor(p) {
    super(p)
    this.state = { data: null, changes: {}, successIndicator: null }

    this.mergeTemplateAndData = this.mergeTemplateAndData.bind(this)
    this.inputChange = this.inputChange.bind(this)
    this.saveForm = this.saveForm.bind(this)
    this.successIndicator = this.successIndicator.bind(this)
  }

  componentWillMount() {
    const { data: stateData } = this.state
    const { form } = this.props

    if (stateData === null && form) {
      this.setState({data: form.data})
    }
  }

  componentWillReceiveProps(np) {
    const { data: stateData } = this.state
    const { form, success } = np
    const { success: oldSuccess } = this.props

    if (stateData === null && form) {
      this.setState({data: form.data})
    }

    if (!oldSuccess.wotcFormUpdate && success.wotcFormUpdate) {
      this.successIndicator('SAVED SUCCESSFULLY')
      this.setState({changes: []})
    }
  }

  successIndicator(text) {
    this.setState({successIndicator: text})
    this.successTimeout = setTimeout(function() {
      this.setState({successIndicator: null})
    }.bind(this), 2000)
  }

  componentWillUnmount() {
    if (this.successTimeout) {
      clearTimeout(this.successTimeout)
      this.successTimeout = null
    }

    window.onbeforeunload = null
  }

  saveForm() {
    const { data } = this.state
    const { saveForm } = this.props
    saveForm(data)
  }

  // takes array of {fieldName: newValue}
  // checks if there are changes using the state.changes obj
  // updates state changes and data
  inputChange(changesArray) {
    const { changes: stateChanges } = this.state

    if (!changesArray) {
      return
    }

    let newValsObj
    let newChangesObj
    let deleteKeysFromChanges = []
    for (var i = 0; i < changesArray.length; i++) {
      const thisChange = changesArray[i]
      newValsObj = {...newValsObj, ...thisChange}

      const keys = Object.keys(thisChange)
      let fieldName
      if (keys.length > 0) {
        fieldName = keys[0]

        const currentChange = stateChanges[fieldName]
        if (currentChange) {
          // this fieldName is already there
          // check if new val is orig value, if so remove
          // if not, update
          if (currentChange['orig'] === thisChange[fieldName]) {
            deleteKeysFromChanges = [...deleteKeysFromChanges, fieldName]
          } else {
            const newChange = {
              orig: currentChange['orig'],
              curr: thisChange[fieldName]
            }
            newChangesObj = {...newChangesObj, [fieldName]: newChange}
          }
        } else {
          // this fieldName is new, just add the change
          const newChange = {
            orig: this.state.data[fieldName],
            curr: thisChange[fieldName]
          }
          newChangesObj = {...newChangesObj, [fieldName]: newChange}
        }
      }
    }

    let newChanges = { ...this.state.changes, ...newChangesObj }
    for (var j = 0; j < deleteKeysFromChanges.length; j++) {
      delete newChanges[deleteKeysFromChanges[j]]
    }

    this.setState({
      data: { ...this.state.data, ...newValsObj },
      changes: newChanges
    })
  }

  mergeTemplateAndData(formURL, data, isAuthenticated) {
    if (!data) {
      return {incompleteRequired: [], rows: null}
    }

    let template = null
    switch (formURL) {
      case 'wotc-questionnaire':
        template = QuestionnaireTemplate
        break;
      default:
        return {incompleteRequired: [], rows: null}
    }

    let incompleteRequired = []
    const rows = template.map((formNode, index) => {
      if (!isAuthenticated && formNode.hideFromApplicant) {
        return null
      }

      switch (formNode.type) {
        case 'pageTitle':
          return (
            <h1 className={formNode.type} key={formNode.type+index}>
              {formNode.value}
            </h1>
          )
        case 'title':
          return (
            <div className={formNode.type} key={formNode.type+index}>
              {formNode.value}
            </div>
          )
        case 'subTitle':
          return (
            <div className={formNode.type} key={formNode.type+index}>
              {formNode.value}
            </div>
          )
        case 'lineBreak':
          return (
            <div className={formNode.type} key={formNode.type+index}>
              <div></div>
            </div>
          )
        case 'text100':
        case 'text50':
        case 'textSmallField':
          let required = ''
          if (formNode.required) {
            if (!data[formNode.fieldName]) {
              required = 'required'
              incompleteRequired = [...incompleteRequired, formNode]
            }
          }
          return (
            <div className={`${formNode.type} ${required}`} key={formNode.type+index}>
              <label htmlFor={formNode.type+index}>
                {formNode.label}
              </label>
              <input
                id={formNode.type+index}
                type="text"
                value={data[formNode.fieldName] ? data[formNode.fieldName] : ''}
                onChange={(e) => this.inputChange([{[formNode.fieldName]: e.target.value}])} />
            </div>
          )
        case 'check':
          return (
            <div className={`${formNode.type} ${formNode.required ? 'required' : ''}`} key={formNode.type+index}>
              <div className="checkboxCont">
                <input
                  type="checkbox"
                  checked={data[formNode.fieldName] ? data[formNode.fieldName] : false}
                  onChange={(e) => this.inputChange([{[formNode.fieldName]: e.target.checked}])} />
              </div>
              <div className="descCont" dangerouslySetInnerHTML={htmlObj(formNode.label)}>
              </div>
            </div>
          )
        case 'state':
          required = ''
          if (formNode.required) {
            if (!data[formNode.fieldName]) {
              required = 'required'
              incompleteRequired = [...incompleteRequired, formNode]
            }
          }
          return (
            <div className={`${formNode.type} ${required}`} key={formNode.type+index}>
              <label
                htmlFor={formNode.type+index}
                >
                State
                <select
                  id={formNode.type+index}
                  value={data[formNode.fieldName]}
                  onChange={(e) => this.inputChange([{[formNode.fieldName]: e.target.value}])}
                >
                  <option value={''}>Choose one below</option>
                  {
                    stateAbbreviations.map((state) => (
                      <option key={state} value={state}>{state}</option>
                    ))
                  }
                </select>
              </label>
            </div>
          )
        case 'signature':
          required = ''
          if (formNode.required) {
            if (!data[formNode.signatureFieldName]) {
              required = 'required'
              incompleteRequired = [...incompleteRequired, formNode]
            }
          }
          return (
            <div className={`${formNode.type}Cont ${required}`} key={formNode.type+index}>
              <FormSignature
                {...formNode}
                required={required}
                name={data[formNode.signatureFieldName]}
                date={data[formNode.dateFieldName]}
                onChange={(newName, newDate) => this.inputChange([{[formNode.signatureFieldName]: newName},{[formNode.dateFieldName]: newDate}])}
                />
            </div>
          )
        default:
          return null
      }
    })
    return {incompleteRequired, rows}
  }

  render() {
    const { loading, applicant, formURL, isAuthenticated, form } = this.props
    const { data, changes, successIndicator } = this.state
    const madeChanges = Object.keys(changes).length > 0

    let fill = null
    if (loading) {
      fill = <Loading />
    }

    if (!fill && (!form || !applicant)) {
      fill = <div>form data could not be found</div>
    }

    if (fill) {
      return (
        <div className="WOTCForm">
          {fill}
        </div>
      )
    }

    window.onbeforeunload = function() {
      if (madeChanges) {
        return 'You have unsaved changes.' // only shows text on some browsers
      } else {
        return // return nothing should cause it to ignore
      }
    }

    const { rows, incompleteRequired } = this.mergeTemplateAndData(formURL, data, isAuthenticated)

    let remainingFields = null
    if (incompleteRequired) {
      if (incompleteRequired.length > 0) {
        if (incompleteRequired.length === 1) {
          remainingFields = '1 REQUIRED FIELD REMAINING'
        } else {
          remainingFields = `${incompleteRequired.length} REQUIRED FIELDS REMAINING`
        }
      }
    }

    let savingSection
    if (madeChanges) {
      savingSection = (
        <div className="saveCont">
          <button onClick={() => this.saveForm()}>
            {
              remainingFields
              ?
              (
                  <span>
                    SAVE CHANGES - {remainingFields}
                  </span>
              )
              :
              (
                <span>
                  SAVE CHANGES AND SUBMIT
                </span>
              )
            }
          </button>
        </div>
      )
    }

    return (
      <div className={`WOTCForm`}>
        <Prompt
          when={madeChanges}
          message="You have unsaved changes. Are you sure you want to leave?"
        />
        {
          !remainingFields
          &&
          !madeChanges
          &&
          (
            <div className="complete top">
              All required fields are complete, be sure to review all questions.
            </div>
          )
        }
        {
          rows
        }
        <CSSTransitionGroup
          transitionName="slideUp"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}>
          {savingSection}
        </CSSTransitionGroup>
        <CSSTransitionGroup
          transitionName="slideUp"
          transitionEnterTimeout={200}
          transitionLeaveTimeout={200}>
          {
            successIndicator
            &&
            (
              <div className="successIndicator">
                {successIndicator}
              </div>
            )
          }
        </CSSTransitionGroup>
        {
          remainingFields
          &&
          (
            <span className="remainingFieldsIndicator">
              {remainingFields}
            </span>
          )
        }
        {
          remainingFields
          &&
          (
            <div className="noncomplete">
              This form is not complete until all required fields have been completed and saved.
            </div>
          )
        }
        {
          !remainingFields
          &&
          (
            !madeChanges
            ?
            (
              <div className="complete">
                All required fields are complete, be sure to review all questions.
              </div>
            )
            :
            (
              <div className="noncomplete">
                This form is not complete until you save your changes.
              </div>
            )
          )
        }
      </div>
    )
  }
}

export default WOTCForm
