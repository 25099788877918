import { isEmpty } from "lodash";
import moment from "moment";
import React, { Component } from "react";

class X8ConfigurationForm extends Component {
  render() {
    const {
      handleChange,
      handleSubmit,
      formValue: { url, customer_id, api_key },
      created_at,
      updated_at,
    } = this.props;

    return (
      <div>
        <h4>Application configuration</h4>
        <div className="d-flex">
          <div className="con-input">
            <label>API authentication key</label>
            <input
              type="text"
              value={api_key}
              name="api_key"
              placeholder="API key"
              onChange={handleChange}
            />
          </div>
          <div className="con-input">
            <label>Customer ID</label>
            <input
              type="text"
              value={customer_id}
              name="customer_id"
              placeholder="Customer ID"
              onChange={handleChange}
            />
          </div>
        </div>
        {!isEmpty(customer_id) && (
          <div className="connector-configuration-input">
            <label>API URL</label>
            <input
              type="text"
              value={url}
              name="url"
              placeholder="API URL"
              disabled
              onChange={handleChange}
            />
          </div>
        )}

        <div className="d-flex">
          {!isEmpty(created_at) && (
            <div className="con-input">
              <label>Create at</label>
              <input
                type="text"
                value={moment(created_at).format("MM/DD/YYYY kk:mm:ss")}
                disabled
              />
            </div>
          )}
          {!isEmpty(updated_at) && (
            <div className="con-input">
              <label>Updated at</label>
              <input
                type="text"
                value={moment(updated_at).format("MM/DD/YYYY kk:mm:ss")}
                disabled
              />
            </div>
          )}
        </div>
        <div className="btn-configuration">
          <button
            className="submitbtncstm"
            type="submit"
            onClick={handleSubmit}
          >
            {!isEmpty(updated_at) ? "Update" : "Submit"}
          </button>
        </div>
      </div>
    );
  }
}

export default X8ConfigurationForm;
