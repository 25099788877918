import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "./ui/Loading";

import SimpleTextSetting from "./settings/SimpleIntegrationTextSetting";
import SelectSetting from "./settings/SelectSetting";
import EmailNameSetting from "./settings/EmailNameSetting";
import CostCenterFilterSetting from "./settings/CostCenterFilterSetting";
import NewUserTemplates from "./settings/NewUserTemplates";

import "../styles/IntegrationSettings.css";
import {
  integrationEmployeeEventProcess,
  integrationEmployeeHFProcess,
  integrationEmployeeSync,
} from "../actions/integrations";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import HiredTimingSettings from "./settings/HiredTimingSettings";
import { oldAppPath } from "../constants/constant";

const showIntegrationSyncAPI = [
  `${oldAppPath}/products/user-provisioning`,
  `${oldAppPath}/integrations/8x8`,
  `${oldAppPath}/integrations/microsoft365`,
  `${oldAppPath}/integrations/salesforce`,
  `${oldAppPath}/integrations/box`,
  `${oldAppPath}/integrations/dropbox`,
  `${oldAppPath}/integrations/zoom`,
  `${oldAppPath}/integrations/hubspot`,
  `${oldAppPath}/integrations/atera`,
  `${oldAppPath}/integrations/onelogin`,
  `${oldAppPath}/integrations/gsuite`,
];

class IntegrationSettings extends Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    saveSettings: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      settings: props.settings,
      successMsg: "",
      errorMsg: "",
      employeeSyncValue: "hired",
      syncLoading: false,
      timingValue: "",
    };

    this.handleEnabledChange = this.handleEnabledChange.bind(this);
    this.handleGenericChange = this.handleGenericChange.bind(this);
    this.renderSetting = this.renderSetting.bind(this);
    this.renderSaveButtons = this.renderSaveButtons.bind(this);
    this.renderSaveAlert = this.renderSaveAlert.bind(this);
  }

  componentWillMount() {
    const { settings } = this.props;
    this.setState({ settings: settings });
  }

  componentWillReceiveProps(newProps) {
    const { settings, settingsAreSaving } = this.props;
    const {
      settings: newSettings,
      settingsAreSaving: newSettingsAreSaving,
      error: newError,
    } = newProps;

    if (newError !== "") {
      this.setState({
        errorMsg: "An error occured, your settings may have not been saved",
        successMsg: "",
      });
      return;
    }

    if (
      settings !== newSettings &&
      settingsAreSaving &&
      !newSettingsAreSaving &&
      newError === ""
    ) {
      this.setState({
        settings: newSettings,
        successMsg: "Settings saved successfully",
        errorMsg: "",
      });
    }
  }

  save() {
    const { settings: settingsState } = this.state;
    const { settings: settingsProp, saveSettings } = this.props;

    if (settingsState !== settingsProp) {
      saveSettings(settingsState);
    }
  }

  cancel() {
    const { settings: settingsProp } = this.props;

    this.setState({ settings: settingsProp });
  }

  handleGenericChange(key, value) {
    this.setState({
      settings: { ...this.state.settings, [key]: value },
      successMsg: "",
      errorMsg: "",
    });
  }

  handleEnabledChange(event) {
    const newValStr = event.target.value;
    const newValBool = newValStr === "true" ? true : false;

    this.handleGenericChange("enabled", newValBool);
  }

  renderSaveAlert(settingsProp, settingsState) {
    return (
      JSON.stringify(settingsProp) !== JSON.stringify(settingsState) && (
        <div className="saveAlert">
          <span className="box"></span>
          <span>=</span> Unsaved Setting
        </div>
      )
    );
  }

  renderSaveButtons(settingsProp, settingsState) {
    return (
      JSON.stringify(settingsProp) !== JSON.stringify(settingsState) && (
        <div className="buttonCont top">
          <button onClick={() => this.cancel()}>CANCEL</button>
          <button onClick={() => this.save()}>SAVE CHANGES</button>
        </div>
      )
    );
  }

  renderSetting = (key, value, oldval) => {
    const intName = this.props.integrationName;

    switch (key) {
      case "hired_timing":
        return (
          <HiredTimingSettings
            setting={value}
            onChange={(newVal) => this.handleGenericChange(key, newVal)}
          />
        );
      case "enabled":
        return (
          <div className="settingsRow">
            <div className="title">ON/OFF</div>
            <SelectSetting
              label={"Integration ENABLED or DISABLED:"}
              onChange={this.handleEnabledChange}
              value={value ? "true" : "false"}
              description={`Setting to DISABLED will halt all actions involving ${intName}`}
              options={[
                { label: "ENABLED", value: "true" },
                { label: "DISABLED", value: "false" },
              ]}
            />
          </div>
        );
      case "email_name":
        return (
          <div className="settingsRow">
            <div className="title">
              {intName === "Salesforce" ||
              intName === "Box" ||
              intName === "Dropbox"
                ? "New Username Settings"
                : "New Email Address Settings"}
            </div>
            <EmailNameSetting
              onChange={(newVal) =>
                this.handleGenericChange("email_name", newVal)
              }
              setting={value}
              integrationName={intName}
            />
          </div>
        );
      case "cost_center_filter":
        return (
          <div className="settingsRow">
            <div className="title">Cost Center Filters</div>
            <div className="tip">
              Limit which employee groups get {intName} accounts provisioned.
              Note: you must add the employee to the cost center before hiring
              in order for this filter to take effect
            </div>
            <CostCenterFilterSetting
              onChange={(newVal) =>
                this.handleGenericChange("cost_center_filter", newVal)
              }
              setting={value}
            />
          </div>
        );
      case "cxn_email_recipient":
        return (
          <div className="settingsRow">
            <div className="title">Connection Email Recipient</div>
            <div className="tip">
              Enter the email address that should receive updates regarding the
              On-Premise connection
            </div>
            <SimpleTextSetting
              value={value ? value.address : ""}
              onChange={(newVal) =>
                this.handleGenericChange("cxn_email_recipient", {
                  address: newVal,
                })
              }
            />
          </div>
        );
      case "new_user_templates":
        return (
          <div className="settingsRow">
            <div className="title">New User Templates</div>
            <div className="tip">
              In order to add new users with settings automatically enabled, map
              a template AD user to various OnePoint categories and new
              employees in those categories will be built of the AD template
              user
            </div>
            <NewUserTemplates
              setting={value}
              onChange={(newVal) =>
                this.handleGenericChange("new_user_templates", newVal)
              }
            />
          </div>
        );
      case "new_password":
        return (
          <div className="settingsRow">
            <div className="title">New User Password</div>
            <div className="tip">
              Choose what you want your new user passwords to be
            </div>
            <SimpleTextSetting
              value={value ? value.value : ""}
              onChange={(newVal) =>
                this.handleGenericChange("new_password", {
                  ...value,
                  value: newVal,
                })
              }
            />
          </div>
        );
      default:
        return null;
    }
  };

  handleEmployeeChange = (e) => {
    this.setState({ employeeSyncValue: e.target.value });
  };

  handleSyncEmployee = async () => {
    this.setState({ syncLoading: true });
    const { employeeSyncValue } = this.state;
    const { dispatch } = this.props;
    const type = employeeSyncValue === "hired" ? 1 : 2;

    try {
      await dispatch(integrationEmployeeSync(employeeSyncValue));
      await dispatch(
        integrationEmployeeHFProcess(this.props.integrationId, type)
      );
      await dispatch(
        integrationEmployeeEventProcess(this.props.integrationId, type)
      );
    } catch (error) {
    } finally {
      this.setState({ syncLoading: false });
    }
  };

  changeSetting = (event) => {
    const { value } = event.target;
    this.setState({ timingValue: value });
  };

  render() {
    const {
      settings: settingsState,
      successMsg,
      errorMsg,
      employeeSyncValue,
      syncLoading,
    } = this.state;
    const { settings: settingsProp, settingsAreSaving } = this.props;
    const { handleEmployeeChange, handleSyncEmployee } = this;

    return (
      <div className="settingsCont">
        <div>
          {settingsAreSaving && (
            <div className="loadCont">
              <Loading />
            </div>
          )}
          {successMsg !== "" && (
            <div className="successMsg good">{successMsg}</div>
          )}
          {errorMsg !== "" && <div className="successMsg bad">{errorMsg}</div>}
          {this.renderSaveAlert(settingsProp, settingsState)}
          {showIntegrationSyncAPI.includes(window.location.pathname) && (
            <div>
              {syncLoading ? (
                <Loading />
              ) : (
                <div className="settingsRow">
                  <div className="title">Sync new employees</div>
                  <div>
                    <select
                      defaultValue={employeeSyncValue}
                      onChange={handleEmployeeChange}
                    >
                      <option value="hired">New Hired</option>
                      <option value="fired">New Fired</option>
                    </select>
                    <button onClick={handleSyncEmployee}>Sync</button>
                  </div>
                  <div className="tip">
                    Select when you want new hires / fired' accounts to be
                    provisioned for company integrations.
                  </div>
                </div>
              )}
            </div>
          )}

          {Object.keys(settingsState).map((key) => (
            <div
              key={key}
              className={
                JSON.stringify(settingsState[key]) !==
                JSON.stringify(settingsProp[key])
                  ? "unsaved"
                  : ""
              }
            >
              {this.renderSetting(key, settingsState[key])}
            </div>
          ))}
          {this.renderSaveButtons(settingsProp, settingsState)}
        </div>
      </div>
    );
  }
}

export default withRouter(connect()(IntegrationSettings));
