import React, { useEffect, useState } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";

import Loading from "../../../components/ui/Loading";
import useFetch from "../../hooks/useFetch";

const EmployeeReport = (props) => {
  const { openAssociation, handleOpenMode, reportId, companyInfo, userInfo } =
    props;
  const [getAssociationById, associationLoading] = useFetch();
  const [getAssCustReportsById, reportLoading] = useFetch();
  const [reportMode, setReportMode] = useState("default");
  const [messageRequired, setMessageRequired] = useState("");
  const [associationData, setAssociationData] = useState([]);
  const [reports, setReports] = useState([]);

  const handleChangeMode = (e) => setReportMode(e.target.value);

  const fetchReports = async () => {
    const payload = {
      endPoint: `/api/tlm/cp/association/companies/${reportId}`,
      method: "get",
    };

    const payloadAss = {
      endPoint: `/api/tlm/cp/association/company/cust/reports/${reportId}`,
      method: "get",
    };

    const response = await getAssociationById(payload);
    const resReports = await getAssCustReportsById(payloadAss);
    if (resReports) {
      setReports(resReports.map((item) => ({ ...item, checked: false })));
    }
    if (response) {
      setAssociationData(
        response.companies.map((item) => ({ ...item, checked: false }))
      );
    }
  };

  const handleGenerateReport = () => {
    if (reportMode === "default") {
      const ids = [];
      const data =
        Array.isArray(associationData) &&
        associationData.filter((elem) => {
          if (companyInfo.short_name === elem.short_name) {
            elem.checked = true;
          }
          return elem.checked === true;
        });

      data.forEach((elem) => {
        ids.push(elem.short_name);
      });

      window.open(`/default/report/?shortname=${ids.join()}`);
    } else {
      const newId = uuidv4();
      const copy = [...reports];
      let reportName = "";
      /* eslint-disable */
      const reportData = copy
        .filter((report) => report.checked)
        .map((report) => {
          if (report.reports) {
            reportName = report.report_name;
            const reportValue = report.reports.map(
              ({ report_id, short_name }) => {
                return {
                  report_name: report.report_name,
                  short_name,
                  report_id: report_id.toString(),
                  association_id: reportId.toString(),
                  key: newId,
                  p_company_id: String(userInfo.company_id),
                };
              }
            );
            return reportValue;
          }
        })
        .flat();

      if (reportData.length === 0) {
        setMessageRequired("Please select report!");
        return;
      }
      setMessageRequired("");

      /* eslint-enable */
      const dataString = JSON.stringify(reportData);
      const encryptedMessage = btoa(dataString);

      const strParams = btoa(
        companyInfo.short_name + reportId + reportName + newId
      );

      const selectedReport = reports.find((report) => report.checked);

      window.open(
        `/custom/report/${encryptedMessage}/${strParams}/${selectedReport.id}`
      );
    }
  };

  const handleChangeDefault = (event, index) => {
    const copy = [...associationData];
    copy[index].checked = !copy[index].checked;
    setAssociationData(copy);
  };

  const handleChangeCustom = (index) => () => {
    const copy = [...reports];
    copy[index].checked = !copy[index].checked;
    setReports(copy);
  };

  useEffect(() => {
    if (openAssociation) {
      fetchReports();
    }
  }, [openAssociation]);

  // console.log("associationData", associationData);
  //   console.log("reports", reports);

  return (
    <div
      className={`dashboard-integration-doc association ${
        openAssociation && "open"
      }`}
    >
      <div className="association-con employee-report">
        <div>
          <span className="icon-btn" onClick={handleOpenMode(null)}>
            <FontAwesomeIcon icon={solid("xmark")} />
          </span>
        </div>
        <div className="integration-document">
          <h1>Company Employees Report</h1>

          <div>
            {associationLoading || reportLoading ? (
              <Loading />
            ) : reportMode === "default" ? (
              <>
                <h3>Association company</h3>
                <div className="col-12 mbcstm asscmp-a-table-scroll">
                  <table className="table tablecustompop">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Company Name</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!isEmpty(associationData) &&
                        Array.isArray(associationData) &&
                        associationData.map((val, ind) => {
                          return (
                            <tr key={ind}>
                              <td>
                                <input
                                  type="checkbox"
                                  id="title"
                                  name="title"
                                  value={val.company_id}
                                  checked={
                                    val.p_company_id ? true : val.checked
                                  }
                                  disabled={val.p_company_id ? true : false}
                                  onChange={(e) => handleChangeDefault(e, ind)}
                                />
                              </td>
                              <td>{val.name}</td>
                              <td>
                                {val.p_company_id ? (
                                  <span className="badgecolor">Primary</span>
                                ) : (
                                  ""
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <>
                <div>
                  <h3>Association custom report</h3>
                  <span className="color-red">{messageRequired}</span>
                  <div className="col-12 mbcstm asscmp-a-table-scroll">
                    <table className="table tablecustompop">
                      <thead>
                        <tr>
                          <th scope="col"></th>
                          <th scope="col">Report Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Array.isArray(reports) &&
                          reports.map((val, ind) => {
                            return (
                              <tr key={ind}>
                                <td>
                                  <input
                                    type="radio"
                                    id="title"
                                    name="title"
                                    value={JSON.stringify(val.reports)}
                                    onChange={handleChangeCustom(ind)}
                                  />
                                </td>
                                <td>{val.report_name}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="my-flex my-justify-content-end">
            <div className="employeereport-select">
              <div className="one-control-select">
                <select
                  value={reportMode}
                  onChange={handleChangeMode}
                  className="report-type"
                >
                  <option value="default">Default</option>
                  <option value="custom">Custom</option>
                </select>
              </div>
            </div>
            <button
              disabled={associationLoading || reportLoading}
              className="one-control-btn"
              onClick={handleGenerateReport}
            >
              Generate report
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { companyInfo, userInfo } = state.products;

  return { companyInfo, userInfo };
};

export default connect(mapStateToProps)(EmployeeReport);
