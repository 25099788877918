import React from 'react'
import queryString from 'query-string'
import { Link } from 'react-router-dom'

import FormContainer from '../containers/FormContainer'
import withAuthInfo from '../hocs/AuthInfo'

import logo from '../assets/images/op-logo.png'
import '../styles/FormPage.css';
const FORM_TOKEN_QUERY_PARAM = "formToken"

const FormPage = (props) => {
  const { formURL, applicantID } = props.match.params
  const parsedURLQuery = queryString.parse(props.location.search)
  const token = parsedURLQuery[FORM_TOKEN_QUERY_PARAM]

  return (
    <div style={{display: 'flex', minHeight: '100vh'}}>
      <div className="FormPageRoot">
        <div className="nav">
          <div className="content">
            <div className="logo">
              <img src={logo} alt="logo" />
            </div>
            <div className="rightSide">
              {
                props.isAuthenticated
                &&
                (
                  <div className="backto">
                    <Link to={'/'}>
                      GO TO ONEPOINT CONNECT
                    </Link>
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className="contentOuter">
          <div className="contentInner">
            <FormContainer entirePage={true} formURL={formURL} applicantID={applicantID} token={token} />
          </div>
          <div className="footer">
          </div>
        </div>
      </div>
    </div>
  )
}

export default withAuthInfo(FormPage)
