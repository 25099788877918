import * as type from "../actions/dashboard";

const initialState = {
  loading: false,
  userAdminPermission: false,
  userPermissionCount: 0,
  productData: [],
  productLoading: false,
  settingsToggle: false,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case type.USER_PERMISSION_API_COUNT:
      return { ...state, userPermissionCount: state.userPermissionCount + 1 };
    case type.PRODUCT_SETTING_TOGGLE:
      return { ...state, settingsToggle: !state.settingsToggle };
    case type.GET_PRODUCT_CONTENT_FAILURE:
      return { ...state, productLoading: false };
    case type.GET_PRODUCT_CONTENT_REQUEST:
      return { ...state, productLoading: true };
    case type.GET_PRODUCT_CONTENT_SUCCESS:
      let data = [];
      if (action.response) {
        data = Object.values(action.response);
      }
      return { ...state, productData: data, productLoading: false };
    case type.GET_USER_WRITE_PERMISSION_REQUEST:
      return { ...state, loading: true };
    case type.GET_USER_WRITE_PERMISSION_SUCCESS:
      let permission = 0;
      if (action.response) {
        permission = action.response.IsWrite ? true : false;
      }
      return { ...state, userAdminPermission: permission, loading: false };

    default:
      return { ...state, loading: false };
  }
};

export default dashboard;
