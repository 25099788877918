import { CALL_API, Schemas } from '../middleware/api'

export const WOTC_APPLICANTS_REQUEST = 'WOTC_APPLICANTS_REQUEST'
export const WOTC_APPLICANTS_SUCCESS = 'WOTC_APPLICANTS_SUCCESS'
export const WOTC_APPLICANTS_FAILURE = 'WOTC_APPLICANTS_FAILURE'

const fetchApplicantsx = () => ({
  [CALL_API]: {
    types: [ WOTC_APPLICANTS_REQUEST, WOTC_APPLICANTS_SUCCESS, WOTC_APPLICANTS_FAILURE ],
    endpoint: 'api/wotc/applicants',
    schema: Schemas.WOTC_APPLICANT_LIST,
  }
})

export const fetchApplicants = () => (dispatch, getState) => {
  return dispatch(fetchApplicantsx())
}

export const WOTC_APPLICANT_UPDATE_REQUEST = 'WOTC_APPLICANT_UPDATE_REQUEST'
export const WOTC_APPLICANT_UPDATE_SUCCESS = 'WOTC_APPLICANT_UPDATE_SUCCESS'
export const WOTC_APPLICANT_UPDATE_FAILURE = 'WOTC_APPLICANT_UPDATE_FAILURE'

const sendQuestionnairex = (appID) => ({
  [CALL_API]: {
    types: [ WOTC_APPLICANT_UPDATE_REQUEST, WOTC_APPLICANT_UPDATE_SUCCESS, WOTC_APPLICANT_UPDATE_FAILURE ],
    endpoint: `api/wotc/applicants/${appID}/sendQuestionnaire`,
    schema: Schemas.WOTC_APPLICANT,
    postdata: {}
  }
})

export const sendQuestionnaire = (appID) => (dispatch, getState) => {
  return dispatch(sendQuestionnairex(appID))
}

export const WOTC_APPLICANT_CREATE_REQUEST = 'WOTC_APPLICANT_CREATE_REQUEST'
export const WOTC_APPLICANT_CREATE_SUCCESS = 'WOTC_APPLICANT_CREATE_SUCCESS'
export const WOTC_APPLICANT_CREATE_FAILURE = 'WOTC_APPLICANT_CREATE_FAILURE'

const createApplicantx = (newApp) => ({
  [CALL_API]: {
    types: [ WOTC_APPLICANT_CREATE_REQUEST, WOTC_APPLICANT_CREATE_SUCCESS, WOTC_APPLICANT_CREATE_FAILURE ],
    endpoint: `api/wotc/applicants`,
    schema: Schemas.WOTC_APPLICANT,
    postdata: newApp
  }
})

export const createApplicant = (newApp) => (dispatch, getState) => {
  return dispatch(createApplicantx(newApp))
}

export const WOTC_FORM_REQUEST = 'WOTC_FORM_REQUEST'
export const WOTC_FORM_SUCCESS = 'WOTC_FORM_SUCCESS'
export const WOTC_FORM_FAILURE = 'WOTC_FORM_FAILURE'

const fetchFormDatax = (url) => ({
  [CALL_API]: {
    types: [ WOTC_FORM_REQUEST, WOTC_FORM_SUCCESS, WOTC_FORM_FAILURE ],
    endpoint: url,
    schema: Schemas.WOTC_APPLICANT,
  }
})

export const fetchFormData = (url) => (dispatch, getState) => {
  return dispatch(fetchFormDatax(url))
}

export const WOTC_FORM_UPDATE_REQUEST = 'WOTC_FORM_UPDATE_REQUEST'
export const WOTC_FORM_UPDATE_SUCCESS = 'WOTC_FORM_UPDATE_SUCCESS'
export const WOTC_FORM_UPDATE_FAILURE = 'WOTC_FORM_UPDATE_FAILURE'

const updateFormDatax = (url, data) => ({
  [CALL_API]: {
    types: [ WOTC_FORM_UPDATE_REQUEST, WOTC_FORM_UPDATE_SUCCESS, WOTC_FORM_UPDATE_FAILURE ],
    endpoint: url,
    schema: Schemas.WOTC_APPLICANT,
    postdata: data
  }
})

export const updateFormData = (url, data) => (dispatch, getState) => {
  return dispatch(updateFormDatax(url, data))
}

export const WOTC_CREATE_EMPLOYEE_REQUEST = 'WOTC_CREATE_EMPLOYEE_REQUEST'
export const WOTC_CREATE_EMPLOYEE_SUCCESS = 'WOTC_CREATE_EMPLOYEE_SUCCESS'
export const WOTC_CREATE_EMPLOYEE_FAILURE = 'WOTC_CREATE_EMPLOYEE_FAILURE'

const createWOTCEmployeex = (applicantID) => ({
  [CALL_API]: {
    types: [ WOTC_CREATE_EMPLOYEE_REQUEST, WOTC_CREATE_EMPLOYEE_SUCCESS, WOTC_CREATE_EMPLOYEE_FAILURE ],
    endpoint: `api/wotc/applicants/${applicantID}/createEmployee`,
    schema: Schemas.WOTC_APPLICANT,
    postdata: {}
  }
})

export const createWOTCEmployee = (applicantID) => (dispatch, getState) => {
  return dispatch(createWOTCEmployeex(applicantID))
}
