import React, { useState } from "react";

import "../styles/BaseProduct.css";
import Loading from "./ui/Loading";
import AccessControl from "./settings/AccessControl";
import {
  integrationEmployeeHFProcess,
  integrationEmployeeSync,
} from "../actions/integrations";

const BaseProduct = ({
  name,
  productsAreFetching,
  productsAreUpdating,
  successMsg,
  children,
  settings,
  renderSetting,
  showAccessControl,
  companyProductId,
  accessControls,
  authorization,
  dispatch,
  history,
  productURL,
}) => {
  const [employeeSyncValue, setEmployeeSyncValue] = useState("hired");
  const [isLoading, setIsLoading] = useState(false);
  const handleEmployeeChange = (e) => {
    setEmployeeSyncValue(e.target.value);
  };

  const handleSyncEmployee = async () => {
    setIsLoading(true);
    try {
      await dispatch(integrationEmployeeSync(employeeSyncValue));
      await dispatch(integrationEmployeeHFProcess("connector_name"));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  if (!name && !productsAreFetching) {
    return <div>That product does not exist.</div>;
  }

  //hard code job boards its grandfathered in

  if (
    (productURL !== "jobs" || productURL !== "mineral" || productURL !== "biz_library") &&
    (!authorization || (!authorization.read && !authorization.write))
  ) {
    return <div>You are not authorized.</div>;
  }

  return (
    <div className="pis mineral-page">
      <div className="breadcrumbs">
        {"/app/system-product/cloudpunch" === history.location.pathname ? (
          <span onClick={() => history.push("/app")}>
            &larr; Back to list of your products
          </span>
        ) : (
          <span onClick={() => history.goBack()}>
            &larr; Back to list of your products
          </span>
        )}
      </div>
      <h1>{name}</h1>
      <div className="productBody">{children}</div>

      {/* {window.location.pathname === "/products/multiple" && <BaseProductEIN />} */}
      {productURL !== "mineral" && (
        productURL !== "biz_library" ?
          <>
            <div className="productSettingCont">
              <h2>Settings</h2>
              <div className="prodSettings">
                {(productsAreFetching || productsAreUpdating) && (
                  <div className="loadCont">
                    <Loading />
                  </div>
                )}
                <div>
                  {successMsg !== "" && (
                    <div className="successMsg">{successMsg}</div>
                  )}
                  <div className="prod-settings">
                    {window.location.pathname ===
                      "/old/products/user-provisioning" &&
                      (isLoading ? (
                        <Loading />
                      ) : (
                        <div className="psrow">
                          <div className="BaseProductSetting">
                            <div className="title">Sync new employees</div>
                            <div className="settingsCont">
                              <div
                                className="bottom"
                                style={{ marginTop: "10px" }}
                              >
                                <div>
                                  <div className="selectSetting">
                                    <div className="selectCont">
                                      <select
                                        defaultValue={employeeSyncValue}
                                        onChange={handleEmployeeChange}
                                      >
                                        <option value="hired">New Hired</option>
                                        <option value="fired">New Fired</option>
                                      </select>
                                      <button onClick={handleSyncEmployee}>
                                        Sync
                                      </button>
                                    </div>
                                  </div>
                                  <div className="note">
                                    Select when you want new hires / fired'
                                    accounts to be provisioned for company
                                    integrations.
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {Object.keys(settings).map((key) =>
                      renderSetting(key, settings[key])
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showAccessControl && (
              <div className="productSettingCont">
                <h2>Access Control</h2>

                <div
                  className="tip"
                  style={{
                    fontStyle: "italic",
                    fontSize: "14px",
                    marginBottom: "10px",
                  }}
                >
                  Control which employees in your company can access this Product.
                  Only Company Administrators can access this area.
                </div>
                <div className="prodSettings accessControl">
                  <AccessControl
                    accessControls={accessControls ? accessControls : []}
                    companyProductId={companyProductId}
                  />
                </div>
              </div>
            )}
          </> : ''
      )}
    </div>
  );
};

export default BaseProduct;
