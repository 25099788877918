import React from "react";
import { Link, withRouter } from "react-router-dom";
import Loading from "./ui/Loading";
import "../styles/Dashboard.css";

// TODO should not be hardcoded
const productsAvailable = {
  [`user-provisioning`]: {
    url: "user-provisioning",
    label: "User Provisioning",
    integrations: [
      "gsuite",
      "microsoft365",
      "salesforce",
      "box",
      "dropbox",
      "activedirectory",
    ],
  },
  jobs: {
    url: "jobs",
    label: "Job Board Integrations",
    integrations: ["indeed", "ziprecruiter"],
  },
  cloudpunch: {
    url: "cloudpunch",
    label: "CloudPunch - Windows Biometric",
    integrations: null,
  },
  webclock: {
    url: "webclock",
    label: "CloudPunch - Webclock",
    integrations: null,
  },
  multiple: {
    url: "multiple",
    label: "Multiple(EIN)",
    integrations: null,
  },
  imports: {
    url: "imports",
    label: "Imports",
    integrations: null,
  },
};

const Dashboard = ({
  products,
  productsAreFetching,
  companyInfo,
  userInfo,
  removeProduct,
  addProduct,
  match,
}) => {
  let prodAvailableToAdd = { ...productsAvailable };
  if (products) {
    products.forEach((prod) => {
      delete prodAvailableToAdd[prod.product_url];
    });
  }

  return (
    <div className="dashboard">
      <h1>OnePoint Connect</h1>
      <h2>Your Products</h2>
      <div className="intsCont">
        {productsAreFetching ? (
          <Loading />
        ) : products.length > 0 ? (
          products.map((product, index) => (
            <div
              className={`intx ${product.product_url}`}
              key={`prods ${index}`}
            >
              <Link to={`${match.url}/products/${product.product_url}`}>
                <div className="title">{product.product_name}</div>
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(/images/${product.product_url}.png)`,
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                  }}
                  alt={product.product_url}
                ></div>
                <div className="desc">{product.product_description}</div>
              </Link>
            </div>
          ))
        ) : (
          <div className="">Your company has no products</div>
        )}
      </div>

      {/* <MarketPlace /> */}

      {userInfo && userInfo.is_system_admin && (
        <div className="admin" style={{ marginTop: "40px" }}>
          <h2 style={{ marginBottom: "20px" }}>System Admin Actions</h2>
          <div className="section">
            <div className="head">Add a Product To This Company</div>
            <div>
              {prodAvailableToAdd &&
                Object.keys(prodAvailableToAdd).length === 0 && (
                  <div>
                    All OnePoint Connect products have already been added
                  </div>
                )}
              {prodAvailableToAdd &&
                Object.keys(prodAvailableToAdd).length > 0 && (
                  <SelectAndSubmit
                    buttonText={"Add Product"}
                    options={Object.keys(prodAvailableToAdd).map((prodID) => ({
                      label: prodAvailableToAdd[prodID].label,
                      value: prodID,
                    }))}
                    submit={(chosenOption) => addProduct(chosenOption)}
                  />
                )}
            </div>
          </div>
          <div className="section">
            <div className="head">Deactivate a Product</div>
            <div>
              {products && products.length === 0 && (
                <div>No products have been added</div>
              )}
              {products && products.length > 0 && (
                <SelectAndSubmit
                  buttonText={"Deactivate Product"}
                  options={products.map((prod) => ({
                    label: prod.product_name,
                    value: prod.product_url,
                  }))}
                  submit={(chosenOption) => removeProduct(chosenOption)}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(Dashboard);

class SelectAndSubmit extends React.Component {
  constructor(p) {
    super(p);
    this.state = { value: null };

    this.onChange = this.onChange.bind(this);
  }

  componentWillMount() {
    const { options } = this.props;
    if (options && options.length > 0) {
      this.setState({ value: options[0].value });
    }
  }

  componentWillReceiveProps(np) {
    const { options } = this.props;
    const { options: newOptions } = np;
    if (options && newOptions) {
      if (options.length !== newOptions.length) {
        if (newOptions.length > 0) {
          this.setState({ value: newOptions[0].value });
        } else {
          this.setState({ value: null });
        }
      }
    }
  }

  onChange(selectedVal) {
    this.setState({ value: selectedVal });
  }

  render() {
    const { buttonText, options, submit } = this.props;
    const { value } = this.state;

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: "10px" }}>
          <select
            value={value ? value : ""}
            onChange={(e) => this.onChange(e.target.value)}
          >
            {options.map((opt) => (
              <option key={opt.label} value={opt.value}>
                {opt.label}
              </option>
            ))}
          </select>
        </span>
        <span>
          <button onClick={() => submit(value)}>{buttonText}</button>
        </span>
      </div>
    );
  }
}
