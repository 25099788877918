import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route } from "react-router";
import { getToken } from "../actions/auth";
import AddCompanyModel from "./AddCompanyModel";
import EditCompanyModel from "./EditCompanyModel";
import SyncCompanyModel from "./SyncCompanyModel";
import {
  fetchCPInfo,
  updateEmployee,
  deleteCPEnrollment,
  updateInstallation,
  fetchAssociations,
  searchCompanies,
  deleteAssociation,
  reActiveAssociation,
  getAssCustReportsById,
} from "../actions/cloudpunch";
import { fingerPositionNames } from "../constants/states";
import { fetchCompanyData } from "../actions/companyData";
import Loading from "./ui/Loading";
import Modal from "./ui/Modal";
import Swal from "sweetalert2";
import "../styles/CloudPunchBody.css";
import {
  getAssociationById,
  deleteAssociationCompanyById,
} from "../actions/cloudpunch";
import { Toaster } from "react-hot-toast";
import EmployeeReportModel from "./EmployeeReportModel";
import MultipleEINDataTable from "./datatable/MultipleEINDataTable";

class AddCC extends Component {
  constructor(p) {
    super(p);
    this.state = {
      value:
        p.ccsAvailable && p.ccsAvailable.length > 0
          ? p.ccsAvailable[0].id
          : null,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentWillReceiveProps(np) {
    this.setState({
      value:
        np.ccsAvailable && np.ccsAvailable.length > 0
          ? np.ccsAvailable[0].id
          : null,
    });
  }

  handleChange(event) {
    this.setState({ value: Number(event.target.value) });
  }

  render() {
    const { ccsAvailable, onAdd } = this.props;

    if (!ccsAvailable || ccsAvailable.length === 0) {
      return <div>No more remaining</div>;
    }

    return (
      <div>
        <select value={this.state.value} onChange={this.handleChange}>
          {ccsAvailable.map((cc) => (
            <option key={`ccadd${cc.id}`} value={cc.id}>
              {cc.name}
            </option>
          ))}
        </select>
        <button
          onClick={() => onAdd(this.state.value)}
          className="addDelete add"
        >
          <div>+</div>
        </button>
      </div>
    );
  }
}

class CostCenterFilters extends Component {
  constructor(p) {
    super(p);
    this.state = { filter: p.filter, editing: false };

    this.renderTreeFilters = this.renderTreeFilters.bind(this);
    this.addCCFilter = this.addCCFilter.bind(this);
    this.removeCCFilter = this.removeCCFilter.bind(this);
    this.cancelEditing = this.cancelEditing.bind(this);
    this.save = this.save.bind(this);
  }

  renderTreeFilters(thisIndexFilter, thisIndexCCs, ccIndex) {
    const { editing } = this.state;

    if (!thisIndexFilter) {
      return <div className="ccTreeList">None</div>;
    }

    const selectedIDs = thisIndexFilter.values.map((v) => v.cost_center_id);
    if (!selectedIDs || selectedIDs.length === 0) {
      return <div className="ccTreeList">None</div>;
    }

    const selectedCCs = selectedIDs.map((id) => {
      const matchedCCs = thisIndexCCs.filter((cc) => cc.id === id);
      return matchedCCs !== null && matchedCCs.length === 1
        ? matchedCCs[0]
        : null;
    });

    return (
      <div className="ccTreeList">
        {selectedCCs.map((cc) => (
          <div className="ccName" key={`selecedccs${cc.id}`}>
            {cc.name}{" "}
            {editing && (
              <button
                onClick={() => this.removeCCFilter(cc.id, ccIndex)}
                className="addDelete delete"
              >
                <div>-</div>
              </button>
            )}
          </div>
        ))}
      </div>
    );
  }

  removeCCFilter(ccID, ccIndex) {
    const { filter } = this.state;
    const newFilterIndexes = filter.indexes.map((f) => {
      if (f.index !== ccIndex) {
        return f;
      }

      const newVals = f.values.filter((f) => f.cost_center_id !== ccID);
      return { index: ccIndex, values: newVals };
    });
    this.setState({ filter: { indexes: newFilterIndexes } });
  }

  addCCFilter(ccIndex, ccID) {
    if (ccID !== null) {
      const { filter } = this.state;

      let seenThisIndex = false;
      let newFilterIndexes = filter.indexes.map((f) => {
        if (f.index !== ccIndex) {
          return f;
        }
        seenThisIndex = true;
        const newVals = [{ cost_center_id: ccID }, ...f.values];
        return { index: ccIndex, values: newVals };
      });

      if (!seenThisIndex) {
        newFilterIndexes = [
          ...newFilterIndexes,
          { index: ccIndex, values: [{ cost_center_id: ccID }] },
        ];
      }

      this.setState({ filter: { indexes: newFilterIndexes } });
    }
  }

  cancelEditing() {
    const { filter } = this.props;

    this.setState({ editing: false, filter: filter });
  }

  save() {
    if (window.confirm("Are you sure you'd like to save these settings?")) {
      const { filter } = this.state;
      this.props.updateInstallation({
        employee_filters: { cost_centers: filter },
      });
    }
  }

  render() {
    const { costCenters } = this.props;
    const { filter, editing } = this.state;

    return (
      <div>
        {costCenters.map((cc) => {
          const filterVals =
            filter.indexes.filter((f) => f.index === cc.index) &&
            filter.indexes.filter((f) => f.index === cc.index)[0];
          const ccsAvailable = cc.values.filter((cc) => {
            let foundMatch = false;
            filterVals &&
              filterVals.values.forEach((filterval) => {
                if (filterval.cost_center_id === cc.id) {
                  foundMatch = true;
                  return;
                }
              });
            if (foundMatch) {
              return false;
            }
            return true;
          });

          return (
            <div
              className="ccTreeCont"
              key={`ccfilter${cc.root_cost_center_id}`}
            >
              <div className="ccTreeName">
                <div className="name">{cc.tree_name}</div>{" "}
                {editing && (
                  <div className="addmore">
                    <span style={{ marginRight: "5px" }}>Add More:</span>{" "}
                    <AddCC
                      ccsAvailable={ccsAvailable}
                      onAdd={(newCCID) => this.addCCFilter(cc.index, newCCID)}
                    />
                  </div>
                )}
              </div>
              <div>
                {this.renderTreeFilters(filterVals, cc.values, cc.index)}
              </div>
            </div>
          );
        })}
        <div>
          {editing ? (
            <button onClick={() => this.cancelEditing()}>CANCEL</button>
          ) : (
            <button onClick={() => this.setState({ editing: !editing })}>
              EDIT
            </button>
          )}
          {editing && (
            <button onClick={() => this.save()} style={{ marginLeft: "20px" }}>
              SAVE CHANGES
            </button>
          )}
        </div>
      </div>
    );
  }
}

class InstallationFilters extends Component {
  constructor(p) {
    super(p);
    this.fetchCostCenters = this.fetchCostCenters.bind(this);
    this.updateInstallation = this.updateInstallation.bind(this);
  }

  componentWillMount() {
    const { costCenters } = this.props;

    if (costCenters === null) {
      this.fetchCostCenters();
    }
  }

  fetchCostCenters() {
    const { dispatch } = this.props;

    dispatch(fetchCompanyData("cost-centers"));
  }

  updateInstallation(updateBody) {
    const { installation, dispatch } = this.props;

    dispatch(updateInstallation(installation.token, updateBody));
  }

  render() {
    const {
      installation,
      costCenters,
      costCentersFetching,
      installIsUpdating,
    } = this.props;

    if (!installation) {
      return (
        <div className="InstallationFilters">
          <Loading />
        </div>
      );
    }

    return (
      <div className="InstallationFilters">
        <h1 className="">{`${installation.display_name} Filters`}</h1>
        <div className="head">Cost Centers</div>
        <div style={{ marginBottom: "20px" }}>
          All employees that have at least one of the following cost centers
          listed as their Default Cost Center will be able to punch in at this
          CloudPunch Installation.
        </div>
        <div>
          {costCentersFetching || installIsUpdating ? (
            <div>
              <Loading />
            </div>
          ) : !costCenters ? (
            <div>Failed to load Cost Centers</div>
          ) : (
            <div>
              <CostCenterFilters
                costCenters={costCenters}
                filter={installation.employee_filters.cost_centers}
                updateInstallation={this.updateInstallation}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToPropsFilters = (state, ownProps) => {
  const { costCenters, isFetching: cdFetching } = state.company_data;
  const { installIsUpdating } = state.cloudpunch;
  const costCentersFetching = cdFetching["cost-centers"];

  return {
    costCenters: costCenters !== undefined ? costCenters : null,
    costCentersFetching,
    installIsUpdating,
  };
};

const ConnectedInstallationFilters = connect(mapStateToPropsFilters)(
  InstallationFilters
);

class EmployeeDetail extends Component {
  render() {
    const { employee, deleteEnr } = this.props;

    if (employee === null) {
      return <div>Employee not found</div>;
    }

    return (
      <div className="EmployeeDetail">
        <h1>{`${employee.first_name} ${employee.last_name}`}</h1>
        {employee.enrollments && (
          <div>
            <div className="head">Fingerprint Enrollments</div>
            <div>
              {employee.enrollments.length === 0 ? (
                <div>
                  No fingerprint enrollments have synced from CloudPunch
                </div>
              ) : (
                <div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="col1">Finger</th>
                        <th className="col2">Status</th>
                        <th className="col3">Time Created</th>
                        <th className="col4"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {employee.enrollments.map((enr) => (
                        <tr
                          key={`enr${enr.id}`}
                          className={`enrrow ${enr.active && "active"}`}
                        >
                          <td className="col1">
                            {fingerPositionNames(enr.position)}
                          </td>
                          <td className="col2">
                            {enr.active ? "Active" : "Inactive"}
                          </td>
                          <td className="col3">{enr.created_date}</td>
                          <td className="col4 but">
                            {enr.active && (
                              <button
                                onClick={() => {
                                  if (
                                    window.confirm(
                                      "This will remove this fingerprint from all relevant CloudPunch installations, are you sure you would like to proceed?"
                                    )
                                  )
                                    deleteEnr(enr.id);
                                }}
                                className="red"
                              >
                                Delete
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

class BaseProductEIN extends Component {
  constructor(p) {
    super(p);
    this.launchEnrollmentsModal = this.launchEnrollmentsModal.bind(this);
    this.launchInstallationModal = this.launchInstallationModal.bind(this);
    this.empToggleActive = this.empToggleActive.bind(this);
    this.state = {
      associationsList: [],
      associationsListFilter: [],
      associationsSelectItem: "",
      associationId: "",
      companies: [],
      companiesList: [],
      reports: [],
      title: "",
      duplicateField: "",
      searchValue: "",
      selectSort: 10,
      isAdmin: "",
      companiesLoading: false,
      associationLoading: false,
    };
  }

  handleSetCompanyLoading = (load) => {
    this.setState({ companiesLoading: load });
  };

  async componentDidMount() {
    const { dispatch } = this.props;
    this.setState({ associationLoading: true });
    await dispatch(fetchAssociations(this.props.userInfo.company_id));
    this.fetchAssociationsList();
    const isAdmin = localStorage.getItem("isAdmin");
    this.setState({
      isAdmin: isAdmin,
      associationsList: this.props.associations,
      associationLoading: false,
    });
  }

  componentWillMount() {
    const { beenLoadedOnce, dispatch } = this.props;
    if (!beenLoadedOnce) {
      dispatch(fetchCPInfo());
    }

    const { token } = getToken();
    if (token) {
      this.setState({ token: token });
    }
  }

  launchEnrollmentsModal(empID) {
    const { match, history } = this.props;

    history.push(`${match.url}/employee/${empID}`);
  }

  launchInstallationModal(insID, type) {
    const { match, history } = this.props;

    history.push(`${match.url}/installation/${insID}/${type}`);
  }

  empToggleActive(accID, oldActive, fname) {
    let stment =
      "Are you sure you'd like to inactivate " +
      fname +
      "? This will remove all of their enrollments from every CloudPunch installation.";
    if (!oldActive) {
      stment = "Are you sure you'd like to activate " + fname + "?";
    }
    if (window.confirm(stment)) {
      const { dispatch } = this.props;
      dispatch(updateEmployee(accID));
    }
  }

  state = {
    toggle: false,
    editToggle: false,
    syncToggle: false,
    employeeModel: false,
  };

  toggleAddCompanyModal = () => {
    this.setState({ toggle: !this.state.toggle });
  };

  getCompanyById = async (id, isNew) => {
    this.setState({ companiesLoading: true });
    const { response } = await this.props.dispatch(getAssociationById(id));
    const { response: resReports } = await this.props.dispatch(
      getAssCustReportsById(id)
    );

    const newCompany = response.companies.map((elem) => {
      return {
        company_id: elem.ID,
        p_company_id: elem.p_company_id,
        name: elem.name,
        checked: true,
        short_name: elem.short_name,
      };
    });

    const isCom = isNew ? this.state.companies : [];

    const reportData = resReports ? resReports : [];

    const modifyReport = Object.values(reportData).map((item) => ({
      ...item,
      checked: false,
    }));

    // const reportData = resReports ? resReports : [];

    // const modifyReport = Object.values(reportData).map((item) => ({
    //   ...item,
    //   checked: false,
    // }));

    this.setState({
      companies: [...isCom, ...newCompany],
      title: response.title,
      companiesLoading: false,
      companiesList: [...newCompany],
      reports: [...modifyReport],
    });
  };

  toggleEditCompanyModal = async (id) => {
    this.setState({
      associationId: id,
      editToggle: !this.state.editToggle,
      duplicateField: "",
    });
    if (id) {
      this.getCompanyById(id, false);
    }
  };

  toggleSyncCompanyModal = () => {
    this.setState({ syncToggle: !this.state.syncToggle });
  };

  toggleEmployeeModel = (id) => {
    this.setState({
      associationId: id,
      employeeModel: !this.state.employeeModel,
    });
    if (id) {
      this.getCompanyById(id, false);
    }
  };

  fetchAssociationsList = async () => {
    const { response } = await this.props.dispatch(
      fetchAssociations(this.props.userInfo.company_id)
    );
    const dataList = [];
    for (let item in response) {
      dataList.push(response[item]);
    }
    this.setState({
      associationsList: dataList,
    });
  };

  deleteAssociationsItem = async (id) => {
    const data = { association_id: id };
    const result = await Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, De-Activated it!",
    });
    if (result.isConfirmed) {
      await this.props.dispatch(deleteAssociation(data));
      this.fetchAssociationsList();
      // this.props.dispatch(fetchAssociations(this.props.userInfo.company_id));
      Swal.fire("De-Activated!", "Your file has been De-Activated.", "success");
    }
  };

  reActiveAssociationsItem = async (id) => {
    const data = { association_id: id };
    const result = await Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Re Active it!",
    });
    if (result.isConfirmed) {
      await this.props.dispatch(reActiveAssociation(data));
      this.fetchAssociationsList();
      // await this.props.dispatch(fetchAssociations(this.props.userInfo.company_id));
      Swal.fire("Re-Activated!", "Your file has been Re-Activated.", "success");
    }
  };

  handleOnchange = (e) => {
    const { value } = e.target;
    this.setState({ title: value });
  };

  handleAddToList = async (data) => {
    const companyExists = this.state.companies.some(
      (company) => company.company_id === data.company_id
    );
    if (!data.company_id) {
      this.setState({
        duplicateField: "Company not found",
        company_id: "",
      });
      return;
    }

    if (companyExists) {
      this.setState({
        duplicateField: "Company already exists",
        company_id: "",
      });
      return;
    }

    this.setState({
      companies: [...this.state.companies, data],
      duplicateField: "",
    });
  };

  handleAddToListDelete = async (index) => {
    const { companies } = this.state;
    if (companies[index].isNew) {
      companies.splice(index, 1);
      this.setState({
        ...this.state,
        companies: [...companies],
      });
    } else {
      this.setState({ companiesLoading: true });
      await this.props.dispatch(
        deleteAssociationCompanyById({
          company_id: companies[index].company_id,
          association_id: this.state.associationId,
        })
      );
      companies.splice(index, 1);
      this.setState({
        ...this.state,
        companies: [...companies],
      });
      this.setState({ companiesLoading: false });
      // this.getCompanyById(this.state.associationId,false);
    }
  };

  requestSearch = (searchedVal) => {
    const filteredRows = this.props.associations.filter((row) => {
      return row.title
        .toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase());
    });
    this.setState({ associationsList: filteredRows });
  };

  handleSearch = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.requestSearch(value);
  };

  handleReportGenerate = (id) => {
    // window.open(
    //   `https://lookerstudio.google.com/reporting/5c0b2792-8f1b-4a98-a899-6a789052d361/page/ZTUJD?params=%7B%22ds0.shortname%22:%22RAUTAH,Chtu%22%7D`
    // );
    // window.open(
    // `https://datastudio.google.com/embed/reporting/e442cee9-a7ff-4d33-ac04-fc146d30a27b/page/spY5C?params=%7B%22ds0.id%22:%22${id}%22%7D`
    // );
  };

  handleSelectSort = (e) => {
    const { value } = e.target;
    const sortRows = this.props.associations.slice(0, value);
    this.setState({ associationsList: sortRows, selectSort: value });
  };

  handleChangeEmployeeReport = async (e, index) => {
    this.state.companiesList[index].checked =
      !this.state.companiesList[index].checked;

    // const response = await this.props.dispatch(
    //   getEmployeeReportCatch(this.state.companiesList[index].short_name)
    // );

    this.setState({
      companiesList: [...this.state.companiesList],
    });
  };

  handleReportNameChange = (index) => (e) => {
    const { checked } = e.target;
    const { reports } = this.state;
    let copy = [...reports];
    copy = copy.map((item) => ({ ...item, checked: false }));
    copy[index].checked = checked;
    this.setState({ reports: copy });
  };

  render() {
    const {
      allFetching,
      installations,
      employees,
      dispatch,
      installsFetching,
      history,
      companyInfo,
      userInfo,
      match,
    } = this.props;
    const { associationLoading, companiesLoading } = this.state;

    return (
      <div className="CloudPunchBody pis">
        <Toaster position="top-right" reverseOrder={false} />
        <Route
          path={`${match.url}/employee/:empID`}
          render={(props) => (
            <Modal closeModal={() => history.push(match.url)}>
              <div>
                <EmployeeDetail
                  deleteEnr={(id) => dispatch(deleteCPEnrollment(id))}
                  empID={props.match.params.empID}
                  employee={
                    employees.filter(
                      (e) => e.id === Number(props.match.params.empID)
                    ).length !== 0
                      ? employees.filter(
                          (e) => e.id === Number(props.match.params.empID)
                        )[0]
                      : null
                  }
                />
              </div>
            </Modal>
          )}
        />
        <Route
          path={`${match.url}/installation/:insID/filters`}
          render={(props) => (
            <Modal closeModal={() => history.push(match.url)}>
              <div>
                <ConnectedInstallationFilters
                  insID={props.match.params.insID}
                  installation={
                    installations.filter(
                      (e) => e.id === Number(props.match.params.insID)
                    ).length !== 0
                      ? installations.filter(
                          (e) => e.id === Number(props.match.params.insID)
                        )[0]
                      : null
                  }
                />
              </div>
            </Modal>
          )}
        />
        {allFetching || associationLoading ? (
          <Loading />
        ) : (
          <div>
            <div className="section installations">
              {installsFetching && (
                <div className="loadCont">
                  <Loading />
                </div>
              )}
              <h2>
                <div className="d-flexcstm">
                  <div className="col">Associations</div>
                </div>
              </h2>
              <div className="tableCont0">
                <MultipleEINDataTable
                  userInfo={userInfo}
                  row={this.state.associationsList}
                  copyRow={this.state.associationsList}
                  isAdmin={this.state.isAdmin}
                  navigate={this.props.history.push}
                  toggleEditCompanyModal={this.toggleEditCompanyModal}
                  deleteAssociationsItem={this.deleteAssociationsItem}
                  reActiveAssociationsItem={this.reActiveAssociationsItem}
                  toggleEmployeeModel={this.toggleEmployeeModel}
                  toggleAddCompanyModal={this.toggleAddCompanyModal}
                />
              </div>
            </div>
          </div>
        )}

        {/* {this.toggleModal &&
            <>
        <div className="">
        <h1 className="">
        </h1>
        <div className="head">
          Cost Centers
        </div>
        <div style={{ marginBottom: '20px' }}>
          All employees that have at least one of the following cost centers listed as their Default Cost Center will be able to punch in at this CloudPunch Installation.
        </div>
        <div>
            (
            <div>
            
                some text
            </div>
            )
        </div>
       </div>
            </>
       } */}

        <AddCompanyModel
          Modaltoggle={this.toggleAddCompanyModal}
          toggleModal={this.state.toggle}
          companyInfo={companyInfo}
          userInfo={userInfo}
          dispatch={dispatch}
          searchCompanies={(company_name) =>
            dispatch(searchCompanies(company_name))
          }
          associationspostError={this.props.associationspostError}
          fetchAssociationsList={this.fetchAssociationsList}
        />
        <EditCompanyModel
          Modaltoggle={this.toggleEditCompanyModal}
          companiesLoading={companiesLoading}
          duplicateField={this.state.duplicateField}
          toggleModal={this.state.editToggle}
          associationId={this.state.associationId}
          companies={this.state.companies}
          title={this.state.title}
          dispatch={dispatch}
          reports={this.state.reports}
          handleOnchange={this.handleOnchange}
          handleAddToList={this.handleAddToList}
          handleAddToListDelete={this.handleAddToListDelete}
          pSetState={this.setState}
          editAssociationsCompanyError={this.props.editAssociationsCompanyError}
          searchCompanies={(company_name) =>
            dispatch(searchCompanies(company_name))
          }
          fetchAssociationsList={this.fetchAssociationsList}
          userInfo={this.props.userInfo}
          companyInfo={companyInfo}
        />
        <SyncCompanyModel
          Modaltoggle={this.toggleSyncCompanyModal}
          toggleModal={this.state.syncToggle}
        />
        <EmployeeReportModel
          dispatch={dispatch}
          Modaltoggle={this.toggleEmployeeModel}
          toggleModal={this.state.employeeModel}
          companies={this.state.companiesList}
          reports={this.state.reports}
          handleReportNameChange={this.handleReportNameChange}
          title={this.state.title}
          isLoading={this.state.companiesLoading}
          handleSetCompanyLoading={this.handleSetCompanyLoading}
          handleChange={this.handleChangeEmployeeReport}
          associationId={this.state.associationId}
          companyInfo={companyInfo}
          userInfo={this.props.userInfo}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { cp_installations, cp_employees, cp_enrollments } = state.entities;
  const {
    beenLoadedOnce,
    allFetching,
    installationsList,
    employeesList,
    installsFetching,
    empsAreFetching,
    associationsList,
    associationspostError,
    editAssociationsCompanyError,
  } = state.cloudpunch;
  const { costCenters, isFetching: cdFetching } = state.company_data;
  const { companyInfo, userInfo } = state.products;
  const costCentersFetching = cdFetching["cost-centers"];

  const instsListJoin =
    installationsList === undefined
      ? []
      : installationsList.map((id) => {
          let thisIn = Object.assign({}, cp_installations[id]);
          return thisIn;
        });

  const empsListJoin =
    employeesList === undefined
      ? []
      : employeesList.map((id) => {
          let thisIn = Object.assign({}, cp_employees[id]);
          thisIn.enrollments = thisIn.enrollments.map((id) => {
            {
              let thisEn = Object.assign({}, cp_enrollments[id]);
              return thisEn;
            }
          });
          return thisIn;
        });

  return {
    beenLoadedOnce,
    allFetching,
    installations: instsListJoin,
    associations: associationsList,
    employees: empsListJoin,
    installsFetching,
    empsAreFetching,
    companyInfo,
    userInfo,
    costCenters: costCenters !== undefined ? costCenters : null,
    costCentersFetching,
    associationspostError,
    editAssociationsCompanyError,
  };
};

export default withRouter(connect(mapStateToProps)(BaseProductEIN));
