import React from "react";

const ProgressBar = ({ bgcolor, progress, height }) => {
  const ParentDiv = {
    height: height,
    width: "100%",
    backgroundColor: "whitesmoke",
  };

  const ChildDiv = {
    height: height,
    width: `${progress === 0 ? 5 : progress}%`,
    backgroundColor: bgcolor,
    // borderRadius: 40,
    textAlign: "right",
  };

  return (
    <div>
      <div style={ParentDiv}>
        <div
          className={`progress-bar ${progress !== 100 && "loading"}`}
          style={ChildDiv}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
