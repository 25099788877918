/* eslint-disable no-undef */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import Loading from "../components/ui/Loading";

import { postOauthCode } from "../actions/oauth";

import "../styles/CallBackPage.css";

class IntegrationsContainer extends Component {
  constructor(props) {
    super(props);
    this.state = { error: false };

    this.getRedirectURL = this.getRedirectURL.bind(this);
  }

  componentWillMount() {
    const { match, location, dispatch } = this.props;
    const callbackName = match.params.callbackName;

    if (callbackName === "" || callbackName === null) {
      console.log("empty callbackName");
      return;
    }

    const parsed = queryString.parse(location.search);
    let authCode = parsed.code;
    const authError = parsed.error;

    if (authError !== undefined) {
      this.setState({ error: true });
      return;
    }

    if (authCode === undefined) {
      authCode = parsed.tenant;
    }

    dispatch(postOauthCode(authCode, callbackName));
  }

  componentWillReceiveProps(newProps) {
    const { success: oldSuccess } = this.props;
    const { success: newSuccess, history, match } = newProps;
    const callbackName = match.params.callbackName;

    if (!oldSuccess && newSuccess) {
      history.replace(this.getRedirectURL(callbackName));
    }
  }

  backToInt() {
    const { match, history } = this.props;
    const callbackName = match.params.callbackName;
    history.replace(this.getRedirectURL(callbackName));
  }

  getRedirectURL(callbackName) {
    if (callbackName === "googlehire") {
      return "/products/googlehire";
    }
    return "/app/integrations/" + callbackName;
  }

  render() {
    const { isPosting, error } = this.props;
    const { error: stateError } = this.state;

    if (stateError) {
      return (
        <div className="callbackPage">
          <div className="errorBox">
            <div className="error">Connection failed.</div>
            <div className="linkBox">
              <button onClick={() => this.backToInt()}>
                GO BACK TO INTEGRATION PAGE
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="callbackPage">
        {error !== "" && (
          <div className="errorBox">
            <div className="error">Error: {error}</div>
            <div className="linkBox">
              <button onClick={() => this.backToInt()}>
                GO BACK TO INTEGRATION PAGE
              </button>
            </div>
          </div>
        )}
        {isPosting && (
          <div>
            <Loading />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { error, isPosting, success } = state.oauth;
  return { error, isPosting, success };
};

export default withRouter(connect(mapStateToProps)(IntegrationsContainer));
