import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "../ui/Loading";
import { connect } from "react-redux";

import { fetchCompanyData } from "../../actions/companyData";

import "../../styles/EmployeeChooser.css";

class CostCenterFilterSetting extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    setting: PropTypes.object.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { selectedCC: "", addMore: false };

    this.handleChoiceChange = this.handleChoiceChange.bind(this);
    this.submitAdd = this.submitAdd.bind(this);
    this.removeCC = this.removeCC.bind(this);
    this.handleCCSelection = this.handleCCSelection.bind(this);
    this.changeAddState = this.changeAddState.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { setting: oldSetting, costCenters: oldCostCenters } = this.props;
    const { setting: newSetting, costCenters: newCostCenters } = newProps;

    // set initial choice
    if (
      oldSetting.all_employees === true &&
      newSetting.all_employees === false
    ) {
      if (newCostCenters === null) {
        this.fetchData();
        this.setState({ addMore: true });
      }
    }

    if (oldCostCenters === null && newCostCenters !== null) {
      if (newCostCenters.length !== 0) {
        if (newCostCenters[0].values.length !== 0) {
          this.setState({
            selectedCC: newCostCenters[0].values[0].id.toString(),
          });
        }
      }
    }
  }

  componentWillMount() {
    const { costCenters } = this.props;

    if (costCenters !== null) {
      if (costCenters.length !== 0) {
        if (costCenters[0].values.length !== 0) {
          this.setState({ selectedCC: costCenters[0].values[0].id.toString() });
        }
      }
    }
  }

  fetchData() {
    const { dispatch } = this.props;

    dispatch(fetchCompanyData("cost-centers"));
  }

  handleChoiceChange(event) {
    const { setting, onChange } = this.props;
    const newChoiceBool = event.target.value === "true" ? true : false;

    onChange({ ...setting, all_employees: newChoiceBool });
  }

  handleCCSelection(event) {
    this.setState({ selectedCC: event.target.value });
  }

  changeAddState() {
    const { costCenters } = this.props;
    const { addMore } = this.state;

    if (addMore === false && costCenters === null) {
      this.fetchData();
    }

    this.setState({ addMore: !addMore });
  }

  removeCC(ccID) {
    const { onChange, setting } = this.props;

    const newSett = Object.assign({}, setting);
    const newCCs = newSett.ccs.filter((cc) => cc.id !== ccID);

    onChange({ ...setting, ccs: newCCs });
  }

  submitAdd(selectedCCID) {
    const { setting, onChange, costCenters } = this.props;

    let exit = false;
    setting.ccs.forEach((cc) => {
      if (cc.id.toString() === selectedCCID) {
        exit = true;
        return;
      }
    });

    if (exit) {
      return;
    }
    let selectedCC = null;
    Array.isArray(costCenters) &&
      costCenters.forEach(
        (cct) =>
          Array.isArray(cct.values) &&
          cct.values.forEach((cc) => {
            if (cc.id.toString() === selectedCCID) {
              selectedCC = Object.assign({}, cc);
            }
          })
      );

    if (selectedCC === null) {
      console.log("didnt find");
      return;
    }

    const newCCs = setting.ccs.concat(selectedCC);

    onChange({ ...setting, ccs: newCCs });
  }

  render() {
    const { setting, isFetching, costCenters } = this.props;
    const { selectedCC, addMore } = this.state;

    return (
      <div className="eChooser ccChooser">
        {isFetching && (
          <div className="loadCont">
            <Loading />
          </div>
        )}
        <div>
          <select
            value={setting.all_employees}
            onChange={this.handleChoiceChange}
          >
            <option key={"ccc1"} value={"true"}>
              Provision all employees
            </option>
            <option key={"ccc2"} value={"false"}>
              Provision employees in selected cost centers only
            </option>
          </select>
        </div>
        <div className={isFetching ? "mainfetching" : ""}>
          {!setting.all_employees && setting.ccs.length !== 0 && (
            <div className="activeEmps nodeWithXCont">
              {setting.ccs.map((cc) => (
                <div className="nodeWithX" key={cc.id}>
                  {cc.name}
                  <button onClick={() => this.removeCC(cc.id)}>X</button>
                </div>
              ))}
            </div>
          )}
          {!setting.all_employees &&
            (addMore ? (
              <div>
                <div className="top">
                  {!setting.all_employees && (
                    <div>
                      <div>Select a Cost Center to add:</div>
                      <select
                        value={selectedCC}
                        onChange={this.handleCCSelection}
                      >
                        {costCenters !== null &&
                          costCenters.map(
                            (costCenterTree) =>
                              costCenterTree.values &&
                              costCenterTree.values.map((cc, index) => (
                                <option key={"usj" + index} value={cc.id}>
                                  {cc.name}
                                </option>
                              ))
                          )}
                      </select>
                      {selectedCC !== "" && !setting.all_employees && (
                        <button
                          className="addCC"
                          onClick={() => this.submitAdd(selectedCC)}
                        >
                          ADD
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div>
                <button onClick={() => this.changeAddState()}>
                  ADD COST CENTERS
                </button>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { costCenters, isFetching } = state.company_data;

  const ccsfetching = isFetching["cost-centers"];

  return {
    costCenters: costCenters !== undefined ? costCenters : null,
    isFetching: ccsfetching,
  };
};

export default connect(mapStateToProps)(CostCenterFilterSetting);
