import * as ProdTypes from "../actions/products";

const initialState = {
  error: "",
  isFetching: false,
  list: [],
  beenFetched: false,
  companyInfo: null,
  isUpdating: false,
  updatingError: "",
  productsUpdate: false,
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case ProdTypes.PRODUCTS_EDIT_REQUEST:
      return Object.assign({}, state, {
        productsUpdate: true,
      });
    case ProdTypes.PRODUCTS_EDIT_SUCCESS:
      return Object.assign({}, state, {
        productsUpdate: false,
      });
    case ProdTypes.PRODUCTS_REQUEST:
      return Object.assign({}, state, {
        error: "",
        isFetching: true,
      });
    case ProdTypes.PRODUCTS_SUCCESS:
      return Object.assign({}, state, {
        error: "",
        isFetching: false,
        beenFetched: true,
        list: action.response.result.products,
        companyInfo: action.response.result.company,
        userInfo: action.response.result.user,
      });
    case ProdTypes.PRODUCTS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
      });
    case ProdTypes.PRODUCT_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        isUpdating: true,
        updatingError: "",
      });
    case ProdTypes.PRODUCT_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        updatingError: "",
      });
    case ProdTypes.PRODUCT_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        isUpdating: false,
        updatingError: action.error,
      });
    default:
      return state;
  }
}
