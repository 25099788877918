import { CALL_API } from "../middleware/api";

export const ADD_REPORT = "ADD_REPORT";
export const LOADING_REPORT = "LOADING_REPORT";
export const NEXT_PAGE_REPORT = "NEXT_PAGE_REPORT";
export const PREV_PAGE_REPORT = "PREV_PAGE_REPORT";
export const SELECT_CHANGE_PAGE_REPORT = "SELECT_CHANGE_PAGE_REPORT";

export const CUSTOM_REPORT_SCHEDULER_CONFIGURATION_SUCCESS =
  "CUSTOM_REPORT_SCHEDULER_CONFIGURATION_SUCCESS";

export const CUSTOM_REPORT_SCHEDULER_CONFIGURATION_REQUEST =
  "CUSTOM_REPORT_SCHEDULER_CONFIGURATION_REQUEST";

export const CUSTOM_REPORT_SCHEDULER_CONFIGURATION_FAILURE =
  "CUSTOM_REPORT_SCHEDULER_CONFIGURATION_FAILURE";

export const GET_CUSTOM_REPORT_SCHEDULER_CONFIGURATION_SUCCESS =
  "GET_CUSTOM_REPORT_SCHEDULER_CONFIGURATION_SUCCESS";

export const GET_CUSTOM_REPORT_SCHEDULER_CONFIGURATION_REQUEST =
  "GET_CUSTOM_REPORT_SCHEDULER_CONFIGURATION_REQUEST";

export const GET_CUSTOM_REPORT_SCHEDULER_CONFIGURATION_FAILURE =
  "GET_CUSTOM_REPORT_SCHEDULER_CONFIGURATION_FAILURE";

export const UPLOAD_CUSTOM_REPORT_SUCCESS = "UPLOAD_CUSTOM_REPORT_SUCCESS";
export const UPLOAD_CUSTOM_REPORT_REQUEST = "UPLOAD_CUSTOM_REPORT_REQUEST";
export const UPLOAD_CUSTOM_REPORT_FAILURE = "UPLOAD_CUSTOM_REPORT_FAILURE";

export const IMPORT_COMPANY_SFTP_SUCCESS = "UPLOAD_CUSTOM_REPORT_SUCCESS";
export const IMPORT_COMPANY_SFTP_REQUEST = "UPLOAD_CUSTOM_REPORT_REQUEST";
export const IMPORT_COMPANY_SFTP_FAILURE = "UPLOAD_CUSTOM_REPORT_FAILURE";

export const IMPORT_OPTION_SFTP_SUCCESS = "UPLOAD_CUSTOM_REPORT_SUCCESS";
export const IMPORT_OPTION_SFTP_REQUEST = "UPLOAD_CUSTOM_REPORT_REQUEST";
export const IMPORT_OPTION_SFTP_FAILURE = "UPLOAD_CUSTOM_REPORT_FAILURE";

export const GET_IMPORT_OPTION_SFTP_SUCCESS = "UPLOAD_CUSTOM_REPORT_SUCCESS";
export const GET_IMPORT_OPTION_SFTP_REQUEST = "UPLOAD_CUSTOM_REPORT_REQUEST";
export const GET_IMPORT_OPTION_SFTP_FAILURE = "UPLOAD_CUSTOM_REPORT_FAILURE";

const testSFTPConnectionx = (body) => ({
  [CALL_API]: {
    types: [
      GET_IMPORT_OPTION_SFTP_SUCCESS,
      GET_IMPORT_OPTION_SFTP_REQUEST,
      GET_IMPORT_OPTION_SFTP_FAILURE,
    ],
    endpoint: `api/tlm/test/sftp/connection`,
    postdata: body,
  },
});

export const testSFTPConnection = (body) => (dispatch) => {
  return dispatch(testSFTPConnectionx(body));
};

const deleteImportOptionByIdSFTPx = (id) => ({
  [CALL_API]: {
    types: [
      GET_IMPORT_OPTION_SFTP_SUCCESS,
      GET_IMPORT_OPTION_SFTP_REQUEST,
      GET_IMPORT_OPTION_SFTP_FAILURE,
    ],
    endpoint: `api/tlm/delete/imports/company/sftp/${id}`,
  },
});

export const deleteImportOptionByIdSFTP = (id) => (dispatch) => {
  return dispatch(deleteImportOptionByIdSFTPx(id));
};

const getImportOptionByIdSFTPx = (id) => ({
  [CALL_API]: {
    types: [
      GET_IMPORT_OPTION_SFTP_SUCCESS,
      GET_IMPORT_OPTION_SFTP_REQUEST,
      GET_IMPORT_OPTION_SFTP_FAILURE,
    ],
    endpoint: `api/tlm/imports/company/sftp/${id}`,
  },
});

export const getImportOptionByIdSFTP = (id) => (dispatch) => {
  return dispatch(getImportOptionByIdSFTPx(id));
};

const getImportOptionSFTPx = () => ({
  [CALL_API]: {
    types: [
      GET_IMPORT_OPTION_SFTP_SUCCESS,
      GET_IMPORT_OPTION_SFTP_REQUEST,
      GET_IMPORT_OPTION_SFTP_FAILURE,
    ],
    endpoint: `api/tlm/all/imports/company/sftp`,
  },
});

export const getImportOptionSFTP = () => (dispatch) => {
  return dispatch(getImportOptionSFTPx());
};

const importOptionSFTPx = () => ({
  [CALL_API]: {
    types: [
      IMPORT_OPTION_SFTP_SUCCESS,
      IMPORT_OPTION_SFTP_REQUEST,
      IMPORT_OPTION_SFTP_FAILURE,
    ],
    endpoint: `api/tlm/all/import/options`,
  },
});

export const importOptionSFTP = () => (dispatch) => {
  return dispatch(importOptionSFTPx());
};

const importCompanySFTPx = (body) => ({
  [CALL_API]: {
    types: [
      IMPORT_COMPANY_SFTP_REQUEST,
      IMPORT_COMPANY_SFTP_SUCCESS,
      IMPORT_COMPANY_SFTP_FAILURE,
    ],
    endpoint: `api/tlm/imports/company/sftp`,
    postdata: body,
  },
});

export const importCompanySFTP = (body) => (dispatch) => {
  return dispatch(importCompanySFTPx(body));
};

const uploadCustomReportx = (body) => ({
  [CALL_API]: {
    types: [
      UPLOAD_CUSTOM_REPORT_SUCCESS,
      UPLOAD_CUSTOM_REPORT_REQUEST,
      UPLOAD_CUSTOM_REPORT_FAILURE,
    ],
    endpoint: `api/tlm/upload/custom/report`,
    postdata: body,
  },
});

export const uploadCustomReport = (body) => (dispatch) => {
  return dispatch(uploadCustomReportx(body));
};

const getCustomReportSchedulerConfigurationx = (
  companyId,
  reportId,
  eventName
) => ({
  [CALL_API]: {
    types: [
      GET_CUSTOM_REPORT_SCHEDULER_CONFIGURATION_SUCCESS,
      GET_CUSTOM_REPORT_SCHEDULER_CONFIGURATION_REQUEST,
      GET_CUSTOM_REPORT_SCHEDULER_CONFIGURATION_FAILURE,
    ],
    endpoint: `api/tlm/get/custom/reports/schedular/conf/${companyId}/${reportId}/${eventName}`,
  },
});

export const getCustomReportSchedulerConfiguration =
  (companyId, reportId, eventName) => (dispatch) => {
    return dispatch(
      getCustomReportSchedulerConfigurationx(companyId, reportId, eventName)
    );
  };

const customReportSchedulerConfigurationx = (body) => ({
  [CALL_API]: {
    types: [
      CUSTOM_REPORT_SCHEDULER_CONFIGURATION_SUCCESS,
      CUSTOM_REPORT_SCHEDULER_CONFIGURATION_REQUEST,
      CUSTOM_REPORT_SCHEDULER_CONFIGURATION_FAILURE,
    ],
    endpoint: `api/tlm/set/custom/reports/schedular/conf`,
    postdata: body,
  },
});

export const customReportSchedulerConfiguration = (body) => (dispatch) => {
  return dispatch(customReportSchedulerConfigurationx(body));
};

export function addReport(data) {
  return {
    type: ADD_REPORT,
    payload: data,
  };
}

export function selectChangePageReport(data) {
  return {
    type: SELECT_CHANGE_PAGE_REPORT,
    payload: data,
  };
}

export function nextPageReport() {
  return {
    type: NEXT_PAGE_REPORT,
  };
}

export function prevPageReport() {
  return {
    type: PREV_PAGE_REPORT,
  };
}

export function loadingReport(data) {
  return {
    type: LOADING_REPORT,
    payload: data,
  };
}
