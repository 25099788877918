import React, { useState, useEffect } from "react";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  documentMap,
  integrationDocumentKeyMapping,
} from "../../../constants/constant";
import ReactQuill from "react-quill";
import useToggle from "../../hooks/useToggle";
import { connect } from "react-redux";
import useFetch from "../../hooks/useFetch";
import Loading from "../../../components/ui/Loading";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

const IntegrationDocument = (props) => {
  const {
    integrationDoc,
    handleIntegrationDoc,
    integrationName,
    userAdminPermission,
  } = props;

  const integrationDocumentKey = integrationDocumentKeyMapping[integrationName];
  const [editable, handleEditable] = useToggle(false);
  const [editorValue, setEditorValue] = useState("");
  const [fetchData, loading] = useFetch();
  const [saveIntegration, loadingIntegration] = useFetch();

  const handleChangeEditor = (value) => {
    setEditorValue(value);
  };
console.log('loadingIntegration',editorValue);
  const handleSave = () => {
    var formData = new FormData();
    formData.append("content_type", integrationDocumentKey);
    formData.append("contents", editorValue);

    const payload = {
      endPoint: "/api/comp/customize/content",
      method: "POST",
      body: formData,
      formData: true,
    };
    saveIntegration(payload);
    handleEditable();
  };

  const fetchIntegrationData = async () => {
    const res = await fetchData({
      endPoint: `/api/get/comp/customize/content/${integrationDocumentKey}`,
      method: "get",
    });
    if (res && res.contents) {
      setEditorValue(res.contents);
    }
  };

  useEffect(() => {
    if (integrationDoc) {
      fetchIntegrationData();
    }
  }, [integrationDoc]);

  return (
    <div className={`dashboard-integration-doc ${integrationDoc && "open"}`}>
      <div>
        <span className="icon-btn" onClick={handleIntegrationDoc}>
          <FontAwesomeIcon icon={solid("xmark")} />
        </span>
        {userAdminPermission && (
          <span className="icon-btn" onClick={handleEditable}>
            <FontAwesomeIcon icon={solid("pen-to-square")} />
          </span>
        )}
      </div>
      <div className="integration-document">
        <h1>Document integration</h1>
        {loading || loadingIntegration ? (
          <Loading />
        ) : editable ? (
          <div>
            <ReactQuill
              value={editorValue}
              theme="snow"
              onChange={handleChangeEditor}
              modules={modules}
              formats={formats}
            />
            <div className="mt-2 my-flex my-justify-content-end">
              <button className="my-btn me-2" onClick={handleEditable}>
                Cancel
              </button>
              <button className="my-btn" onClick={handleSave}>
                Save
              </button>
            </div>
          </div>
        ) : documentMap[integrationName] ? (
          <div dangerouslySetInnerHTML={{ __html: editorValue }}></div>
        ) : (
          <h1>Integration not found!</h1>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userAdminPermission } = state.dashboard;
  return { userAdminPermission };
};

export default connect(mapStateToProps)(IntegrationDocument);
