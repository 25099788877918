import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import * as yup from "yup";

import useForm from "../../hooks/useForm";
import useFetch from "../../hooks/useFetch";
import useToggle from "../../hooks/useToggle";
import { customReportSchedulerConfiguration } from "../../../actions/customReport";
import {
  convertToISO,
  convertToUTC,
  dateConvertToUTC,
  getNextDayOfTheWeek,
} from "../../../utils/utils";
import CheckBox from "../UI/CheckBox";
import SchedulerTypeContainer from "../imports/SchedulerTypeContainer";
import Loading from "../../../components/ui/Loading";
import moment from "moment";
import ImportSFTPConnectionTest from "../imports/ImportSFTPConnectionTest";
import CustomCompanyReportSFTP from "./CustomCompanyReportSFTP";
import SFTPCompanyDataTable from "./SFTPCompanyDatatable";
import toast from "react-hot-toast";

const defaultValue = {
  event: "csv",
  host: "",
  port: 22,
  password: "",
  username: "",
  remote_file_path: "",
  period: "day",
  typeId: "",
  day: "",
  interval_time: "",
  interval_date: "",
  logType: "All",
  dateFormat: "MMDDYYYY_2",
  companySFTP: [],
  fileName: "",
};

const configurationValueSchema = yup.object().shape({
  host: yup.string().required(),
  port: yup.number().required(),
  password: yup.string().required(),
  username: yup.string().required(),
  remote_file_path: yup.string().required(),
  period: yup.string(),
  typeId: yup.string(),
  day: yup.string().when("period", {
    is: (period) => period === "weekly" || period === "biweekly",
    then: (schema) => schema.required(),
  }),
  interval_time: yup.string().required(),
  interval_date: yup.string().when("period", {
    is: (period) => period === "one-time" || period === "monthly",
    then: (schema) => schema.required(),
  }),
  logType: yup.string(),
  dateFormat: yup.string(),
  companySFTP: yup.array(),
  fileName: yup.string(),
});

const connectValueSchema = yup.object().shape({
  host: yup.string().required(),
  port: yup.number().required(),
  password: yup.string().required(),
  username: yup.string().required(),
  remote_file_path: yup.string().required(),
});

// export format file name
const exportFileNames = [
  {
    title: `filename{MMDDYYYY}`,
    value: `MMDDYYYY_2`,
  },
  {
    title: `filename{MM-DD-YYYY}`,
    value: `MM-DD-YYYY_2`,
  },
  {
    title: `filename{MM.DD.YYYY}`,
    value: `MM.DD.YYYY_2`,
  },
  {
    title: `{MMDDYYYY}filename`,
    value: `MMDDYYYY_1`,
  },
  {
    title: `{MM-DD-YYYY}filename`,
    value: `MM-DD-YYYY_1`,
  },
  {
    title: `{MM.DD.YYYY}filename`,
    value: `MM.DD.YYYY_1`,
  },
];

const CustomReportScheduler = (props) => {
  const {
    history,
    dispatch,
    match: { params },
    importLoading,
    userInfo,
  } = props;

  const {
    formValue,
    handleChange,
    handleSubmit,
    errors,
    handleSetFormValue,
    handleSchemaCheck,
    append,
  } = useForm({
    defaultValue,
    validationSchema: configurationValueSchema,
  });
  const [fetchCustomReport, getSFTPLoading] = useFetch();
  const [toggleState, handleToggle] = useToggle(false);
  const [getAssociationById, , response] = useFetch();

  // fetch the types from API
  const fetchOption = async () => {
    try {
      const payload = {
        endPoint: `/api/tlm/cp/association/companies/${params.id}`,
      };
      getAssociationById(payload);
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSubmit = async (data) => {
    try {
      console.log("onSubmit");
      const dateFormatValue = formValue.dateFormat.split("_");

      const body = {
        event: formValue.event,
        association_id: Number(params.id),
        report_id: Number(params.reportId),
        period: formValue.period,
        interval: 1,
        day: formValue.day,
        interval_date: !isEmpty(formValue.interval_date)
          ? dateConvertToUTC(formValue.interval_date)
          : "",
        interval_time: convertToUTC(formValue.interval_time),
        global_sftp: {
          host: formValue.host,
          username: formValue.username,
          password: formValue.password,
          port: Number(formValue.port),
          remote_file_path: formValue.remote_file_path,
        },
        company_sftp: formValue.companySFTP.map((item) => ({
          ...item,
          company_id: Number(item.company_id),
          port: Number(item.port),
        })),
        email_notification_settings: `${JSON.stringify({
          log_types: formValue.logType,
          email_recipient: [],
        })}`,
        file_format_settings: `${JSON.stringify({
          file_name: formValue.fileName,
          date_format: dateFormatValue[0],
          is_first: dateFormatValue[1] === "1" ? "1" : "0",
        })}`,
        company_id: userInfo.company_id,
      };

      if (params.id) {
        body.id = Number(params.id);
      }

      if (data.period === "biweekly") {
        body.interval_date = moment(
          getNextDayOfTheWeek(data.day, false)
        ).format("YYYY-MM-DD");
      }

      let { response } = await dispatch(
        customReportSchedulerConfiguration(body)
      );

      response = Object.values(response).join("");

      console.log("response", response);

      if (response.toLowerCase() === "success") {
        toast.success("Updated successfully!");
        history.goBack();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // fetch the edit
  const fetchEditRecord = async () => {
    try {
      const payload = {
        endPoint: `/api/tlm/get/custom/reports/schedular/conf/${params.id}/${params.reportId}/${formValue.event}`,
      };
      let response = await fetchCustomReport(payload);

      console.log("response", response);
      if (response) {
        const stateBody = {};
        const fileFormatSettings = JSON.parse(
          response.event_config.file_format_settings
        );

        const emailSettings = JSON.parse(
          response.event_config.email_notification_settings
        );

        const formatCondition =
          fileFormatSettings.is_first === "1" ? "_1" : "_2";

        const dateFormat = fileFormatSettings.date_format + formatCondition;

        stateBody.companySFTP = response.company_sftp_config;
        stateBody.interval_time = convertToISO(
          response.event_config.interval_time
        );
        stateBody.company_id = response.event_config.company_id;
        stateBody.association_id = response.event_config.association_id;
        stateBody.day = response.event_config.day;
        stateBody.event = response.event_config.event;
        stateBody.interval = response.event_config.interval;
        stateBody.interval_date = response.event_config.interval_date;
        stateBody.period = response.event_config.period;
        stateBody.report_id = response.event_config.report_id;

        stateBody.dateFormat = dateFormat;
        stateBody.logType = emailSettings.log_types;
        stateBody.fileName = fileFormatSettings.file_name;

        stateBody.port = response.global_sftp_config.port;
        stateBody.host = response.global_sftp_config.host;
        stateBody.remote_file_path =
          response.global_sftp_config.remote_file_path;
        stateBody.username = response.global_sftp_config.username;
        stateBody.id = response.global_sftp_config.id;

        handleSetFormValue(stateBody);
      } else {
        handleSetFormValue({ ...defaultValue, event: formValue.event });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const QuickConnect = async () => {
    try {
      const checkError = await handleSchemaCheck(connectValueSchema, {
        host: formValue.host,
        port: formValue.port,
        password: formValue.password,
        username: formValue.username,
        remote_file_path: formValue.remote_file_path,
      });
      if (checkError) {
        handleToggle();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleAddSFTPCompany = (data) => {
    console.log("data", data);
    append("companySFTP", data);
  };

  // this method find company by company ID
  const findCompanyName = (id) => {
    let data =
      !isEmpty(response) && Array.isArray(response.companies)
        ? response.companies.find(({ ID }) => ID === Number(id))
        : "";
    return data;
  };

  useEffect(() => {
    fetchEditRecord();
  }, [formValue.event]);

  useEffect(() => {
    fetchOption();
  }, []);

  // console.log("formValue", formValue);
  // console.log("errors", errors);
  // console.log("response", response);

  return (
    <div className="configuration-multiple-ein">
      <div className="product-modal-content">
        {toggleState && (
          <ImportSFTPConnectionTest
            handleToggle={handleToggle}
            formValue={formValue}
            dispatch={dispatch}
            userInfo={userInfo}
            reportType="import_report"
            reportId={!isEmpty(params.id) ? params.id : null}
          />
        )}
        <div>
          <span onClick={history.goBack} className="back-btn">
            ← Back to list of your associations custom reports
          </span>
        </div>
        <div className="import-schedule-report heading-report-scheduler">
          <h1>Association Custom Reports Scheduler</h1>
          {getSFTPLoading || importLoading.loading ? (
            <Loading />
          ) : (
            <div className="imp-container-report">
              <div className="my-flex mb-2">
                <div className="one-col-1 my-flex my-align-item-center sftp-section ">
                  <strong>
                    Global SFTP <span className="color-red">*</span>
                  </strong>
                </div>
                <div className="one-col-11">
                  <div className="my-flex">
                    <div className="one-col-2 sftp-input">
                      <div className="one-control-input">
                        <input
                          type="text"
                          placeholder="Host"
                          onChange={handleChange}
                          name="host"
                          value={formValue.host}
                        />
                        {!isEmpty(errors.host) && (
                          <span className="color-red">{errors.host}</span>
                        )}
                      </div>
                    </div>
                    <div className="one-col-2 sftp-input">
                      <div className="one-control-input">
                        <input
                          type="text"
                          placeholder="Username"
                          onChange={handleChange}
                          name="username"
                          value={formValue.username}
                          autoComplete="new-username"
                        />
                        {!isEmpty(errors.username) && (
                          <span className="color-red">{errors.username}</span>
                        )}
                      </div>
                    </div>
                    <div className="one-col-2 sftp-input">
                      <div className="one-control-input">
                        <input
                          type="password"
                          placeholder="Password"
                          onChange={handleChange}
                          name="password"
                          autoComplete="new-password"
                          value={formValue.password}
                        />
                        {!isEmpty(errors.password) && (
                          <span className="color-red">{errors.password}</span>
                        )}
                      </div>
                    </div>
                    <div className="one-col-2 sftp-input">
                      <div className="one-control-input">
                        <input
                          type="number"
                          placeholder="Port"
                          onChange={handleChange}
                          name="port"
                          value={formValue.port}
                        />
                        {!isEmpty(errors.port) && (
                          <span className="color-red">{errors.port}</span>
                        )}
                      </div>
                    </div>
                    <div className="one-col-2 sftp-input">
                      <div className="one-control-input">
                        <input
                          type="text"
                          placeholder="Report file path"
                          onChange={handleChange}
                          name="remote_file_path"
                          value={formValue.remote_file_path}
                        />
                        {!isEmpty(errors.remote_file_path) && (
                          <span className="color-red">
                            {errors.remote_file_path}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="one-col-2 sftp-input">
                      <button
                        className="one-control-btn quick-connect"
                        onClick={QuickConnect}
                      >
                        Quick connect
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <CustomCompanyReportSFTP
                handleAddSFTPCompany={handleAddSFTPCompany}
                response={response}
              />

              <SFTPCompanyDataTable
                formValue={formValue}
                findCompanyName={findCompanyName}
              />

              <div className="my-flex mb-2">
                <div className="one-col-1 my-flex my-align-item-center sftp-section">
                  <strong>
                    Event <span className="color-red">*</span>
                  </strong>
                </div>
                <div className="one-col-11">
                  <div className="my-flex">
                    <div className="one-col-2 import-input">
                      <div className="one-control-select">
                        <select
                          name="event"
                          value={formValue.event}
                          onChange={handleChange}
                        >
                          <option value="csv">Import as a CSV</option>
                          <option value="excel">Import as a Excel</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-flex mb-2">
                <div className="one-col-1 my-flex my-align-item-center sftp-section">
                  <strong>
                    Scheduler <span className="color-red">*</span>
                  </strong>
                </div>
                <div className="one-col-11">
                  <div className="my-flex scheduler-type">
                    <div className="one-col-1 scheduler daily">
                      <CheckBox
                        name={"period"}
                        label={"Daily"}
                        value={"day"}
                        checked={"day" === formValue.period}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="one-col-1 scheduler weekly">
                      <CheckBox
                        name={"period"}
                        label={"Weekly"}
                        value={"weekly"}
                        checked={"weekly" === formValue.period}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="one-col-1 scheduler biweekly">
                      <CheckBox
                        name={"period"}
                        label={"Biweekly"}
                        value={"biweekly"}
                        checked={"biweekly" === formValue.period}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="one-col-1 scheduler monthly">
                      <CheckBox
                        name={"period"}
                        label={"Monthly"}
                        value={"monthly"}
                        checked={"monthly" === formValue.period}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="one-col-1 scheduler onetime">
                      <CheckBox
                        name={"period"}
                        label={"One Time"}
                        value={"one-time"}
                        checked={"one-time" === formValue.period}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-flex mb-2">
                <div className="one-col-1"></div>
                <div className="one-col-11 sftp-scheduler-type">
                  <SchedulerTypeContainer
                    handleChange={handleChange}
                    formValue={formValue}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="my-flex mb-2">
                <div className="one-col-1 my-flex my-align-item-center sftp-section">
                  <strong>Notification logs types</strong>
                </div>
                <div className="one-col-11">
                  <div className="my-flex">
                    <div className="one-col-2 notification-type">
                      <div className="one-control-select">
                        <select
                          name="logType"
                          value={formValue.logType}
                          onChange={handleChange}
                        >
                          <option value="All">All</option>
                          <option value="Success">Success</option>
                          <option value="Failed">Failed</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-flex mb-2">
                <div className="one-col-1 my-flex my-align-item-center sftp-section">
                  <strong>Export file name</strong>
                </div>
                <div className="one-col-11">
                  <div className="my-flex">
                    <div className="one-col-2 export-file-type">
                      <div className="one-control-select">
                        <select
                          name="dateFormat"
                          value={formValue.dateFormat}
                          onChange={handleChange}
                        >
                          {exportFileNames.map((item) => {
                            return (
                              <option key={item.title} value={item.value}>
                                {item.title}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="one-col-2 export-file-name">
                      <div className="one-control-input">
                        <input
                          type="text"
                          placeholder="File name"
                          name="fileName"
                          value={formValue.fileName}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-flex my-justify-content-end">
                <button
                  className="one-control-btn"
                  onClick={handleSubmit(onSubmit)}
                >
                  Schedule
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    products: { userInfo },
    company_data,
    customReport: { importLog },
  } = state;

  const { employees } = company_data;

  return { importLoading: importLog, userInfo, employees };
};

export default withRouter(connect(mapStateToProps)(CustomReportScheduler));
