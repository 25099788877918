/* eslint-disable no-undef */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Dashboard from "../components/Dashboard";

import { editCompanyProductsList } from "../actions/products";

class DashboardContainer extends Component {
  constructor(p) {
    super(p);

    this.removeProduct = this.removeProduct.bind(this);
    this.addProduct = this.addProduct.bind(this);
  }

  removeProduct(productURL) {
    this.props.dispatch(editCompanyProductsList(productURL, "DELETE"));
  }

  addProduct(productURL) {
    this.props.dispatch(editCompanyProductsList(productURL, "ADD"));
  }

  render() {
    const { products, productsAreFetching, companyInfo, userInfo } = this.props;

    return (
      <Dashboard
        productsAreFetching={productsAreFetching}
        products={products}
        companyInfo={companyInfo}
        userInfo={userInfo}
        removeProduct={this.removeProduct}
        addProduct={this.addProduct}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    list,
    isFetching: productsAreFetching,
    companyInfo,
    userInfo,
  } = state.products;
  const { products } = state.entities;

  const prodListJoin =
    list === undefined
      ? []
      : list.map((prodURL) => {
          return products[prodURL];
        });

  return {
    productsAreFetching,
    products: prodListJoin,
    companyInfo,
    userInfo,
  };
};

export default withRouter(connect(mapStateToProps)(DashboardContainer));
