import React from "react";
import { connect } from "react-redux";
import AuthPageContainer from "./AuthPageContainer";

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.auth;

  return {
    isAuthenticated,
  };
};

const RequireAuth = (Component) => {
  const CheckAuth = (props) => {
    if (!props.isAuthenticated) {
      // props.history.push("/login");

      return <AuthPageContainer />;
    }
    return <Component {...props} />;
  };
  return connect(mapStateToProps)(CheckAuth);
};

export default RequireAuth;
