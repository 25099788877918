import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const MultipleEINDataTable = (props) => {
  const {
    row,
    copyRow,
    history,
    handleEditAssociationOpen,
    deleteAssociationsItem,
    reActiveAssociationsItem,
    handleOpenModeReport,
    handleOpenMode,
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState(row);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const isAdmin = localStorage.getItem("isAdmin");

  const pageCount = Math.ceil(rowData.length / rowPerPage);

  const offset = currentPage * rowPerPage;

  const rowPerPageValue = [10, 25, 50, 100, 150, 200];

  const handleSearchChange = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    const filterData = copyRow.filter((item) => {
      let firstArray = Object.values(item);
      let secondArray = Object.values(firstArray[firstArray.length - 1]);

      delete firstArray[firstArray.length - 1];

      return [...firstArray, ...secondArray]
        .join("")
        .toLocaleLowerCase()
        .includes(value.toLocaleLowerCase());
    });

    setRowData(filterData);
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const handleSelectSort = (e) => {
    const { value } = e.target;
    setCurrentPage(0);
    setRowPerPage(value);
  };

  useEffect(() => {
    (() => {
      setRowData(row);
    })();
  }, [row]);

  return (
    <div>
      <div className="integration-table">
        <div className="header-action">
          <select
            className="sort-select"
            onChange={handleSelectSort}
            name=""
            id=""
            defaultValue={rowPerPage}
          >
            {rowPerPageValue.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>

          <input
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
          />
          {isAdmin === "true" && (
            <button
              onClick={handleOpenMode("ADD")}
              className="cstmbtn1 bg-theme ms-10-px"
            >
              ADD
            </button>
          )}
        </div>
      </div>
      <div className="table-responsive">
        <table className="table associations-table">
          <thead>
            <tr>
              <th className="col1">S.NO</th>
              <th className="col5">Title</th>
              <th className="col2">Parent Company</th>
              <th className="col3">Associated Company Count </th>
              <th className="col4">Created Date</th>
              <th className="col4">Last Modified Date</th>
              <th className="col5">Status</th>
              <th className="col5">Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(rowData) &&
              rowData.slice(offset, offset + rowPerPage).map((item, index) => {
                return (
                  <tr className="row" key={index}>
                    <td>{index + 1}</td>
                    <td>{item.title}</td>
                    <td>{item.Parent_company_name}</td>
                    <td className="text-center">
                      <Link to={`/app/product/view/${item.id}`}>
                        {item.associated_company_count}
                      </Link>
                    </td>
                    <td>
                      {moment(item.created_at).format("MM/DD/YYYY kk:mm:ss") ||
                        "N/A"}
                    </td>
                    <td>
                      {moment(item.updated_at).format("MM/DD/YYYY kk:mm:ss") ||
                        "N/A"}
                    </td>
                    <td>{Number(item.status) === 1 ? "ACTIVE" : "INACTIVE"}</td>
                    <td>
                      <div className="text-center">
                        <button
                          className="me-10-px"
                          title="View Logs"
                          disabled={!item.status}
                          onClick={() =>
                            history.push(`/app/reports/view/${item.id}`)
                          }
                        >
                          <FontAwesomeIcon icon={solid("eye")} />
                        </button>
                        {isAdmin === "true" && (
                          <>
                            <button
                              className="me-10-px"
                              title="Edit"
                              disabled={!item.status}
                              onClick={handleEditAssociationOpen(item.id)}
                            >
                              <FontAwesomeIcon icon={solid("pen-to-square")} />
                            </button>
                            {item.status ? (
                              <button
                                className="me-10-px"
                                title="Delete"
                                onClick={deleteAssociationsItem(item.id)}
                              >
                                <FontAwesomeIcon icon={solid("trash-alt")} />
                              </button>
                            ) : (
                              <button
                                className="me-10-px"
                                title="Delete"
                                onClick={reActiveAssociationsItem(item.id)}
                              >
                                <FontAwesomeIcon icon={solid("repeat")} />
                              </button>
                            )}
                          </>
                        )}

                        <button
                          title="file-export"
                          disabled={!item.status}
                          onClick={handleOpenModeReport(item.id)}
                        >
                          <FontAwesomeIcon icon={solid("file-export")} />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
        containerClassName={"pagination-container"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"disabled"}
        activeClassName={"active"}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
      />
    </div>
  );
};

export default MultipleEINDataTable;
