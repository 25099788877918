import React from 'react'

export const Documentation = (
  <div className="documentationBody">
    <p>
      The Active Directory User Provisioning integration allows OnePoint to become the driving force behind your Organization's Active Directory instance. It will handle new hires, terminated employees, and changes to current employees.
    </p>
    <p>
      In order to get started, enable this integration on this page and contact OnePoint to receive a Powershell script. This script is the link between OnePoint and your Active Directory instance.
    </p>
    <div className="subHead">
      New Hires
    </div>
    <p>
      When your company hires a new employee, the integration will create a new user in AD. Use the New User Templates setting above to select "Template Users" from your AD which are used to create new users with certain settings including address, group memberships, and OUs.
    </p>
    <p>
      The new AD user gets properties set from values that were entered into OnePoint. These field mappings are listed below:
    </p>
    <ul>
      <li>
        Given Name = OP First Name
      </li>
      <li>
        Surname = OP Last Name
      </li>
      <li>
        SamAccountName = OP Username
      </li>
      <li>
        UserPrincipalName = OP Username @ (OU of template user)
      </li>
      <li>
        EmployeeID = OP Employee ID
      </li>
      <li>
        Address = Copied from template user
      </li>
      <li>
        MobilePhone = OP CellPhone
      </li>
      <li>
        OfficePhone = OP WorkPhone
      </li>
      <li>
        Title = OP Job Title (Default HR Job name)
      </li>
      <li>
        EmailAddress = Uses "New Email Address Settings" above
      </li>
    </ul>
    <div className="subHead">
      Terminations
    </div>
    <p>
      When an employee is terminated in OnePoint, they are Disabled in AD. <b>In order for this to work successfully, the employee's OnePoint username must equal the AD account's SAMAccountName.</b> If they do not match, the Powershell script will be unable to target the correct account to be disabled.
    </p>
    <div className="subHead">
      Employee Updates
    </div>
    <p>
      When HR or the employees themselves edit their profile info in OnePoint, this integration will try to update the Active Directory account info. The fields that get updates are First and Last Name, EmployeeID, Email, MobilePhone, OfficePhone, and Title.
    </p>
  </div>
)
