import React from "react";
import BaseProductSetting from "./BaseProductSetting";

const accountTimingOption = [
  { value: "date_hired", label: "Date Started" },
  {
    value: "date_started_sub_1",
    label: "1 Day Prior to Date Started",
  },
  {
    value: "date_started_sub_week",
    label: "1 week prior to start date",
  },
  {
    value: "date_started_sub_n",
    label: "N Days Prior to Date Started",
  },
  {
    value: "date_started_add_1",
    label: "1 Day After to Date Started",
  },
  {
    value: "date_started_add_week",
    label: "1 week after start date",
  },
  {
    value: "date_started_add_n",
    label: "N Days After to Date Started",
  },
];

const HiredTimingSettings = (props) => {
  const { setting, onChange } = props;

  const handleChangeSelect = (e) => {
    onChange({ choice: e.target.value });
  };

  const handleChangeDays = (e) => {
    onChange({ ...setting, days: e.target.value });
  };

  return (
    <div className="settingsRow">
      <BaseProductSetting title={"New Account Timing"} tip={""}>
        <div className="settingsCont">
          <div className="bottom" style={{ marginTop: "10px" }}>
            <div className="selectSetting">
              <div className="selectCont my-flex">
                <select value={setting.choice} onChange={handleChangeSelect}>
                  {accountTimingOption.map((opt) => (
                    <option key={opt.label} value={opt.value}>
                      {opt.label}
                    </option>
                  ))}
                </select>
                {setting.choice[setting.choice.length - 1] === "n" && (
                  <div className="timing-account">
                    <input
                      type="number"
                      value={setting.days}
                      onChange={handleChangeDays}
                      placeholder="Days"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="note">
              Select when you want new new hires' accounts to be provisioned
              relative to their Start and Hired Dates in OnePoint.
            </div>
          </div>
        </div>
      </BaseProductSetting>
    </div>
  );
};

export default HiredTimingSettings;
