import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Loading from "../ui/Loading";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Modal from "../ui/Modal";
import { toast, Toaster } from "react-hot-toast";
import {
  convertToISO,
  convertToUTC,
  weeklyDays,
} from "../multipleEINReport/CustomReportSchedule";
import { fetchCompanyData } from "../../actions/companyData";
import {
  getImportOptionByIdSFTP,
  importCompanySFTP,
  importOptionSFTP,
  testSFTPConnection,
} from "../../actions/customReport";
import { oldAppPath } from "../../constants/constant";

// SFTP configuration form initial values
const formValueInitialValue = {
  period: "day",
  type: {},
  typeId: "",
  day: "",
  interval_time: "",
  interval_date: "",
  host: "",
  port: "",
  password: "",
  username: "",
  remote_file_path: "",
  SFTP: [],
  settings: [],
  fileName: "",
  dateFormat: "filename-{MMDDYYYY}",
};

// schedule validation check function
const validateScheduleForm = (formData) => {
  let error = { status: false, values: {} };

  const emptyString = /^[a-zA-Z0-9/][^\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?].*$/;

  const fields = [
    "host",
    "port",
    "password",
    "username",
    "event",
    "interval_time",
    "remote_file_path",
    "typeId",
  ];

  for (let key in formData) {
    if (fields.includes(key)) {
      const strCheck = emptyString.test(formData[key]);
      if (!strCheck) {
        error.values[key] = `${key} field is required.`;
        error.status = true;
      }
    }
  }

  if (["weekly", "biweekly"].includes(formData.period)) {
    const strCheck = emptyString.test(formData.day);
    if (!strCheck) {
      error.values.day = `day field is required.`;
      error.status = true;
    }
  }

  if (formData.period === "monthly" || formData.period === "one-time") {
    const strCheck = emptyString.test(formData.interval_date);
    if (!strCheck) {
      error.values.interval_date = `interval_date field is required.`;
      error.status = true;
    }
  }

  return error;
};

// company SFTP validation method
export const validateScheduleCompany = (formData) => {
  let error = { status: false, values: {} };

  const emptyString = /^[a-zA-Z0-9/][^\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?].*$/;

  const fields = ["host", "port", "password", "username", "remote_file_path"];

  for (let key in formData) {
    if (fields.includes(key)) {
      const strCheck = emptyString.test(formData[key]);
      if (!strCheck) {
        error.values[key] = `${key} field is required.`;
        error.status = true;
      }
    }
  }

  if (["weekly", "biweekly"].includes(formData.period)) {
    const strCheck = emptyString.test(formData.day);
    if (!strCheck) {
      error.values.day = `day field is required.`;
      error.status = true;
    }
  }

  if (formData.period === "monthly" || formData.period === "one-time") {
    const strCheck = emptyString.test(formData.interval_date);
    if (!strCheck) {
      error.values.interval_date = `interval_date field is required.`;
      error.status = true;
    }
  }

  return error;
};

// show the modal quick connect SFTP connection test response show
export const ViewCompanySFTPModal = ({
  handleToggle,
  dispatch,
  formValue,
  userInfo,
  reportType,
  reportId,
  handleDisableFieldForSFTP,
}) => {
  const [loading, setLoading] = useState(true);
  const [responseData, setResponseData] = useState({});

  // handle SFTP credentials test
  const handleSFTPConnectionTest = async () => {
    try {
      const body = {
        host: formValue.host,
        username: formValue.username,
        password: formValue.password,
        company_id: userInfo.company_id,
        report_id: reportId ? Number(reportId) : null,
        report_type: reportType,
        remote_file_path: formValue.remote_file_path,
        port: Number(formValue.port),
      };

      const { response } = await dispatch(testSFTPConnection(body));
      console.log("response", response);
      if (response) {
        setResponseData(response);
        if (response.status === 200) {
          handleDisableFieldForSFTP && handleDisableFieldForSFTP();
        }
      } else {
        setResponseData({ status: "", message: "" });
      }

      setLoading(false);
    } catch (error) {
      setResponseData({ status: "", message: "" });
      setLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    handleSFTPConnectionTest();
  }, []);

  return (
    <>
      <Modal closeModal={handleToggle}>
        {loading ? (
          <>
            <Loading />
            <h1>Connecting to {formValue.host}</h1>
          </>
        ) : (
          <div className="connect-sftp">
            <h2>
              {responseData.status === 200 ? (
                <FontAwesomeIcon
                  icon={regular("circle-check")}
                  size="2xl"
                  style={{ color: "#002e6c" }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={regular("circle-xmark")}
                  size="2xl"
                  style={{ color: "#ff0000" }}
                />
              )}
            </h2>
            <h1>
              {responseData.status === 400 ? "Failed" : "Connected"} to{" "}
              {formValue.host}
            </h1>
            <p
              className={
                responseData.status === 400 ? "color-red" : "color-green"
              }
            >
              {responseData.message && responseData.message}
            </p>
          </div>
        )}
        <div className="scheduler-btn">
          <button onClick={handleToggle}>Cancel</button>
        </div>
      </Modal>
    </>
  );
};

// add configuration form component
const AddConfiguration = (props) => {
  // destructure props values
  const { userInfo, dispatch, history, match } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [formValue, setFormValue] = useState(formValueInitialValue);
  const [errors, setErrors] = useState({});
  const [toggle, setToggle] = useState(false);
  // eslint-disable-next-line
  const [modalOpenIndex, setModalOpenIndex] = useState(null);
  const [optionList, setOptionList] = useState([]);
  const [selectType, setSelectType] = useState({});
  const [logType, setLogType] = useState("All");

  const handleToggle = (index) => {
    if (isEmpty(index)) {
      setModalOpenIndex(index);
    } else {
      setModalOpenIndex(null);
    }

    setToggle(!toggle);
  };

  // handle log types change
  const handleLogTypeChange = (e) => {
    const { value } = e.target;
    setLogType(value);
  };

  // handle SFTP configuration change
  const handleChange = (e) => {
    let { name, value } = e.target;
    let copy = { ...formValue };
    if (name === "typeId") {
      let selected = optionList.find(
        (item) => Number(value) === Number(item.TypeId)
      );
      setSelectType(selected);
      copy.event = selected.FileType;
    }
    copy[name] = value;
    setFormValue(copy);
  };

  // delete company SFTP configuration
  const handleSFTPDelete = (index) => () => {
    const copy = { ...formValue };
    copy.SFTP.splice(index, 1);
    setFormValue(copy);
  };

  const handleSchedule = async () => {
    try {
      const responseError = validateScheduleForm(formValue);
      console.log("responseError", responseError);

      if (responseError.status) {
        setErrors(responseError.values);
        return null;
      }

      setIsLoading(true);

      console.log("formValue", formValue);

      const body = {
        event: selectType.FileType,
        type_id: Number(formValue.typeId),
        type_name: selectType.Label,
        host: formValue.host,
        username: formValue.username,
        password: formValue.password,
        remote_file_path: formValue.remote_file_path,
        port: Number(formValue.port),
        period: formValue.period,
        interval_time: convertToUTC(formValue.interval_time),
        interval_date: formValue.interval_date,
        day: formValue.day,
        email_notification_settings: `${JSON.stringify({
          log_types: logType,
          email_recipients: formValue.settings,
        })}`,
      };

      if (match.params.id) {
        body.id = Number(match.params.id);
      }
      const { response } = await dispatch(importCompanySFTP(body));
      if (response.message === "success") {
        history.push(`${oldAppPath}/products/imports`);
        toast.success("Add successfully");
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  // fetch the types from API
  const fetchOption = async () => {
    try {
      setIsLoading(true);
      const { response } = await dispatch(importOptionSFTP());
      setOptionList(response.Imports);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  // fetch the edit
  const fetchEditRecord = async () => {
    try {
      setIsLoading(true);
      let { response } = await dispatch(
        getImportOptionByIdSFTP(match.params.id)
      );
      response = !isEmpty(response) ? Object.values(response) : [];
      if (response.length !== 0) {
        const str = response[0].email_notification_settings;
        // console.log("str", str);
        // const settingsData = JSON.parse(str.slice(1, str.length - 1));
        const settingsData = JSON.parse(str);
        // console.log("settingsData", settingsData.email_recipients);

        const data = {
          period: response[0].period,
          typeId: response[0].type_id,
          day: response[0].day,
          interval_time: convertToISO(response[0].interval_time),
          interval_date: response[0].interval_date,
          host: response[0].host,
          port: response[0].port,
          password: "",
          username: response[0].username,
          remote_file_path: response[0].remote_file_path,
          event: response[0].event,
          settings: settingsData.email_recipients,
        };
        setLogType(settingsData.log_types);
        setFormValue({ ...formValue, ...data });
        setSelectType({
          FileType: response[0].event,
          TypeId: response[0].type_id,
          Label: response[0].type_name,
        });
        setIsLoading(false);

        // console.log("response", response[0]);
        // console.log("data", data);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  // this function quick connect test SFTP credentials
  const handleQuickConnect = () => {
    const responseError = validateScheduleCompany(formValue);

    console.log("responseError", responseError);

    if (responseError.status) {
      setErrors(responseError.values);
      return null;
    }
    setErrors(responseError.values);

    handleToggle();
  };

  // fetch employee records
  const fetchEmployeeData = async () => {
    setIsLoading(true);
    await dispatch(fetchCompanyData("employees"));
    setIsLoading(false);
  };

  useEffect(() => {
    fetchOption();
    fetchEmployeeData();
    if (!isEmpty(match.params.id)) {
      fetchEditRecord();
    }
  }, []);

  return (
    <div>
      <Toaster />
      <div className="breadcrumbs">
        <Link to={`${oldAppPath}/products/imports`}>
          &larr; Back to list of your imports
        </Link>
      </div>
      <h1>Import Report Configuration</h1>
      {toggle && (
        <ViewCompanySFTPModal
          formValue={formValue}
          dispatch={dispatch}
          handleToggle={handleToggle}
          userInfo={userInfo}
          reportType="import_report"
          reportId={!isEmpty(match.params.id) ? match.params.id : null}
        />
      )}
      <div className="section installations">
        {isLoading && (
          <div className="loadCont">
            <Loading />
          </div>
        )}

        <div className="schedule-report">
          <div className="Custmrow custmmb-2">
            <div className="custmCol-2">
              <div className="">
                <strong>Company SFTP *</strong>
              </div>
            </div>
            <div className="col-10">
              <div className="Custmrow">
                <div className="custmCol-3 custmmb-2">
                  <input
                    type="text"
                    className="formInput"
                    placeholder="Host"
                    name="host"
                    value={formValue.host}
                    onChange={handleChange}
                  />
                  {!isEmpty(errors.host) && (
                    <label className="color-red">{errors.host}</label>
                  )}
                </div>
                <div className="custmCol-3 custmmb-2">
                  <input
                    type="text"
                    className="formInput"
                    placeholder="Username"
                    name="username"
                    value={formValue.username}
                    autoComplete="new-username"
                    onChange={handleChange}
                  />
                  {!isEmpty(errors.username) && (
                    <label className="color-red">{errors.username}</label>
                  )}
                </div>
                <div className="custmCol-3 custmmb-2">
                  <input
                    type="password"
                    className="formInput"
                    placeholder="Password"
                    name="password"
                    autoComplete="new-password"
                    value={formValue.password}
                    onChange={handleChange}
                  />
                  {!isEmpty(errors.password) && (
                    <label className="color-red">{errors.password}</label>
                  )}
                </div>
                <div className="custmCol-3 custmmb-2">
                  <input
                    className="formInput"
                    type="number"
                    placeholder="Port"
                    name="port"
                    value={formValue.port}
                    onChange={handleChange}
                  />
                  {!isEmpty(errors.port) && (
                    <label className="color-red">{errors.port}</label>
                  )}
                </div>
                <div className="custmCol-3 custmmb-2">
                  <input
                    className="formInput"
                    type="text"
                    placeholder="Remote file path"
                    name="remote_file_path"
                    value={formValue.remote_file_path}
                    onChange={handleChange}
                  />
                  {!isEmpty(errors.remote_file_path) && (
                    <label className="color-red">
                      {errors.remote_file_path}
                    </label>
                  )}
                </div>
                <div className="custmCol-3 custmmb-2">
                  <button className="CustmBtn" onClick={handleQuickConnect}>
                    Quick connect
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="table-company-sftp">
            <div className="Custmrow custmmb-2 col-10">
              <div className="custmCol-12 custmmb-2">
                {formValue.SFTP && formValue.SFTP.length !== 0 && (
                  <div className="ass-custom-report-table">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="col1">S.NO</th>
                          <th className="col2">Name</th>
                          <th className="col2">Host</th>
                          <th className="col2">Port</th>
                          <th className="col3">username</th>
                          <th className="col3">Remote file path</th>
                          <th className="col3">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {formValue.SFTP.map((item, index) => (
                          <tr key={index}>
                            <td className="col2">{index + 1 || "N/A"}</td>
                            <td className="col5">{item.name || "N/A"}</td>
                            <td className="col5">{item.host || "N/A"}</td>
                            <td className="col5">{item.username || "N/A"}</td>
                            <td className="col5">
                              {item.remote_file_path || "N/A"}
                            </td>
                            <td className="col5">
                              {item.remote_file_path || "N/A"}
                            </td>
                            <td className="text-center">
                              <button
                                title="view"
                                onClick={() => handleToggle(index)}
                              >
                                <FontAwesomeIcon icon={solid("eye")} />
                              </button>
                              <button
                                title="delete"
                                onClick={handleSFTPDelete(index)}
                                style={{ marginLeft: "10px" }}
                              >
                                <FontAwesomeIcon icon={solid("trash-alt")} />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="Custmrow custmmb-2">
            <div className="custmCol-2 ">
              <div className="">
                <strong>Types *</strong>
              </div>
            </div>
            <div className="col-10">
              <div className="Custmrow">
                <div className="custmCol-3 custmmb-2">
                  <select
                    name="typeId"
                    className="formselect"
                    value={formValue.typeId}
                    onChange={handleChange}
                    disabled={!isEmpty(match.params.id)}
                  >
                    <option disabled value="">
                      Please select type
                    </option>
                    {Array.isArray(optionList) &&
                      optionList.map((item) => {
                        return (
                          <option key={item.TypeId} value={item.TypeId}>
                            {item.Label}
                          </option>
                        );
                      })}
                  </select>
                  {!isEmpty(errors.typeId) && (
                    <label className="color-red">{errors.typeId}</label>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="Custmrow custmmb-2">
            <div className="custmCol-2 ">
              <div className="">
                <strong>Event *</strong>
              </div>
            </div>
            <div className="col-10">
              <div className="Custmrow">
                <div className="custmCol-3 custmmb-2">
                  <input
                    type="text"
                    className="formInput"
                    disabled
                    value={formValue.event}
                    // value={selectType.FileType}
                  />
                  {/* <select
                    name="event"
                    className="formselect"
                    value={formValue.event}
                    onChange={handleChange}
                  >
                    <option value="csv">Import as a CSV</option>
                    <option value="excel">Import as a Excel</option>
                  </select> */}
                  {/* {!isEmpty(errors.event) && (
                    <label className="color-red">{errors.event}</label>
                  )} */}
                </div>
              </div>
            </div>
          </div>
          <div className="Custmrow custmmb-2">
            <div className="custmCol-2">
              <div className="">
                <strong>Scheduler *</strong>
              </div>
            </div>
            <div className="col-10">
              <div className="Custmrow">
                <div className="custmCol-12 custmmb-2">
                  <div className="d-flex">
                    <div className="ch custmCol-2">
                      <input
                        type="radio"
                        name="period"
                        id="Daily"
                        value={"day"}
                        checked={"day" === formValue.period}
                        onChange={handleChange}
                      />
                      <label htmlFor="Daily" className="px-1">
                        Daily
                      </label>
                    </div>
                    <div className="ch custmCol-2">
                      <input
                        type="radio"
                        name="period"
                        id="Weekly"
                        value={"weekly"}
                        checked={"weekly" === formValue.period}
                        onChange={handleChange}
                      />
                      <label htmlFor="Weekly" className="px-1">
                        Weekly
                      </label>
                    </div>
                    <div className="ch custmCol-2">
                      <input
                        type="radio"
                        name="period"
                        id="Biweekly"
                        value={"biweekly"}
                        checked={"biweekly" === formValue.period}
                        onChange={handleChange}
                      />
                      <label htmlFor="Biweekly" className="px-1">
                        Biweekly
                      </label>
                    </div>
                    <div className="ch custmCol-2">
                      <input
                        type="radio"
                        name="period"
                        id="Monthly"
                        value={"monthly"}
                        checked={"monthly" === formValue.period}
                        onChange={handleChange}
                      />
                      <label htmlFor="Monthly" className="px-1">
                        Monthly
                      </label>
                    </div>
                    <div className="ch custmCol-2">
                      <input
                        type="radio"
                        name="period"
                        id="one-time"
                        value={"one-time"}
                        checked={"one-time" === formValue.period}
                        onChange={handleChange}
                      />
                      <label htmlFor="one-time" className="px-1">
                        One Time
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="time-management-sftp Custmrow custmmb-2 col-10">
            <div className="custmCol-12 custmmb-2">
              <div className="py-2">
                {formValue.period === "day" && (
                  <div className="daily">
                    <div className="CustmWidth">
                      <strong>Time</strong>
                      <input
                        className="formInput"
                        type="time"
                        name="interval_time"
                        value={formValue.interval_time}
                        onChange={handleChange}
                      />
                    </div>
                    {!isEmpty(errors.interval_time) && (
                      <label className="color-red">
                        {errors.interval_time}
                      </label>
                    )}
                  </div>
                )}
                {formValue.period === "weekly" && (
                  <div className="daily d-flex custmSpaceBetwen">
                    <div className="col-5">
                      <div className="CustmWidth col-12">
                        <strong>Day</strong>
                        <select
                          className="custmFullWidth"
                          name="day"
                          value={formValue.day}
                          onChange={handleChange}
                        >
                          <option value="">Please Select</option>
                          {weeklyDays.map((item) => {
                            return (
                              <option value={item.name}>{item.name}</option>
                            );
                          })}
                        </select>
                      </div>
                      {!isEmpty(errors.day) && (
                        <label className="color-red">{errors.day}</label>
                      )}
                    </div>
                    <div className="col-5">
                      <div className="CustmWidth col-12">
                        <strong>Time</strong>
                        <input
                          type="time"
                          className="formInput"
                          name="interval_time"
                          value={formValue.interval_time}
                          onChange={handleChange}
                        />
                      </div>
                      {!isEmpty(errors.interval_time) && (
                        <label className="color-red">
                          {errors.interval_time}
                        </label>
                      )}
                    </div>
                  </div>
                )}
                {formValue.period === "biweekly" && (
                  <div className="daily d-flex custmSpaceBetwen">
                    <div className="col-5">
                      <div className="CustmWidth col-12">
                        <strong>Day</strong>
                        <select
                          className="custmFullWidth"
                          name="day"
                          value={formValue.day}
                          onChange={handleChange}
                        >
                          <option value="">Please Select</option>
                          {weeklyDays.map((item) => {
                            return (
                              <option key={item.name} value={item.name}>
                                {item.name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      {!isEmpty(errors.day) && (
                        <label className="color-red">{errors.day}</label>
                      )}
                    </div>
                    <div className="col-5">
                      <div className="CustmWidth col-12">
                        <strong>Time</strong>
                        <input
                          className="formInput"
                          type="time"
                          name="interval_time"
                          value={formValue.interval_time}
                          onChange={handleChange}
                        />
                      </div>
                      {!isEmpty(errors.interval_time) && (
                        <label className="color-red">
                          {errors.interval_time}
                        </label>
                      )}
                    </div>
                  </div>
                )}
                {formValue.period === "monthly" && (
                  <div className="daily d-flex custmSpaceBetwen">
                    <div className="col-5">
                      <div className="CustmWidth col-12">
                        <strong>Date</strong>
                        <input
                          className="formInput"
                          type="date"
                          name="interval_date"
                          value={formValue.interval_date}
                          onChange={handleChange}
                        />
                      </div>
                      {!isEmpty(errors.interval_date) && (
                        <label className="color-red">
                          {errors.interval_date}
                        </label>
                      )}
                    </div>
                    <div className="col-5">
                      <div className="CustmWidth col-12">
                        <strong>Time</strong>
                        <input
                          type="time"
                          className="formInput"
                          name="interval_time"
                          value={formValue.interval_time}
                          onChange={handleChange}
                        />
                      </div>
                      {!isEmpty(errors.interval_time) && (
                        <label className="color-red">
                          {errors.interval_time}
                        </label>
                      )}
                    </div>
                  </div>
                )}
                {formValue.period === "one-time" && (
                  <div className="daily d-flex custmSpaceBetwen">
                    <div className="col-5">
                      <div className="CustmWidth col-12">
                        <strong>Date</strong>
                        <input
                          className="formInput"
                          type="date"
                          name="interval_date"
                          value={formValue.interval_date}
                          onChange={handleChange}
                        />
                      </div>
                      {!isEmpty(errors.interval_date) && (
                        <label className="color-red">
                          {errors.interval_date}
                        </label>
                      )}
                    </div>
                    <div className="col-5">
                      <div className="CustmWidth col-12">
                        <strong>Time</strong>
                        <input
                          type="time"
                          className="formInput"
                          name="interval_time"
                          value={formValue.interval_time}
                          onChange={handleChange}
                        />
                      </div>
                      {!isEmpty(errors.interval_time) && (
                        <label className="color-red">
                          {errors.interval_time}
                        </label>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* email notification start */}
          <div className="Custmrow custmmb-2">
            <div className="custmCol-2">
              <div className="">
                <strong>Notification logs types</strong>
              </div>
            </div>
            <div className="col-10">
              <div className="prod-settings">
                <div className="psrow">
                  <div className="eChooser">
                    <div style={{ display: "flex" }}>
                      <div className="freeinput">
                        <div className="top">
                          <div>
                            <div className="custmmb-2">Log Type</div>
                            <div style={{ margin: "5px 0" }}>
                              <select
                                name=""
                                style={{ margin: 0 }}
                                onChange={handleLogTypeChange}
                                value={logType}
                              >
                                <option value="All">All</option>
                                <option value="Success">Success</option>
                                <option value="Failed">Failed</option>
                              </select>
                              {!isEmpty(errors.email) && (
                                <label className="color-red">
                                  {errors.email}
                                </label>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* email notification end */}
        </div>

        <div className="scheduler-btn">
          <button onClick={handleSchedule}>Schedule</button>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (props) => {
  const {
    products: { userInfo },
    company_data,
  } = props;

  const { employees } = company_data;

  return { userInfo, employees };
};

export default connect(mapStateToProps)(AddConfiguration);
