import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import { fetchGoogleHireInfo, deauthGoogleHireInfo } from '../actions/google_hire'

import '../styles/GoogleHireBody.css';

import Loading from './ui/Loading'

class GoogleHireBody extends Component {
  constructor(props) {
    super(props)
    this.state = {}

    this.renderOAuthLink = this.renderOAuthLink.bind(this)
    this.launchWindow = this.launchWindow.bind(this)
    this.deauthenticate = this.deauthenticate.bind(this)
    this.displayUnixTime = this.displayUnixTime.bind(this)
  }

  componentWillMount() {
    const { auth_info, dispatch } = this.props
    if (!auth_info) {
      dispatch(fetchGoogleHireInfo())
    }
  }

  launchWindow(url) {
    window.location.replace(url)
  }

  displayUnixTime(unixTime) {
    var a = new Date(unixTime * 1000)
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    var year = a.getFullYear()
    var month = months[a.getMonth()]
    var date = a.getDate()
    var hour = a.getHours()
    var min = "0" + a.getMinutes()

    var time = month + ' ' + date + ', ' + year + ' ' + hour + ':' + min.substr(-2)
    return time;
  }

  renderOAuthLink() {
    const clientID = '296350640338-1vosi9tcli87utnsvvq9rooo17n11tmo.apps.googleusercontent.com'

    let redirect_uri = 'http://localhost:3000/callbacks/googlehire'
    if (process.env.NODE_ENV === 'production') {
      redirect_uri = 'https://connect.onehcm.com/callbacks/googlehire'
    }

    const scope = 'https://www.googleapis.com/auth/hire.application.readonly%20https://www.googleapis.com/auth/hire.candidate.readonly%20https://www.googleapis.com/auth/hire.job.readonly'
    const access_type = 'offline'

    const url = `https://accounts.google.com/o/oauth2/v2/auth?prompt=consent&response_type=code&client_id=${clientID}&redirect_uri=${redirect_uri}&scope=${scope}&access_type=${access_type}`
    return (
      <button className="" onClick={() => this.launchWindow(url)} >
        Sign Into Google Hire
      </button>
    )
  }

  deauthenticate() {
    this.props.dispatch(deauthGoogleHireInfo())
  }

  render() {
    const { isFetching, auth_info, isDeauthing, createdApplicants } = this.props

    if (isFetching || isDeauthing) {
      return (
        <div className="GoogleHireBody">
          <Loading />
        </div>
      )
    }

    return (
      <div className="GoogleHireBody">
        <div className="header">
          <h2>Authentication</h2>
        </div>
        <div>
          {
            auth_info && auth_info.authenticated
              ?
              (
                <div>
                  <div>
                    Integration is authenticated! To stop further activity, click the button below.
                  </div>
                  <div>
                    <button onClick={() => this.deauthenticate()}>Disconnect from Hire</button>
                  </div>
                </div>
              )
              :
              (
                <div>
                  <div>
                    Integration is <b>not</b> authenticated. A Google Hire Admin must click button below to enable this integration to access your company's Hire data.
                  </div>
                  <div>
                    {this.renderOAuthLink()}
                  </div>
                </div>
              )
          }
        </div>
        <div className="section">
          <h2>Applicants Created</h2>
          <div className="tableCont">
            <table className="table">
              <thead>
                <tr>
                  <th className="">
                    Time Created
                  </th>
                  <th className="">
                    Applicant Name
                  </th>
                  <th className="">
                    OnePoint Username
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  createdApplicants.map((app) => (
                    <tr key={app.gh_applicant_resource_name}>
                      <td className="">
                        {this.displayUnixTime(app.created_at)}
                      </td>
                      <td>
                        {app.applicant_name}
                      </td>
                      <td>
                        {app.op_username}
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { auth_info, isFetching, isDeauthing, createdAppsList } = state.google_hire
  const { created_applicants } = state.entities

  const createdApplicants = createdAppsList && createdAppsList.map((id) => created_applicants[id])

  return { auth_info, isFetching, isDeauthing, createdApplicants }
}

export default withRouter(connect(mapStateToProps)(GoogleHireBody))
