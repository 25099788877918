import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "../ui/Loading";
import { connect } from "react-redux";

import { fetchCompanyData } from "../../actions/companyData";

import "../../styles/EmployeeChooser.css";
import "../../styles/SettingComponents.css";

class EmployeeChooser extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    values: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = { currentSelection: "", disabled: true, emailInput: "" };

    this.toggleDisabled = this.toggleDisabled.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.submitAdd = this.submitAdd.bind(this);
    this.submitEmailAdd = this.submitEmailAdd.bind(this);
    this.removeEmail = this.removeEmail.bind(this);
  }

  componentWillReceiveProps(newProps) {
    const { employees: oldEmployees } = this.props;
    const { employees: newEmployees } = newProps;

    if (oldEmployees === null && newEmployees !== null) {
      this.setState({ currentSelection: newEmployees[0].name });
    }
  }

  componentWillMount() {
    const { employees } = this.props;

    if (employees !== null) {
      this.setState({ currentSelection: employees[0].name });
    }
  }

  fetchEmployeeData() {
    const { dispatch } = this.props;

    dispatch(fetchCompanyData("employees"));
  }

  toggleDisabled() {
    const { disabled } = this.state;
    const { employees } = this.props;

    if (disabled && employees === null) {
      this.fetchEmployeeData();
    }

    if (!disabled) {
      this.setState({ disabled: !disabled });
    } else {
      this.setState({ disabled: !disabled });
    }
  }

  handleChange(event) {
    this.setState({ currentSelection: event.target.value });
  }

  removeEmployee(empID) {
    const { onChange, values } = this.props;

    const newValues = values.filter((employee) => employee.id !== empID);

    onChange(newValues);
  }

  submitAdd() {
    const { currentSelection } = this.state;
    const { employees, onChange, values } = this.props;

    const thisEmpExisting = values.filter(
      (employee) => employee.name === currentSelection
    );
    if (thisEmpExisting.length !== 0) {
      return;
    }

    const thisEmp = employees.filter(
      (employee) => employee.name === currentSelection
    );
    if (thisEmp.length === 0) {
      return;
    }

    const thisID = thisEmp[0].id;

    var newArr = values.concat({ name: currentSelection, id: thisID });

    onChange(newArr);
  }

  submitEmailAdd() {
    const { emailInput } = this.state;
    const { onChange, values } = this.props;

    if (emailInput === "") {
      return;
    }

    const thisEmailExisting = values.filter(
      (val) => val.address === emailInput
    );
    if (thisEmailExisting.length !== 0) {
      return;
    }

    var newArr = values.concat({ type: "email", address: emailInput });

    onChange(newArr);
  }

  removeEmail(address) {
    const { onChange, values } = this.props;

    const newValues = values.filter((val) => val.address !== address);

    onChange(newValues);
  }

  render() {
    const {
      title,
      isFetching,
      employees,
      values,
      tip,
      dontAllowEmail,
      addLabel,
    } = this.props;
    const { currentSelection, disabled, emailInput } = this.state;

    return (
      <div className="eChooser">
        {isFetching && (
          <div className="loadCont">
            <Loading />
          </div>
        )}
        <div className={isFetching ? "mainfetching" : ""}>
          <div className="title">{title}</div>
          {tip !== "" && <div className="tip">{tip}</div>}
          {values.length !== 0 && (
            <div className="activeEmps">
              {values.map((val) => {
                if (val.type === "email") {
                  return (
                    <div className="nodeWithX" key={val.address}>
                      {val.address}
                      <button onClick={() => this.removeEmail(val.address)}>
                        X
                      </button>
                    </div>
                  );
                } else {
                  return (
                    <div className="nodeWithX" key={val.id}>
                      {val.name}
                      <button onClick={() => this.removeEmployee(val.id)}>
                        X
                      </button>
                    </div>
                  );
                }
              })}
            </div>
          )}
          <div style={{ display: "flex" }}>
            <div className="employees">
              <div className="top">
                {!disabled && (
                  <div>
                    <div>Select Employee to Add</div>
                    <select
                      value={currentSelection}
                      onChange={this.handleChange}
                    >
                      {employees !== null &&
                        employees.map((employee, index) => (
                          <option key={"usjej" + index} value={employee.name}>
                            {employee.name}
                          </option>
                        ))}
                    </select>
                  </div>
                )}
              </div>
              <div className="bot">
                {disabled ? (
                  <div className="buttonCont">
                    <button onClick={() => this.toggleDisabled()}>
                      {values.length !== 0
                        ? "ADD MORE"
                        : addLabel
                        ? addLabel
                        : "ADD RECIPIENT"}
                    </button>
                  </div>
                ) : (
                  <div className="buttonCont">
                    <button onClick={() => this.submitAdd()}>
                      ADD EMPLOYEE
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div style={{ margin: "0 20px" }}>
              {!dontAllowEmail && !disabled && "OR"}
            </div>
            {!dontAllowEmail && !disabled && (
              <div className="freeinput">
                <div className="top">
                  <div>
                    <div>Enter Email Address</div>
                    <div style={{ margin: "5px 0" }}>
                      <input
                        style={{ width: "250px" }}
                        value={emailInput}
                        type="text"
                        onChange={(e) =>
                          this.setState({ emailInput: e.target.value })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="bot">
                  <div className="buttonCont">
                    <button onClick={() => this.submitEmailAdd()}>
                      ADD EMAIL ADDRESS
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div>
            {!disabled && (
              <div style={{ marginTop: "15px" }} className="buttonCont">
                <button onClick={() => this.toggleDisabled()}>CLOSE</button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { employees, isFetching } = state.company_data;

  const efetching = isFetching["employees"];

  return {
    employees: employees !== undefined ? employees : null,
    isFetching: efetching,
  };
};

export default connect(mapStateToProps)(EmployeeChooser);
