import React, { Component } from "react";
import moment from "moment";
import { isEmpty } from "lodash";

const handleValidate = (body) => {
  let error = {};
  let status = false;
  for (let key in body) {
    if (isEmpty(body[key])) {
      error[key] = `${key} field is required!`;
      status = true;
    }
  }
  return { error, status };
};

class OneLoginConfigurationForm extends Component {
  constructor(props) {
    super(props);
    this.state = { errorBody: {} };
  }

  handleSubmit = () => {
    const { formValue } = this.props;
    const error = handleValidate(formValue);
    if (error.status) {
      this.setState({ errorBody: error.error });
      return;
    }
    this.props.handleSubmit();
  };

  render() {
    const {
      handleChange,
      formValue: {
        sup_client_id,
        sup_client_secret,
        sup_redirect_url,
        oneloginDomain,
        client_id,
        client_secret,
      },
      created_at,
      updated_at,
    } = this.props;

    const {
      handleSubmit,
      state: { errorBody },
    } = this;

    return (
      <div>
        <div className="connector-configuration-input">
          <label className="bold">Domain URL</label>
          <input
            type="text"
            value={oneloginDomain}
            name="oneloginDomain"
            placeholder="https://api.onelogin.com"
            onChange={handleChange}
          />
          {!isEmpty(errorBody.oneloginDomain) && (
            <span className="color-red">{errorBody.oneloginDomain}</span>
          )}
        </div>
        <h4>Application configuration</h4>
        <div className="d-flex">
          <div className="con-input">
            <label>Client ID</label>
            <input
              type="text"
              value={client_id}
              name="client_id"
              placeholder="Client ID"
              onChange={handleChange}
            />
            {!isEmpty(errorBody.client_id) && (
              <span className="color-red">{errorBody.client_id}</span>
            )}
          </div>
          <div className="con-input">
            <label>Client secret</label>
            <input
              type="text"
              value={client_secret}
              name="client_secret"
              placeholder="Client secret"
              onChange={handleChange}
            />
            {!isEmpty(errorBody.client_secret) && (
              <span className="color-red">{errorBody.client_secret}</span>
            )}
          </div>
        </div>
        <h4>API configuration</h4>
        <div className="d-flex">
          <div className="con-input">
            <label>Client ID</label>
            <input
              type="text"
              value={sup_client_id}
              name="sup_client_id"
              placeholder="Client ID"
              onChange={handleChange}
            />
            {!isEmpty(errorBody.sup_client_id) && (
              <span className="color-red">{errorBody.sup_client_id}</span>
            )}
          </div>
          <div className="con-input">
            <label>Client secret</label>
            <input
              type="text"
              value={sup_client_secret}
              name="sup_client_secret"
              placeholder="Client secret"
              onChange={handleChange}
            />
            {!isEmpty(errorBody.sup_client_secret) && (
              <span className="color-red">{errorBody.sup_client_secret}</span>
            )}
          </div>
        </div>
        <div className="connector-configuration-input">
          <label className="bold">Redirect URL</label>
          <input
            type="text"
            value={sup_redirect_url}
            name="sup_redirect_url"
            placeholder="https://api.test.com/callbacks/onelogin"
            disabled={true}
            onChange={handleChange}
          />
          {!isEmpty(errorBody.sup_redirect_url) && (
            <span className="color-red">{errorBody.sup_redirect_url}</span>
          )}
        </div>
        <div className="d-flex">
          {!isEmpty(created_at) && (
            <div className="con-input">
              <label>Create at</label>
              <input
                type="text"
                value={moment(created_at).format("MM/DD/YYYY kk:mm:ss")}
                disabled
              />
            </div>
          )}
          {!isEmpty(updated_at) && (
            <div className="con-input">
              <label>Updated at</label>
              <input
                type="text"
                value={moment(updated_at).format("MM/DD/YYYY kk:mm:ss")}
                disabled
              />
            </div>
          )}
        </div>
        <div className="btn-configuration">
          <button
            className="submitbtncstm"
            type="submit"
            onClick={handleSubmit}
          >
            {!isEmpty(updated_at) ? "Update" : "Submit"}
          </button>
        </div>
      </div>
    );
  }
}

export default OneLoginConfigurationForm;
