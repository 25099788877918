import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";

const ImportBodyDataTable = (props) => {
  const { row, copyRow, userInfo, handleDeleteImportLog } = props;
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState(row);
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const pageCount = Math.ceil(rowData.length / rowPerPage);

  const offset = currentPage * rowPerPage;

  const rowPerPageValue = [10, 25, 50, 100, 150, 200];

  const handleSearchChange = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    const filterData = copyRow.filter((item) => {
      let firstArray = Object.values(item);
      let secondArray = Object.values(firstArray[firstArray.length - 1]);

      delete firstArray[firstArray.length - 1];

      return [...firstArray, ...secondArray]
        .join("")
        .toLocaleLowerCase()
        .includes(value.toLocaleLowerCase());
    });

    setRowData(filterData);
  };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const handleSelectSort = (e) => {
    const { value } = e.target;
    setCurrentPage(0);
    setRowPerPage(value);
  };

  useEffect(() => {
    (() => {
      setRowData(row);
    })();
  }, [row]);

  return (
    <div className="product-table">
      <div className="integration-table">
        <div className="header-action">
          <select
            className="sort-select"
            onChange={handleSelectSort}
            name=""
            id=""
            defaultValue={rowPerPage}
          >
            {rowPerPageValue.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>

          <input
            type="text"
            placeholder="Search"
            value={searchValue}
            onChange={handleSearchChange}
          />

          <Link to="/app/product/imports-add">
            <button className="cstmbtn1 bg-theme ms-10-px">ADD</button>
          </Link>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table import-table">
          <thead>
            <tr>
              <th className="col1">S.NO</th>
              <th className="col5">Type name</th>
              <th className="col2">Event</th>
              <th className="col3">Host</th>
              <th className="col4">Port</th>
              <th className="col4">username</th>
              <th className="col5">File path</th>
              <th className="col5">Actions</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(rowData) &&
              rowData.slice(offset, offset + rowPerPage).map((item, index) => {
                return (
                  <tr className="row" key={index}>
                    <td>{index + 1}</td>
                    <td>{item.type_name}</td>
                    <td>{item.event}</td>
                    <td>{item.host}</td>
                    <td>{item.port}</td>
                    <td>{item.username}</td>
                    <td>{item.remote_file_path}</td>
                    <td>
                      <div className="text-center">
                        <Link
                          to={`/app/logs/import_report/${item.id}/${userInfo.company_id}`}
                        >
                          <button className="me-10-px" title="View Logs">
                            <FontAwesomeIcon
                              icon={solid("clock-rotate-left")}
                            />
                          </button>
                        </Link>
                        <Link to={`/app/product/imports/${item.id}`}>
                          <button className="me-10-px" title="Edit">
                            <FontAwesomeIcon icon={solid("pen-to-square")} />
                          </button>
                        </Link>
                        <button
                          title="Delete"
                          onClick={handleDeleteImportLog(item.id)}
                        >
                          <FontAwesomeIcon icon={solid("trash-alt")} />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="Next"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="Previous"
        renderOnZeroPageCount={null}
        containerClassName={"pagination-container"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"disabled"}
        activeClassName={"active"}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
      />
    </div>
  );
};

export default ImportBodyDataTable;
