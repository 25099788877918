import * as JobTypes from "../actions/cloudpunch";

const initialState = {
  error: "",
  allFetching: false,
  installsFetching: false,
  empsAreFetching: false,
  installationsList: [],
  employeesList: [],
  associationsList: [],
  beenLoadedOnce: false,
  installIsUpdating: false,
  employeeIsUpdating: false,
  associationspost: false,
  associationspostError: false,
  associationsDelete: false,
  associationsMessage: "",
  getAssociationsById: false,
  deleteAssociationsCompanyById: false,
  editAssociationsCompanyById: false,
  editAssociationsCompanyError: false,
  getAssociationsCompanyById: false,
  viewSettings: [],
};

export default function cloudpunch(state = initialState, action) {
  switch (action.type) {
    case JobTypes.CP_REQUEST:
      return Object.assign({}, state, {
        error: "",
        allFetching: true,
      });
    case JobTypes.CP_SUCCESS:
      return Object.assign({}, state, {
        error: "",
        allFetching: false,
        installationsList: action.response.result.installations,
        employeesList: action.response.result.employees,
        beenLoadedOnce: true,
      });
    case JobTypes.CP_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        allFetching: false,
      });
    case JobTypes.CP_INST_REQUEST:
      return Object.assign({}, state, {
        error: "",
        installsFetching: true,
      });
    case JobTypes.CP_INST_SUCCESS:
      return Object.assign({}, state, {
        error: "",
        installsFetching: false,
        installationsList: action.response.result,
      });
    case JobTypes.CP_INST_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        installsFetching: false,
      });
    case JobTypes.CP_EMP_REQUEST:
      return Object.assign({}, state, {
        error: "",
        empsAreFetching: true,
      });
    case JobTypes.CP_EMP_SUCCESS:
      return Object.assign({}, state, {
        error: "",
        empsAreFetching: false,
        employeesList: action.response.result,
      });
    case JobTypes.CP_EMP_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        empsAreFetching: false,
      });
    case JobTypes.CP_UPDATEDINSTALL_REQUEST:
      return Object.assign({}, state, {
        installIsUpdating: true,
      });
    case JobTypes.CP_UPDATEDINSTALL_SUCCESS:
    case JobTypes.CP_UPDATEDINSTALL_FAILURE:
      return Object.assign({}, state, {
        installIsUpdating: false,
      });
    case JobTypes.CP_UPDATEDEMP_REQUEST:
      return Object.assign({}, state, {
        employeeIsUpdating: true,
      });
    case JobTypes.CP_UPDATEDEMP_SUCCESS:
    case JobTypes.CP_UPDATEDEMP_FAILURE:
      return Object.assign({}, state, {
        employeeIsUpdating: false,
      });
    case JobTypes.CP_ASSOCIATION_SUCCESS:
      return Object.assign({}, state, {
        associationsList: Object.values(action.response),
      });

    case JobTypes.CP_POST_ASSOCIATION_REQUEST:
      return Object.assign({}, state, {
        associationspost: true,
      });
    case JobTypes.CP_POST_ASSOCIATION_SUCCESS:
      return Object.assign({}, state, {
        associationspost: false,
        associationspostError: false,
      });
    case JobTypes.CP_POST_ASSOCIATION_FAILURE:
      return Object.assign({}, state, {
        associationspost: false,
        associationspostError: true,
      });
    case JobTypes.CP_DELETE_ASSOCIATION_REQUEST:
      return Object.assign({}, state, {
        associationsDelete: true,
      });
    case JobTypes.CP_DELETE_ASSOCIATION_SUCCESS:
    case JobTypes.CP_DELETE_ASSOCIATION_FAILURE:
      return Object.assign({}, state, {
        associationsDelete: false,
      });
    case JobTypes.CP_GET_ASSOCIATION_BYID_REQUEST:
      return Object.assign({}, state, {
        getAssociationsById: true,
      });
    case JobTypes.CP_GET_ASSOCIATION_BYID_SUCCESS:
      return Object.assign({}, state, {
        getAssociationsById: false,
      });
    case JobTypes.CP_GET_ASSOCIATION_BYID_FAILURE:
      return Object.assign({}, state, {
        getAssociationsById: false,
      });
    case JobTypes.DELETE_ASSOCIATION_COMPANY_REQUEST:
      return Object.assign({}, state, {
        deleteAssociationsCompanyById: true,
      });
    case JobTypes.DELETE_ASSOCIATION_COMPANY_SUCCESS:
    case JobTypes.DELETE_ASSOCIATION_COMPANY_FAILURE:
      return Object.assign({}, state, {
        deleteAssociationsCompanyById: false,
      });
    case JobTypes.EDIT_ASSOCIATION_COMPANY_REQUEST:
      return Object.assign({}, state, {
        editAssociationsCompanyById: true,
      });
    case JobTypes.EDIT_ASSOCIATION_COMPANY_SUCCESS:
      return Object.assign({}, state, {
        editAssociationsCompanyError: false,
      });
    case JobTypes.EDIT_ASSOCIATION_COMPANY_FAILURE:
      return Object.assign({}, state, {
        editAssociationsCompanyById: false,
        editAssociationsCompanyError: true,
      });
    case JobTypes.GET_ASSOCIATION_COMPANY_REQUEST:
      return Object.assign({}, state, {
        getAssociationsCompanyById: true,
      });
    case JobTypes.GET_ASSOCIATION_COMPANY_SUCCESS:
    case JobTypes.GET_ASSOCIATION_COMPANY_FAILURE:
      return Object.assign({}, state, {
        getAssociationsCompanyById: false,
      });
    case JobTypes.CLOUD_PUNCH_SUCCESS:
      return Object.assign({}, state, {});
    case JobTypes.CLOUD_PUNCH_REQUEST:
    case JobTypes.CLOUD_PUNCH_FAILURE:
      return Object.assign({}, state, {});
    case JobTypes.GET_CLOUD_PUNCH_SUCCESS:
      return Object.assign({}, state, {
        viewSettings: action.response,
      });
    case JobTypes.GET_CLOUD_PUNCH_REQUEST:
    case JobTypes.GET_CLOUD_PUNCH_FAILURE:
      return Object.assign({}, state, {});
    case JobTypes.EMPLOYEE_REPORT_CATCH_SUCCESS:
      return Object.assign({}, state, {});
    case JobTypes.EMPLOYEE_REPORT_CATCH_REQUEST:
    case JobTypes.EMPLOYEE_REPORT_CATCH_FAILURE:
      return Object.assign({}, state, {});
    default:
      return state;
  }
}
