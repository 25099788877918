import React from 'react'
import gif from '../../assets/images/loading-op.gif'

const Loading = (props) => {
  return (
    <img src={gif} alt="loading" className="loadinGif" />
  )
}

export default Loading
