import React from "react";
import SideBar from "./SideBar";
import Navigation from "./Navigation";
import { useState } from "react";

const EmployeeLayout = ({ children }) => {
  const [sidebar, setSidebar] = useState(false);

  const sidebarHandle = () => {
    setSidebar((prev) => !prev);
  };

  return (
    <>
      <Navigation sidebarValue={sidebar} sidebarHandle={sidebarHandle} />
      <div className={`emp-content ${sidebar && "sidebar"}`}>
        <SideBar />
        <div className="emp-body">
          <div className="emp-body-inner">{children}</div>
        </div>
      </div>
    </>
  );
};

export default EmployeeLayout;
