import { CALL_API, Schemas } from "../middleware/api";

export const EVENTS_REQUEST = "EVENTS_REQUEST";
export const EVENTS_SUCCESS = "EVENTS_SUCCESS";
export const EVENTS_FAILURE = "EVENTS_FAILURE";

const fetchEventsx = (intURL) => ({
  [CALL_API]: {
    types: [EVENTS_REQUEST, EVENTS_SUCCESS, EVENTS_FAILURE],
    endpoint: "api/integrations/" + intURL + "/events/0/50",
    schema: Schemas.EVENT,
  },
});

export const fetchEvents = (intURL) => (dispatch, getState) => {
  return dispatch(fetchEventsx(intURL));
};
