import * as EventTypes from '../actions/events'

const initialState = {
  error: '',
  isFetching: false,
}

export default function auth(state = initialState, action) {
  switch (action.type) {
    case EventTypes.EVENTS_REQUEST:
      return Object.assign({}, state, {
        error: '',
        isFetching: true,
      })
    case EventTypes.EVENTS_SUCCESS:
      return Object.assign({}, state, {
        error: '',
        isFetching: false,
      })
    case EventTypes.EVENTS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
      })
    default:
      return state
  }
}
