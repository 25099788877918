import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";

import useFetch from "../../../hooks/useFetch";
import Loading from "../../../../components/ui/Loading";
import SlackConfigurationForm from "./SlackConfigurationForm";
import X8x8ConfigurationForm from "./8x8ConfigurationForm";
import OneLoginConfigurationForm from "./OneLoginConfigurationForm";
import HubspotConfigurationForm from "./HubspotConfigurationForm";
import ZoomConfigurationForm from "./ZoomConfigurationForm";
import MineralConfigurationForm from "./MineralConfigurationForm";
import BizLibraryConfigurationForm from "./BizLibraryConfigurationForm";

const FormMap = {
  "8x8": X8x8ConfigurationForm,
  slack: SlackConfigurationForm,
  onelogin: OneLoginConfigurationForm,
  hubspot: HubspotConfigurationForm,
  zoom: ZoomConfigurationForm,
  mineral: MineralConfigurationForm,
  biz_library:BizLibraryConfigurationForm,
};

const IntegrationConfiguration = (props) => {
  const { handleConfigurationToggle, configurationState, integration } = props;
  const [connectorConfigFetch, loading] = useFetch();
  const [connectorConfigPost, loadingPost] = useFetch();
  const [formResponse, setFormResponse] = useState(null);

  const handleUpdate = async (data) => {
    const payload = {
      endPoint: `/api/connector_cred`,
      method: "post",
      body: {
        integration_id: integration.integration_id,
        name: integration.url,
        config: JSON.stringify(data),
      },
    };
    const response = await connectorConfigPost(payload);
    console.log("response", response);
    handleConfigurationToggle();
  };

  const fetchConfig = async () => {
    const payload = {
      endPoint: `/api/connector/config/${integration.integration_id}`,
      method: "get",
    };
    const response = await connectorConfigFetch(payload);
    if (response.message !== "error") {
      setFormResponse(response.data);
    }
  };

  useEffect(() => {
    if (configurationState && !isEmpty(integration)) {
      fetchConfig();
    }
  }, [configurationState]);

  const FormConfiguration = FormMap[integration.url];

  return (
    <div
      className={`dashboard-integration-doc association ${
        configurationState && "open"
      }`}
    >
      <div>
        <span className="icon-btn" onClick={handleConfigurationToggle}>
          <FontAwesomeIcon icon={solid("xmark")} />
        </span>
      </div>
      <div className="integration-configuration">
        <h1>Configuration</h1>

        <div className="association-form">
          {loading || loadingPost ? (
            <Loading />
          ) : FormConfiguration ? (
            <FormConfiguration
              handleUpdate={handleUpdate}
              formResponse={formResponse}
              handleConfigurationToggle={handleConfigurationToggle}
            />
          ) : (
            "Configuration form not available"
          )}
        </div>
      </div>
    </div>
  );
};

export default IntegrationConfiguration;
