import React, { Component } from "react";
import { Chart } from "react-google-charts";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

import "../../styles/customReportStyle.css";
import customerIcon from "../../assets/images/customers.png";
import Loading from "../ui/Loading";
import companyIcon from "../../assets/images/profit.png";
import ClickAwayListener from "../../utils/ClickAwayListener";
import { ws } from "../EmployeeReportModel";
import { connect } from "react-redux";
import {
  nextPageReport,
  prevPageReport,
  selectChangePageReport,
} from "../../actions/customReport";
import ProgressBar from "./ProgressBar";
import { makeCsv } from "../../utils/utils";

const dataChartStatic = [["Task", "Hours per Day"]];

const optionsChart = {
  is3D: true,
};

function getSheetData(data, header) {
  var fields = header;
  var sheetData = data.map(function (row) {
    return fields.map(function (fieldName) {
      return row[fieldName] ? row[fieldName] : "";
    });
  });
  sheetData.unshift(header);
  return sheetData;
}

class CustomReport extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      employeeList: [],
      employeeListCopy: [],
      employeeListCopyOr: [],
      companies: [],
      rowPerPage: 10,
      page: 0,
      totalPage: 1,
      dataChart: dataChartStatic,
      isLoading: false,
      showExport: false,
      filterCompany: "all",
      tableHeader: [],
      loadingExport: false,
    };
  }

  // start web socket code
  handleSocketConnection = () => {
    ws.onerror = (e) => {
      console.log("error", e);
    };

    ws.onopen = () => {
      console.info("web socket connected!");
    };

    ws.onclose = (e) => {
      console.error("web socket close!", e);
    };

    ws.onmessage = (event) => {
      const response = JSON.parse(event.data);
      console.log("response", response);
      // const { str } = this.props.match.params;
      // const reqId = atob(str) + "ajfldjf895432neknfdj34434nm";
      // console.log("response.report_name", response.report_name);
      // console.log("reqId", reqId);
      // response.report_name == reqId &&

      if (response.report_name && Array.isArray(response.report)) {
        const { rowPerPage, employeeListCopy } = this.state;
        const tableHeader = response.report[0];

        const totalPage = Math.ceil(employeeListCopy.length / rowPerPage - 1);

        let stateBody = {
          tableHeader,
          employeeList: response.report.slice(1, rowPerPage),
          employeeListCopy: response.report.slice(1),
          employeeListCopyOr: response.report.slice(1),
          totalPage,
          isLoading: false,
          // dataChart: Object.entries(companyData),
        };

        this.setState(stateBody);
      } else {
        console.log("response", response);
      }
    };
  };
  // end web socket code

  async componentDidMount() {
    // const { id } = this.props.match.params;
    // const { token, userToken } = getToken();
    // let endPoint = `/api/tlm/get/association/reports`;
    // let headers = {
    //   "Content-Type": "application/json",
    //   Authorization: token,
    //   OPUserToken: userToken,
    //   id,
    // };
    // fetch(endPoint, {
    //   headers,
    // });
    // let companyData = { Task: "Hours per Day" };
    // let company = {};
    // if (result.reports) {
    //   console.log(result.report_count);
    //   result.report_count.forEach(({ name, count }) => {
    //     companyData[name] = count;
    //     company[name] = count;
    //   });
    //   const tableHeader = result.reports[0];
    //   const reports = result.reports.slice(1);
    // const totalPage = Math.ceil(result.reports.length / rowPerPage - 1);
    //   this.setState({
    //     employeeList: reports.slice(0, rowPerPage),
    //     employeeListCopy: reports,
    //     employeeListCopyOr: reports,
    //     totalPage,
    //     dataChart: Object.entries(companyData),
    //     tableHeader,
    //   });
    // }
  }

  handleSelectChange = (e) => {
    const value = e.target.value;
    this.props.dispatch(selectChangePageReport(value));
  };

  handleFilterChange = (e) => {
    const { value } = e.target;
    const { employeeListCopyOr, rowPerPage } = this.state;

    if (value !== "all") {
      console.log("value", value);
      let dataFilter = employeeListCopyOr.filter((item) => {
        // eslint-disable-next-line
        return item.EmployeeEIN == value;
      });

      const totalPage = Math.ceil(dataFilter.length / rowPerPage - 1);

      this.setState({
        employeeList: dataFilter.slice(0, rowPerPage),
        employeeListCopy: dataFilter,
        totalPage,
        page: 0,
      });
    } else {
      const totalPage = Math.ceil(employeeListCopyOr.length / rowPerPage - 1);

      this.setState({
        employeeList: employeeListCopyOr.slice(0, rowPerPage),
        employeeListCopy: employeeListCopyOr,
        totalPage,
        page: 0,
      });
    }

    this.setState({ filterCompany: value });
  };

  handleNext = () => {
    const { dispatch } = this.props;
    dispatch(nextPageReport());
  };

  handlePrev = () => {
    this.props.dispatch(prevPageReport());
  };

  toggleDropDown = () => {
    this.setState({ showExport: !this.state.showExport });
  };

  handleExportExcel = async () => {
    const {
      customReport: { tableHeaders, tableRowCopy },
    } = this.props;

    const workbook = await XlsxPopulate.fromBlankAsync();

    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(tableRowCopy, tableHeaders);

    const totalColumns = sheetData[0].length;

    sheet1.cell("A1").value(sheetData);
    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style("bold", true);
    sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
    range.style("border", true);

    const workBookRes = await workbook.outputAsync();

    // console.log("workBookRes", workBookRes);
    // const myFile = new File([workBookRes], "myReportData.xlsx", {
    //   type: workBookRes.type,
    // });
    // console.log("myFile", myFile);

    saveAs(workBookRes, "report.xlsx");
    this.toggleDropDown();
  };

  handleExportFile = (fileType) => async () => {
    this.setState({ loadingExport: true });

    const {
      customReport: { tableHeaders, tableRowCopy },
      match: { params },
    } = this.props;

    let myFile;
    let event;

    if (fileType === "csv") {
      const csvData = tableRowCopy.map((item) => Object.values(item));

      const blob = await makeCsv([tableHeaders, ...csvData]);

      myFile = new File([blob], "report.csv", {
        type: blob.type,
      });
      event = "csv";
    } else {
      // excel sheet convert start
      const workbook = await XlsxPopulate.fromBlankAsync();
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(tableRowCopy, tableHeaders);
      const totalColumns = sheetData[0].length;

      sheet1.cell("A1").value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style("bold", true);
      sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
      range.style("border", true);

      const workBookRes = await workbook.outputAsync();

      myFile = new File([workBookRes], "report.xlsx", {
        type: workBookRes.type,
      });
      event = "excel";
    }

    let association_id =
      JSON.parse(atob(this.props.match.params.id))[0].association_id || 0;

    const formData = new FormData();
    let myHeaders = { Authorization: localStorage.getItem("opintx_token") };

    formData.append("event", event);
    formData.append("association_id", association_id);
    formData.append("report_id", params.reportId);
    formData.append("report", myFile);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };

    const response = await fetch(
      "/api/tlm/upload/custom/report",
      requestOptions
    );

    console.log("response", response);

    this.toggleDropDown();
    this.setState({ loadingExport: false });
  };

  render() {
    const { showExport, loadingExport } = this.state;

    const {
      customReport: {
        tableHeaders,
        tableRow,
        tableRowCopy,
        loading,
        totalPage,
        rowPerPage,
        currentPage,
        processPercentage,
        reportCount,
        companyData,
      },
    } = this.props;

    // console.log("tableRowCopy", tableHeaders);
    // console.log("tableRowCopy", tableRowCopy);
    // console.log("tableRow ", tableRow);

    const csvData = tableRowCopy.map((item) => Object.values(item));

    // console.log("csv", [tableHeaders, ...csvData]);

    return (
      <div
        className={`ein-report dashboard-report-emp ${
          processPercentage !== 100 && "curser-loading"
        }`}
      >
        <ProgressBar
          bgcolor={"#002e6c"}
          progress={processPercentage}
          height={7}
        />
        {loadingExport && (
          <div className="loadCont">
            <Loading />
          </div>
        )}
        <div className="heading">
          <h1>Association Company Members Report</h1>
        </div>
        <div className="report-container" ref={this.chartRef}>
          <div className="report-card-row">
            <div className="report-card">
              <div className="item-card">
                <div className="icon employee-icon">
                  <img src={customerIcon} alt="customerIcon" />
                </div>
                <div>
                  <h5>Total members</h5>
                  <h2>
                    {Array.isArray(tableRowCopy) ? tableRowCopy.length : 0}
                  </h2>
                  <p>Association total members</p>
                </div>
              </div>
            </div>
            <div className="report-card">
              <div className="item-card">
                <div className="icon company-icon">
                  <img src={companyIcon} alt="customerIcon" />
                </div>
                <div>
                  <h5>Total companies report</h5>
                  <h2>{Array.isArray(reportCount) ? reportCount.length : 0}</h2>
                  <p>Association total companies report</p>
                </div>
              </div>
            </div>
            <div className="report-card">
              <div className="item-card graph-item">
                <Chart
                  chartType="PieChart"
                  data={Object.entries(companyData)}
                  loader={<div>Loading Data...</div>}
                  options={optionsChart}
                  width={"100%"}
                  height={"195px"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="report-container">
          <div className="export-btn-container">
            <div className="option-container">
              <div className="btn-export" onClick={this.toggleDropDown}>
                <button>Export</button>
              </div>
            </div>
            {showExport && (
              <ClickAwayListener onClickAway={this.toggleDropDown}>
                <ul className="dropdown-list">
                  <li onClick={this.toggleDropDown}>
                    <CSVLink
                      filename={"MultipleEIN-Report.csv"}
                      data={[tableHeaders, ...csvData]}
                    >
                      CSV
                    </CSVLink>
                  </li>
                  <li onClick={this.handleExportExcel}>Excel</li>
                  {/* <li>
                    SAVE
                    <ul className="inner-drop-down">
                      <li onClick={this.handleExportFile("csv")}>In CSV</li>
                      <li onClick={this.handleExportFile("excel")}>In Excel</li>
                    </ul>
                  </li> */}
                  {/* <li>
                    <PDFDownloadLink
                      document={
                        <Template
                          employeeList={employeeList}
                          chartRef={this.chartRef}
                        />
                      }
                      fileName="report.pdf"
                    >
                      {({ loading }) => (loading ? "Loading.." : "PDF")}
                    </PDFDownloadLink>
                  </li> */}
                </ul>
              </ClickAwayListener>
            )}
          </div>
          {loading ? (
            <Loading />
          ) : Array.isArray(tableHeaders) && tableHeaders.length !== 0 ? (
            <div className="table-report">
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    {tableHeaders.map((item, index) => (
                      <th key={index}>{item}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(tableRow) &&
                    tableRow.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {rowPerPage === "All"
                            ? index + 1
                            : currentPage * rowPerPage + index + 1}
                        </td>
                        {Object.values(item).map((elem, index) => (
                          <td key={index}>{elem ? elem : ""}</td>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          ) : (
            <h1>No record found</h1>
          )}

          <div className="report-pagination">
            <label htmlFor="">Rows per page:</label>
            <select onChange={this.handleSelectChange} value={rowPerPage}>
              {[10, 25, 50, 100, 200, 500, 1000, "All"].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </select>
            <span>
              {rowPerPage === "All" ? (
                <>Total {tableRowCopy.length}</>
              ) : (
                <>
                  {currentPage * rowPerPage + 1} –{" "}
                  {currentPage * rowPerPage + rowPerPage} of{" "}
                  {tableRowCopy.length}
                </>
              )}
            </span>
            <button disabled={currentPage === 0} onClick={this.handlePrev}>
              Prev
            </button>
            <button
              disabled={rowPerPage === "All" || currentPage === totalPage}
              onClick={this.handleNext}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { customReport } = state;

  return {
    customReport,
  };
};

export default connect(mapStateToProps)(CustomReport);
