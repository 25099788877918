import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import useToggle from "../../hooks/useToggle";
import ClickAwayListener from "../../../utils/ClickAwayListener";
import { DateRangePicker } from "react-date-range";
import { CSVLink } from "react-csv";
import toast from "react-hot-toast";
import moment from "moment";
import Loading from "../../../components/ui/Loading";
import { dynamicCSSClass } from "../../../constants/constant";
import ReactPaginate from "react-paginate";

const ExportsLogsBody = (props) => {
  const {
    match: { params },
    history,
  } = props;

  const [getExportLogs, getLogsLoading] = useFetch();
  const [rangeShow, handleShow] = useToggle();
  const [rangeDate, setRangeDate] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const [rowData, setRowData] = useState([]);
  const [rowDataCopy, setRowDataCopy] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // this state define the pagination
  const [rowPerPage, setRowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  //this convert the pagination logic
  const pageCount = Math.ceil(rowData.length / rowPerPage);
  const offset = currentPage * rowPerPage;
  const rowPerPageValue = [10, 25, 50, 100, 150, 200];

  // this method fetch the logs API
  const fetchLogs = async () => {
    try {
      let { endDate, startDate } = rangeDate[0];
      endDate = moment(endDate).format("YYYY-MM-DD");
      startDate = moment(startDate).format("YYYY-MM-DD");

      const payload = {
        endPoint: `/api/tlm/get/logs/${params.logName}/${params.id}/${params.assId}/${startDate}/${endDate}`,
        method: "get",
      };

      const response = await getExportLogs(payload);

      if (response.hasOwnProperty("error")) {
        return;
      }

      if (response) {
        setRowData(Object.values(response));
        setRowDataCopy(Object.values(response));
      }
    } catch (error) {
      // setIsLoading(false);
      console.log("error", error);
    }
  };

  // data table sort function
  const handleSelectSort = (e) => {
    const { value } = e.target;
    setCurrentPage(0);
    setRowPerPage(value);
  };

  // handle change for the date range piker change
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setRangeDate([selection]);
  };

  // search data table for logs
  const onChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    const filterData = rowDataCopy.filter((item) => {
      return Object.values(item)
        .join()
        .toLowerCase()
        .includes(value.toLowerCase());
    });
    setRowData(filterData);
  };

  const handleFilter = () => {
    fetchLogs();
    handleShow();
  };

  // pagination select page function
  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  useEffect(() => {
    fetchLogs();
  }, []);

  // csv export data
  const csvData = rowData.map(
    ({
      que_id,
      line_number,
      log_types,
      status_code,
      status_message,
      date,
      time,
      message,
    }) =>
      Object.values({
        que_id,
        line_number,
        log_types,
        status_code,
        status_message,
        date,
        time,
        message,
      })
  );

  // csv header array for export csv file
  let csvHeader = [
    "que_id",
    "line_number",
    "log_types",
    "status_code",
    "status_message",
    "date",
    "time",
    "message",
  ];

  return (
    <div>
      <div className="product-modal-content productmodalcontent-sm">
        <div>
          <span onClick={history.goBack} className="back-btn">
            ← Back to list of your imports
          </span>
        </div>
        <h1 className="pb-0">Exports Logs</h1>
        <div>
          <div className="date-picker my-flex">
            <div className="one-col-sm-2">
              <button onClick={fetchLogs}>Refresh</button>
            </div>
            <div className="one-col-sm-10 d-flex">
              <select
                className="sort-select"
                onChange={handleSelectSort}
                defaultValue={rowPerPage}
              >
                {rowPerPageValue.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>
              <input
                type="text"
                placeholder="Search"
                className="mr-1"
                value={searchValue}
                onChange={onChange}
              />
              <button onClick={handleShow}>Filter Date</button>
              {rangeShow && (
                <div className="date-range-log">
                  <ClickAwayListener onClickAway={handleShow}>
                    <div>
                      <DateRangePicker
                        onChange={handleOnChange}
                        ranges={rangeDate}
                      />
                    </div>
                    <div className="log-btn">
                      <button onClick={handleFilter}>Filter</button>
                    </div>
                  </ClickAwayListener>
                </div>
              )}
              <CSVLink
                filename={"logs-file.csv"}
                className="csv-export-btn"
                data={[csvHeader, ...csvData]}
                onClick={() => {
                  if (csvData.length === 0) {
                    toast.error("Logs not found!");
                    return false;
                  }
                }}
              >
                Export
              </CSVLink>
            </div>
          </div>
          {getLogsLoading ? (
            <Loading />
          ) : (
            <>
              <div className="logs-table">
                <table className="table tablecstm ">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Queue Id</th>
                      <th className="text-center">Status type</th>
                      <th>Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rowData.length !== 0 ? (
                      rowData
                        .slice(offset, offset + rowPerPage)
                        .map(
                          ({
                            id,
                            message,
                            date,
                            time,
                            status_message,
                            que_id,
                          }) => (
                            <tr key={id}>
                              <td>
                                {moment(new Date(date)).format("MMM DD,YYYY") +
                                  " " +
                                  time}
                              </td>
                              <td className="queId">
                                <span
                                  // onClick={handleModal(que_id)}
                                  className="queue-id"
                                >
                                  {que_id}
                                </span>
                              </td>
                              <td className="text-center">
                                <span
                                  className={`status ${dynamicCSSClass[status_message]}`}
                                >
                                  {status_message}
                                </span>
                              </td>
                              <td>{message}</td>
                            </tr>
                          )
                        )
                    ) : (
                      <tr>
                        <td colSpan={4}>
                          <h3 className="text-center">
                            No logs found for today, please select another date
                          </h3>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next"
                onPageChange={handlePageClick}
                pageCount={pageCount}
                previousLabel="Previous"
                forcePage={currentPage}
                renderOnZeroPageCount={null}
                containerClassName={"pagination-container"}
                previousLinkClassName={"pagination__link"}
                nextLinkClassName={"pagination__link"}
                disabledClassName={"disabled"}
                activeClassName={"active"}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(ExportsLogsBody);
