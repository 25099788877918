import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
  getAssCustReportsById,
  getAssociationCustReportDelete,
  getAssociationCustReportNameDelete,
} from "../actions/cloudpunch";
import { connect } from "react-redux";
import "../styles/CloudPunchBody.css";
import Loading from "./ui/Loading";
import CustomReportTable from "./datatable/CustomReportTable";

class AssociationsCustomReport extends Component {
  constructor(p) {
    super(p);
    this.state = {
      associationsList: [],
      associationsListFilter: [],
      title: "",
      searchValue: "",
      selectSort: 10,
      isLoading: false,
      isReportLoading: false,
    };
  }

  fetchCustomReport = async () => {
    this.setState({ isLoading: true });
    const {
      dispatch,
      match: { params },
    } = this.props;

    const { response } = await dispatch(getAssCustReportsById(params.id));

    if (response) {
      this.setState({
        isLoading: false,
        associationsList: Object.values(response),
        isReportLoading: false,
      });
    } else {
      this.setState({
        isLoading: false,
        isReportLoading: false,
      });
    }
  };

  componentDidMount() {
    this.fetchCustomReport();
  }

  handleDeleteReportId =
    ({ report_id, company_id, report_name }) =>
    async () => {
      const {
        dispatch,
        match: { params },
      } = this.props;

      const body = {
        association_id: Number(params.id),
        company_id: Number(company_id),
        report_id: report_id,
        report_name: report_name,
      };

      this.setState({ isReportLoading: true });

      await dispatch(getAssociationCustReportDelete(body));

      this.fetchCustomReport();
    };

  handleDeleteReportName =
    ({ report_name }) =>
    async () => {
      const {
        dispatch,
        match: { params },
      } = this.props;

      const body = {
        association_id: Number(params.id),
        report_name: report_name,
      };

      this.setState({ isLoading: true });

      await dispatch(getAssociationCustReportNameDelete(body));

      this.fetchCustomReport();
    };

  requestSearch = (searchedVal) => {
    const filteredRows = this.state.associationsListFilter.filter((row) => {
      return row.name
        .toString()
        .toLowerCase()
        .includes(searchedVal.toString().toLowerCase());
    });
    this.setState({ associationsList: filteredRows });
  };

  handleSearch = (e) => {
    const { name, value } = e.target;
    this.setState({
      ...this.state,
      [name]: value,
    });
    this.requestSearch(value);
  };

  handleSelectSort = (e) => {
    const { value } = e.target;
    const sortRows = this.state.associationsListFilter.slice(0, value);
    this.setState({ associationsList: sortRows, selectSort: value });
  };

  render() {
    const { associationsList, isLoading, isReportLoading } = this.state;
    const { history, match } = this.props;
    // const selectValue = [10, 25, 50, 100, 150, 200];

    // console.log("match", match.params.id);

    return (
      <div>
        <div>
          <div className="breadcrumbs">
            <p onClick={history.goBack}>
              &larr; Back to list of your associations
            </p>
          </div>
          <h1>Association Custom Reports</h1>
          <div className="section installations">
            {isLoading && (
              <div className="loadCont">
                <Loading />
              </div>
            )}
            <h2>
              <div className="d-flexcstm">
                <div className="col">{this.state.title}</div>
                {/* <div className="col">
                  <select
                    className="sort-select"
                    onChange={this.handleSelectSort}
                    name=""
                    id=""
                  >
                    {selectValue.map((item) => (
                      <option key={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                  <input
                    type="text"
                    className="search-input"
                    name="searchValue"
                    value={this.state.searchValue}
                    onChange={this.handleSearch}
                    placeholder="Search"
                  />
                </div> */}
              </div>
            </h2>
            <div className="tableCont">
              {associationsList && associationsList.length === 0 ? (
                <div className="noapps">No reports have created yet</div>
              ) : (
                <CustomReportTable
                  history={history}
                  data={associationsList}
                  handleDeleteReportId={this.handleDeleteReportId}
                  handleDeleteReportName={this.handleDeleteReportName}
                  isReportLoading={isReportLoading}
                  assId={match.params.id}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { cp_installations, cp_employees, cp_enrollments } = state.entities;
  const {
    beenLoadedOnce,
    allFetching,
    installationsList,
    employeesList,
    installsFetching,
    empsAreFetching,
    associationsList,
    getAssociationsById,
  } = state.cloudpunch;
  // console.log("state.cloudpunch",state.cloudpunch.getAssociationsById)
  const { costCenters, isFetching: cdFetching } = state.company_data;
  const { companyInfo, userInfo } = state.products;
  // console.log("userInfo userInfo",state)
  const costCentersFetching = cdFetching["cost-centers"];

  const instsListJoin =
    installationsList === undefined
      ? []
      : installationsList.map((id) => {
          let thisIn = Object.assign({}, cp_installations[id]);
          return thisIn;
        });

  const empsListJoin =
    employeesList === undefined
      ? []
      : employeesList.map((id) => {
          let thisIn = Object.assign({}, cp_employees[id]);
          thisIn.enrollments = thisIn.enrollments.map((id) => {
            {
              let thisEn = Object.assign({}, cp_enrollments[id]);
              return thisEn;
            }
          });
          return thisIn;
        });

  return {
    beenLoadedOnce,
    allFetching,
    installations: instsListJoin,
    associations: associationsList,
    employees: empsListJoin,
    installsFetching,
    empsAreFetching,
    companyInfo,
    userInfo,
    costCenters: costCenters !== undefined ? costCenters : null,
    costCentersFetching,
    getAssociationsById,
  };
};

export default withRouter(connect(mapStateToProps)(AssociationsCustomReport));
