import React, { useEffect } from "react";
import useForm from "../../../hooks/useForm";
import { isEmpty } from "lodash";

const initialValues = {
  client_id: "",
  client_secret: "",
  redirect_url: "",
  created_at: "",
  updated_at: "",
};
const HubspotConfigurationForm = (props) => {
  const { handleUpdate, formResponse, handleConfigurationToggle } = props;
  const { formValue, handleChange, handleSubmit, setFormValue } = useForm({
    defaultValue: initialValues,
  });

  const onSubmit = (data) => {
    handleUpdate(data);
  };

  useEffect(() => {
    if (formResponse) {
      setFormValue(() => {
        return {
          client_id: formResponse.config.client_id,
          client_secret: formResponse.config.client_secret,
          redirect_url: formResponse.config.redirect_url,
          created_at: formResponse.created_at,
          updated_at: formResponse.updated_at,
        };
      });
    }
  }, [formResponse]);

  return (
    <>
      <h4>Application configuration</h4>
      <div>
        <div className="one-control-input">
          <label htmlFor="">
            Client ID <span className="color-red">*</span>
          </label>
          <input
            type="text"
            placeholder="Client ID"
            name="client_id"
            value={formValue.client_id}
            onChange={handleChange}
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
        <div className="one-control-input">
          <label htmlFor="">
            Client secret <span className="color-red">*</span>
          </label>
          <input
            type="text"
            placeholder="Secret ID"
            name="client_secret"
            value={formValue.client_secret}
            onChange={handleChange}
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
        <div className="one-control-input">
          <label htmlFor="">
            Redirect URL <span className="color-red">*</span>
          </label>
          <input
            type="text"
            placeholder="Redirect URL"
            name="redirect_url"
            value={formValue.redirect_url}
            onChange={handleChange}
          />
          {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
        </div>
        {!isEmpty(formValue.created_at) && (
          <div className="one-control-input">
            <label htmlFor="">Create At</label>
            <input
              type="text"
              placeholder="Create At"
              name="redirect_url"
              value={formValue.created_at}
              onChange={handleChange}
              disabled
            />
            {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
          </div>
        )}
        {!isEmpty(formValue.updated_at) && (
          <div className="one-control-input">
            <label htmlFor="">Updated At</label>
            <input
              type="text"
              placeholder="Updated At"
              name="redirect_url"
              value={formValue.updated_at}
              onChange={handleChange}
              disabled
            />
            {/* {!isEmpty() && <span className="color-red">{errors.host}</span>} */}
          </div>
        )}
      </div>
      <div className="mul-association-btn my-flex my-justify-content-end">
        <button className="ms-2" onClick={handleConfigurationToggle}>
          Cancel
        </button>
        <button className="ms-2" onClick={handleSubmit(onSubmit)}>
          Save
        </button>
      </div>
    </>
  );
};

export default HubspotConfigurationForm;
