import React, { useEffect, useState } from "react";
import { productsAvailable } from "../../../constants/constant";
import { connect } from "react-redux";
import {
  editCompanyProductsList,
  fetchProducts,
} from "../../../actions/products";
import Loading from "../../../components/ui/Loading";

const ProductSettings = (props) => {
  const { products, dispatch, productsUpdate } = props;
  let prodAvailableToAdd = { ...productsAvailable };
  const [valueData, setValueData] = useState({
    addProduct: null,
    removeProduct: null,
  });

  if (products) {
    products.forEach((prod) => {
      delete prodAvailableToAdd[prod.product_url];
    });
  }

  const handleAddProduct = async () => {
    await dispatch(editCompanyProductsList(valueData.addProduct, "ADD"));
    await dispatch(fetchProducts());
  };

  const handleRemoveProduct = async () => {
    await dispatch(editCompanyProductsList(valueData.removeProduct, "DELETE"));
    await dispatch(fetchProducts());
  };

  const handleChange = (e) => {
    const { value, name } = e.target;
    setValueData({ ...valueData, [name]: value });
  };

  useEffect(() => {
    if (Object.keys(prodAvailableToAdd).length !== 0) {
      setValueData((prev) => ({
        ...prev,
        addProduct: Object.keys(prodAvailableToAdd)[0],
      }));
    }
    if (products.length !== 0) {
      setValueData((prev) => ({
        ...prev,
        removeProduct: products[0].product_url,
      }));
    }
  }, []);

  return (
    <div className="middleproductcard-section-overlay">
      <div className="system-admin-outer">
        <h3>System Admin Actions</h3>
        {productsUpdate ? (
          <Loading />
        ) : (
          <>
            <div className="system-admin-inner systemadmininner-select">
              <label>Add a Product To This Company</label>
              <div>
                {prodAvailableToAdd &&
                Object.keys(prodAvailableToAdd).length > 0 ? (
                  <>
                    <select
                      name="addProduct"
                      value={valueData.addProduct ? valueData.addProduct : ""}
                      onChange={handleChange}
                    >
                      {Object.keys(prodAvailableToAdd).map((prod) => {
                        return (
                          <option key={prod} value={prod}>
                            {prodAvailableToAdd[prod].label}
                          </option>
                        );
                      })}
                    </select>
                    <button onClick={handleAddProduct}>Add Products</button>
                  </>
                ) : (
                  <div>
                    All OnePoint Connect products have already been added
                  </div>
                )}
              </div>
            </div>
            <div className="system-admin-inner product-deactivate systemadmininner-select">
              <label>Deactivate a Product</label>
              {Array.isArray(products) && products.length ? (
                <div>
                  <select
                    name="removeProduct"
                    value={
                      valueData.removeProduct ? valueData.removeProduct : ""
                    }
                    onChange={handleChange}
                  >
                    {products.map((item) => {
                      return (
                        <option
                          key={item.product_name}
                          value={item.product_url}
                        >
                          {item.product_name}
                        </option>
                      );
                    })}
                  </select>
                  <button onClick={handleRemoveProduct}>
                    Deactivate Product
                  </button>
                </div>
              ) : (
                <div>No products have been added</div>
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    list,
    isFetching: productsAreFetching,
    companyInfo,
    userInfo,
    productsUpdate,
  } = state.products;
  const { products } = state.entities;

  const prodListJoin =
    list === undefined
      ? []
      : list.map((prodURL) => {
          return products[prodURL];
        });

  return {
    productsAreFetching,
    products: prodListJoin,
    companyInfo,
    userInfo,
    productsUpdate,
  };
};

export default connect(mapStateToProps)(ProductSettings);
