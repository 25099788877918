import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash";
import * as yup from "yup";

import useForm from "../../hooks/useForm";
import CheckBox from "../UI/CheckBox";
import useFetch from "../../hooks/useFetch";
import SchedulerTypeContainer from "./SchedulerTypeContainer";
import { convertToISO, convertToUTC } from "../../../utils/utils";
import { importCompanySFTP } from "../../../actions/customReport";
import Loading from "../../../components/ui/Loading";
import ImportSFTPConnectionTest from "./ImportSFTPConnectionTest";
import useToggle from "../../hooks/useToggle";
import { ImportTypes } from "../../../constants/importConfigurationtypes";

const defaultValue = {
  host: "",
  port: 22,
  password: "",
  username: "",
  remote_file_path: "",
  period: "day",
  typeId: "",
  day: "",
  interval_time: "",
  interval_date: "",
  logType: "All",
};

const configurationValueSchema = yup.object().shape({
  host: yup.string().required(),
  port: yup.number().required(),
  password: yup.string().required(),
  username: yup.string().required(),
  remote_file_path: yup.string().required(),
  period: yup.string(),
  typeId: yup.string().required(),
  day: yup.string().when("period", {
    is: (period) => period === "weekly" || period === "biweekly",
    then: (schema) => schema.required(),
  }),
  interval_time: yup.string().required(),
  interval_date: yup.string().when("period", {
    is: (period) => period === "one-time" || period === "monthly",
    then: (schema) => schema.required(),
  }),
  logType: yup.string(),
});

const connectValueSchema = yup.object().shape({
  host: yup.string().required(),
  port: yup.number().required(),
  password: yup.string().required(),
  username: yup.string().required(),
  remote_file_path: yup.string().required(),
});

const ImportConfiguration = (props) => {
  const {
    history,
    dispatch,
    match: { params },
    importLoading,
    userInfo,
  } = props;

  const {
    formValue,
    handleChange,
    handleSubmit,
    errors,
    handleSetFormValue,
    handleSchemaCheck,
  } = useForm({
    defaultValue,
    validationSchema: configurationValueSchema,
  });
  // const [fetchData, fetchLoading, response] = useFetch();
  const [getImportOptionByIdSFTP, getSFTPLoading] = useFetch();
  const [selectedType, setSelectType] = useState(null);
  const [response ] = useState({ Imports: ImportTypes });
  const [toggleState, handleToggle] = useToggle(false);


  const handleSelectChange = (e) => {
    const { value } = e.target;
    handleChange(e);
    if (!isEmpty(response) && Array.isArray(response.Imports)) {
      let selected = response.Imports.find(
        (item) => Number(value) === Number(item.TypeId)
      );
      setSelectType(selected);
    }
  };

  const onSubmit = async (data) => {
    try {
      const body = {
        event: selectedType.FileType,
        type_id: Number(data.typeId),
        type_name: selectedType.Label,
        host: data.host,
        username: data.username,
        password: data.password,
        remote_file_path: data.remote_file_path,
        port: Number(data.port),
        period: data.period,
        interval_time: convertToUTC(data.interval_time),
        interval_date: data.interval_date,
        day: data.day,
        email_notification_settings: `${JSON.stringify({
          log_types: data.logType,
          email_recipients: [],
        })}`,
      };

      if (params.id) {
        body.id = Number(params.id);
      }

      const { response } = await dispatch(importCompanySFTP(body));
      console.log("response", response);

      if (response.message === "success") {
        history.push(`/app/product/imports`);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  // fetch the types from API
  // const fetchOption = async () => {
  //   try {
  //     const payload = {
  //       endPoint: "/api/tlm/all/import/options",
  //     };
  //     // fetchData(payload);
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };

  // fetch the edit
  const fetchEditRecord = async () => {
    try {
      const payload = {
        endPoint: `/api/tlm/imports/company/sftp/${params.id}`,
      };
      let response = await getImportOptionByIdSFTP(payload);
      console.log("response", response);

      response = !isEmpty(response) ? response : [];
      if (response.length !== 0) {
        const str = response[0].email_notification_settings;
        const settingsData = JSON.parse(str);

        const data = {
          host: response[0].host,
          port: response[0].port,
          username: response[0].username,
          password: "",
          remote_file_path: response[0].remote_file_path,
          period: response[0].period,
          typeId: response[0].type_id,
          day: response[0].day,
          interval_time: convertToISO(response[0].interval_time),
          interval_date: response[0].interval_date,
          settings: settingsData.email_recipients,
          logType: settingsData.log_types,
          // event: response[0].event,
        };
        handleSetFormValue(data);
        setSelectType({
          FileType: response[0].event,
          TypeId: response[0].type_id,
          Label: response[0].type_name,
        });
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const QuickConnect = async () => {
    try {
      const checkError = await handleSchemaCheck(connectValueSchema, {
        host: formValue.host,
        port: formValue.port,
        password: formValue.password,
        username: formValue.username,
        remote_file_path: formValue.remote_file_path,
      });
      if (checkError) {
        handleToggle();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // fetchOption();
    if (!isEmpty(params.id)) {
      fetchEditRecord();
    }
  }, []);

  return (
    <div>
      <div className="product-modal-content productmodalcontent-lg">
        {toggleState && (
          <ImportSFTPConnectionTest
            handleToggle={handleToggle}
            formValue={formValue}
            dispatch={dispatch}
            userInfo={userInfo}
            reportType="import_report"
            reportId={!isEmpty(params.id) ? params.id : null}
          />
        )}
        <div>
          <span onClick={history.goBack} className="back-btn">
            ← Back to list of your imports
          </span>
        </div>
        <div className="import-schedule-report">
          <h1>Import Report Configuration</h1>
          {getSFTPLoading || importLoading.loading ? (
            <Loading />
          ) : (
            <div className="imp-container-report">
              <div className="my-flex ">
                <div className="one-col-2 my-flex my-align-item-center mb-2">
                  <strong>
                    Company SFTP <span className="color-red">*</span>
                  </strong>
                </div>
                <div className="one-col-10 my-flex-grow-1 mb-2">
                  <div className="my-flex">
                    <div className="one-col-2">
                      <div className="one-control-input">
                        <input
                          type="text"
                          placeholder="Host"
                          onChange={handleChange}
                          name="host"
                          value={formValue.host}
                        />
                        {!isEmpty(errors.host) && (
                          <span className="color-red">{errors.host}</span>
                        )}
                      </div>
                    </div>
                    <div className="one-col-2">
                      <div className="one-control-input">
                        <input
                          type="text"
                          placeholder="Username"
                          onChange={handleChange}
                          name="username"
                          value={formValue.username}
                          autoComplete="new-username"
                        />
                        {!isEmpty(errors.username) && (
                          <span className="color-red">{errors.username}</span>
                        )}
                      </div>
                    </div>
                    <div className="one-col-2">
                      <div className="one-control-input">
                        <input
                          type="password"
                          placeholder="Password"
                          onChange={handleChange}
                          name="password"
                          autoComplete="new-password"
                          value={formValue.password}
                        />
                        {!isEmpty(errors.password) && (
                          <span className="color-red">{errors.password}</span>
                        )}
                      </div>
                    </div>
                    <div className="one-col-2">
                      <div className="one-control-input">
                        <input
                          type="number"
                          placeholder="Port"
                          onChange={handleChange}
                          name="port"
                          value={formValue.port}
                        />
                        {!isEmpty(errors.port) && (
                          <span className="color-red">{errors.port}</span>
                        )}
                      </div>
                    </div>
                    <div className="one-col-2">
                      <div className="one-control-input">
                        <input
                          type="text"
                          placeholder="Report file path"
                          onChange={handleChange}
                          name="remote_file_path"
                          value={formValue.remote_file_path}
                        />
                        {!isEmpty(errors.remote_file_path) && (
                          <span className="color-red">
                            {errors.remote_file_path}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="one-col-2">
                      <button
                        className="one-control-btn"
                        onClick={QuickConnect}
                      >
                        Quick connect
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-flex mb-2">
                <div className="one-col-2 my-flex my-align-item-center">
                  <strong>
                    Types <span className="color-red">*</span>
                  </strong>
                </div>
                <div className="one-col-10">
                  <div className="my-flex">
                    <div className="one-col-4">
                      <div className="one-control-select">
                        <select
                          name="typeId"
                          onChange={handleSelectChange}
                          value={formValue.typeId}
                        // disabled={!isEmpty(params.id)}
                        >
                          <option disabled value="">
                            Please select type
                          </option>
                          {!isEmpty(response) &&
                            Array.isArray(response.Imports) ? (
                            response.Imports.map((item) => {
                              return (
                                <option key={item.TypeId} value={item.TypeId}>
                                  {item.Label}
                                </option>
                              );
                            })
                          ) : (
                            <option disabled>Types not found</option>
                          )}
                        </select>
                        {!isEmpty(errors.typeId) && (
                          <span className="color-red">{errors.typeId}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-flex mb-2">
                <div className="one-col-2 my-flex my-align-item-center">
                  <strong>
                    Event <span className="color-red">*</span>
                  </strong>
                </div>
                <div className="one-col-10">
                  <div className="my-flex">
                    <div className="one-col-4 ">
                      <div className="one-control-input">
                        <input
                          type="text"
                          placeholder="Event"
                          disabled
                          value={
                            !isEmpty(selectedType) ? selectedType.FileType : ""
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-flex mb-2">
                <div className="one-col-2 my-flex my-align-item-center">
                  <strong>
                    Scheduler <span className="color-red">*</span>
                  </strong>
                </div>
                <div className="one-col-10">
                  <div className="my-flex">
                    <div className="one-col-2">
                      <CheckBox
                        name={"period"}
                        label={"Daily"}
                        value={"day"}
                        checked={"day" === formValue.period}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="one-col-2">
                      <CheckBox
                        name={"period"}
                        label={"Weekly"}
                        value={"weekly"}
                        checked={"weekly" === formValue.period}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="one-col-3">
                      <CheckBox
                        name={"period"}
                        label={"Biweekly"}
                        value={"biweekly"}
                        checked={"biweekly" === formValue.period}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="one-col-2">
                      <CheckBox
                        name={"period"}
                        label={"Monthly"}
                        value={"monthly"}
                        checked={"monthly" === formValue.period}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="one-col-3">
                      <CheckBox
                        name={"period"}
                        label={"One Time"}
                        value={"one-time"}
                        checked={"one-time" === formValue.period}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-flex mb-2">
                <div className="one-col-2"></div>
                <div className="one-col-10">
                  <SchedulerTypeContainer
                    handleChange={handleChange}
                    formValue={formValue}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="my-flex mb-2">
                <div className="one-col-2 my-flex my-align-item-center">
                  <strong>Notification logs types</strong>
                </div>
                <div className="one-col-10">
                  <div className="my-flex">
                    <div className="one-col-4">
                      <div className="one-control-select">
                        <select
                          name="logType"
                          value={formValue.logType}
                          onChange={handleChange}
                        >
                          <option value="All">All</option>
                          <option value="Success">Success</option>
                          <option value="Failed">Failed</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-flex my-justify-content-end">
                <button
                  className="one-control-btn"
                  onClick={handleSubmit(onSubmit)}
                >
                  Schedule
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    products: { userInfo },
    company_data,
    customReport: { importLog },
  } = state;

  const { employees } = company_data;

  return { importLoading: importLog, userInfo, employees };
};

export default withRouter(connect(mapStateToProps)(ImportConfiguration));
