import * as IntTypes from "../actions/integrations";

const initialState = {
  error: "",
  isFetching: false,
  v: false,
  list: [],
  beenFetched: false,
  companyInfo: null,
  isDisconnecting: false,
  settingsAreSaving: false,
  quickApplySponsorship: {},
};

export default function auth(state = initialState, action) {
  switch (action.type) {
    case IntTypes.INTEGRATIONS_REQUEST:
      return Object.assign({}, state, {
        error: "",
        isFetching: true,
      });
    case IntTypes.INTEGRATIONS_SUCCESS:
      return Object.assign({}, state, {
        error: "",
        isFetching: false,
        beenFetched: true,
        list: action.response.result.integrations,
        companyInfo: action.response.result.company,
      });
    case IntTypes.INTEGRATIONS_FAILURE:
      return Object.assign({}, state, {
        error: action.error,
        isFetching: false,
      });
    case IntTypes.INTEGRATION_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        error: "",
        settingsAreSaving: true,
      });
    case IntTypes.INTEGRATION_DISCONNECT_REQUEST:
      return Object.assign({}, state, {
        error: "",
        isDisconnecting: true,
      });
    case IntTypes.INTEGRATION_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        error: "",
        settingsAreSaving: false,
      });
    case IntTypes.INTEGRATION_SETTINGS_FAILURE:
      return Object.assign({}, state, {
        error: "An error occured, setting were not saved",
        settingsAreSaving: false,
      });
    case IntTypes.INTEGRATION_DISCONNECT_SUCCESS:
    case IntTypes.INTEGRATION_DISCONNECT_FAILURE:
      return Object.assign({}, state, {
        error: "",
        isDisconnecting: false,
      });
    case IntTypes.QUICK_APPLY_SPONSORSHIP_FAILURE:
    case IntTypes.QUICK_APPLY_SPONSORSHIP_REQUEST:
    case IntTypes.QUICK_APPLY_SPONSORSHIP_SUCCESS:
      return Object.assign({}, state, {
        quickApplySponsorship: action.response,
      });
    case IntTypes.ADD_QUICK_APPLY_SPONSORSHIP_FAILURE:
    case IntTypes.ADD_QUICK_APPLY_SPONSORSHIP_REQUEST:
    case IntTypes.ADD_QUICK_APPLY_SPONSORSHIP_SUCCESS:
      return Object.assign({}, state, {});
    case IntTypes.GET_QUICK_APPLY_APPLICATION_FAILURE:
    case IntTypes.GET_QUICK_APPLY_APPLICATION_REQUEST:
    case IntTypes.GET_QUICK_APPLY_APPLICATION_SUCCESS:
      return Object.assign({}, state, {});
    case IntTypes.INTEGRATION_CONNECT_DIS_FAILURE:
    case IntTypes.INTEGRATION_CONNECT_DIS_REQUEST:
    case IntTypes.INTEGRATION_CONNECT_DIS_SUCCESS:
      return Object.assign({}, state, {});
    default:
      return state;
  }
}
