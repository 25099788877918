import { CALL_API, Schemas } from '../middleware/api'

export const GOOGLEHIRE_REQUEST = 'GOOGLEHIRE_REQUEST'
export const GOOGLEHIRE_SUCCESS = 'GOOGLEHIRE_SUCCESS'
export const GOOGLEHIRE_FAILURE = 'GOOGLEHIRE_FAILURE'

const fetchGoogleHireInfox = () => ({
  [CALL_API]: {
    types: [GOOGLEHIRE_REQUEST, GOOGLEHIRE_SUCCESS, GOOGLEHIRE_FAILURE],
    endpoint: 'api/googlehire/info',
    schema: Schemas.GH_APPLICANTS_LIST,
  }
})

export const fetchGoogleHireInfo = () => (dispatch, getState) => {
  return dispatch(fetchGoogleHireInfox())
}

export const GOOGLEHIRE_DEAUTH_REQUEST = 'GOOGLEHIRE_DEAUTH_REQUEST'
export const GOOGLEHIRE_DEAUTH_SUCCESS = 'GOOGLEHIRE_DEAUTH_SUCCESS'
export const GOOGLEHIRE_DEAUTH_FAILURE = 'GOOGLEHIRE_DEAUTH_FAILURE'

const deauthGoogleHireInfox = () => ({
  [CALL_API]: {
    types: [GOOGLEHIRE_DEAUTH_REQUEST, GOOGLEHIRE_DEAUTH_SUCCESS, GOOGLEHIRE_DEAUTH_FAILURE],
    endpoint: 'api/googlehire/deauthenticate',
    postdata: {}
  }
})

export const deauthGoogleHireInfo = () => (dispatch, getState) => {
  return dispatch(deauthGoogleHireInfox())
}