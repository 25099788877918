import React, { Component } from "react";
import PropTypes from "prop-types";

import "../../styles/SettingComponents.css";

class SelectSetting extends Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  render() {
    const { label, value, onChange, options, description } = this.props;

    return (
      <div>
        <div className="selectSetting">
          {label !== "" && <label>{label}</label>}
          <div className="selectCont">
            <select value={value} onChange={onChange}>
              {options.map((opt) => (
                <option key={opt.label} value={opt.value}>
                  {opt.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="note">{description}</div>
      </div>
    );
  }
}

export default SelectSetting;
