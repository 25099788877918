import React, { Component } from "react";
import Modal from "../ui/Modal";
import { connect } from "react-redux";
import { Toaster } from "react-hot-toast";
import {
  ASSIGN_APPLICANTS_BG_CHECK_FAILURE,
  assignApplicant,
} from "../../actions/integrations";
import Loading from "../ui/Loading";

export const packageOption = [
  { title: "Package 1", id: "658771232131" },
  { title: "Package 2", id: "658771232131" },
  { title: "Package 3", id: "658771232131" },
];

class BackgroundVerificationModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isToggle: false,
      loading: false,
      packageId: "",
      packageError: null,
      APIError: null,
    };
  }

  handleToggle = () => {
    this.setState({
      isToggle: !this.state.isToggle,
    });
  };

  handleAssign = async () => {
    const { data, dispatch } = this.props;
    const { packageId } = this.state;

    if (!packageId) {
      this.setState({
        packageError: "Please select package!",
      });
      return;
    }
    this.setState({
      packageError: null,
      loading: true,
    });
    const body = {
      ApplicantId: data.op_applicant_id,
      InvitationId: packageId,
      OpJobId: data.op_job_id,
    };

    const response = await dispatch(assignApplicant(body));

    console.log("response", response);

    const stateBody = {
      loading: false,
    };

    if (response.type === ASSIGN_APPLICANTS_BG_CHECK_FAILURE) {
      stateBody.APIError = response.error;
    }

    this.setState(stateBody);
  };

  handleChangePackage = (e) => {
    const { value } = e.target;
    this.setState({
      packageId: value,
    });
  };

  render() {
    const { isToggle, packageError, loading, APIError } = this.state;
    const { title, data } = this.props;

    return (
      <>
        <Toaster position="top-right" reverseOrder={false} />
        {isToggle && (
          <Modal closeModal={this.handleToggle}>
            <div>
              <div className="bg-check">
                <h1 className="text-center">Assign for BG check</h1>
                {packageError && (
                  <p className="color-red package-para">
                    Please select package
                  </p>
                )}
                {APIError && <p className="color-red">{APIError}</p>}
                {loading ? (
                  <Loading />
                ) : (
                  <div className="bg-container">
                    <div>
                      <p>
                        <strong>ID</strong> : {data.op_applicant_id}
                      </p>
                      <p>
                        <strong>Name</strong> : {data.applicant_first_name}{" "}
                        {data.applicant_last_name}
                      </p>
                      <select name="" id="" onChange={this.handleChangePackage}>
                        <option value="">Select package</option>
                        {packageOption.map(({ title, id }, index) => (
                          <option key={id + index} value={id}>
                            {title}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div>
                      <button onClick={this.handleAssign}>Assign</button>
                    </div>
                  </div>
                )}
              </div>
              {/* <div>
                <h1 className="text-center">Track applicants</h1>
                <table>
                  <tr>
                    <th>S.No</th>
                    <th>Status</th>
                    <th>Description</th>
                    <th>Time & date</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Inprogress</td>
                    <td>description data</td>
                    <td>13:37:04 05/01/2023</td>
                  </tr>
                </table>
              </div> */}
            </div>
          </Modal>
        )}
        <button onClick={this.handleToggle} title="View">
          {title}
        </button>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { userInfo, companyInfo } = state.products;

  return {
    userInfo,
    companyInfo,
  };
};

export default connect(mapStateToProps)(BackgroundVerificationModel);
