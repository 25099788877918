import React, { useState } from "react";
import "../../css/employeeStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { productSettingsToggle } from "../../../actions/dashboard";

const sideBarDataAdmin = [
  { title: "Product", to: "/app" },
  {
    title: "Company",
    open: false,
    dropDown: [
      {
        title: "Company Status",
        to: "/app/status-company",
      },
      {
        title: "Add company",
        to: "/app/add-company",
      },
      {
        title: "Company list",
        to: "/app/list-company",
      },
    ],
  },
  { title: "Settings", to: "/app/settings" },
];

const sideBarData = [
  { title: "Product", to: "/app" },
  // { title: "Settings", to: "/app/settings" },
];

const SideBar = ({ history, location, userInfo, dispatch }) => {
  const [sideBarState, setSideBarData] = useState(sideBarData);
  const [sideBarAdminState, setSideBarAdminData] = useState(sideBarDataAdmin);

  const handleGotoPage = (to) => () => {
    if (to === "/app/settings") {
      dispatch(productSettingsToggle());
    } else {
      history.push(to);
    }
  };

  const handleOpenDropDown = (index) => () => {
    if (userInfo && userInfo.is_system_admin) {
      setSideBarAdminData((prev) => {
        const copy = [...prev];
        copy[index].open = !copy[index].open;
        return copy;
      });
    } else {
      setSideBarData((prev) => {
        const copy = [...prev];
        copy[index].open = !copy[index].open;
        return copy;
      });
    }
  };

  // const dropDownOpenCheck = (down) => {
  //   const index = down.findIndex(({ to }) => to === location.pathname);
  //   return -1 < index ? true : false;
  // };

  return (
    <div className="emp-side-bar">
      <ul className="dash-menu">
        {(userInfo && userInfo.is_system_admin
          ? sideBarAdminState
          : sideBarState
        ).map((item, index) => {
          if (item.to) {
            return (
              <li key={index}>
                <span
                  className={item.to === location.pathname ? "active" : ""}
                  onClick={handleGotoPage(item.to)}
                >
                  <FontAwesomeIcon icon={solid("chart-column")} />
                  <span>{item.title}</span>
                </span>
              </li>
            );
          } else {
            return (
              <li key={index}>
                <span
                  onClick={handleOpenDropDown(index)}
                  className={item.to === location.pathname ? "active" : ""}
                >
                  <div className="my-flex my-justify-content-between">
                    <span>
                      <FontAwesomeIcon icon={regular("building")} />
                      {item.title}
                    </span>
                    <span>
                      {item.open ? (
                        <FontAwesomeIcon icon={solid("arrow-up")} />
                      ) : (
                        <FontAwesomeIcon icon={solid("arrow-down")} />
                      )}
                    </span>
                  </div>
                </span>
                {item.open ? (
                  <ul className="drop-down">
                    {item.dropDown.map((down, i) => {
                      return (
                        <li key={i} onClick={handleGotoPage(down.to)}>
                          <span
                            className={
                              down.to === location.pathname ? "active" : ""
                            }
                          >
                            <FontAwesomeIcon
                              className="me-2"
                              icon={solid("chart-column")}
                            />
                            <span>{down.title}</span>
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                ) : null}
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { userInfo, isFetching: productsAreFetching } = state.products;

  return { userInfo, productsAreFetching };
};

export default withRouter(connect(mapStateToProps)(SideBar));
