import { CALL_API, Schemas } from '../middleware/api'

export const NEW_TOKEN_REQUEST = 'NEW_TOKEN_REQUEST'
export const NEW_TOKEN_SUCCESS = 'NEW_TOKEN_SUCCESS'
export const NEW_TOKEN_FAILURE = 'NEW_TOKEN_FAILURE'

const postOauthCodex = (code,name) => ({
  [CALL_API]: {
    types: [ NEW_TOKEN_REQUEST, NEW_TOKEN_SUCCESS, NEW_TOKEN_FAILURE ],
    endpoint: 'api/oauth/code',
    schema: Schemas.INTEGRATION,
    postdata: { code, name },
  }
})

export const postOauthCode = (code,name) => (dispatch, getState) => {
  return dispatch(postOauthCodex(code,name))
}
