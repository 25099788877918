import { isEmpty } from "lodash";
import React from "react";
import * as yup from "yup";

import useForm from "../../hooks/useForm";

const defaultValue = {
  host: "",
  port: 22,
  password: "",
  username: "",
  remote_file_path: "",
  company_id: "",
};

const configurationValueSchema = yup.object().shape({
  host: yup.string().required(),
  port: yup.number().required(),
  password: yup.string().required(),
  username: yup.string().required(),
  remote_file_path: yup.string().required(),
  company_id: yup.string().required(),
});

const CustomCompanyReportSFTP = ({ id, handleAddSFTPCompany, response }) => {
  const { formValue, handleChange, handleSubmit, errors } = useForm({
    defaultValue,
    validationSchema: configurationValueSchema,
  });

  const onSubmit = (data) => {
    handleAddSFTPCompany(data);
  };

  return (
    <div className="my-flex mb-2">
      <div className="one-col-1 my-flex my-align-item-center sftp-section">
        <strong>Company SFTP</strong>
      </div>
      <div className="one-col-11 ">
        <div className="my-flex">
          <div className="one-col-2 sftp-input ">
            <div className="one-control-select">
              <select
                name="company_id"
                onChange={handleChange}
                value={formValue.company_id}
              >
                <option disabled value="">
                  Please select company
                </option>
                {!isEmpty(response) && Array.isArray(response.companies) ? (
                  response.companies.map((item) => {
                    return (
                      <option key={item.ID} value={item.ID}>
                        {item.name}
                      </option>
                    );
                  })
                ) : (
                  <option disabled>Types not found</option>
                )}
              </select>
              {!isEmpty(errors.company_id) && (
                <span className="color-red">{errors.company_id}</span>
              )}
            </div>
          </div>
          <div className="one-col-2 sftp-input">
            <div className="one-control-input">
              <input
                type="text"
                placeholder="Host"
                onChange={handleChange}
                name="host"
                value={formValue.host}
              />
              {!isEmpty(errors.host) && (
                <span className="color-red">{errors.host}</span>
              )}
            </div>
          </div>
          <div className="one-col-2 sftp-input">
            <div className="one-control-input">
              <input
                type="text"
                placeholder="Username"
                onChange={handleChange}
                name="username"
                value={formValue.username}
                autoComplete="new-username"
              />
              {!isEmpty(errors.username) && (
                <span className="color-red">{errors.username}</span>
              )}
            </div>
          </div>
          <div className="one-col-2 sftp-input">
            <div className="one-control-input">
              <input
                type="password"
                placeholder="Password"
                onChange={handleChange}
                name="password"
                autoComplete="new-password"
                value={formValue.password}
              />
              {!isEmpty(errors.password) && (
                <span className="color-red">{errors.password}</span>
              )}
            </div>
          </div>
          <div className="one-col-2 sftp-input">
            <div className="one-control-input">
              <input
                type="number"
                placeholder="Port"
                onChange={handleChange}
                name="port"
                value={formValue.port}
              />
              {!isEmpty(errors.port) && (
                <span className="color-red">{errors.port}</span>
              )}
            </div>
          </div>
          <div className="one-col-2 sftp-input">
            <div className="one-control-input">
              <input
                type="text"
                placeholder="Report file path"
                onChange={handleChange}
                name="remote_file_path"
                value={formValue.remote_file_path}
              />
              {!isEmpty(errors.remote_file_path) && (
                <span className="color-red">{errors.remote_file_path}</span>
              )}
            </div>
          </div>
          <div className="one-col-2 sftp-input">
            <button
              className="one-control-btn sftp-add-btn"
              onClick={handleSubmit(onSubmit)}
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomCompanyReportSFTP;
