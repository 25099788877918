export const ImportTypes = [
    {
        "TypeId": 183,
        "Label": "ACA Employee Data",
        "FileType": "Excel",
        "Description": "Import ACA employee history"
    },
    {
        "TypeId": 151,
        "Label": "Account Hardware Custom Settings",
        "FileType": "Excel",
        "Description": "Import account custom settings per terminal (Example: access policy, grace in)"
    },
    {
        "TypeId": 232,
        "Label": "Accrual Balances (XLSX)",
        "FileType": "Excel 2007",
        "Description": "Remaining time off balances by category for each employee (XLSX Format)"
    },
    {
        "TypeId": 273,
        "Label": "Accrual Schedule",
        "FileType": "Excel",
        "Description": "Entries for Personal Accrual Schedule"
    },
    {
        "TypeId": 242,
        "Label": "Additional Compensation History",
        "FileType": "Excel",
        "Description": "Update or add additional compensation entry by date"
    },
    {
        "TypeId": 198,
        "Label": "Applicants",
        "FileType": "Excel",
        "Description": "Import Applicants"
    },
    {
        "TypeId": 182,
        "Label": "Asset Assignments",
        "FileType": "Excel",
        "Description": "Import assets (property) assignments"
    },
    {
        "TypeId": 164,
        "Label": "Assets",
        "FileType": "Excel",
        "Description": "Import assets (property)"
    },
    {
        "TypeId": 252,
        "Label": "Automatic Pay Grade Steps",
        "FileType": "Excel",
        "Description": "Create the tables for the automatic steps."
    },
    {
        "TypeId": 156,
        "Label": "Bank Account Transactions",
        "FileType": "Excel",
        "Description": "Import bank account transactions. This import can be started from the list of transactions assosiated with the bank"
    },
    {
        "TypeId": 236,
        "Label": "Bankruptcy Wizard Deductions",
        "FileType": "Excel",
        "Description": "Add bankruptcy deduction entry by start and end date"
    },
    {
        "TypeId": 122,
        "Label": "Base Compensation History",
        "FileType": "Excel",
        "Description": "Update or add base compensation entry by date"
    },
    {
        "TypeId": 214,
        "Label": "Benefit Coverage Rate Table",
        "FileType": "Excel",
        "Description": "Import Benefit Plan Coverage Rate Table"
    },
    {
        "TypeId": 230,
        "Label": "Benefit Plan Feature",
        "FileType": "Excel",
        "Description": "Import Benefit Plan Feature"
    },
    {
        "TypeId": 231,
        "Label": "Benefit Plan Premiums",
        "FileType": "Excel",
        "Description": "Import Benefit Plan Premiums"
    },
    {
        "TypeId": 157,
        "Label": "Benefit Plans",
        "FileType": "Excel",
        "Description": "Import employee benefit plans"
    },
    {
        "TypeId": 244,
        "Label": "Checklists & SOC",
        "FileType": "Excel",
        "Description": "Import checklists and SOC"
    },
    {
        "TypeId": 233,
        "Label": "Child Support Wizard Deductions",
        "FileType": "Excel",
        "Description": "Add child support deduction entry by start and end date"
    },
    {
        "TypeId": 173,
        "Label": "Company Information",
        "FileType": "Excel",
        "Description": "Import company information"
    },
    {
        "TypeId": 259,
        "Label": "Company Products",
        "FileType": "Excel",
        "Description": "Import Company Products"
    },
    {
        "TypeId": 206,
        "Label": "Company Settings",
        "FileType": "Excel",
        "Description": "Import Company Settings"
    },
    {
        "TypeId": 186,
        "Label": "Company Tax",
        "FileType": "Excel",
        "Description": "Import company taxes"
    },
    {
        "TypeId": 185,
        "Label": "Cost Center Lists",
        "FileType": "Excel",
        "Description": "Lists of cost centers"
    },
    {
        "TypeId": 106,
        "Label": "Cost Centers (Extended Layout)",
        "FileType": "Excel",
        "Description": "Unit to allocate time against, (Example: division, department, client, task)"
    },
    {
        "TypeId": 105,
        "Label": "Cost Centers (Standard Layout)",
        "FileType": "Excel",
        "Description": "Unit to allocate time against, (Example: division, department, client, task)"
    },
    {
        "TypeId": 107,
        "Label": "Cost Centers (XML)",
        "FileType": "XML",
        "Description": "Unit to allocate time against, (Example: division, department, client, task) (XML Format)"
    },
    {
        "TypeId": 204,
        "Label": "Counter Definitions",
        "FileType": "Excel",
        "Description": "Import Counters Definitions"
    },
    {
        "TypeId": 197,
        "Label": "Counters",
        "FileType": "Excel",
        "Description": "Import Counters"
    },
    {
        "TypeId": 267,
        "Label": "Courses/Certifications",
        "FileType": "Excel",
        "Description": "Import Courses/Certifications"
    },
    {
        "TypeId": 159,
        "Label": "Courses/Certifications",
        "FileType": "Excel",
        "Description": "Import Courses/Certifications"
    },
    {
        "TypeId": 235,
        "Label": "Creditor Garnishment Wizard Deductions",
        "FileType": "Excel",
        "Description": "Add creditor garnishment deduction entry by start and end date"
    },
    {
        "TypeId": 285,
        "Label": "Custom Translations",
        "FileType": "Excel",
        "Description": "Import Custom Translations for custom translatable objects. This import can be started from Custom Translations report."
    },
    {
        "TypeId": 286,
        "Label": "Deduction Codes",
        "FileType": "Excel 2007",
        "Description": "Import company deduction codes"
    },
    {
        "TypeId": 271,
        "Label": "Delivery Policies",
        "FileType": "Excel",
        "Description": "Import Delivery Policies, Cover and Instruction Pages"
    },
    {
        "TypeId": 193,
        "Label": "Demographic Custom Fields",
        "FileType": "Excel",
        "Description": "Import Demographic Custom Fields"
    },
    {
        "TypeId": 287,
        "Label": "Earning Codes",
        "FileType": "Excel 2007",
        "Description": "Import company earning codes"
    },
    {
        "TypeId": 155,
        "Label": "Education",
        "FileType": "Excel",
        "Description": "Import employee education history"
    },
    {
        "TypeId": 152,
        "Label": "Employee Credentials",
        "FileType": "Excel",
        "Description": "Import employee credential (Example: drivers license)"
    },
    {
        "TypeId": 266,
        "Label": "Employee Credentials",
        "FileType": "Excel",
        "Description": "Import employee credential (Example: drivers license)"
    },
    {
        "TypeId": 103,
        "Label": "Employee Daily Schedules",
        "FileType": "Excel",
        "Description": "Employee schedule for a day (Example: On 4/10/2004 employee is scheduled for 8 hours)"
    },
    {
        "TypeId": 104,
        "Label": "Employee Daily Schedules (XML)",
        "FileType": "XML",
        "Description": "Employee schedule for a day (XML Format)"
    },
    {
        "TypeId": 125,
        "Label": "Employee Direct Deposit Settings",
        "FileType": "Excel",
        "Description": "Add direct deposit entry by start and end date"
    },
    {
        "TypeId": 201,
        "Label": "Employee Effective Dated Badges",
        "FileType": "Excel",
        "Description": "Import Employee Effective Dated Badges"
    },
    {
        "TypeId": 165,
        "Label": "Employee Inventory Items",
        "FileType": "Excel",
        "Description": "Import employee inventory items"
    },
    {
        "TypeId": 184,
        "Label": "Employee Job Change History",
        "FileType": "Excel",
        "Description": "Import Employee Job Change History"
    },
    {
        "TypeId": 162,
        "Label": "Employee Managed Cost Centers",
        "FileType": "Excel",
        "Description": "Import Employee Managed Cost Centers"
    },
    {
        "TypeId": 153,
        "Label": "Employee Notes",
        "FileType": "Excel",
        "Description": "Import employee notes"
    },
    {
        "TypeId": 126,
        "Label": "Employee Payroll History",
        "FileType": "Excel",
        "Description": "Import historical payroll data"
    },
    {
        "TypeId": 127,
        "Label": "Employee Payroll History (Columnar)",
        "FileType": "Excel",
        "Description": "Import historical payroll data"
    },
    {
        "TypeId": 245,
        "Label": "Employee Primary National ID",
        "FileType": "Excel",
        "Description": "Update primary national ID type"
    },
    {
        "TypeId": 216,
        "Label": "Employee Schedules (All)",
        "FileType": "Excel",
        "Description": "Employee Schedules import that can import both predefined schedules (Daily Rules) or On The Fly Schedules"
    },
    {
        "TypeId": 174,
        "Label": "Employee Skills",
        "FileType": "Excel",
        "Description": "Import Employee Skills"
    },
    {
        "TypeId": 150,
        "Label": "Employee Tax Settings",
        "FileType": "Excel",
        "Description": "Import Employee Tax Settings"
    },
    {
        "TypeId": 129,
        "Label": "Employee Void Payroll History",
        "FileType": "Excel",
        "Description": "Import historical void payroll data"
    },
    {
        "TypeId": 175,
        "Label": "Employee Work Availability & Preferences",
        "FileType": "Excel",
        "Description": "Import Employee Work Availability & Preferences"
    },
    {
        "TypeId": 100,
        "Label": "Employees",
        "FileType": "Excel",
        "Description": "Personal information, profiles, and settings per record"
    },
    {
        "TypeId": 209,
        "Label": "Employees (XLSX)",
        "FileType": "Excel 2007",
        "Description": "Personal information, profiles, and settings per record (XLSX Format)"
    },
    {
        "TypeId": 101,
        "Label": "Employees (XML)",
        "FileType": "XML",
        "Description": "Personal information, profiles, and settings per record (XML Format)"
    },
    {
        "TypeId": 160,
        "Label": "Employees Contacts",
        "FileType": "Excel",
        "Description": "Employee contacts per record"
    },
    {
        "TypeId": 176,
        "Label": "Employees Contracts",
        "FileType": "Excel",
        "Description": "Import Employees Contracts"
    },
    {
        "TypeId": 102,
        "Label": "Employees for Small Business Edition",
        "FileType": "Excel",
        "Description": "Personal information, and settings per record"
    },
    {
        "TypeId": 211,
        "Label": "Employees for Small Business Edition (XLSX)",
        "FileType": "Excel 2007",
        "Description": "Personal information, and settings per record (XLSX Format)"
    },
    {
        "TypeId": 192,
        "Label": "Entitlement",
        "FileType": "Excel",
        "Description": "Import Entitlement"
    },
    {
        "TypeId": 280,
        "Label": "Expected Counters",
        "FileType": "Excel",
        "Description": "Import Expected Counters"
    },
    {
        "TypeId": 128,
        "Label": "External Payroll Data Upload",
        "FileType": "Excel",
        "Description": "Import payroll data from an external system. (Example: T&A data)"
    },
    {
        "TypeId": 158,
        "Label": "External Payroll Data Upload (Columnar)",
        "FileType": "Excel",
        "Description": "Import payroll data from an external system. (Example: T&A data)"
    },
    {
        "TypeId": 118,
        "Label": "Extra Pay",
        "FileType": "Excel",
        "Description": "Import Extra Pay"
    },
    {
        "TypeId": 241,
        "Label": "Federal Levy Wizard Deductions",
        "FileType": "Excel",
        "Description": "Add federal levy deduction entry by start and end date"
    },
    {
        "TypeId": 238,
        "Label": "Federal Student Loan Wizard Deductions",
        "FileType": "Excel",
        "Description": "Add federal student loan deduction entry by start and end date"
    },
    {
        "TypeId": 210,
        "Label": "GL Account Descriptions",
        "FileType": "Excel",
        "Description": "Import general ledger account descriptions"
    },
    {
        "TypeId": 178,
        "Label": "GL Codes",
        "FileType": "Excel",
        "Description": "Import general ledger codes"
    },
    {
        "TypeId": 181,
        "Label": "Group Permissions",
        "FileType": "XML",
        "Description": "Import Group Permissions"
    },
    {
        "TypeId": 212,
        "Label": "Holiday Profiles",
        "FileType": "Excel",
        "Description": "Import Holiday Profiles"
    },
    {
        "TypeId": 170,
        "Label": "HR Custom Fields",
        "FileType": "Excel",
        "Description": "Import HR custom fields"
    },
    {
        "TypeId": 163,
        "Label": "Incidents",
        "FileType": "Excel",
        "Description": "Import employee incidents"
    },
    {
        "TypeId": 281,
        "Label": "InTouch Installations",
        "FileType": "Excel",
        "Description": "Import multiple InTouch Devices"
    },
    {
        "TypeId": 166,
        "Label": "Inventory Profile Fields",
        "FileType": "Excel",
        "Description": "Import inventory profile fields"
    },
    {
        "TypeId": 180,
        "Label": "Involuntary Deductions",
        "FileType": "Excel",
        "Description": "Add involuntary deduction entry by start and end date"
    },
    {
        "TypeId": 202,
        "Label": "Job Requisition Custom Fields",
        "FileType": "Excel",
        "Description": "Import Job Requisition Custom Fields"
    },
    {
        "TypeId": 168,
        "Label": "Jobs",
        "FileType": "Excel",
        "Description": "Import jobs"
    },
    {
        "TypeId": 275,
        "Label": "Labor Distribution Profiles",
        "FileType": "Excel 2007",
        "Description": "Create or Update Labor Distribution Profiles"
    },
    {
        "TypeId": 191,
        "Label": "Leave Case Entries",
        "FileType": "Excel",
        "Description": "Import Leave Case Entries"
    },
    {
        "TypeId": 190,
        "Label": "Leave Cases",
        "FileType": "Excel",
        "Description": "Import Leave Cases"
    },
    {
        "TypeId": 213,
        "Label": "Leave Cases (v2)",
        "FileType": "Excel",
        "Description": "Import Leave Cases (v2)"
    },
    {
        "TypeId": 188,
        "Label": "Lookup Lists",
        "FileType": "Excel",
        "Description": "Import Lookup Lists"
    },
    {
        "TypeId": 218,
        "Label": "Managed Scheduled Cost Centers",
        "FileType": "Excel",
        "Description": "Import Scheduled Cost Centers"
    },
    {
        "TypeId": 255,
        "Label": "Master KYC Import",
        "FileType": "Excel 2007",
        "Description": "Master KYC Import"
    },
    {
        "TypeId": 278,
        "Label": "Non-Primary Assignment Rate Update",
        "FileType": "Excel",
        "Description": "This import can be used to update an entry or create a new entry in the existing rate table in the non-primary assignment."
    },
    {
        "TypeId": 179,
        "Label": "On The Fly Schedule",
        "FileType": "Excel",
        "Description": "On the fly schedule for an employee"
    },
    {
        "TypeId": 187,
        "Label": "Pay Grades",
        "FileType": "Excel",
        "Description": "Import Pay Grades"
    },
    {
        "TypeId": 249,
        "Label": "Payment Warehouse Transactions",
        "FileType": "Excel",
        "Description": "Import payment warehouse transactions to create manual transactions for ACH returns and tax adjustments."
    },
    {
        "TypeId": 251,
        "Label": "Payroll Expected Amounts",
        "FileType": "Excel",
        "Description": "Import Payroll Expected Amounts"
    },
    {
        "TypeId": 258,
        "Label": "Payroll Expected Amounts (Columnar)",
        "FileType": "Excel",
        "Description": "Import Payroll Expected Amounts"
    },
    {
        "TypeId": 256,
        "Label": "Payroll Export Codes",
        "FileType": "Excel",
        "Description": "Create or Update Payroll Export Codes"
    },
    {
        "TypeId": 263,
        "Label": "Payroll Services",
        "FileType": "Excel",
        "Description": "Import Payroll Services"
    },
    {
        "TypeId": 203,
        "Label": "Performance Review",
        "FileType": "Excel",
        "Description": "Import performance review"
    },
    {
        "TypeId": 169,
        "Label": "Performance Review Competencies",
        "FileType": "Excel",
        "Description": "Import performance review competencies"
    },
    {
        "TypeId": 257,
        "Label": "Periodic Reviews",
        "FileType": "Excel 2007",
        "Description": "Periodic Reviews"
    },
    {
        "TypeId": 161,
        "Label": "Points",
        "FileType": "Excel",
        "Description": "Import Employee Point History"
    },
    {
        "TypeId": 269,
        "Label": "Pro Notification Settings",
        "FileType": "Excel",
        "Description": "Import Pro Notification Settings"
    },
    {
        "TypeId": 208,
        "Label": "Punch Code Lookup Tables",
        "FileType": "Excel",
        "Description": "Import Punch Code Lookup Tables"
    },
    {
        "TypeId": 113,
        "Label": "Rate Table",
        "FileType": "Excel",
        "Description": "Monetary rate assigned to an individual or a group based on specified criteria (Example: shift, cost center, project)"
    },
    {
        "TypeId": 119,
        "Label": "Raw Time Punches",
        "FileType": "Excel",
        "Description": "Import Raw Time Punches"
    },
    {
        "TypeId": 262,
        "Label": "Risk Factor Items",
        "FileType": "Excel 2007",
        "Description": "Risk Factor Items"
    },
    {
        "TypeId": 261,
        "Label": "Risk Factors",
        "FileType": "Excel 2007",
        "Description": "Risk Factors"
    },
    {
        "TypeId": 111,
        "Label": "Schedule Daily Rules",
        "FileType": "Excel",
        "Description": "Specific predefined work times, (Example: 8:00 - 5:30; 8:00 - 10:00 in time, 8 hours per day)"
    },
    {
        "TypeId": 196,
        "Label": "Schedule Shifts",
        "FileType": "Excel",
        "Description": "Import Schedule Shifts"
    },
    {
        "TypeId": 217,
        "Label": "Scheduled Cost Centers",
        "FileType": "Excel",
        "Description": "Import to add or remove employee scheduled cost centers"
    },
    {
        "TypeId": 124,
        "Label": "Scheduled Deduction",
        "FileType": "Excel",
        "Description": "Add deduction entry by start and end date"
    },
    {
        "TypeId": 123,
        "Label": "Scheduled Earning",
        "FileType": "Excel",
        "Description": "Add earning entry by start and end date"
    },
    {
        "TypeId": 115,
        "Label": "Skills",
        "FileType": "Excel",
        "Description": "Import Skills"
    },
    {
        "TypeId": 283,
        "Label": "Smart Tax Bulk Migration",
        "FileType": "Excel",
        "Description": "Import Companies for Smart Tax Bulk Migration"
    },
    {
        "TypeId": 239,
        "Label": "State Levy Wizard Deductions",
        "FileType": "Excel",
        "Description": "Add state levy deduction entry by start and end date"
    },
    {
        "TypeId": 240,
        "Label": "State Student Loan Wizard Deductions",
        "FileType": "Excel",
        "Description": "Add state student loan deduction entry by start and end date"
    },
    {
        "TypeId": 226,
        "Label": "Succession Plans",
        "FileType": "Excel",
        "Description": "Import employee succession plans"
    },
    {
        "TypeId": 207,
        "Label": "Telephony Phones",
        "FileType": "Excel",
        "Description": "Import A-Telephony Phones"
    },
    {
        "TypeId": 221,
        "Label": "Template Shifts",
        "FileType": "Excel",
        "Description": "Import Template Shifts"
    },
    {
        "TypeId": 172,
        "Label": "Termination Details",
        "FileType": "Excel",
        "Description": "Import termination details"
    },
    {
        "TypeId": 171,
        "Label": "Termination Reasons",
        "FileType": "Excel",
        "Description": "Import termination reasons"
    },
    {
        "TypeId": 117,
        "Label": "Time Adjustments",
        "FileType": "Excel",
        "Description": "Import Time Adjustments"
    },
    {
        "TypeId": 116,
        "Label": "Time Entries",
        "FileType": "Excel",
        "Description": "Import Time Entries"
    },
    {
        "TypeId": 108,
        "Label": "Time Off Categories",
        "FileType": "Excel",
        "Description": "Specific buckets used to apply time off against, (Example: sick, personal, vacation, jury duty)"
    },
    {
        "TypeId": 120,
        "Label": "Time Off Requests",
        "FileType": "Excel",
        "Description": "Import Time Off Requests"
    },
    {
        "TypeId": 109,
        "Label": "Time Off Taken",
        "FileType": "Excel",
        "Description": "Hours already taken (or scheduled out) per employee"
    },
    {
        "TypeId": 154,
        "Label": "Training/Certifications",
        "FileType": "Excel",
        "Description": "Import employee training/certification"
    },
    {
        "TypeId": 268,
        "Label": "Training/Certifications",
        "FileType": "Excel",
        "Description": "Import employee training/certification"
    },
    {
        "TypeId": 189,
        "Label": "Vehicles",
        "FileType": "Excel",
        "Description": "Import Vehicles"
    },
    {
        "TypeId": 177,
        "Label": "Vendor PR",
        "FileType": "Excel",
        "Description": "Import vendors"
    },
    {
        "TypeId": 112,
        "Label": "Work Schedule Cycle",
        "FileType": "Excel",
        "Description": "Recurring work schedule cycles (Daily rules assigned to work days)"
    },
    {
        "TypeId": 222,
        "Label": "Work Schedule Cycle (XLSX)",
        "FileType": "Excel 2007",
        "Description": "Recurring work schedule cycles (Daily rules assigned to work days) (XLSX Format)"
    },
    {
        "TypeId": 246,
        "Label": "Workday Breakdown Templates",
        "FileType": "Excel",
        "Description": "Create or Update Workday Breakdown Templates"
    },
    {
        "TypeId": 167,
        "Label": "Workers Claims",
        "FileType": "Excel",
        "Description": "Import workers claims"
    },
    {
        "TypeId": 225,
        "Label": "Workload Templates",
        "FileType": "Excel",
        "Description": "Import Workload Templates"
    }
]
