import React from 'react'
import { connect } from 'react-redux'

function withAuthInfo(WrappedComponent) {
  const newComponent = class extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  const mapStateToProps = (state, ownProps) => {
    const { isAuthenticated } = state.auth
    return {
      isAuthenticated
    }
  }

  return connect(mapStateToProps)(newComponent)
}

export default withAuthInfo
