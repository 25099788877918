import React from 'react'

export const Documentation = (
  <div className="documentationBody">
    <p>
      In order to get started, a Box administrator with the permissions required to create and suspend users must connect above in the Connection Status section. This will redirect them to a dialogue where they grant Onepoint access to perform actions on their behalf.
    </p>
    <p>
      If you wish to temporarily stop these automatic actions from occuring, select Disabled in the Integration Settings area.
    </p>
    <p>
      <b>Important</b>: under the New Username Settings, select the email address format that best suites your company. The Box Username should match the user's email address.
    </p>
    <p>
      The new user will receive an email with a link that will allow them to create a password.
    </p>
    <p>
      Upon termination in OnePoint, the user's account in Box will be made Inactive. They will be blocked from logging in.
    </p>
    <p>
      We process new hires on the <b>Date Hired</b> in OnePoint, and we process terminations using the <b>Date Terminated</b>.
    </p>
    <div className="subHead">
      Common Error Causes
    </div>
    <ul>
      <li>
        Connecting to Box with a user that does not have admin permissions. The user must be allowed to create and update users. If not, this integration will fail.
      </li>
      <li>
        Terminating an employee while their Primary Email in OnePoint is different than their Username in Box. These must match for this integration to deactivate the correct User account in Box.
      </li>
      <li>
        Hiring an employee while their Primary Email in OnePoint is already being used as a Username in Box. Box Usernames must be unique across <b>all</b> organizations.
      </li>
    </ul>
  </div>
)
