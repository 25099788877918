import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Modal from "../ui/Modal";
import { useState } from "react";
import { isEmpty } from "lodash";
import Loading from "../ui/Loading";
import { oldAppPath } from "../../constants/constant";

const CompanyAssociationReport = ({
  reports,
  report_name,
  handleDeleteReportId,
  handleDeleteReportName,
  isReportLoading,
  history,
  association_id,
  id,
  assId,
}) => {
  const [modalToggle, setModelToggle] = useState(false);

  const handleToggle = () => {
    setModelToggle((prev) => !prev);
  };

  return (
    <>
      <button title="View Report" onClick={handleToggle}>
        <FontAwesomeIcon icon={solid("eye")} />
      </button>

      <button
        style={{ marginLeft: "10px" }}
        title="View Logs"
        onClick={() =>
          history.push(
            `${oldAppPath}/exports/logs/custom_report/${id}/${assId}`
          )
        }
      >
        <FontAwesomeIcon icon={solid("clock-rotate-left")} />
      </button>

      <button
        title="Schedule Report"
        onClick={() =>
          history.push(`${oldAppPath}/reports/schedule/${association_id}/${id}`)
        }
        style={{ marginLeft: "10px" }}
      >
        <FontAwesomeIcon icon={solid("calendar-days")} />
      </button>

      <button
        title="Delete Report"
        style={{ marginLeft: "10px" }}
        onClick={handleDeleteReportName({ report_name })}
      >
        <FontAwesomeIcon icon={solid("trash-alt")} />
      </button>
      {modalToggle && (
        <Modal closeModal={handleToggle}>
          <div>
            <h1>{report_name}</h1>
            {isReportLoading ? (
              <Loading />
            ) : (
              <table>
                <thead>
                  <tr>
                    <th>Report ID</th>
                    <th>Name</th>
                    <th>Short Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {!isEmpty(reports) &&
                    reports.map(
                      ({ name, short_name, report_id, company_id }) => (
                        <tr key={company_id}>
                          <td>{report_id}</td>
                          <td>{name}</td>
                          <td>{short_name}</td>
                          <td>
                            <button
                              title="View Report"
                              onClick={handleDeleteReportId({
                                name,
                                short_name,
                                report_id,
                                company_id,
                                report_name,
                              })}
                            >
                              <FontAwesomeIcon icon={solid("trash-alt")} />
                            </button>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            )}
          </div>
        </Modal>
      )}
    </>
  );
};

const CustomReportTable = ({
  data,
  handleDeleteReportId,
  handleDeleteReportName,
  isReportLoading,
  history,
  assId,
}) => {
  return (
    <div className="ass-custom-report-table">
      <table className="table tablecstm">
        <thead>
          <tr>
            <th className="col1">S.NO</th>
            <th className="col2">Report Name</th>
            <th className="col3">Count</th>
            <th className="col3">Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={`installs${item.ID}`}>
              <td className="col2">{index + 1 || "N/A"}</td>
              <td className="col5">{item.report_name || "N/A"}</td>
              <td className="col5">
                {!isEmpty(item.reports) ? item.reports.length : "0"}
              </td>
              <td>
                <CompanyAssociationReport
                  {...item}
                  history={history}
                  handleDeleteReportId={handleDeleteReportId}
                  handleDeleteReportName={handleDeleteReportName}
                  isReportLoading={isReportLoading}
                  assId={assId}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomReportTable;
