import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import HideShow from "../ui/HideShow";
import Modal from "../ui/Modal";

import {
  newIndeedSponsorship,
  updateIndeedSponsorship,
  newZRSponsorship,
  fetchJobsIndeed,
} from "../../actions/jobs";

import "../../styles/Jobs.css";

import Loading from "../ui/Loading";
import IntegrationSettings from "../IntegrationSettings";
import ReactPaginate from "react-paginate";

export const IndeedDocumentation = (
  <div className="documentationBody">
    <p>
      This integration will automatically post your OnePoint job requisitions to
      Indeed. By default, the jobs are posted for free. They will show up in
      "Organic Search Results" on Indeed. Optionally, you can use this tool to
      "Sponsor" your posting, allowing it to show up at the top of Indeed search
      result pages.
    </p>
    <p>
      This integration also increases the likelyhood of job seekers applying to
      your jobs by providing the "Indeed Apply" option, which allows the
      applicant to apply without leaving Indeed. According to Indeed, "data
      shows that an integration with Indeed Apply, on average, increases
      application rates by 60%".
    </p>
    <p>
      When applicants apply on Indeed, they will receive an email with a link
      instructing them where to apply for your job in OnePoint. You can also
      visit OnePoint Connect to view all job site applicants.
    </p>
    <p>
      <b>
        If you'd like to opt-out of this integration, simply switch the "ON/OFF"
        setting under the Integration Settings to "DISABLED".
      </b>
    </p>
    <div className="subHead">Sponsorship Details</div>
    <p>
      Indeed’s pay-per-click model is designed to drive the hires you need. You
      pay based on job Ad performance and set the budgets you are willing to pay
      to target candidates. You are only charged for sponsorship when a job
      seeker clicks on your job.
    </p>
    <p>
      By selecting to sponsor a job, Indeed will run a 30-day advertising
      campaign for the job. If the job is removed from OnePoint before the 30th
      day, the job will be removed from Indeed and the advertising campaign will
      stop. You will only be billed for the actual number of clicks on this job.
    </p>
  </div>
);

export const ZRDocumentation = (
  <div className="documentationBody">
    <p>
      This integration will automatically post your OnePoint job requisitions to
      ZipRecruiter. By default, the jobs are posted for free. They will show up
      in "Organic Search Results" on Indeed. Optionally, you can use this tool
      to "boost" your posting, allowing it to be included in ZipRecruiter's
      email alerts, AI matching, and distribution network.
    </p>
    <p>
      When applicants apply on ZipRecruiter, they will receive an email with a
      link instructing them where to apply for your job in OnePoint. You can
      also visit OnePoint Connect to view all job site applicants.
    </p>
    <p>
      <b>
        If you'd like to opt-out of this integration, simply switch the "ON/OFF"
        setting under the Integration Settings to "DISABLED".
      </b>
    </p>
    <div className="subHead">Sponsorship Details</div>
    <p>
      ZipRecruiter allows you to boost your posting which includes it in email
      alerts, AI matching, and distribution network. This can be helpful if you
      are having trouble finding the right candidates.
    </p>
    <p>
      There are two levels of sponsorship, Single and Double, which refer to the
      amount of visitors each ad is allotted. Single allows for 100 visitors and
      Double allows for 200. Both levels last for 30 days, unless the visitor
      limits are reached first.
    </p>
    <p>Payment for ZipRecruiter sponsorships will be done through OnePoint.</p>
  </div>
);

class IndeedStartStopSponsor extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
  }

  submit() {
    const { makeChange } = this.props;

    makeChange();
  }

  render() {
    const { restartTrueEndFalse } = this.props;

    return (
      <Modal closeModal={this.props.closeModal}>
        <div className="spacebelow">
          {restartTrueEndFalse
            ? "Restarting your Sponsorship will cause Indeed to try to spend all of your remaining budget before the initial End Date."
            : "Click below to end your Sponsorship. This will allow you to stop being charged for this posting. This can take up to 6 hours for Indeed to process."}
        </div>
        <div className="buttoncont">
          <button onClick={() => this.submit()}>
            {restartTrueEndFalse ? "Restart Now" : "Stop Sponsorship Now"}
          </button>
        </div>
      </Modal>
    );
  }
}

class IndeedUpdateBudget extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedOption: "" };

    this.submit = this.submit.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
  }

  submit() {
    const { selectedOption } = this.state;

    if (selectedOption === "") {
      return;
    }

    this.props.updateIndeedSponsorshipBudget(selectedOption);
  }

  handleOptionChange(changeEvent) {
    this.setState({
      selectedOption: changeEvent.target.value,
    });
  }

  render() {
    return (
      <Modal closeModal={this.props.closeModal}>
        <h1>Update Your Budget</h1>
        <div className="spacebelow">
          If you would like to add budget to a job currently being sponsored
          through this integration, the additional budget will be added to the
          original budget and will be spent within the initial 30 days of the
          job going live. If you do not want this additional budget to be spent
          in the first 30 days, please wait until your initial 30-day lifetime
          spend ends before re-sponsoring.
        </div>
        <div className="spacebelow">Select your new maximum budget:</div>
        <div className="spacebelow">
          <div className="radio">
            <label>
              <input
                type="radio"
                value="1500"
                checked={this.state.selectedOption === "1500"}
                onChange={this.handleOptionChange}
              />
              $1,500 (~$50 per day)
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                value="900"
                checked={this.state.selectedOption === "900"}
                onChange={this.handleOptionChange}
              />
              $900 (~$30 per day)
            </label>
          </div>
          <div className="radio">
            <label>
              <input
                type="radio"
                value="450"
                checked={this.state.selectedOption === "450"}
                onChange={this.handleOptionChange}
              />
              $450 (~$15 per day)
            </label>
          </div>
        </div>
        <div className="buttoncont">
          <button onClick={() => this.submit()}>Update Your Budget Now</button>
        </div>
      </Modal>
    );
  }
}

class ZRAddSponsor extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedOption: "", error: "", page: 0 };

    this.submit = this.submit.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.changePage = this.changePage.bind(this);
  }

  changePage(forwardTrueBackFalse) {
    const { page, selectedOption } = this.state;

    if (forwardTrueBackFalse) {
      if (selectedOption === "") {
        this.setState({ error: "Please choose a level!" });
        return;
      }

      this.setState({ page: page + 1 });
    } else {
      this.setState({ page: page - 1 });
    }
  }

  submit() {
    const { selectedOption } = this.state;
    const { createSponsorship } = this.props;

    if (selectedOption === "") {
      this.setState({ error: "Please choose a level!" });
      return;
    }

    createSponsorship(selectedOption);
  }

  handleOptionChange(changeEvent) {
    this.setState({
      selectedOption: changeEvent.target.value,
      error: "",
    });
  }

  render() {
    const { job } = this.props;
    const { error, page, selectedOption } = this.state;

    if (page === 1) {
      return (
        <Modal closeModal={this.props.closeModal}>
          <div>
            <div className="spacebelow" style={{ textAlign: "center" }}>
              <b>
                You agree to pay {selectedOption === "single" ? "$199" : "$299"}{" "}
                to OnePoint for the new sponsorship?
              </b>
            </div>
            <div className="spacebelow buttoncont">
              <button onClick={() => this.submit()}>I agree</button>
            </div>
          </div>
        </Modal>
      );
    }

    return (
      <Modal closeModal={this.props.closeModal}>
        <div>
          <h1>Sponsor {job.title} on ZipRecruiter</h1>
          <div className="spacebelow">
            Your jobs will be posted on ZipRecruiter for free by default, but if
            you feel like you need more applicants we offer the ability to
            "boost" your posting. Choosing to sponsor your job posting on
            ZipRecruiter will allow the job to be get included in their email
            alerts, AI matching, and distribution network. There are two
            choices: "Single" and "Double". The difference between the two boost
            levels is the amount of visitors each ad is allotted during the
            sponsorship period, Single is alloted 100 visitors and Double 200.
            The Sponsorships last for <b>30 days</b>, unless the job hits the
            visitor limit sooner.
          </div>
          <div className="somespacebelow">Choose your desired level below:</div>
          <div className="spacebelow">
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="single"
                  checked={this.state.selectedOption === "single"}
                  onChange={this.handleOptionChange}
                />
                Single ($199)
              </label>
            </div>
            <div className="radio">
              <label>
                <input
                  type="radio"
                  value="double"
                  checked={this.state.selectedOption === "double"}
                  onChange={this.handleOptionChange}
                />
                Double ($299)
              </label>
            </div>
          </div>
          {error !== "" && (
            <div
              style={{ padding: "5px", borderRadius: "5px" }}
              className="spacebelow red"
            >
              {error}
            </div>
          )}
          <div className="spacebelow">
            <b>
              By clicking the button below, you are agreeing to pay the selected
              amount. This will be added to your OnePoint bill.
            </b>
          </div>
          <div className="spacebelow buttoncont">
            <button onClick={() => this.changePage(true)}>
              Sponsor job on ZipRecruiter now
            </button>
          </div>
        </div>
      </Modal>
    );
  }
}

class IndeedAddSponsor extends Component {
  constructor(p) {
    super(p);
    this.state = {
      page: 1,
      selectedOption: "",
      error: "",
      phone: "",
      contactName:
        p.userInfo && p.userInfo.first_name !== ""
          ? p.userInfo.first_name + " " + p.userInfo.last_name
          : "",
      customBudget: "",
    };

    this.nextPage = this.nextPage.bind(this);
    this.submit = this.submit.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleNumberChange = this.handleNumberChange.bind(this);
  }

  nextPage() {
    const { page, selectedOption, contactName, customBudget } = this.state;

    if (contactName === "") {
      this.setState({ error: "Please enter your name" });
      return;
    }

    if (customBudget === "") {
      if (selectedOption === "") {
        this.setState({ error: "Please choose a budget!" });
        return;
      }
    }

    const budget =
      customBudget !== ""
        ? customBudget
        : selectedOption !== ""
        ? selectedOption
        : "";
    let budgetN = parseInt(budget, 10);
    if (isNaN(budgetN)) {
      this.setState({
        error: "Budget must be a number",
        customBudget: "",
        selectedOption: "",
      });
      return;
    }

    if (budgetN < 200) {
      this.setState({ error: "Budget must be greater than $200" });
      return;
    }

    this.setState({ page: page + 1, selectedOption: budget, error: "" });
  }

  submit(skip) {
    const { phone, selectedOption, contactName } = this.state;
    const { createIndeedSponsorship } = this.props;

    if (phone === "" && !skip) {
      this.setState({ error: "Please choose enter a phone number!" });
      return;
    }

    createIndeedSponsorship(selectedOption, phone, contactName);
  }

  handleOptionChange(changeEvent) {
    const { selectedOption } = this.state;

    if (selectedOption === changeEvent.target.value) {
      this.setState({
        selectedOption: "",
        error: "",
      });
      return;
    }

    this.setState({
      selectedOption: changeEvent.target.value,
      error: "",
    });
  }

  handleNumberChange(changeEvent) {
    this.setState({
      phone: changeEvent.target.value,
      error: "",
    });
  }

  render() {
    const { page, phone, error, contactName, customBudget } = this.state;

    return (
      <Modal closeModal={this.props.closeModal}>
        {page === 1 && (
          <div>
            <h1>Sponsor this job on Indeed</h1>
            <div className="spacebelow">
              Sponsor this job on Indeed for prominent placement on the world’s
              #1 job site.*
            </div>
            <div className="spacebelow">
              Indeed’s pay-per-click model is designed to drive the hires you
              need. You pay based on job Ad performance and set the budgets you
              are willing to pay to target candidates. You are only charged for
              sponsorship when a job seeker clicks on your job.
            </div>
            <div className="spacebelow">
              <div className="bold">
                Launch a sponsored job campaign on Indeed when you need to:
              </div>
              <ul className="list">
                <li>Hire quickly</li>
                <li>Recruit for a hard-to-fill role</li>
                <li>Receive a large pool of candidates</li>
              </ul>
            </div>
            <div className="bold somespacebelow">
              Recommended: Start with $20 per day for 30 days to secure quality
              candidates.
            </div>
            <div className="it spacebelow">
              By selecting to sponsor this job, Indeed will run a 30-day
              advertising campaign for this job. If this job is removed from the
              ATS before the 30th day, the job will be removed from Indeed and
              the advertising campaign will stop. You will only be billed for
              the actual number of clicks on this job, with a maximum budget of:
            </div>
            <div>Select a maximum budget to sponsor this job:</div>
            <div className="spacebelow">
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="1500"
                    checked={this.state.selectedOption === "1500"}
                    onClick={this.handleOptionChange}
                    onChange={() => {}}
                  />
                  $1,500 (~$50 per day)
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="900"
                    checked={this.state.selectedOption === "900"}
                    onClick={this.handleOptionChange}
                    onChange={() => {}}
                  />
                  $900 (~$30 per day)
                </label>
              </div>
              <div className="radio">
                <label>
                  <input
                    type="radio"
                    value="450"
                    checked={this.state.selectedOption === "450"}
                    onClick={this.handleOptionChange}
                    onChange={() => {}}
                  />
                  $450 (~$15 per day)
                </label>
              </div>
              <div className=" textinput">
                <label>
                  <b>OR</b> Enter Custom Budget (min. $200):
                </label>
                <input
                  type="text"
                  placeholder="Enter Custom Budget"
                  value={customBudget}
                  onChange={(e) =>
                    this.setState({ customBudget: e.target.value })
                  }
                />
              </div>
            </div>
            <div className="it spacebelow">
              This job will be added to your Indeed advertiser account for
              sponsorship based on the email address connected to your ATS
              account. If you do not have an Indeed account, you will create one
              and will receive an email to help you complete the setup.
            </div>
            <div className="spacebelow">
              <a
                href="https://youtu.be/LcCCHeSVhqE"
                target="_blank"
                rel="noopener noreferrer"
              >
                Watch this video
              </a>{" "}
              to learn more or{" "}
              <a
                href="https://www.indeed.com/hire/contact-us"
                rel="noopener noreferrer"
                target="_blank"
              >
                contact Indeed
              </a>{" "}
              to discuss more options.
            </div>
            {error !== "" && (
              <div
                style={{ padding: "5px", borderRadius: "5px" }}
                className="spacebelow red"
              >
                {error}
              </div>
            )}
            <div className="spacebelow textinput">
              <label>
                <b>Your Name:</b>
              </label>
              <input
                type="text"
                placeholder="Your Name"
                value={contactName}
                onChange={(e) => this.setState({ contactName: e.target.value })}
              />
            </div>
            <div className="spacebelow buttoncont">
              <button onClick={() => this.nextPage()}>
                Sponsor job on Indeed now
              </button>
            </div>
            <div className="spacebelow">
              By sponsoring this job and creating an account, you agree to
              Indeed’s{" "}
              <a
                href="https://www.indeed.com/legal?hl=en_US#tos"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>{" "}
              and consent to our{" "}
              <a
                href="https://www.indeed.com/legal?hl=en_US#privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>{" "}
              and{" "}
              <a
                href="https://www.indeed.com/legal?hl=en_US#cookies"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie Policy
              </a>
              .
            </div>
            <div>*ComScore, Total Site Visits</div>
          </div>
        )}
        {page === 2 && (
          <div>
            <h1>Thank you for sponsoring your job on Indeed</h1>
            <div className="spacebelow">
              You’re one step closer to your next great hire!
            </div>
            <div className="spacebelow">
              Indeed is committed to the success of your advertising campaign.
              Please help us connect with you by sharing your phone number.
            </div>
            <div className="bold somespacebelow">Phone</div>
            <div className="spacebelow">
              <input
                type="text"
                value={phone}
                onChange={this.handleNumberChange}
              />
            </div>
            <div className="spacebelow">
              A member of Indeed’s sales team will contact you to discuss your
              ad’s performance and offer suggestions for optimization.
            </div>
            <div className="it spacebelow">
              **By providing my phone number and clicking the button below I
              confirm that I am the subscriber to the telephone number entered
              and I hereby consent to receive autodialed calls and texts from
              Indeed for marketing and informational purposes at the telephone
              number provided above, including if this number is a wireless
              (cell phone) number. Agreement to the above is not a condition of
              purchase of any Indeed product. I agree that this information will
              be dealt with in accordance with Indeed’s Cookie and Privacy
              Policy and Terms of Service.
            </div>
            <div className="spacebelow">
              If you have an Indeed advertiser account currently set up with a
              valid credit card, your ad should be live on Indeed within 3-8
              hours.* You can check the status of your job in your Indeed
              account by visiting{" "}
              <a
                href="https://www.indeed.com/hire"
                target="_blank"
                rel="noopener noreferrer"
              >
                indeed.com/hire
              </a>
              .
            </div>
            <div className="spacebelow">
              If you do not have a current account on Indeed, you will receive
              an email from{" "}
              <a href="mailto:ads-noreply@indeed.com">ads-noreply@indeed.com</a>{" "}
              to help you set up the account so your job can be sponsored on
              Indeed.
            </div>
            <div className="spacebelow">
              Please contact{" "}
              <a href="mailto:alliancessupport@indeed.com">
                alliancessupport@indeed.com
              </a>{" "}
              with any additional technical questions or concerns.
            </div>
            {error !== "" && (
              <div
                style={{ padding: "5px", borderRadius: "5px" }}
                className="spacebelow red"
              >
                {error}
              </div>
            )}
            <div className="spacebelow buttoncont">
              <button onClick={() => this.submit(false)}>
                Submit phone number
              </button>
            </div>
            <div className="spacebelow" style={{ textAlign: "center" }}>
              <button onClick={() => this.submit(true)}>
                Skip Phone Number
              </button>
            </div>
            <div>*Indeed Terms, Conditions, and Quality Standards apply</div>
          </div>
        )}
      </Modal>
    );
  }
}

class JobBoard extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    saveSettings: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      modal: null,
      success: "",
      indeedJobs: [],
      indeedLoading: false,
      indeedJobsCopy: [],
      rowPerPage: 5,
      currentPage: 0,
    };

    this.fetchJobs = this.fetchJobs.bind(this);
    this.convertTime = this.convertTime.bind(this);
    this.getCurrentUnixSecs = this.getCurrentUnixSecs.bind(this);
    this.launchModal = this.launchModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.createIndeedSponsorship = this.createIndeedSponsorship.bind(this);
    this.updateIndeedSponsorship = this.updateIndeedSponsorship.bind(this);
    this.createZRSponsorship = this.createZRSponsorship.bind(this);
  }

  componentDidMount() {
    const { indeedJobs } = this.state;

    if (indeedJobs.length === 0) {
      this.fetchJobs();
    }
  }

  componentWillMount() {
    const { indeedJobs } = this.state;

    if (indeedJobs.length === 0) {
      this.fetchJobs();
    }
  }

  componentWillReceiveProps(newProps) {
    const {
      indeedIsPosting: indeedWasPosting,
      indeedIsUpdating: indeedWasUpdating,
    } = this.props;
    const { indeedIsPosting, indeedIsUpdating, error } = newProps;

    if (indeedWasPosting && !indeedIsPosting) {
      if (error === "") {
        this.setState({
          modal: null,
          success:
            "Your Job Posting has been sponsored! Indeed processes updates about four times a day, so please allow up to 3-8 hours for this change to take effect.",
        });
        return;
      } else {
        this.closeModal();
      }
    }

    if (indeedWasUpdating && !indeedIsUpdating) {
      if (error === "") {
        this.setState({
          modal: null,
          success:
            "Your Job Posting has been updated! Indeed processes updates about four times a day, so please allow up to 5 hours for this change to take effect.",
        });
        return;
      } else {
        this.closeModal();
      }
    }
  }

  fetchJobs = async () => {
    const { dispatch, topicURL } = this.props;
    this.setState({ indeedLoading: true });

    const keyName = topicURL === "indeed" ? "indeed" : "zip_req";

    const { response } = await dispatch(fetchJobsIndeed(keyName));
    if (response) {
      this.setState({
        indeedJobs: Object.values(response),
        indeedJobsCopy: Object.values(response),
        indeedLoading: false,
      });
    }
  };

  createIndeedSponsorship(opJobID, budget, phone, contactName) {
    this.props.dispatch(
      newIndeedSponsorship(opJobID, budget, phone, contactName)
    );
  }

  createZRSponsorship(opJobID, level) {
    this.props.dispatch(newZRSponsorship(opJobID, level));
  }

  updateIndeedSponsorship(opJobID, sponsorshipID, budget, manually_ended) {
    this.props.dispatch(
      updateIndeedSponsorship(opJobID, sponsorshipID, budget, manually_ended)
    );
  }

  convertTime(timeInMillis) {
    var d = new Date(0);
    d.setUTCSeconds(timeInMillis);
    return d.toDateString();
  }

  getCurrentUnixSecs() {
    return Date.now() / 1000;
  }

  launchModal(type, job, sponsorship) {
    this.setState({ modal: { type, job, sponsorship } });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  renderModal(loading, modal, jobboardurl, userInfo) {
    if (modal === null) {
      return null;
    }

    if (loading) {
      return (
        <Modal closeModal={this.closeModal}>
          <Loading />
        </Modal>
      );
    }

    switch (modal.type) {
      case "new":
        if (jobboardurl === "indeed") {
          return (
            <IndeedAddSponsor
              job={modal.job}
              createIndeedSponsorship={(budget, phone, cn) =>
                this.createIndeedSponsorship(modal.job.id, budget, phone, cn)
              }
              closeModal={this.closeModal}
              userInfo={userInfo}
            />
          );
        } else {
          return (
            <ZRAddSponsor
              job={modal.job}
              createSponsorship={(level) =>
                this.createZRSponsorship(modal.job.id, level)
              }
              closeModal={this.closeModal}
            />
          );
        }
      case "budget":
        return (
          <IndeedUpdateBudget
            updateIndeedSponsorshipBudget={(budget) =>
              this.updateIndeedSponsorship(
                modal.job.id,
                modal.sponsorship.id,
                budget,
                modal.sponsorship.manually_ended
              )
            }
            closeModal={this.closeModal}
          />
        );
      case "end":
        return (
          <IndeedStartStopSponsor
            closeModal={this.closeModal}
            restartTrueEndFalse={false}
            makeChange={() =>
              this.updateIndeedSponsorship(
                modal.job.id,
                modal.sponsorship.id,
                modal.sponsorship.budget,
                true
              )
            }
          />
        );
      case "restart":
        return (
          <IndeedStartStopSponsor
            closeModal={this.closeModal}
            restartTrueEndFalse={true}
            makeChange={() =>
              this.updateIndeedSponsorship(
                modal.job.id,
                modal.sponsorship.id,
                modal.sponsorship.budget,
                false
              )
            }
          />
        );
      default:
        return null;
    }
  }

  handlePageClick = ({ selected: selectedPage }) => {
    this.setState({ currentPage: selectedPage });
  };

  handleSelectOptionChange = (e) => {
    const { value } = e.target;
    this.setState({ rowPerPage: value });
  };

  render() {
    const {
      data,
      saveSettings,
      settingsAreSaving,
      error,
      jobsFetching,
      jobs,
      indeedIsPosting,
      indeedIsUpdating,
      userInfo,
    } = this.props;

    const {
      modal,
      success,
      indeedJobs,
      indeedLoading,
      indeedJobsCopy,
      rowPerPage,
      currentPage,
    } = this.state;

    const rowPerPageOption = [5, 10, 25, 50, 100];

    const offset = currentPage * rowPerPage;

    if (data === undefined) {
      return null;
    }

    return (
      <div className="Indeed JobBoard">
        {this.renderModal(
          indeedIsPosting || indeedIsUpdating,
          modal,
          data.url,
          userInfo
        )}
        <div>
          <div className="head">
            <h1>
              <div>{data.integration_name} Integration</div>
              <div className="desc disappearSmall">
                This integration automatically pushes your OnePoint job
                requisitions to {data.integration_name}. From here, you can
                choose to sponsor your posting on a per-job basis. If you'd like
                to opt-out of this integration, select Disabled below under
                Integration Settings.
              </div>
            </h1>
            <div className="img">
              <img src={`/images/${data.image_name}`} alt=" " />
            </div>
          </div>
          {jobsFetching ? (
            <Loading />
          ) : (
            <div className="sponsor">
              <h2>Manage Job Postings</h2>
              {indeedLoading && <Loading />}
              {success !== "" && <div className="success">{success}</div>}
              {jobs && jobs.length === 0 && (
                <div className="">
                  Your currently have no open job requisitions
                </div>
              )}
              {Array.isArray(indeedJobs) &&
                indeedJobs.slice(offset, offset + rowPerPage).map((job) => (
                  <div className="job" key={job.id}>
                    <div className="top">
                      <div className="main">
                        <div className="title">{job.title}</div>
                        <span
                          className={
                            job[data.url + "_sponsorship"].active
                              ? "label sponsored"
                              : "label unsponsored"
                          }
                        >
                          {job[data.url + "_sponsorship"].active
                            ? "Currently Sponsored"
                            : "Currently NOT Sponsored"}
                        </span>
                      </div>
                      <div className="actions">
                        {!job[data.url + "_sponsorship"].active &&
                          !(
                            job[data.url + "_sponsorship"].sponsorships.length >
                              0 &&
                            job[data.url + "_sponsorship"].sponsorships[0]
                              .manually_ended &&
                            this.getCurrentUnixSecs() <
                              job[data.url + "_sponsorship"].sponsorships[0]
                                .time_end
                          ) && (
                            <div>
                              <button
                                onClick={() =>
                                  this.launchModal("new", job, null)
                                }
                                className="good"
                              >
                                CREATE NEW SPONSORSHIP
                              </button>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="bottom">
                      {job[data.url + "_sponsorship"].sponsorships.length >
                        0 && (
                        <div
                          style={{
                            marginTop: "10px",
                            marginBottom: "5px",
                            fontSize: "14px",
                          }}
                        >
                          Sponsorships
                        </div>
                      )}
                      {job[data.url + "_sponsorship"].sponsorships.map(
                        (spon, index) => (
                          <div
                            className={
                              spon.active ? "sponsorship active" : "sponsorship"
                            }
                            key={"spon" + spon.id}
                          >
                            <div className="main">
                              <div className="status">
                                {spon.manually_ended
                                  ? "MANUALLY STOPPED"
                                  : spon.active
                                  ? "ACTIVE"
                                  : "EXPIRED"}
                              </div>
                              {data.url === "indeed" && (
                                <div className="budget">
                                  Budget: ${spon.budget}
                                </div>
                              )}
                              {data.url === "ziprecruiter" && (
                                <div style={{ display: "flex" }}>
                                  <div className="budget">
                                    Level: {spon.level}
                                  </div>
                                  <div className="ends">
                                    Ends: {this.convertTime(spon.time_end)}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div>
                              {spon.active && data.url === "indeed" ? (
                                <div className="actions">
                                  <button
                                    onClick={() =>
                                      this.launchModal("budget", job, spon)
                                    }
                                  >
                                    CHANGE BUDGET
                                  </button>
                                  <button
                                    onClick={() =>
                                      this.launchModal("end", job, spon)
                                    }
                                    className="bad marg"
                                  >
                                    END SPONSORSHIP
                                  </button>
                                </div>
                              ) : (
                                index === 0 &&
                                job[data.url + "_sponsorship"].sponsorships
                                  .length > 0 &&
                                job[data.url + "_sponsorship"].sponsorships[0]
                                  .manually_ended &&
                                this.getCurrentUnixSecs() <
                                  job[data.url + "_sponsorship"].sponsorships[0]
                                    .time_end &&
                                data.url === "indeed" && (
                                  <div className="actions">
                                    <button
                                      onClick={() =>
                                        this.launchModal("restart", job, spon)
                                      }
                                      className="good"
                                    >
                                      RESTART SPONSORSHIP
                                    </button>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              <div className="my-flex my-justify-content-end my-align-item-center">
                <div className="one-select-option">
                  <select
                    onChange={this.handleSelectOptionChange}
                    value={rowPerPage}
                  >
                    {rowPerPageOption.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                </div>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel="Next"
                  onPageChange={this.handlePageClick}
                  pageCount={Math.ceil(indeedJobsCopy.length / rowPerPage)}
                  previousLabel="Previous"
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination-container"}
                  previousLinkClassName={"pagination__link"}
                  nextLinkClassName={"pagination__link"}
                  disabledClassName={"disabled"}
                  activeClassName={"active"}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={1}
                />
              </div>
            </div>
          )}
          <div className="intSect">
            <div className="settings sect">
              <h2>Integration Settings</h2>
              <IntegrationSettings
                settingsAreSaving={settingsAreSaving}
                settings={data.settings}
                saveSettings={saveSettings}
                error={error}
                integrationName={data.integration_name}
              />
            </div>
          </div>
          <div>
            <HideShow
              label={<h2>Integration Documentation</h2>}
              body={
                data.url === "indeed" ? IndeedDocumentation : ZRDocumentation
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    list,
    isFetching: jobsFetching,
    indeedIsPosting,
    indeedIsUpdating,
    error,
  } = state.jobs;
  const { jobs } = state.entities;
  const { userInfo } = state.products;

  // const jobsListJoin =
  //   list === undefined
  //     ? []
  //     : list.map((jobid) => {
  //         let thisJob = Object.assign({}, jobs[jobid]);
  //         const appsJoin =
  //           thisJob.CC_sponsorship.applications &&
  //           thisJob.CC_sponsorship.applications.map(
  //             (appID) => applications[appID]
  //           );
  //         thisJob.applications = appsJoin;
  //         return thisJob;
  //       });

  const jobsListJoin =
    list === undefined
      ? []
      : list.map((jobid) => {
          let thisJob = jobs[jobid];
          // const appsJoin = thisJob.applications.map(
          //   (appID) => applications[appID]
          // );
          // thisJob.applications = appsJoin;
          return thisJob;
        });

  return {
    jobsFetching,
    jobs: jobsListJoin,
    indeedIsPosting,
    indeedIsUpdating,
    error,
    userInfo,
  };
};

export default connect(mapStateToProps)(JobBoard);
