import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loading from '../ui/Loading'
import { connect } from 'react-redux'

import { fetchCompanyData } from '../../actions/companyData'

import '../../styles/NewUserTemplates.css';

class JobTitleChooser extends Component {
  constructor(props) {
    super(props)
    this.state = { selectedJT: '' }
    this.handleJTSelection = this.handleJTSelection.bind(this)
  }

  handleJTSelection(event) {
    this.setState({ selectedJT: event.target.value });
  }

  componentWillReceiveProps(np) {
    const { values: oldVals } = this.props
    const { values } = np

    if (oldVals) {
      if (values) {
        if (values.length !== 0 && values.length !== oldVals.length) {
          this.setState({ selectedJT: values[0].id.toString() })
        }
      }
    }
  }

  componentWillMount() {
    this.setState({ selectedJT: this.props.values.length !== 0 ? this.props.values[0].id.toString() : '' })
  }

  render() {
    const { selectedJT } = this.state
    const { values, add, keyd } = this.props

    if (values && values.length === 0) {
      return (
        null
      )
    }

    return (
      <div>
        <select value={selectedJT} onChange={this.handleJTSelection}>
          {
            values !== null && values.map((jobTitle, index) => (
              <option key={keyd + index} value={jobTitle.id}>
                {jobTitle.name}
              </option>
            ))
          }
        </select>
        {
          selectedJT !== ''
          &&
          (
            <button style={{ marginLeft: '5px' }} className="addCC" onClick={() => add(selectedJT)}>
              ADD
              </button>
          )
        }
      </div>
    )
  }
}

class NewUserTemplates extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {}

    this.handleChoiceChange = this.handleChoiceChange.bind(this)
    this.addBlankTemplate = this.addBlankTemplate.bind(this)
    this.handleUsernameChange = this.handleUsernameChange.bind(this)
    this.removeTemplate = this.removeTemplate.bind(this)
    this.removeJT = this.removeJT.bind(this)
    this.filterOutSelected = this.filterOutSelected.bind(this)
  }

  fetchData() {
    const { dispatch } = this.props

    dispatch(fetchCompanyData('job-titles'))
  }

  handleChoiceChange(event) {
    const { setting, onChange } = this.props

    onChange({ ...setting, op_employee_field: event.target.value });
  }

  addBlankTemplate() {
    const { setting, onChange } = this.props

    const newT = setting.templates.concat({ ad_username: '', op_values: [] })

    onChange({ ...setting, templates: newT })
  }

  removeTemplate(index) {
    const { setting, onChange } = this.props

    const newTemps = setting.templates.filter((t, i) => i !== index)

    onChange({ ...setting, templates: newTemps })
  }

  handleUsernameChange(targetIndex, event) {
    const { setting, onChange } = this.props
    const newV = event.target.value

    const newTemps = setting.templates.map((t, index) => {
      if (index === targetIndex) {
        let nt = Object.assign({}, t)
        nt.ad_username = newV
        return nt
      }
      return Object.assign({}, t)
    })

    onChange({ ...setting, templates: newTemps })
  }

  submitAdd(index, jobID) {
    const { setting, onChange, jobTitles } = this.props

    const targetJobArr = jobTitles.filter((job) => job.id.toString() === jobID)
    if (targetJobArr.length === 0) {
      return
    }

    const newJTs = setting.templates[index].op_values.concat(targetJobArr[0])

    const newT = setting.templates.map((temp, i) => {
      if (index === i) {
        let nt = Object.assign({}, temp)
        nt.op_values = newJTs
        return nt
      }

      return Object.assign({}, temp)
    })

    onChange({ ...setting, templates: newT })
  }

  removeJT(index, valueID) {
    const { setting, onChange } = this.props

    const newVals = setting.templates[index].op_values.filter((v) => v.id !== valueID)
    const newT = setting.templates.map((temp, i) => {
      if (index === i) {
        let nt = Object.assign({}, temp)
        nt.op_values = newVals
        return nt
      }

      return Object.assign({}, temp)
    })

    onChange({ ...setting, templates: newT })
  }

  filterOutSelected(jobs, templates) {
    const filt = jobs && jobs.filter((job) => {
      let found = false
      templates.forEach((template) => {
        template.op_values.forEach((val) => {
          if (val.id === job.id) {
            found = true
          }
        })
      })

      if (found) {
        return false
      }
      return true
    })

    return filt
  }

  render() {
    const { setting: settingProp, isFetching, jobTitles: allJobs } = this.props

    if (!settingProp) {
      return null
    }

    const templates = settingProp.templates.map((temp) => {
      return Object.assign({}, temp)
    })
    let setting = Object.assign(settingProp, { templates })

    const jobTitles = this.filterOutSelected(allJobs, setting.templates)

    return (
      <div className="NewUserTemplates">
        {
          isFetching
          &&
          (
            <div className="loadCont">
              <Loading />
            </div>
          )
        }
        <div className="opEmpField">
          <div>
            <label>
              OnePoint Employee Field:
            </label>
            <select value={setting.op_employee_field} onChange={this.handleChoiceChange}>
              <option key={'job_title'} value={'job_title'}>
                Job Title
              </option>
            </select>
          </div>
          {
            jobTitles && jobTitles.length === 0
            &&
            (
              <div className="alldone">
                &#10003; All Job Titles have been assigned
              </div>
            )
          }
        </div>
        <div className={isFetching ? 'mainfetching' : ''}>
          {
            setting.templates.length !== 0
            &&
            (
              <table>
                <thead>
                  <tr>
                    <th>
                      Template User Display Name
                    </th>
                    <th>
                      Applies To
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    setting.templates.map((template, index) => (
                      <tr key={'st' + index}>
                        <td className="leftcol">
                          <input type="text" value={template.ad_username} onChange={(event) => this.handleUsernameChange(index, event)} />
                        </td>
                        <td>
                          <div className="rightcol">
                            <div className="vals eChooser">
                              {
                                template.op_values.length !== 0
                                &&
                                (
                                  <div className="list activeEmps">
                                    {template.op_values.map((val) => (
                                      <div className="nodeWithXCont" key={'scsdwe' + val.id}>
                                        {val.name}
                                        <button onClick={() => this.removeJT(index, val.id)}>
                                          X
                                          </button>
                                      </div>
                                    ))
                                    }
                                  </div>
                                )
                              }
                              <div className="add">
                                {
                                  jobTitles
                                    ?
                                    (
                                      <JobTitleChooser
                                        keyd={'wefwwe1q' + index}
                                        values={jobTitles}
                                        add={(val) => this.submitAdd(index, val)}
                                      />
                                    )
                                    :
                                    (
                                      <button onClick={() => this.fetchData()}>
                                        ADD JOB TITLE
                                    </button>
                                    )
                                }
                              </div>
                            </div>
                            <div className="delete">
                              <button onClick={() => this.removeTemplate(index)}>
                                -
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            )
          }
          <div>
            <button onClick={() => this.addBlankTemplate()}>
              CREATE NEW TEMPLATE
            </button>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const { jobTitles, isFetching } = state.company_data

  const jtfetching = isFetching['job-titles']

  return { jobTitles, isFetching: jtfetching }
}

export default connect(mapStateToProps)(NewUserTemplates)
