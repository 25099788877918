import React, { useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";

import "../styles/App.css";
import DashboardBody from "./DashboardBody";
import DashboardLayout from "./components/layout/DashboardLayout";
import AuthPageContainer from "../containers/AuthPageContainer";
import { fetchProducts } from "../actions/products";
import ProductContainer from "../containers/ProductContainer";
import "../styles/responsive.css";
import AppIntegration from "./components/AppIntegration";
import AddCompany from "./components/company/AddCompany";
import CompanyList from "./components/company/CompanyList";
import CompanyStatus from "./components/company/CompanyStatus";

const EmployeeIndex = ({ match, userInfo }) => {
  return (
    <div className="emp-dashboard-body">
      <DashboardLayout>
        <Switch>
          <Route
            path={`${match.url}/system-product/:productURL`}
            component={ProductContainer}
          />

          <Route
            path={`${match.url}/integrations-app/:topicURL`}
            component={AppIntegration}
          />

          {userInfo && userInfo.is_system_admin && (
            <Route
              path={`${match.url}/add-company`}
              component={(props) => <AddCompany {...props} />}
            />
          )}
          {userInfo && userInfo.is_system_admin && (
            <Route
              path={`${match.url}/list-company`}
              component={(props) => <CompanyList {...props} />}
            />
          )}
          {/* status company */}
          {userInfo && userInfo.is_system_admin && (
            <Route
              path={`${match.url}/status-company`}
              component={(props) => <CompanyStatus {...props} />}
            />
          )}

          <Route
            path={match.url}
            component={(props) => <DashboardBody {...props} />}
          />

          {/* <Route component={NoMatch} /> */}
        </Switch>
      </DashboardLayout>
    </div>
  );
};

const EmployeeApp = (props) => {
  const { intsBeenFetched, dispatch, isAuthenticated, products } = props;

  useEffect(() => {
    if (
      isAuthenticated &&
      !intsBeenFetched &&
      Object.keys(products).length === 0
    ) {
      dispatch(fetchProducts());
    }
  }, []);

  return (
    <main>
      {!isAuthenticated ? <AuthPageContainer /> : <EmployeeIndex {...props} />}
    </main>
  );
};

const mapStateToProps = (state) => {
  const { isAuthenticated } = state.auth;
  const { isFetching: intsAreFetching, beenFetched: intsBeenFetched } =
    state.integrations;
  const { userInfo } = state.products;
  const { products } = state.entities;

  return {
    isAuthenticated,
    intsAreFetching,
    intsBeenFetched,
    userInfo,
    products,
  };
};

export default withRouter(connect(mapStateToProps)(EmployeeApp));
