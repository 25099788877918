import React from "react";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import BackgroundVerificationModel, {
  packageOption,
} from "./BackgroundVerificationModel";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

const BackgroundVerificationDataTable = ({ data, row, copyRow }) => {
  const [searchValue, setSearchValue] = useState("");
  const [rowData, setRowData] = useState(row);
  const [rowPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const [showPackageData, setShowPackageData] = useState([]);
  const [allSelect, setAllSelect] = useState(false);

  const pageCount = Math.ceil(rowData.length / rowPerPage);

  const offset = currentPage * rowPerPage;

  // const rowPerPageValue = [10, 25, 50, 100, 150, 200];

  const handleSearchChange = (e) => {
    const { value } = e.target;

    setSearchValue(value);

    const filterData = copyRow.filter((item) => {
      return Object.values(item)
        .join("")
        .toLocaleLowerCase()
        .includes(value.toLocaleLowerCase());
    });

    setRowData(filterData);
  };

  // const handleSelectSort = (e) => {
  //   const { value } = e.target;
  //   setCurrentPage(0);
  //   setRowPerPage(value);
  // };

  function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);
  }

  const handleTableCheckChange = (e, item, index) => {
    const { checked } = e.target;
    let showPackageDataState = [];

    if (checked) {
      showPackageDataState = [...showPackageData, { ...item }];
      setShowPackageData([...showPackageData, { ...item }]);
      copyRow[index].checkBox = true;
      setRowData([...copyRow]);
    } else {
      const copy = [...showPackageData];
      copyRow[index].checkBox = false;
      setRowData([...copyRow]);

      const filData = copy.filter(
        (data) => data.applicant_email !== item.applicant_email
      );
      showPackageDataState = filData;
      setShowPackageData(filData);
    }
    if (showPackageDataState.length === rowData.length) {
      setAllSelect(true);
    } else {
      setAllSelect(false);
    }
  };

  const handleRowSelect = (index) => () => {
    let copy = [...rowData];
    copy[index].checkBox = !copy[index].checkBox;
    setRowData([...copy]);

    const allSelected = rowData.filter((item) => item.checkBox);
    setShowPackageData([...allSelected]);

    if (allSelected.length === rowData.length) {
      setAllSelect(true);
    } else {
      setAllSelect(false);
    }
  };

  const handleAllChange = (e) => {
    const { checked } = e.target;
    if (checked) {
      const copy = rowData.map((item) => {
        item.checkBox = true;
        return item;
      });
      setShowPackageData([...copy]);
      setRowData([...copy]);
    } else {
      const copy = rowData.map((item) => {
        item.checkBox = false;
        return item;
      });
      setRowData([...copy]);
      setShowPackageData([]);
    }
    setAllSelect(checked);
  };

  return (
    <div>
      <div className="integration-table">
        <div className="header-action">
          {showPackageData.length !== 0 && (
            <div className="bg-container-db">
              <div>
                <select name="" id="">
                  <option value="">Select package</option>
                  {packageOption.map(({ title, id }, index) => (
                    <option key={id + index} value={id}>
                      {title}
                    </option>
                  ))}
                </select>
              </div>
              <div>
                <button>Bulk assign</button>
              </div>
            </div>
          )}
          {/* <s`elect
            className="sort-select"
            onChange={handleSelectSort}
            name=""
            id=""
            defaultValue={rowPerPage}
          >
            {rowPerPageValue.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>` */}
          <div className="ml-1">
            <input
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={handleSearchChange}
            />
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table bg-data-table">
          <thead>
            <tr>
              <th className="first-sm ">
                <input
                  type="checkbox"
                  name=""
                  id="All"
                  checked={allSelect}
                  onChange={handleAllChange}
                />
                {/* <label htmlFor="All">All</label> */}
              </th>
              <th className="first">S.No</th>
              <th className="first">First Name</th>
              <th className="last">Last Name</th>
              <th className="last">Email</th>
              <th className="email">Status</th>
              <th className="description">Action</th>
            </tr>
          </thead>
          <tbody>
            {rowData.slice(offset, offset + rowPerPage).map((item, index) => (
              <tr
                className={`row ${item.checkBox ? `selected` : ``}`}
                onClick={handleRowSelect(index)}
              >
                <td className="col first-sm text-center">
                  <input
                    type="checkbox"
                    checked={item.checkBox}
                    onChange={(e) => handleTableCheckChange(e, item, index)}
                  />
                </td>
                <td className="col first">{index + 1}</td>
                <td className="col last">{item.applicant_first_name}</td>
                <td className="col last">{item.applicant_last_name}</td>
                <td className="col last">{item.applicant_email}</td>
                <td className="col last text-center">
                  {item.status === null ? (
                    <strong className="color-red not-assign">Not Assign</strong>
                  ) : (
                    item.status
                  )}
                </td>
                <td className="col description text-center">
                  <div>
                    <BackgroundVerificationModel
                      title={<FontAwesomeIcon icon={solid("eye")} />}
                      data={item}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ReactPaginate
        breakLabel="..."
        nextLabel="next"
        onPageChange={handlePageClick}
        pageCount={pageCount}
        previousLabel="previous"
        renderOnZeroPageCount={null}
        containerClassName={"pagination-container"}
        previousLinkClassName={"pagination__link"}
        nextLinkClassName={"pagination__link"}
        disabledClassName={"disabled"}
        activeClassName={"active"}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
      />
    </div>
  );
};

export default BackgroundVerificationDataTable;
