import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

import ProductIntegrationCard from "../cards/ProductIntegrationCard";
import useToggle from "../../hooks/useToggle";
import Loading from "../../../components/ui/Loading";
import UserProvisioningSettings from "../settings/UserProvisioningSettings";

const UserProvisioningBody = (props) => {
  const {
    integrations,
    productsAreFetching,
    productsAreUpdating,
    settings,
    companyProductId,
    companyInfo,
    userAdminPermission,
  } = props;

  const [settingsToggle, handleSettingsToggle] = useToggle(true);
  integrations.sort((a, b) => b.authenticated - a.authenticated);
  return (
    <div>
      <div className="product-modal-content productmodal-padding">
        <div className="product-modal-header">
          <h3>Integration</h3>

          <span onClick={handleSettingsToggle}>
            <FontAwesomeIcon icon={solid("gear")} />
          </span>
        </div>
        <div className="product-card-content-outer Integration-divider">
          <div
            className={`Integration-outer ${
              !settingsToggle && "integration-active"
            }`}
          >
            <div className="product-card-content">
              {productsAreFetching ? (
                <Loading />
              ) : (
                Array.isArray(integrations) &&
                integrations.map((item, index) => (
                  <ProductIntegrationCard
                    key={index}
                    companyInfo={companyInfo}
                    userAdminPermission={userAdminPermission}
                    {...item}
                  />
                ))
              )}
            </div>
          </div>
          <div
            className={`product-setting-section ${
              settingsToggle ? "setting-show" : "setting-hide"
            }`}
          >
            <UserProvisioningSettings
              settings={settings}
              companyProductId={companyProductId}
              productsAreUpdating={productsAreUpdating}
              productsAreFetching={productsAreFetching}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { products, integrations } = state.entities;
  const {
    isFetching: productsAreFetching,
    isUpdating: productsAreUpdating,
    companyInfo,
    userInfo,
  } = state.products;
  const { userAdminPermission } = state.dashboard;

  const productData = products["user-provisioning"];
  
  const intsList =
  productData === undefined
  ? []
  : productData.integrations.map((intURL) => {
    return integrations[intURL];
  });

  return {
    companyInfo,
    userInfo,
    integrations: intsList,
    productsAreFetching,
    productsAreUpdating,
    settings: productData ? productData.settings : {},
    companyProductId:
      productData === undefined ? null : productData.company_product_id,
    userAdminPermission,
  };
};

export default withRouter(connect(mapStateToProps)(UserProvisioningBody));
