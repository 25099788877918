import React from 'react'

function checkBlank(value) {
  return value ? value : '-'
}

const Row = ({ label, value }) => {
    return (
      <tr>
        <td>
          {label}:
        </td>
        <td>
          { checkBlank(value) }
        </td>
      </tr>
    )
}

export default Row
