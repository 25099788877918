import React, { Component, createRef } from "react";
import Modal from "../ui/Modal";
import { connect } from "react-redux";
import previewImage from "../../assets/images/imagePreview.png";
import closeIcon from "../../assets/images/close.png";
import { getQuickApplySponsorship } from "../../actions/integrations";
import { Toaster, toast } from "react-hot-toast";
import QRCode from "qrcode.react";
import Loading from "../ui/Loading";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class ConfigurationModel extends Component {
  constructor(props) {
    super(props);
    this.qrCodeRef = createRef();
    this.state = {
      isToggle: false,
      isLoading: false,
      previewLogo: previewImage,
      uploadLogo: "",
      customField: [{ value: "", index: 0 }],
      opJobId: "",
      qrCodeValue: "",
      qrCode: "",
      jobDescription: "",
      jobTitle: "",
      customValidation: { status: false, value: {} },
      descriptionValidation: false,
      jobTitleId: "",
    };
  }

  modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
  };

  formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  getConfiguration = async () => {
    const { dispatch, jobs } = this.props;

    const { response } = await dispatch(getQuickApplySponsorship());

    if (response) {
      const customField = response.custom_fields ? response.custom_fields : [];

      const jobTitleObj = jobs.find(
        (job) => Number(job.id) === Number(response.op_job_id)
      );

      this.setState({
        previewLogo: response.logo,
        customField,
        jobDescription: response.job_description,
        qrCodeValue: response.qr_code_url,
        qrCode: response.qr_code_url,
        isLoading: false,
        jobTitleId: response.op_job_id,
        jobTitle: jobTitleObj ? jobTitleObj.title : "",
      });
    } else {
      this.setState({
        previewLogo: "",
        customField: [],
        jobDescription: "",
        qrCodeValue: "",
        jobTitleId: jobs.length === 0 ? "" : jobs[0].id,
        qrCode: "",
        isLoading: false,
        jobTitle: jobs.length === 0 ? "" : jobs[0].job_title,
      });
    }
  };

  handleToggle = () => {
    this.setState({
      isToggle: !this.state.isToggle,
      isLoading: true,
      // jobTitleId: jobs[0].id,
      // jobTitle: jobs[0].job_title,
    });

    if (!this.state.isToggle) {
      this.getConfiguration();
    }
  };

  handleQRCodeChange = (e) => {
    const { value } = e.target;

    this.setState({
      qrCodeValue: value,
    });
  };

  handleGenerateQRCode = (e) => {
    this.setState({
      qrCode: this.state.qrCodeValue,
    });
  };

  handleDownloadQRCode = (e) => {
    const canvas = document.getElementsByTagName("canvas")[0];
    let img = canvas.toDataURL("image/png");
    let link = document.createElement("a");
    link.download = "QRCode.png";
    link.href = img;
    link.click();
  };

  handleChange = (e) => {
    const { files } = e.target;

    const previewUrl = URL.createObjectURL(files[0]);
    this.setState({
      previewLogo: previewUrl,
      uploadLogo: files[0],
    });
  };

  handleChangeDescription = (e) => {
    const { value } = e.target;

    this.setState({
      jobDescription: value,
    });
  };

  handleCustomChange = (e, index) => {
    const { value } = e.target;
    const { customField } = this.state;
    const copy = [...customField];
    copy[index].value = value;
    this.setState({ customField: copy });
  };

  handleAddCustomField = () => {
    const { customField } = this.state;
    this.setState({
      customField: [...customField, { value: "", index: customField.length }],
    });
  };

  handleDeleteCustomField = (index) => {
    const { customField } = this.state;
    const copy = [...customField];
    copy.splice(index, 1);

    this.setState({ customField: copy });
  };

  handleValidateCustomField = (data) => {
    const error = { status: false, value: {} };

    const emptyString = /^[a-zA-Z][^\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?].*$/;

    for (let key in data) {
      const strCheck = emptyString.test(data[key].value);
      if (!strCheck) {
        error.value[key] = `This field is required.`;
        error.status = true;
      }
    }

    return error;
  };

  handleSelectChange = (e) => {
    const { value } = e.target;
    const { jobs } = this.props;

    // eslint-disable-next-line
    const job = jobs.find((item) => item.id === value);

    this.setState({
      jobTitleId: value,
      jobTitle: job.title,
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    const {
      customField,
      uploadLogo,
      jobDescription,
      qrCode,
      jobTitle,
      jobTitleId,
    } = this.state;

    const validationError = this.handleValidateCustomField(customField);

    // const emptyString = /^[a-zA-Z][^\s~`!@#$%^&*()_\-+={[}\]|\\:;"'<,>.?].*$/;
    // const validationErrorDescription = emptyString.test(jobDescription);

    this.setState({
      customValidation: validationError,
      descriptionValidation: !jobDescription,
    });

    if (validationError.status || !jobDescription) return;

    this.setState({ isLoading: true });

    const formData = new FormData();
    let myHeaders = { Authorization: localStorage.getItem("opintx_token") };

    formData.append("custom_fields", JSON.stringify(customField));
    formData.append("op_job_id", jobTitleId);
    formData.append("job_description", jobDescription);
    formData.append("job_title", jobTitle);
    formData.append("qr_code_url", qrCode);

    if (uploadLogo) {
      formData.append("logo", uploadLogo);
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
    };

    const response = await fetch(
      "/api/add/quick-apply/sponsorship",
      requestOptions
    );

    await response.text();

    toast.success("successfully!");

    this.setState({ isLoading: false, isToggle: false });
  };

  handleChangeEditor = (value) => {
    this.setState({ jobDescription: value });
  };

  render() {
    const {
      isToggle,
      previewLogo,
      customField,
      qrCodeValue,
      qrCode,
      isLoading,
      jobDescription,
      customValidation,
      descriptionValidation,
      jobTitleId,
    } = this.state;
    const { companyInfo, jobs } = this.props;

    console.log("jobs", jobs);

    return (
      <>
        <Toaster position="top-right" reverseOrder={false} />
        {isToggle && (
          <Modal closeModal={this.handleToggle}>
            {isLoading ? (
              <Loading />
            ) : jobs.length === 0 ? (
              <div className="career-model">
                <h1 className="text-center">Configuration</h1>
                <h2 className="text-center-imp center">No jobs available</h2>
              </div>
            ) : (
              <form onSubmit={this.handleSubmit}>
                <div className="career-model">
                  <h1 className="text-center">Configuration</h1>
                  <div className="career-input">
                    <label htmlFor="">Company Name</label>
                    <input type="text" disabled value={companyInfo.name} />
                  </div>
                  <div className="career-input">
                    <label htmlFor="">Job title</label>
                    <div className="configuration-title">
                      <select
                        defaultValue={jobTitleId}
                        onChange={this.handleSelectChange}
                      >
                        {jobs.map((item) => (
                          <option key={item.id} value={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </select>
                    </div>
                    {/* <input
                      type="text"
                      disabled
                      value={
                        ""
                        // jobs.length !== 0 ? jobs[0].CC_sponsorship.title : ""
                      }
                    /> */}
                  </div>
                  <div className="career-input">
                    <label htmlFor="">Job description</label>
                    <div className="description">
                      {/* <textarea
                        name=""
                        id=""
                        rows="5"
                        value={jobDescription}
                        onChange={this.handleChangeDescription}
                      ></textarea> */}

                      <ReactQuill
                        value={jobDescription}
                        theme="snow"
                        onChange={this.handleChangeEditor}
                        modules={this.modules}
                        formats={this.formats}
                      />

                      {descriptionValidation && (
                        <div>
                          <span className="color-red">
                            This field is required.
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="career-input">
                    <label htmlFor="">URL</label>
                    <div>
                      <input
                        type="text"
                        placeholder="URL"
                        value={qrCodeValue}
                        onChange={this.handleQRCodeChange}
                      />
                      <button
                        type="button"
                        className="qr-btn"
                        onClick={this.handleGenerateQRCode}
                      >
                        Generate QR code
                      </button>
                    </div>
                  </div>
                  <div className="career-input-d">
                    <div className="conta">
                      <div className="preview-box">
                        <div className="preview-logo">
                          <img src={previewLogo} alt="" />
                        </div>
                        <div className="text-center">
                          <input
                            hidden
                            id="uploadImage"
                            type="file"
                            onChange={this.handleChange}
                          />
                          <button type="button">
                            <label htmlFor="uploadImage">Upload Logo</label>
                          </button>
                        </div>
                      </div>
                      {qrCode && (
                        <div>
                          <div className="qr-code-box">
                            <QRCode ref={this.qrCodeRef} value={qrCode} />
                          </div>
                          <div className="text-center">
                            <button
                              type="button"
                              onClick={this.handleDownloadQRCode}
                            >
                              Download
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {customField.map((item, index) => {
                    return (
                      <div key={index} className="custom-field">
                        <div className="box">
                          <div className="career-input opacity">
                            <label htmlFor="">Custom field {index + 1}</label>
                            <input
                              type="text"
                              value={item.value}
                              onChange={(e) =>
                                this.handleCustomChange(e, index)
                              }
                            />
                            <span className="color-red">
                              {customValidation.value[index]
                                ? customValidation.value[index]
                                : ""}
                            </span>
                          </div>

                          <div
                            className="close-icon"
                            onClick={() => this.handleDeleteCustomField(index)}
                          >
                            <img src={closeIcon} alt="" />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <button type="button" onClick={this.handleAddCustomField}>
                    Add Field
                  </button>
                  <div className="submit-form">
                    <button className="submitbtncstm" type="submit">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Modal>
        )}
        <button onClick={this.handleToggle}>Configuration</button>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { userInfo, companyInfo } = state.products;

  return {
    userInfo,
    companyInfo,
  };
};

export default connect(mapStateToProps)(ConfigurationModel);
