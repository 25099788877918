import React, { Component } from "react";
import Loading from "../ui/Loading";
import resumeIcon from "../../assets/images/resume.png";

class JobsSiteOuter extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const {
      application,
      sendEmail,
      idUpdating,
      children,
      markAsReviewed,
    } = this.props;

    return (
      <div>
        <div className="jobInfoUpper">
          <div>
            {idUpdating === application.id ? (
              <Loading />
            ) : (
              !application.finish_email_sent && (
                <div className="topbuts email">
                  <button onClick={sendEmail}>
                    SEND EMAIL WITH LINK TO APPLY ON ONEPOINT
                  </button>
                </div>
              )
            )}
            {application.resume_url ? (
              <div className="topbuts resume">
                <a
                  href={application.resume_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={resumeIcon} alt="resume" /> <span>View Resume</span>
                </a>
              </div>
            ) : (
              <div className="topbuts resume none">
                <span>No resume file was sent</span>
              </div>
            )}
          </div>
          <div className="ur">
            {application.user_reviewed ? (
              <div className="revd">&#10003; Reviewed</div>
            ) : (
              <div>
                <button onClick={markAsReviewed}>Mark as reviewed</button>
              </div>
            )}
          </div>
        </div>
        {children}
      </div>
    );
  }
}

export default JobsSiteOuter;
