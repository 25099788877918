/* eslint-disable no-undef */

import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BaseIntegration from "../components/BaseIntegration";
import { saveSettings, disconnectIntegration } from "../actions/integrations";

class IntegrationsContainer extends Component {
  saveSettings(newSettings, intURL) {
    const { dispatch } = this.props;

    dispatch(saveSettings(newSettings, intURL));
  }

  disconnectIntegration(intURL) {
    const { dispatch } = this.props;

    dispatch(disconnectIntegration(intURL));
  }

  render() {
    return (
      <BaseIntegration
        {...this.props}
        saveSettings={this.saveSettings.bind(this)}
        disconnectIntegration={this.disconnectIntegration.bind(this)}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    isDisconnecting: intsAreDisconnecting,
    settingsAreSaving,
    error,
  } = state.integrations;
  const { list: productList, isFetching: intsAreFetching } = state.products;
  const { integrations, products } = state.entities;
  const { match } = ownProps;

  const topicURL = match.params.topicURL;

  let topicData =
    integrations[topicURL] && Object.assign(integrations[topicURL], {});

  if (productList !== undefined) {
    for (var i = 0; i < productList.length; i++) {
      const productURL = productList[i];
      const product = products[productURL];
      const prodInts = product.integrations;

      for (var x = 0; x < prodInts.length; x++) {
        const intURL = prodInts[x];
        if (intURL === topicURL) {
          topicData.productURL = productURL;
        }
      }
    }
  }

  return {
    intsAreFetching,
    topicData,
    topicURL,
    intsAreDisconnecting,
    settingsAreSaving,
    error,
  };
};

export default withRouter(connect(mapStateToProps)(IntegrationsContainer));
