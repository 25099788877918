import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import queryString from "query-string";

import {
  fetchApplicants,
  sendQuestionnaire,
  createApplicant,
} from "../actions/wotc";

import Loading from "./ui/Loading";
import Modal from "./ui/Modal";
import FormContainer from "../containers/FormContainer";

import "../styles/WOTCBody.css";

class WOTCBody extends Component {
  constructor(props) {
    super(props);
    this.state = { formModal: null };
    this.sendQuestionnaire = this.sendQuestionnaire.bind(this);
    this.createApplicant = this.createApplicant.bind(this);
    this.launchForm = this.launchForm.bind(this);
    this.createWOTCEmployeeFromApplicant =
      this.createWOTCEmployeeFromApplicant.bind(this);
  }

  componentWillMount() {
    this.props.dispatch(fetchApplicants());
  }

  sendQuestionnaire(appID) {
    this.props.dispatch(sendQuestionnaire(appID));
  }

  createApplicant(app) {
    this.props.dispatch(createApplicant(app));
  }

  launchForm(applicantID, formID) {
    const { location, history } = this.props;

    if (!formID) {
      return;
    }

    history.push(
      `${location.pathname}?form=${formID}&applicant=${applicantID}`
    );
  }

  createWOTCEmployeeFromApplicant(applicant) {
    console.log(applicant);
  }

  render() {
    const { applicants, loading, success, location, history } = this.props;

    const parsedURLQuery = queryString.parse(location.search);
    const formIDParam = parsedURLQuery["form"];
    const applicantIDParam = parsedURLQuery["applicant"];

    return (
      <div className="WOTCBody">
        {formIDParam && applicantIDParam && (
          <Modal closeModal={() => history.push(location.pathname)}>
            <div>
              <FormContainer
                applicantID={applicantIDParam}
                formURL={"wotc-questionnaire"}
              />
            </div>
          </Modal>
        )}
        <div className="section">
          <h2>
            Applicants{" "}
            <button onClick={() => this.props.dispatch(fetchApplicants())}>
              REFRESH
            </button>
          </h2>
          <div className="tableCont">
            {applicants && applicants.length === 0 ? (
              <div className="noapps">No applicants yet</div>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th className="col1">Name</th>
                    <th className="col2">Email</th>
                    <th className="col4">Questionnaire Form</th>
                    <th className="col6">Sent to Applicant</th>
                    <th className="col3">Questionnaire Status</th>
                    <th className="col5">Provisionally Eligible</th>
                  </tr>
                </thead>
                <tbody>
                  {applicants.map((app) => (
                    <tr className="appNode" key={"eqdq331" + app.id}>
                      <td className="col1">
                        {app.first_name} {app.last_name}
                      </td>
                      <td className="col2">{app.email}</td>
                      <td className="col4">
                        {app.form_id && (
                          <button
                            onClick={() => this.launchForm(app.id, app.form_id)}
                          >
                            View/Edit
                          </button>
                        )}
                      </td>
                      <td className="col6">
                        {app.questionnaire_sent ? (
                          "Sent"
                        ) : (
                          <button
                            onClick={() => this.sendQuestionnaire(app.id)}
                          >
                            Email Link
                          </button>
                        )}
                      </td>
                      <td className="col3">
                        {app.questionnaire_completed
                          ? "Complete ✅"
                          : "Incomplete"}
                      </td>
                      <td className="col5">
                        {app.questionnaire_completed ? (
                          app.wotc_eligible ? (
                            <span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              {"YES ✅"}
                              {!app.wotc_employee_id && (
                                <button
                                  style={{ marginLeft: "5px" }}
                                  onClick={() =>
                                    this.createWOTCEmployeeFromApplicant(app)
                                  }
                                >
                                  HIRED?
                                </button>
                              )}
                            </span>
                          ) : (
                            "NO 🚫"
                          )
                        ) : (
                          "N/A"
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          <div>
            <CreateApplicant
              loading={loading.newApplicant}
              create={this.createApplicant}
              success={success.newApplicant}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { entities, wotc } = state;
  const { wotc_applicants } = entities;
  const { list: applicantIDList, loading, success } = wotc;

  const applicants =
    !applicantIDList || !wotc_applicants
      ? []
      : applicantIDList.map((id) => {
          return wotc_applicants[id];
        });

  return {
    applicants,
    loading,
    success,
  };
};

class CreateApplicant extends Component {
  constructor(p) {
    super(p);
    this.state = {
      expanded: false,
      error: null,
      data: {
        first_name: "",
        last_name: "",
        mi: "",
        email: "",
        phone_number: "",
      },
    };
    this.changeData = this.changeData.bind(this);
    this.create = this.create.bind(this);
  }

  componentWillReceiveProps(np) {
    const { success } = this.props;
    const { success: newSuccess } = np;
    if (!success && newSuccess) {
      this.setState({
        expanded: false,
        data: {
          first_name: "",
          last_name: "",
          mi: "",
          email: "",
          phone_number: "",
        },
      });
    }
  }

  changeData(field, newVal) {
    const { data } = this.state;
    this.setState({ data: { ...data, [field]: newVal }, error: null });
  }

  create() {
    const { data } = this.state;
    if (data.first_name === "") {
      this.setState({ error: "First Name is required." });
      return;
    }
    if (data.last_name === "") {
      this.setState({ error: "Last Name is required." });
      return;
    }
    if (data.email === "") {
      this.setState({ error: "Email is required." });
      return;
    }

    this.props.create(data);
  }

  render() {
    const { expanded, data, error } = this.state;
    const { loading } = this.props;

    return (
      <div className="CreateApplicant">
        {!expanded && (
          <div className="toggle">
            <button onClick={() => this.setState({ expanded: true })}>
              Add New Applicant
            </button>
          </div>
        )}
        {expanded && (
          <div className="form">
            {loading && (
              <div className="loading">
                <Loading />
              </div>
            )}
            <div className="title">Add New Applicant</div>
            {error && <div className="error">{error}</div>}
            <div className="fields">
              <div className="field">
                <label>First Name:</label>
                <input
                  type="text"
                  autoFocus="true"
                  value={data.first_name}
                  onChange={(e) =>
                    this.changeData("first_name", e.target.value)
                  }
                ></input>
              </div>
              <div className="field">
                <label>Last Name:</label>
                <input
                  type="text"
                  value={data.last_name}
                  onChange={(e) => this.changeData("last_name", e.target.value)}
                ></input>
              </div>
              <div className="field">
                <label>Middle Initial:</label>
                <input
                  type="text"
                  value={data.mi}
                  onChange={(e) => this.changeData("mi", e.target.value)}
                ></input>
              </div>
              <div className="field">
                <label>Email:</label>
                <input
                  type="text"
                  value={data.email}
                  onChange={(e) => this.changeData("email", e.target.value)}
                ></input>
              </div>
              <div className="field">
                <label>Phone:</label>
                <input
                  type="text"
                  value={data.phone_number}
                  onChange={(e) =>
                    this.changeData("phone_number", e.target.value)
                  }
                ></input>
              </div>
            </div>
            <div className="submit">
              <button
                className="cancel"
                onClick={() => this.setState({ expanded: false })}
              >
                CANCEL
              </button>
              <button onClick={() => this.create()}>CREATE</button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(connect(mapStateToProps)(WOTCBody));
