import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Loading from "../ui/Loading";
import Swal from "sweetalert2";
import {
  deleteImportOptionByIdSFTP,
  getImportOptionSFTP,
} from "../../actions/customReport";
import { oldAppPath } from "../../constants/constant";

const ExportsBody = (props) => {
  // destructure props values
  const { dispatch, history, userInfo } = props;
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // get SFTP configuration data
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const { response } = await dispatch(getImportOptionSFTP());
      if (response) {
        setRowData(Object.values(response));
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // delete SFTP configuration data
  const handleDeleteImportRecord = (id) => async () => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete",
      });
      if (result.isConfirmed) {
        setIsLoading(true);
        const response = await dispatch(deleteImportOptionByIdSFTP(id));
        console.log("response", response);
        fetchData();

        Swal.fire("Deleted", "Your record has been deleted.", "success");
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  return (
    <div>
      {isLoading && (
        <div className="loadCont">
          <Loading />
        </div>
      )}

      <div className="integration-table">
        <div className="header-action">
          <Link to={`${oldAppPath}/exports/data`}>
            <button className="cstmbtn1 bg-theme ms-10-px">ADD</button>
          </Link>
        </div>
      </div>
      <table className="table tablecstm">
        <thead>
          <tr>
            <th className="col1">S.NO</th>
            <th className="col5">Type name</th>
            <th className="col2">Event</th>
            <th className="col3">Host</th>
            <th className="col4">Port</th>
            <th className="col4">username</th>
            <th className="col5">File path</th>
            <th className="col5">Actions</th>
          </tr>
        </thead>
        <tbody>
          {rowData.map(
            (
              { id, event, host, port, username, remote_file_path, type_name },
              index
            ) => (
              <tr key={id}>
                <td>{index + 1}</td>
                <td>{type_name}</td>
                <td>{event}</td>
                <td>{host}</td>
                <td>{port}</td>
                <td>{username}</td>
                <td>{remote_file_path}</td>
                <td>
                  <div className="text-center">
                    <button
                      className="me-10-px"
                      title="View Logs"
                      onClick={() =>
                        history.push(
                          `${oldAppPath}/exports/logs/import_report/${id}/${userInfo.company_id}`
                        )
                      }
                    >
                      <FontAwesomeIcon icon={solid("clock-rotate-left")} />
                    </button>
                    <button
                      className="me-10-px"
                      title="Edit"
                      onClick={() =>
                        history.push(`${oldAppPath}/exports/data/${id}`)
                      }
                    >
                      <FontAwesomeIcon icon={solid("pen-to-square")} />
                    </button>
                    <button
                      title="Delete"
                      onClick={handleDeleteImportRecord(id)}
                    >
                      <FontAwesomeIcon icon={solid("trash-alt")} />
                    </button>
                  </div>
                </td>
              </tr>
            )
          )}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { userInfo: state.products.userInfo };
};

export default withRouter(connect(mapStateToProps)(ExportsBody));
