import React, { useEffect, useState } from "react";
import useToggle from "../../hooks/useToggle";
import { connect } from "react-redux";
import Loading from "../../../components/ui/Loading";
import { getToken } from "../../../actions/auth";
import { validateEmail } from "../../../utils/utils";
import { isEmpty } from "lodash";
import HideShow from "../../../components/ui/HideShow";

const EmployeeEmailAdd = (props) => {
  const { handleUpdateSettings, value, employees, setEmployees } = props;
  const [isFetching, setIsFetching] = useState(false);
  const [selectEmail, setSelectEmail] = useState("");
  const [inputEmail, setInputEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [empError, setEmpError] = useState("");
  const { token, userToken } = getToken();

  const fetchEmployees = async () => {
    try {
      setIsFetching(true);
      const response = await fetch(`/api/company/employees`, {
        headers: {
          Opusertoken: userToken,
          Authorization: token,
        },
      });
      const { data } = await response.json();
      if (Array.isArray(data.values)) {
        setEmployees(data.values);
      }

      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);
      console.log("error", error);
    }
  };

  const handleSelectChange = (e) => {
    const { value } = e.target;
    setSelectEmail(value);
  };

  const handleInputChange = (e) => {
    const { value } = e.target;
    setInputEmail(value);
  };

  const handleAddEmail = () => {
    if (validateEmail(inputEmail)) {
      const dataArray = [...value, { type: "email", address: inputEmail }];
      handleUpdateSettings(dataArray);
      setEmailError("");
    } else {
      setEmailError("Please enter valid email!");
    }
  };

  const handleAddSelect = () => {
    const selectedValue = employees.find((emp) => emp.name === selectEmail);
    const existValue = value.find((emp) => emp.name === selectEmail);
    if (selectedValue && !existValue) {
      const dataArray = [...value, selectedValue];
      handleUpdateSettings(dataArray);
    } else if (!selectedValue) {
      setEmpError("Please select employee");
    } else {
      setEmpError("Employee already exists!");
    }
  };

  useEffect(() => {
    if (employees.length === 0) {
      fetchEmployees();
    }
  }, []);

  if (isFetching) {
    return <Loading />;
  }

  return (
    <div className="my-flex addEmloyeeCustom">
      <div className="employees  one-col-12  pe-1">
        <div className="top ">
          <div className="label">Select Employee</div>
          <select
            className="mb-2 mt-1"
            value={selectEmail}
            onChange={handleSelectChange}
          >
            <option value="" disabled>
              Select Employee
            </option>
            {Array.isArray(employees) &&
              employees.map((item) => (
                <option key={item.name} value={item.name}>
                  {item.name}
                </option>
              ))}
          </select>
          <span className="color-red">{!isEmpty(empError) && empError}</span>
        </div>
        <div className="bot mb-2">
          <button className="m-0" onClick={handleAddSelect}>
            ADD EMPLOYEE
          </button>
        </div>
      </div>
      <div className="horizontalLinewithOr"><div className="hrLine"></div>or<div className="hrLine"></div></div>
      <div className="freeinput one-col-12">
        <div className="top">
          <div>
            <div className="label">Enter Email Address</div>
            <input
              type="text"
              value={inputEmail}
              onChange={handleInputChange}
              className="form-input mb-2 mt-1"
              placeholder="Enter Email Address"
            />
            <span className="color-red">
              {!isEmpty(emailError) && emailError}
            </span>
          </div>
        </div>
        <div className="bot">
          <div className="buttonCont mb-2">
            <button onClick={handleAddEmail}>ADD EMAIL</button>
          </div>
        </div>
      </div>
    </div>
  );
};

const NotificationEmailSetting = (props) => {
  const { value, handleUpdateSettings } = props;
  const [showSettings, handleShowSettings] = useToggle(false);
  const [employees, setEmployees] = useState([]);

  const handleRemoveEmployee = (id) => () => {
    const updatedValue = value.filter((item) => item.id !== id);
    handleUpdateSettings(updatedValue);
  };

  const handleRemoveEmail = (address) => () => {
    const updatedValue = value.filter((item) => item.address !== address);
    handleUpdateSettings(updatedValue);
  };

  return (
    <div className="product-setting-inner email-notification mb-2">
      <HideShow 
        label= {<label htmlFor="">Notification Email Recipients</label>}
        body = {
      <div>
        <p>
          Choose employees in your company you want to recieve notification emails
          when Users get provisioned. This email will contain a summary of which
          accounts got created/removed and will include passwords for new
          accounts.
        </p>
        <div className="activeEmps">
        {Array.isArray(value) &&
          value.map((item) => {
            if (item.type === "email") {
              return (
                <div key={item.address} className="nodeWithX">
                  {item.address}
                  <button onClick={handleRemoveEmail(item.address)}>X</button>
                </div>
              );
            } else {
              return (
                <div key={item.name} className="nodeWithX">
                  {item.name}
                  <button onClick={handleRemoveEmployee(item.id)}>X</button>
                </div>
              );
            }
          })}
        </div>
        {showSettings && (
        <EmployeeEmailAdd
          handleUpdateSettings={handleUpdateSettings}
          value={value}
          employees={employees}
          setEmployees={setEmployees}
        />
      )}

      <button className="m-0" onClick={handleShowSettings}>
        {showSettings ? "Close" : "ADD MORE"}
      </button>
      </div>
        }
      />
     
      

     
    </div>
  );
  
};

export default connect()(NotificationEmailSetting);
