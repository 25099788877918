import React, { Component } from 'react'

import SelectSetting from './SelectSetting'

import '../../styles/SettingComponents.css';

class IPRestrictionSetting extends Component {
  constructor(props) {
    super(props)
    this.state = { ipInput: [null, null, null, null] }

    this.removeIP = this.removeIP.bind(this)
    this.newIPInputChange = this.newIPInputChange.bind(this)
    this.addIP = this.addIP.bind(this)
  }

  removeIP(index, ip) {
    const { value, onChange } = this.props
    let { allowed_ips } = value
    allowed_ips.splice(index, 1)
    const newSetting = { ...value, allowed_ips }
    onChange(newSetting)
  }

  newIPInputChange(index, newVal) {
    let { ipInput } = this.state

    let newValInt = parseInt(newVal, 10);

    if (!isNaN(newValInt)) {
      if (newValInt >= 0 && newValInt <= 255) {
        ipInput[index] = newValInt
      }
    } else {
      ipInput[index] = null
    }

    this.setState({ ipInput })
  }

  addIP() {
    const { ipInput } = this.state

    let ipStr = ''
    let quit = false
    ipInput.forEach((ip, index) => {
      let ipNum = parseInt(ip, 10);
      if (!isNaN(ipNum)) {
        if (ipNum >= 0 && ipNum <= 255) {
          ipStr += ipNum
          if (index < 3) {
            ipStr += '.'
          }
        } else {
          quit = true
          return
        }
      } else {
        quit = true
        return
      }
    });

    if (quit) {
      return
    }

    const { value, onChange } = this.props
    const { allowed_ips } = value
    const newIps = [...allowed_ips, ipStr]
    const newSetting = { ...value, allowed_ips: newIps }
    onChange(newSetting)
    this.setState({ ipInput: [null, null, null, null] })
  }

  render() {
    const { value, onChange } = this.props
    const { ipInput } = this.state

    return (
      <div className="IPRestrictionSetting">
        <div className="top">
          <SelectSetting
            onChange={(e) => onChange({ ...value, allow_all: e.target.value === 'yes' ? false : true })}
            label={'Restrict to Selected IP Addresses:'}
            value={value.allow_all ? 'no' : 'yes'}
            options={
              [
                {
                  label: 'Yes',
                  value: 'yes'
                },
                {
                  label: 'No (allow punches from any location)',
                  value: 'no'
                }
              ]
            }
          />
        </div>
        {
          !value.allow_all
          &&
          (
            <div className="bottom">
              {
                (!value.allowed_ips || value.allowed_ips.length === 0)
                &&
                (
                  <span style={{ margin: '10px', fontStyle: 'italic' }} className="list nodeWithXCont">
                    You have not added any IP addresses
                  </span>
                )
              }
              {
                value.allowed_ips && value.allowed_ips.length > 0
                &&
                (
                  <div className="list nodeWithXCont">
                    {
                      value.allowed_ips.map((ip, i) => (
                        <div className="nodeWithX" key={ip}>
                          {ip}
                          <button onClick={() => this.removeIP(i, ip)}>
                            X
                          </button>
                        </div>
                      ))
                    }
                  </div>
                )
              }
              <div className="addMore">
                <span className="label">Add IP Address:</span>
                <span className="inputs">
                  {
                    ipInput.map((ip, index) => {
                      return <input key={'input' + index} value={ip !== null ? ip : ''} onChange={(e) => this.newIPInputChange(index, e.target.value)} type="text" />
                    })
                  }
                </span>
                <span className="but">
                  <button onClick={() => this.addIP()}>
                    ADD
                  </button>
                </span>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}

export default IPRestrictionSetting
