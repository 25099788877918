import React, { Component } from "react";
import { CSVLink } from "react-csv";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";
import { Chart } from "react-google-charts";

// eslint-disable-next-line
import "../../styles/customReportStyle.css";
import customerIcon from "../../assets/images/customers.png";
import Loading from "../ui/Loading";
import companyIcon from "../../assets/images/profit.png";
import ClickAwayListener from "../../utils/ClickAwayListener";

const dataChartStatic = [
  ["Task", "Hours per Day"],
  // ["Retina Associates of Utah", 11],
  // ["Eat", 2],
  // ["Commute", 2],
  // ["Watch TV", 2],
  // ["Sleep", 7],
];

const optionsChart = {
  is3D: true,
};

// RAUTAH

class DefaultReport extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      employeeList: [],
      employeeListCopy: [],
      employeeListCopyOr: [],
      companies: [],
      rowPerPage: 10,
      page: 0,
      totalPage: 0,
      dataChart: dataChartStatic,
      isLoading: false,
      companiesList: [],
      showExport: false,
      filterCompany: "all",
      tableHeader: [],
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });
    const search = this.props.location.search;
    const params = new URLSearchParams(search).get("shortname");
    const paramsId = new URLSearchParams(search).get("id");
    const { rowPerPage } = this.state;

    let endPoint;
    let res;

    if (paramsId) {
      endPoint = `/api/tlm/get/association/reports`;
      res = await fetch(endPoint, { headers: { id: paramsId } });
    } else {
      endPoint = `/api/tlm/get/employees/reports?shortnames=${params}`;
      res = await fetch(endPoint);
    }

    let result = await res.json();

    let companyData = { Task: "Hours per Day" };

    if (result) {
      // result.forEach((element) => {
      //   if (!companyData[element.EmployeeEIN]) {
      //     companyData[element.EmployeeEIN] = 1;
      //   } else {
      //     companyData[element.EmployeeEIN] =
      //       companyData[element.EmployeeEIN] + 1;
      //   }
      // });

      const tableHeader = Object.keys(result[0]);
      // console.log("tableHeader", tableHeader);
      result = result.slice(1);

      const totalPage = Math.ceil(result.length / rowPerPage - 1);

      const companiesList = Object.keys(companyData);
      companiesList.shift();

      this.setState({
        employeeList: result.slice(0, rowPerPage),
        employeeListCopy: result,
        employeeListCopyOr: result,
        companies: params ? params.split(",") : [],
        totalPage,
        dataChart: Object.entries(companyData),
        companiesList,
        tableHeader,
      });
    }

    this.setState({
      isLoading: false,
    });
  }

  handleSelectChange = (e) => {
    const value = parseInt(e.target.value);
    const { employeeListCopy } = this.state;

    const totalPage = Math.ceil(employeeListCopy.length / value - 1);

    this.setState({
      rowPerPage: value,
      employeeList: employeeListCopy.slice(0, value),
      totalPage,
      page: 0,
    });
  };

  handleFilterChange = (e) => {
    const { value } = e.target;
    const { employeeListCopyOr, rowPerPage } = this.state;

    if (value !== "all") {
      let dataFilter = employeeListCopyOr.filter((item) => {
        // eslint-disable-next-line
        return item.EmployeeEIN == value;
      });

      const totalPage = Math.ceil(dataFilter.length / rowPerPage - 1);

      this.setState({
        employeeList: dataFilter.slice(0, rowPerPage),
        employeeListCopy: dataFilter,
        totalPage,
        page: 0,
      });
    } else {
      const totalPage = Math.ceil(employeeListCopyOr.length / rowPerPage - 1);

      this.setState({
        employeeList: employeeListCopyOr.slice(0, rowPerPage),
        employeeListCopy: employeeListCopyOr,
        totalPage,
        page: 0,
      });
    }

    this.setState({ filterCompany: value });
  };

  handleNext = () => {
    const { rowPerPage, page, employeeListCopy } = this.state;

    const start = (page + 1) * rowPerPage;
    const end = (page + 1) * rowPerPage + rowPerPage;

    this.setState({
      page: page + 1,
      employeeList: employeeListCopy.slice(start, end),
    });
  };

  handlePrev = () => {
    const { rowPerPage, page, employeeListCopy } = this.state;

    this.setState({
      page: page - 1,
      employeeList: employeeListCopy.slice(
        page * rowPerPage,
        page * rowPerPage + rowPerPage
      ),
    });
  };

  toggleDropDown = () => {
    this.setState({ showExport: !this.state.showExport });
  };

  handleExportExcel = async () => {
    // CSVLink;

    function getSheetData(data, header) {
      var fields = Object.keys(data[0]);
      var sheetData = data.map(function (row) {
        return fields.map(function (fieldName) {
          return row[fieldName] ? row[fieldName] : "";
        });
      });
      sheetData.unshift(header);
      return sheetData;
    }

    const dataAPI = this.state.employeeListCopy;

    const header = [
      "EmployeeId",
      "EmployeeEIN",
      "First Name",
      "Last Name",
      "Department",
      "Location",
      "Line Type",
      "Amount",
      "Raw Total",
      "Earning Deduction Tax Name",
      "Misc Functions",
    ];

    const workbook = await XlsxPopulate.fromBlankAsync();

    const sheet1 = workbook.sheet(0);
    const sheetData = getSheetData(dataAPI, header);
    const totalColumns = sheetData[0].length;

    sheet1.cell("A1").value(sheetData);
    const range = sheet1.usedRange();
    const endColumn = String.fromCharCode(64 + totalColumns);
    sheet1.row(1).style("bold", true);
    sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
    range.style("border", true);

    const workBookRes = await workbook.outputAsync();
    saveAs(workBookRes, "report.xlsx");
  };

  render() {
    const {
      employeeList,
      companies,
      employeeListCopy,
      rowPerPage,
      page,
      dataChart,
      isLoading,
      showExport,
      totalPage,
      tableHeader,
    } = this.state;

    console.log("tableHeader", tableHeader);
    console.log("employeeList", employeeList);

    return (
      <div className="ein-report dashboard-report-emp ">
        <div className="heading">
          <h1>Company Association Employees Report</h1>
        </div>
        <div className="report-container" ref={this.chartRef}>
          <div className="report-card-row">
            <div className="report-card">
              <div className="item-card">
                <div className="icon employee-icon">
                  <img src={customerIcon} alt="customerIcon" />
                </div>
                <div>
                  <h5>Total employees</h5>
                  <h2>{employeeListCopy.length}</h2>
                  <p>Association total employees</p>
                </div>
              </div>
            </div>
            <div className="report-card">
              <div className="item-card">
                <div className="icon company-icon">
                  <img src={companyIcon} alt="customerIcon" />
                </div>
                <div>
                  <h5>Total companies</h5>
                  <h2>{companies.length}</h2>
                  <p>Association total companies</p>
                </div>
              </div>
            </div>
            <div className="report-card">
              <div className="item-card graph-item">
                <Chart
                  chartType="PieChart"
                  data={dataChart}
                  loader={<div>Loading Data...</div>}
                  options={optionsChart}
                  width={"100%"}
                  height={"195px"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="report-container">
          {/* <div className="report-table">
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>EmployeeId</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                  </tr>
                </thead>
                <tbody>
                  {employeeList.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}.</td>
                      <td>{item.EmployeeId}</td>
                      <td>{item.FirstName}</td>
                      <td>{item.LastName}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="pagination">
              <div className="pagination-item">
                <span>1 - 79 / 79</span>
                <span> &lt;</span>
              </div>
            </div>
          </div> */}
          <div className="export-btn-container">
            <div className="option-container">
              {/* <div>
                <select
                  value={filterCompany}
                  onChange={this.handleFilterChange}
                >
                  <option value="all">All</option>
                  {companiesList.map((item) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div> */}
              <div className="btn-export" onClick={this.toggleDropDown}>
                <button>Export</button>
              </div>
            </div>
            {showExport && (
              <ClickAwayListener onClickAway={this.toggleDropDown}>
                <ul className="dropdown-list">
                  <li>
                    <CSVLink
                      filename={"MultipleEIN-Report.csv"}
                      data={employeeListCopy}
                    >
                      CSV
                    </CSVLink>
                  </li>
                  <li onClick={this.handleExportExcel}>Excel</li>
                  {/* <li>
                    <PDFDownloadLink
                      document={
                        <Template
                          employeeList={employeeList}
                          chartRef={this.chartRef}
                        />
                      }
                      fileName="report.pdf"
                    >
                      {({ loading }) => (loading ? "Loading.." : "PDF")}
                    </PDFDownloadLink>
                  </li> */}
                </ul>
              </ClickAwayListener>
            )}
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div className="table-report">
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      {Array.isArray(tableHeader) &&
                        tableHeader.map((item) => <th>{item}</th>)}
                      {/* <th>EmployeeId</th>
                      <th>EmployeeEIN</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Department</th>
                      <th>Location</th>
                      <th>LineType</th>
                      <th>Amount</th>
                      <th>RawTotal</th>
                      <th>EarningDeductionTaxName</th>
                      <th>MiscFunctions</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(employeeList) &&
                      employeeList.map((item, index) => (
                        <tr key={index}>
                          <td>{page * rowPerPage + index + 1}</td>
                          {Object.values(item).map((elem) => (
                            <td>{elem ? elem : "N/A"}</td>
                          ))}
                          {/* <td>{index + 1 + rowPerPage * page}.</td>
                          <td>{item.EmployeeId}</td>
                          <td>{item.EmployeeEIN}</td>
                          <td>{item.FirstName}</td>
                          <td>{item.LastName}</td>
                          <td>{item.Department ? item.Department : "N/A"}</td>
                          <td>{item.Location ? item.Location : "N/A"}</td>
                          <td>{item.LineType}</td>
                          <td>{item.Amount}</td>
                          <td>{item.RawTotal}</td>
                          <td>{item.EarningDeductionTaxName}</td>
                          <td>
                            {item.MiscFunctions ? item.MiscFunctions : "N/A"}
                          </td> */}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </>
          )}

          <div className="report-pagination">
            <label htmlFor="">Rows per page:</label>
            <select onChange={this.handleSelectChange} value={rowPerPage}>
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
              <option value={500}>500</option>
              <option value={1000}>1000</option>
            </select>
            <span>
              {page * rowPerPage + 1} – {page * rowPerPage + rowPerPage} of{" "}
              {employeeListCopy.length}
            </span>
            <button disabled={page === 0} onClick={this.handlePrev}>
              Prev
            </button>
            <button disabled={page === totalPage} onClick={this.handleNext}>
              Next
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default DefaultReport;
